import {ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers/index';
import {FeatureSelectors, MessageActions, MessageSelectors, NaturalPersonSelectors, OrganizationActions, OrganizationLogoActions, OrganizationLogoSelectors, OrganizationSelectors, UserAvatarActions} from 'app/+store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {User} from 'app/models/user';
import {environment} from 'environments/environment';
import {WebsocketService} from 'app/services/websocket.service';
import {IFoxdoxStatus} from 'app/shared/modules/api/models/foxdoxStatus.interface';
import {NaturalPerson} from 'app/models/natural-person.model';
import {MessageBuilder} from 'app/+store/message/message.builder';
import {distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {LicenceControlService} from 'app/services/licence-control.service';
import {TranslateService} from '@ngx-translate/core';
import {Organization} from 'app/models/organization.model';
import {Router} from '@angular/router';
import {CmsBlogActions, CmsBlogSelectors} from 'app/+store/cms-blog';
import {CmsBlog} from 'app/+store/cms-blog/cms-blog';
import {Feature} from 'app/+store/feature/feature';
import {IOrganizationLogo} from 'app/+store/organization-logo/organization-logo.interface';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {VersionCheckService} from 'app/services/version-check.service';
import {UserLogoutRequest} from '../../../../../actions/user-actions';

@Component({
  selector: 'dvtx-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})

export class PageHeaderComponent implements OnDestroy, OnInit {
  private onDestroy: any = new Subject();

  @Input() userSessionEnabled = true;
  public versionChangeNotify$: Observable<boolean>;

  showDropdown = false;

  /**
   * Current user.
   * Required to setup all session based menus.
   */
  public user: User;

  private _currentUser$: Observable<User>;

  userName: string;
  person$: Observable<NaturalPerson>;

  @Output() themeChange = new EventEmitter<any>();

  public foxdoxLink: string;
  public dmsStatus$: Observable<IFoxdoxStatus>;
  public homeLink: string;
  public currentOrganization$: Observable<Organization>;

  public unreadCount$: Observable<number>;
  private notificationSubscription;

  public selectedOrganization;

  // public organizations: Observable<Organization[]>;
  public blog$: Observable<CmsBlog>;
  public featureSet$: Observable<Feature>;

  @ViewChild('mainHeader', {static: true}) el: ElementRef;
  public searchForm: UntypedFormGroup;

  @Input()
  public error;

  public logo$: Observable<IOrganizationLogo>;

  private _logoLoaded = false;
  public smallScreen: boolean;

  @Input()
  private addPaddingToAppContainer = true;

  constructor(private _store: Store<AppState>,
              private _ws: WebsocketService,
              private licenceControl: LicenceControlService,
              private dialog: MatDialog,
              private _translateSvc: TranslateService,
              private _router: Router,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone,
              private _fb: UntypedFormBuilder,
              private _versionCheckSvc: VersionCheckService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.smallScreen = window.innerWidth < 800 ? true : false;
  }

  ngOnInit(): void {
    // make sure add padding top on app when page header is displayed (used because of the login different themes)
    const pageWrapper: any = document.querySelector('.fivef-app--wrapper');
    if (this.addPaddingToAppContainer && pageWrapper && pageWrapper.style) {
      pageWrapper.style['padding-top'] = '75px'
    }
    
    this.versionChangeNotify$ = this._versionCheckSvc.versionChangeNotify;
    this.smallScreen = window.innerWidth < 800 ? true : false;
    // Skip all user session based variables if used in session container.
    if (!this.userSessionEnabled) {
      this.homeLink = '/session/sign-in';
      return;
    }

    this.searchForm = this._fb.group({
      searchTerm: ['']
    });

    this.currentOrganization$ = this._store.select(OrganizationSelectors.getSelected);
    this.blog$ = this._store.select(CmsBlogSelectors.getCmsBlogOfSelectedOrg);
    this.logo$ = this._store.select(OrganizationLogoSelectors.getCurrentLogo);
    this.dmsStatus$ = this._store.select('foxdoxStatus');
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this._currentUser$ = this._store.select('currentUser');
    this.person$ = this._store.select(NaturalPersonSelectors.getUsersNaturalPerson);

    this._fetchOrganizationBlog();
    this._fetchOrganizationLogo();

    this._currentUser$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        this.user = user;

        if (user) {
          this.homeLink = '/dashboard';
          this._fetchPageHeaderData(user);
          this._initFoxdoxLink(user);
          this.connectNotificationChannel(user);

          this.unreadCount$ = combineLatest(
            this._store.select(MessageSelectors.unreadCount),
            this._store.select(MessageSelectors.invitationCount),
            (unreadCount, invitationCount) => {
              const unread = unreadCount + invitationCount;
              return unread > 0 ? unread : null;
            }
          );

        } else {
          this.homeLink = '/session/sign-in';
          // unsubscribe if user is resetted.
          this.selectedOrganization = null;
          this.destroyNotificationChannel();
        }
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    if (this.userSessionEnabled) {
      this.destroyNotificationChannel();
    }
  }

  /**
   * Global header setup
   * Currently unused.
   * ngAfterViewInit() {
   *   this._store.dispatch(new GetMainHeaderHeight(this.el.nativeElement.offsetHeight));
   *   this.screen$ = this._store.select('screen')
   *     .pipe(
   *       takeUntil(this.onDestroy)
   *     ).subscribe(() => {
   *       this._store.dispatch(new GetMainHeaderHeight(this.el.nativeElement.offsetHeight));
   *     });
   * }
   */

  private connectNotificationChannel(user) {
    this._ngZone.runOutsideAngular(() => {
      if (this.notificationSubscription) {
        return;
      }
      this.notificationSubscription = this._ws.subscribe(
        user,
        'Message::UpdateChannel',
        'follow',
        {user_id: user.id},
        (res) => {
          this._store.dispatch(new MessageActions.LoadMessageStats());
          if (res.data) {
            const builder = new MessageBuilder();
            const message = builder.fromResponse(res.data);
            this._store.dispatch(new MessageActions.SaveMessageSuccess(message));
          }
        });
    });
  }

  private destroyNotificationChannel() {
    if (this.notificationSubscription) {
      const channel = this.notificationSubscription;
      try {
        channel.unsubscribe();
      } catch (error) {
        console.error(error);
      }
      this.notificationSubscription = null;
    }
  }

  public handleOrganizationClick(id: string) {
    const licenceChecker = this.licenceControl.hasRequiredLicence('Standard');
    if (licenceChecker) {
      this._store.dispatch(new OrganizationActions.Select(id));
      this._router.navigate([`/organization/${id}/navigation`]);
    }
  }

  public handleOrganizationAddressbookClick(id: string) {
    const licenceChecker = this.licenceControl.hasRequiredLicence('Standard');
    if (licenceChecker) {
      this._store.dispatch(new OrganizationActions.SelectAddressBook(id));
    }
  }

  public signOut(): void {
    this._store.dispatch(new UserLogoutRequest());
  }

  private _fetchOrganizationBlog() {
    this._ngZone.runOutsideAngular(() => {
      this._store.select(OrganizationSelectors.getSelected)
        .pipe(filter(org => !!org), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
        .subscribe(org => {
          this._store.dispatch(new CmsBlogActions.LoadOne(org.id));
        });
    });
  }

  private _fetchPageHeaderData(user) {
    if (!user) return;

    this._ngZone.runOutsideAngular(() => {
      this._store.dispatch(new MessageActions.LoadMessageStats());
      this._store.dispatch(new UserAvatarActions.LoadOne(user.email));
    });
  }

  private _initFoxdoxLink(user) {
    this.foxdoxLink = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/foxdox/redirects` +
      `?target=/documents&uid=${encodeURIComponent(user.uid)}&access_token=${encodeURIComponent(user.accessToken)}&client=${encodeURIComponent(user.client)}`
  }

  /*
   * Logo / Brand fetching.
   * Case 1: No user / Unauthenticated => Reset
   * Case 2: Business Partner WO organization: User => Organization fetched => but null => Fetch Logo
   * Case 3: Organization switch: Organization changes => Fetch Logo.
   *
   * A check for orgLoadingState is done to skip calls on missing ongoing organizations call to CPP service.
   */
  private _fetchOrganizationLogo() {
    const organizationLoadingState$ = this._store.select(OrganizationSelectors.loadingState);

    this._ngZone.runOutsideAngular(() => {
      combineLatest(this.currentOrganization$, this._currentUser$, organizationLoadingState$)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(([org, user, orgLoadingState]) => {
          // If the organizations are loaded again, e.g. after browser refresh we must reset the logoLoading state to
          // avoid a race condition between logo fetching and organization loading (logo call made before organizations call initiated).
          if (orgLoadingState) {
            this._logoLoaded = false;
          }

          if (!user) {
            // Logout scenario. LogoLoaded is not used in view.
            this._store.dispatch(new OrganizationLogoActions.Reset());
            this._logoLoaded = false;

          } else {
            if (!orgLoadingState) {
              // Business partner without organization: Background Logo is validated by backend related to inviter of BP.
              const rule1 = !org && !this._logoLoaded;
              // Organization is present: First change null => org1, second org1 => org2
              const rule2 = org && !this._logoLoaded || org && this._logoLoaded && this.selectedOrganization !== org;

              if (rule1 && !rule2 || !rule1 && rule2) {
                this._store.dispatch(new OrganizationLogoActions.LoadCurrent(org));
                this._store.dispatch(new MessageActions.LoadMessageStats);
                this._logoLoaded = true;
              }
            }
          }

          this._ngZone.run(() => {
            this.selectedOrganization = org;
          });
        });
    });
  }

  public _showDropdown() {
    setTimeout(() => this.showDropdown = true, 300);
  }

  public reloadPage() {
    window.location.reload()
  }
}
