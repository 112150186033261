import {Component, OnInit, OnDestroy, ChangeDetectorRef, TemplateRef, ViewContainerRef, ViewChild} from '@angular/core';
import {OrganizationSelectors} from '../../../../../+store/organization';
import {Observable, Subject} from 'rxjs';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {InvitationType} from 'app/+store/invitation/invitation.model';
import {filter, takeUntil, distinctUntilKeyChanged} from 'rxjs/operators';
import {ClientActions, ClientSelectors, ContactSelectors} from 'app/+store';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Client} from 'app/+store/client/client';
import {FiveFCardProfile} from '../../../../../five-f/organization-card/containers/organization-card/organization-card.component';
import {ITabNavRoute} from '../../../../../five-f/organization-card/models/tab-nav-route.interface';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {ClientActionType} from '../../../../../lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';

@Component({
  selector: 'dvtx-organization-client-frame',
  templateUrl: './clients-frame.component.html',
  styleUrls: ['./clients-frame.component.scss']
})
export class ClientsFrameComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();
  private tabs = ['contacts', 'clients'];

  public selectedTab: any = 0;

  public organization$: Observable<Organization>;
  public orgId;
  public InvitationType = InvitationType;
  returnUrl: string;
  clientCount$: Observable<number>;
  contactsCount: number;
  clientCount: number = null;

  FiveFCardProfile = FiveFCardProfile;
  routes: ITabNavRoute[] = [];
  activeLink: string;
  searchTerm: string = null;

  @ViewChild('callToActionRef', {static: true}) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;
  ClientActionType = ClientActionType;

  constructor(private store: Store<AppState>,
              private _route: ActivatedRoute,
              private router: Router,
              private _viewContainerRef: ViewContainerRef,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.orgId = this._route.snapshot.params.id;

    this.routes = [{
      title: 'CONTACTS.CONTACTS_TITLE',
      route: `/organization/${this.orgId}/addressbook/contacts`
    }, {
      title: 'CLIENT.CLIENTS',
      route: `/organization/${this.orgId}/addressbook/clients`
    }];
    this.activeLink = this.routes[1].title;

    this.clientCount$ = this.store.select(ClientSelectors.getClientCount);
    this.clientCount$.pipe(takeUntil(this.onDestroy))
      .subscribe(count => {
        this.clientCount = count;
        this.updateNavigation();
      });

    if (this._route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this._route.snapshot.queryParams['returnUrl'];
    }
    this.store.select(ContactSelectors.getContactsOfSelectedOrg)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(contacts => {
        if (contacts) {
          this.contactsCount = contacts.length;
          this.updateNavigation();
        }
      });

    this._route.params.pipe(
      filter(params => !!params && params['tab']),
      distinctUntilKeyChanged('tab'),
      takeUntil(this.onDestroy)
    ).subscribe(tabs => {
      const routingTab = tabs.tab;
      this.selectedTab = this.tabs.indexOf(routingTab);
      if (this.selectedTab < 0) {
        this.selectedTab = 0;
      }
      this._cdr.detectChanges();
    })

    this.organization$ = this.store.select(OrganizationSelectors.getSelected);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  private updateNavigation() {
    this.routes = [{
      title: 'CONTACTS.CONTACTS_TITLE',
      route: `/organization/${this.orgId}/addressbook/contacts`,
      count: this.contactsCount
    }, {
      title: 'CLIENT.CLIENTS',
      route: `/organization/${this.orgId}/addressbook/clients`,
      count: this.clientCount
    }];
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    this.router.navigate([`/organization/${this.orgId}/addressbook/${this.tabs[event.index]}`]);
  }

  navigateBack() {
    if (this.returnUrl)
      this.router.navigateByUrl(this.returnUrl);
    else
      this.router.navigate([`/organization/${this.orgId}/navigation`]);
  }

  search($event: string) {
    this.searchTerm = $event;
  }
}
