import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Update,
  UpdateFail,
  UpdateSuccess,
  Delete,
  DeleteExternal,
  DeleteExternalFail,
  DeleteExternalSuccess,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllConcat,
  LoadAllFail,
  LoadAllRefresh,
  LoadAllRefreshFail,
  LoadAllRefreshSuccess,
  LoadAllSuccess,
  ProcessParticipantActionTypes
} from './process-participant.actions';
import {ProcessParticipantService} from './process-participant.service';
import {ProcessParticipant} from './process-participant';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/reducers';
import { EmailToNameAction } from '..';
import {StringUtils} from 'app/lib/string_utils';

@Injectable()
export class ProcessParticipantEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id, action.recursive).pipe(
        first(),
        concatMap((res: ProcessParticipant[]) => {
          this.loadtoEmailToNameStore(res);
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadAllConcat$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.LoadAllConcat),
    concatMap((action: LoadAllConcat) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((res: ProcessParticipant[]) => {
          this.loadtoEmailToNameStore(res);
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadAllRefresh$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.LoadAllRefresh),
    switchMap((action: LoadAllRefresh) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((participants: ProcessParticipant[]) => {
          this.loadtoEmailToNameStore(participants);
          return [
            new LoadAllRefreshSuccess(participants)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllRefreshFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.Create),
    concatMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((participant: ProcessParticipant) => {
          this.loadtoEmailToNameStore([participant]);
          if (action.params.successMessage) {
            this._notifyService.success(action.params.successMessage)
          }
          return [new CreateSuccess(participant)];
        }),
        catchError(err => {
          if (action.params.failMessage) {
            this._notifyService.error(action.params.failMessage)
          }
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.Update),
    concatMap((action: Update) => {
      return this._svc.update(action.params).pipe(
        first(),
        concatMap((participant: ProcessParticipant) => {
          this.loadtoEmailToNameStore([participant]);
          if (action.params.successMessage) {
            this._notifyService.success(action.params.successMessage)
          }
          return [new UpdateSuccess(participant)];
        }),
        catchError(err => {
          if (action.params.failMessage) {
            this._notifyService.error(action.params.failMessage)
          }
          console.error(err);
          return of(new UpdateFail(err));
        }));
    })
  ));

  delete$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.Delete),
    concatMap((action: Delete) => {
      return this._svc.delete(action.processId, action.participantId, action.recursive).pipe(
        first(),
        concatMap((participant: ProcessParticipant) => {
          this._notifyService.success('GENERAL.REMOVE_PARTICIPANT_SUCCESSFULLY')
          return [new DeleteSuccess(participant)];
        }),
        catchError(err => {
          this._notifyService.error('GENERAL.REMOVE_PARTICIPANT_FAIL')
          this.printApiError(err);
          return of(new DeleteFail(err));
        }));
    })
  ));

  deleteExternal$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantActionTypes.DeleteExternal),
    concatMap((action: DeleteExternal) => {
      return this._svc.deleteExternal(action.participant).pipe(
        first(),
        concatMap((participant: ProcessParticipant) => {
          return [new DeleteExternalSuccess(participant)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DeleteExternalFail(err));
        }));
    })
  ));

  printApiError(err) {
    if (err.status && err.status === 422 && err.error && err.error.errors && err.error.errors[0]) {
      this._notifyService.error(err.error.errors[0].title);
    } else {
      console.error(err);
    }
  }

  /**
   * Puts collected name/email data into our email->name lookup store.
   * @param res
   * @private
   */
  private loadtoEmailToNameStore(res: ProcessParticipant[]): void {
   if (res && res.length > 0) {
     const participantNameArray = [];
     res.forEach((participant) => {
      if (participant.email && participant.firstName && participant.lastName) {
        const particpantName = {id: StringUtils.normalizeString(participant.email) , firstName : participant.firstName , lastName: participant.lastName};
        participantNameArray.push(particpantName);
      }
     });
     if (participantNameArray && participantNameArray.length > 0) {
      this._store.dispatch(new EmailToNameAction.LoadAllSuccess(participantNameArray));
     }
   }
  }
  constructor(private actions: Actions,
              private _store: Store<AppState>,
              private _svc: ProcessParticipantService,
              private _notifyService: NotificationService,
              private _translateSvc: TranslateService) {
  }
}
