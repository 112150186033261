import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {CommonModule} from '@angular/common';
import {PersonsFilterModule} from 'app/shared/modules/persons-filter/persons-filter.module';
import {LicenceControlService} from 'app/services/licence-control.service';
import {ParticipantModule} from 'app/modules/workflow-engine/modules/participant/participant.module';
import {SimpleProcessTableComponent} from './containers/simple-process-table/simple-process-table.component';
import {ClientsFilterModule} from 'app/shared/modules/clients-filter/clients-filter.module';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ProcessIconModule} from 'app/five-f/process-icon/process-icon.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ParticipantModule,
    PersonsFilterModule,
    ClientsFilterModule,
    FiltersContainerModule,
    TooltipModule,
    ProcessIconModule
  ],
  declarations: [
    SimpleProcessTableComponent
  ],
  providers: [
    LicenceControlService
  ],
  exports: [
    SimpleProcessTableComponent,
    ProcessIconModule
  ]
})
export class ProcessTableModule {
}
