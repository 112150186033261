import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  AddFavorite,
  AddFavoriteFail,
  AddFavoriteSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllFromParent,
  LoadAllFromParentFail,
  LoadAllFromParentSuccess,
  LoadAllSuccess,
  LoadOne,
  LoadOneSuccess,
  Move,
  MoveFail,
  MoveSuccess,
  ProcessActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  RunCommand,
  RunCommandFail,
  RunCommandSuccess,
  Track,
  TrackSuccess,
  Unauthorized
} from './process.actions';
import {ProcessService} from './process.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Process} from './process';
import {CommandQueue} from './process.interface';
import * as fromFavorite from '../favorite/favorite.actions';
import {Favorite} from '../favorite/favorite';
import {FavoriteActions} from '../favorite';
import CommandQueueName = CommandQueue.CommandQueueName;
import {EmailToNameAction} from '../email-to-name';


@Injectable()
export class ProcessEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(null, action.params).pipe(
        first(),
        concatMap((res: Process[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          // TODO: Add translation
          // this._notifyService.error('NOTIFICATION.LOAD_SETTINGS_ERROR')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadAllFromParent$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.LoadAllFromParent),
    switchMap((action: LoadAllFromParent) => {
      return this._svc.getAllFromParent(action.id, action.dense).pipe(
        first(),
        concatMap((res: Process[]) => {
          return [new LoadAllFromParentSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          // TODO: Add translation
          // this._notifyService.error('NOTIFICATION.LOAD_SETTINGS_ERROR')
          return of(new LoadAllFromParentFail(err));
        }));
    })
  ));

  // TODO: Reimplement based on favorite store. Process should not have a favorite attribute: Unstable.
  addFavorite$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.AddFavorite),
    switchMap((action: AddFavorite) => {
      return this._svc.favorite(action.payload, action.id).pipe(first()).pipe(
        switchMap((res: Favorite) => {
          // Quickfix to enable favorites
          return [new AddFavoriteSuccess({id: res.id, favorite: true}), new FavoriteActions.LoadOne(res.resourceId)];
        }),
        catchError(err => {
          console.error(err);
          return of(new AddFavoriteFail(err));
        }));
    })
  ));

  track$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.Track),
    switchMap((action: Track) => {
      return this._svc.track(action.id).pipe(
        first(),
        switchMap((_) => {
          return [new TrackSuccess(null)];
        }),
        catchError(err => {
          console.error(err);
          return of(new TrackSuccess(null));
        }));
    })
  ));

  runOne$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.LoadOne),
    concatMap((action: LoadOne) => {
      return this._svc.getOne(action.id, action.dense).pipe(
        first(),
        switchMap((process: Process) => {
          if (process.participants && process.participants.length) {
            return [new LoadOneSuccess(process), new EmailToNameAction.LoadAllSuccess(process.participants)]
          }
          return [new LoadOneSuccess(process)];
        }),
        catchError(err => {
          console.error(err);
          return of(new Unauthorized(err));
        }));
    })
  ));

  move$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.Move),
    switchMap((action: Move) => {
      return this._svc.move(action.id, action.targetId).pipe(
        first(),
        switchMap((process: Process) => {
          return [new MoveSuccess(process)];
        }),
        catchError(err => {
          console.error(err);
          return of(new MoveFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.id).pipe(
        first(),
        switchMap((_process: Process) => {
          return [new RemoveSuccess(action.id), new fromFavorite.LoadAll()];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  runCmd$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.RunCommand),
    switchMap((action: RunCommand) => {
      return this._svc.loadEnv(action.id, action.action).pipe(
        first(),
        switchMap((process: Process) => {
          return [new RunCommandSuccess(process, action.queue)];
        }),
        catchError(err => {
          this._notifyService.error('AUTH.NOT_FOUND_OR_NOT_PERMITTED');
          return of(new RunCommandFail(err));
        }));
    })
  ));

  execCmd$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionTypes.RunCommandSuccess),
    switchMap((action: RunCommandSuccess) => {
      try {
        action.process.runCommands(action.queue as CommandQueueName);
      } catch (error) {
        console.error('ERROR: RunCommandSuccess', error);
      }
      return of(null);
    })
  ), { dispatch: false });

  constructor(private actions: Actions,
              private _svc: ProcessService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
