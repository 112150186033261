<li class="nav-item dropdown" *ngIf="(selectedOrganization | async)">
  <a style="max-width: 200px;" (click)="clearSearch()" class="header-main-org-nav-link nav-link dropdown-toggle"
     type="button"
     id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
    <mat-icon>domain</mat-icon>
    <span [matTooltip]="selectedOrg.name" *ngIf="(selectedOrganization | async) as selectedOrg; else NoOrg"
          class="banner-element-title hidden-sm-down text-truncate">{{ (selectedOrganization | async)?.name }}</span>
    <ng-template #NoOrg>
      <span class="banner-element-title hidden-sm-down text-truncate">{{
          'BANNER.LINK.ORGANIZATION_TITLE' | translate
        }}</span>
    </ng-template>
  </a>
  <ul style="min-width: 464px;" class="dropdown-menu dropdown-menu-right text-capitalize"
      aria-labelledby="navbarDropdownMenuLink"
      data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">

    <mat-form-field *ngIf="allOrganizations?.length > 5" class="dvtx-banner-element-search-field">
      <input matInput [placeholder]="'GENERAL.SEARCH' | translate" name="search" #searchFieldRef autocomplete="off"
             [(ngModel)]="searchTerm" (keyup)="applySearch(searchFieldRef.value)"/>
      <span matPrefix><mat-icon matPrefix>search</mat-icon></span>
      <button *ngIf="searchFieldRef?.value" matSuffix mat-icon-button [disableRipple]="true"
              aria-label="Clear" (click)="clearSearch(); searchFieldRef.value = ''; $event.stopPropagation();">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div class="dvtx-organization-switcher--link">
      <li style="max-height: unset !important;" *ngFor="let organization of filteredOrgs"
          class="dropdown-item-wrapper">
        <mat-icon>settings</mat-icon>
        <a (click)="handleOrganizationClick(organization.id)"
           class="dropdown-item dvtx-organization-switcher--menu-item" [matTooltip]="organization.name">
          {{ organization.name }}
        </a>
      </li>

      <li *ngIf="!filteredOrgs || filteredOrgs?.length == 0" style="max-height: unset !important;"
          class="col-12 dropdown-item-wrapper">
        <div class="no-entries-found dvtx-organization-switcher--menu-item d-flex justify-content-center">
        <span>
          {{ 'GENERAL.NO_ENTRIES_FOUND' | translate }}
        </span>
        </div>
      </li>
    </div>
  </ul>
</li>
