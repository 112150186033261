<div class="w-100 d-flex justify-content-center m-0 p-0">
  <div style="flex-grow: 1; max-width: 600px; min-width: 300px;">
    <dvtx-unsupported-browser
      [alertMsg]="'SYSTEM.BROWSER.FUNCTIONALITY_OF_WEBSITE_UNAVAILABLE' | translate"></dvtx-unsupported-browser>
  </div>
</div>

<ng-template #contactDialogTpl>
  <fivef-dialog [isCallToAction]="true" [title]="'Add further Component Auditor'">
    <form [formGroup]="creationForm" novalidate>
      <div class="row m-0">
        <mat-form-field class="col-12 col-md-5">
          <input matInput placeholder="{{ 'CONTACTS.FIRST_NAME' | translate }}" formControlName="first_name" required>
        </mat-form-field>

        <mat-form-field class="col-12 col-md-5">
          <input matInput placeholder="{{ 'CONTACTS.LAST_NAME' | translate }}" formControlName="last_name" required>
        </mat-form-field>

        <mat-form-field class="col-12 col-md-2">
          <mat-select matInput formControlName="role" placeholder="{{ 'CONTACTS.ROLE' | translate }}">
            <mat-option value="staff">Staff</mat-option>
            <mat-option value="manager">Manager</mat-option>
            <mat-option value="partner">Partner</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row m-0">
        <mat-form-field class="col-12 col-md-6">
          <input matInput placeholder="{{ 'CONTACTS.EMAIL' | translate }}" formControlName="email" required>
        </mat-form-field>
      </div>

      <div class="row m-0">
        <mat-form-field class="col-12 col-md-6">
          <input matInput placeholder="{{ 'CONTACTS.PHONE' | translate }}" formControlName="phone">
        </mat-form-field>

        <mat-form-field class="col-12 col-md-6">
          <input matInput placeholder="{{ 'CONTACTS.MOBILE' | translate }}" formControlName="mobile">
        </mat-form-field>
      </div>

      <div class="row m-0">
        <mat-form-field class="col-12">
          <textarea matInput placeholder="{{ 'GENERAL.COMMENT' | translate }}" formControlName="comment"></textarea>
        </mat-form-field>
      </div>
    </form>

    <div fivef-dialog-footer>
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="primary"
              (click)="saveEntry()" [disabled]="!this.creationForm.valid || this.creationForm.pristine">
        {{ 'GENERAL.CREATE_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>

<ng-template #removalDialogTpl>
  <fivef-dialog [title]="'Confirm removal from the audit'" [infoColor]="'warn'">
    <div class="five-f-lock-folder-dialog">
      <p class="h3 mb-2"
         style="align-self: center; height: 12px;">{{ 'Do you really want to be removed from the audit?' | translate }}</p>

      <p class="h3 mb-3"><span class="dvtx-inline-valign"><mat-icon
        class="fivef-color-status-warn">warning</mat-icon></span>&nbsp;
        <strong>Warning:</strong> This cannot be undone
      </p>
    </div>

    <div fivef-dialog-footer>
      <button mat-button (click)="closeRemovalDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
      <button mat-raised-button color="warn" (click)="confirmRemoval()">
        {{ 'GENERAL.CONFIRM_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>

<div *ngIf="data && !showThankYou" class="d-flex justify-content-center">
  <div style="max-width: 1366px">
    <mat-card appearance="outlined" class="p-0 five-f-card five-f-elevation-z-2">
      <mat-card-header>
        <mat-card-title>
          <p class="h2 dvtx-fg-color dvtx-fg-fivef-blue-500" *ngIf="isAuditing">Verification of the Component
            Auditor</p>
          <p class="h2 dvtx-fg-color dvtx-fg-fivef-blue-500" *ngIf="isCommunication">Verification of contact people for
            project communication</p>
          {{ data.rows[0]?.organizationName }} <span
          *ngIf="data?.due_date">| Answer by: <b>{{ data?.due_date | date:'shortDate' }}</b></span>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <p class="h4 mb-2">Dear sir or madam,</p>
        <ng-template [ngIf]="isAuditing" [ngIfElse]="communicationProcess">
          we are the appointed auditors of {{ data?.contractee[0] }} for the year ending {{
            data?.business_year_end | date:'shortDate'
          }}.
          Your office has been requested to assist in conducting procedures in accordance with our group audit
          instructions.
          <br><br>
          In order to verify our component auditors‘ contact data we kindly ask you to confirm the following
          information.
          If deviations exist, we ask you to provide further details.
        </ng-template>

        <ng-template #communicationProcess>
          Your office has been requested to assist in conducting procedures in our project communication.
          <br><br>
          In order to verify our participating‘ contact data we kindly ask you to confirm the following information.
          If deviations exist, we ask you to provide further details.
        </ng-template>
        <br><br>

        Please provide your answer by <b>{{ data?.due_date | date:'shortDate' }}</b>.

        <!--        <ng-container *ngIf="data?.auditors && data?.auditors?.length">-->
        <!--          <br><br>-->
        <!--          <span *ngIf="isAuditing"><strong>For your information:</strong> The following auditors are also asked for verification:</span>-->
        <!--          <span *ngIf="isCommunication"><strong>For your information:</strong> The following people are also asked for verification:</span>-->
        <!--          <br>-->
        <!--          <ul class="list-unstyled mt-1">-->
        <!--            <li *ngFor="let auditor of data?.auditors">-->
        <!--              <div class="d-flex p-2">-->
        <!--                <div class="d-flex align-self-center"><mat-icon>person</mat-icon></div>-->
        <!--                <div class="d-flex ml-2 align-self-center">{{auditor}}</div>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </ng-container>-->
      </mat-card-content>
    </mat-card>

    <br>

    <ng-container *ngIf="data && globalComment">
      <mat-card appearance="outlined" class="p-0 five-f-card five-f-elevation-z-2">
        <mat-card-header>
          <mat-card-title>
            <h4>Additional comment of {{ data?.sender_name }}</h4>
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div [froalaView]="globalComment"></div>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <br><br>

    <div [formGroup]="auditorForm.form">
      <div [formArrayName]="'auditors'">
        <div [formGroupName]="i"
             *ngFor="let formGroup of $any(auditorForm).form.controls.auditors.controls; let i = index">
          <mat-card appearance="outlined" class="p-0 five-f-card five-f-elevation-z-2">
            <mat-card-header>
              <mat-card-title>
                <strong *ngIf="isAuditing">Component Auditor {{ i + 1 }}</strong>
                <strong *ngIf="isCommunication">Contact Person {{ i + 1 }}</strong>({{ data.rows[i].organizationName }})
              </mat-card-title>
            </mat-card-header>

            <mat-card-content>

              <div *ngIf="!readOnly">
                <h3>Is this information correct and complete?</h3>
                <mat-radio-group formControlName="reviewer_confirmed">
                  <mat-radio-button [value]="true">Yes</mat-radio-button>
                  <mat-radio-button [value]="false">No, change to...</mat-radio-button>
                </mat-radio-group>
              </div>

            </mat-card-content>

            <mat-card-content class="contact-line">
              <dvtx-audit-contact-confirm-item [contact]="data.rows[i]"
                                               [form]="$any(auditorForm).form.controls.auditors.controls[i]"
                                               [isReadOnly]="data.rows[i].reviewer_confirmed"></dvtx-audit-contact-confirm-item>
            </mat-card-content>

            <div class="pb-1 pl-2"> <!--*ngIf="formGroup?.controls['reviewer_confirmed']?.value === true"> -->
              <div class="d-flex align-items-center">
                <div class="h4 d-flex align-self-center">In case you are not taking part in this Audit please press
                  &nbsp;
                </div>
                <div>
                  <button mat-button color="warn" (click)="openRemovalDialog(i)">
                    <mat-icon matPrefix>delete</mat-icon>
                    Delete
                  </button>
                </div>
              </div>
            </div>

          </mat-card>
        </div>
      </div>
    </div>

    <br>

    <ng-container *ngIf="newComponentAuditors && newComponentAuditors.length > 0">
      <mat-card appearance="outlined" class="p-0 five-f-card five-f-elevation-z-1">
        <mat-card-header>
          <mat-card-title>
            <p class="h4" *ngIf="isAuditing">Further Component Auditors</p> <!--({{ data.rows[i].organizationName }})-->
            <p class="h4" *ngIf="isCommunication">Further Contact Person</p>
            <!--({{ data.rows[i].organizationName }})-->
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <ul>
            <li *ngFor="let entry of newComponentAuditors; let i = index">
              <div class="dvtx-audit-contact-confirm-item" [ngClass]="{'read-only': true}">
                <mat-grid-list rowHeight="70px" cols="30">

                  <mat-grid-tile colspan="4" class="form-tile">
                    <mat-form-field class="form-element">
                      <input matInput placeholder="{{ 'CONTACTS.FIRST_NAME' | translate }}" [value]="entry.first_name"
                             [readonly]="true"/>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="4" class="form-tile">
                    <mat-form-field class="form-element">
                      <input matInput placeholder="{{ 'CONTACTS.LAST_NAME' | translate }}" [value]="entry.last_name"
                             [readonly]="true"/>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="4" class="form-tile">
                    <mat-form-field class="form-element">
                      <mat-select placeholder="{{ 'CONTACTS.ROLE' | translate }}" [value]="entry.role"
                                  [disabled]="true">
                        <mat-option value="partner">Partner</mat-option>
                        <mat-option value="manager">Manager</mat-option>
                        <mat-option value="staff">Staff</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="4" class="form-tile">
                    <mat-form-field class="form-element">
                      <input matInput placeholder="{{ 'CONTACTS.EMAIL' | translate }}" [value]="entry.email"
                             [readonly]="true"/>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="4" class="form-tile">
                    <mat-form-field class="form-element">
                      <input matInput placeholder="{{ 'CONTACTS.MOBILE' | translate }}" [value]="entry.mobile"
                             [readonly]="true"/>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="4" class="form-tile">
                    <mat-form-field class="form-element">
                      <input matInput placeholder="{{ 'CONTACTS.PHONE' | translate }}" [value]="entry.phone"
                             [readonly]="true"/>
                    </mat-form-field>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="4" class="form-tile text-right">
                    <button mat-button color="primary" (click)="removeEntry(i)">
                      <mat-icon>delete</mat-icon>
                      {{ 'GENERAL.REMOVE_ACTION' | translate }}
                    </button>
                  </mat-grid-tile>

                  <mat-grid-tile colspan="6">
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <br>

    <ng-container *ngIf="data?.auditors && data?.auditors?.length">
      <mat-card appearance="outlined" class="p-1 five-f-card five-f-elevation-z-1 mb-1">
        <mat-card-content>
          <p class="h3 mt-1 mb-2 ml-2"><span class="dvtx-inline-valign"><mat-icon
            class="fivef-color-status-warn">warning</mat-icon>&nbsp;&nbsp;</span>For your information
          </p>

          <p><span *ngIf="isAuditing"> The following auditors are also asked for verification.</span>
            <span *ngIf="isCommunication">The following people are also asked for verification.</span>
            It is not needed to add them as further auditors.</p>

          <ul class="list-unstyled mt-1">
            <li *ngFor="let auditor of data?.auditors">
              <div class="d-flex p-2">
                <div class="d-flex align-self-center">
                  <mat-icon>person</mat-icon>
                </div>
                <div class="d-flex ml-2 align-self-center">{{ auditor }}</div>
              </div>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <div *ngIf="!readOnly">
      <button mat-button color="primary" (click)="openCreationDialog()">
        <mat-icon matPrefix>person_add</mat-icon>
        <ng-template [ngIf]="isAuditing" [ngIfElse]="addCommunicationButtonTitle">
          Add further component auditor
        </ng-template>

        <ng-template #addCommunicationButtonTitle>
          Add further contact person
        </ng-template>
      </button>
    </div>

    <br>

    <div>
      <mat-card appearance="outlined" class="card-content-devided five-f-card five-f-elevation-z-1">
        <!--<mat-card-content>-->
        <!--<div [formGroup]="form">-->
        <!--<mat-form-field class="full-width">-->
        <!--<input matInput placeholder="{{ 'GENERAL.COMMENT' | translate }}" formControlName="reviewer_comment"/>-->
        <!--</mat-form-field>-->
        <!--</div>-->
        <!--</mat-card-content>-->

        <mat-card-content>
          <dvtx-subarea-verifier-request-contact-form [form]="form"
                                                      [readOnly]="readOnly"></dvtx-subarea-verifier-request-contact-form>
        </mat-card-content>
      </mat-card>
    </div>

    <div>
      <mat-card appearance="outlined" class="card-content-devided five-f-card five-f-elevation-z-1">
        <mat-card-content>
          <!--<h5>Mit freundlichen Grüßen</h5>-->
          <h5>Kind regards,</h5>
          <h5>{{ data?.sender_name }}</h5>
          <div *ngIf="data?.signature" [froalaView]="data?.signature"></div>
        </mat-card-content>

        <mat-card-content>

          <mat-grid-list rowHeight="64px" cols="6" *ngIf="!readOnly">
            <mat-grid-tile [colspan]="6" class="pull-end">
              <button mat-raised-button
                      color="primary"
                      class="wide" (click)="verifyData()"
                      [disabled]="form.invalid || submitOngoing">
                VERIFY DATA
              </button>
            </mat-grid-tile>
          </mat-grid-list>

        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="showThankYou" class="d-flex justify-content-center">
  <mat-card appearance="outlined" class="five-f-card five-f-elevation-z-2">
    <mat-card-title>
      <!-- Currently only english is supported-->
      <!--{{ 'GENERAL.THANK_YOU_FOR_YOUR_EFFORTS' | translate }}-->
      <!--      <p class="h2 dvtx-fg-color dvtx-fg-fivef-blue-500">Thank you for your support</p>-->
    </mat-card-title>

    <mat-card-content>

      <div class="d-flex justify-content-center mb-3" style="flex-grow: 1; max-width: 900px; min-width: 300px;">
        <dvtx-alert [color]="'success'" [icon]="'check_circle'" [text]="'Thank you for your support!'"
                    style="flex-grow: 1; max-width: 900px; min-width: 300px;"></dvtx-alert>
      </div>

      <p class="h3 dvtx-fg-color dvtx-fg-fivef-blue-500">If you have any questions, please consult</p>
      <p class="h4 dvtx-fg-color dvtx-fg-fivef-blue-500">{{ data?.sender_name }}</p>

      <div class="d-flex justify-content-end" *ngIf="showDashboardLink || showLoginLink">
        <button mat-button color="primary" (click)="navigateToDashboard()"
                *ngIf="showDashboardLink">{{ 'GENERAL.BACK_TO_DASHBOARD' | translate }}
          <mat-icon matSuffix>arrow_forward</mat-icon>
        </button>
        <button mat-button color="primary" (click)="navigateToLogin()"
                *ngIf="showLoginLink">{{ 'GENERAL.BACK_TO_LOGIN' | translate }}
          <mat-icon matSuffix>arrow_forward</mat-icon>
        </button>
      </div>

      <div *ngIf="data?.signature" [froalaView]="data?.signature"
           style="flex-grow: 1; max-width: 900px; min-width: 300px;"></div>
    </mat-card-content>
  </mat-card>
</div>
