<mat-table multiTemplateDataRows #table [dataSource]="clientListing" class="dvtx-client-table">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- clientId Column -->
  <ng-container matColumnDef="icon">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon *ngIf="isInViewPort.indexOf(element.id) > -1" svgIcon="clients"></mat-icon>
    </mat-cell>
  </ng-container>

  <!-- clientId Column -->
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>{{ 'CLIENT.CLIENT_ID' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isInViewPort.indexOf(element.id) > -1" class="w-100 d-flex flex-column">
        <div [matTooltip]="element?.clientId" class="w-100 text-truncate">
          {{ element?.clientId }}
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>{{ 'CLIENT.NAME' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isInViewPort.indexOf(element.id) > -1" class="w-100 d-flex flex-column">
        <!-- <div class="w-100 text-truncate">
          {{element?.name}}
        </div> -->
        <dvtx-title-editable
          [matTooltip]="element?.name"
          [classNames]="'m-0'"
          [disabled]="!element?.canEditClient"
          [renameDoc]="true"
          [placeholder]="'CONTACTS.ADD_CLIENT_NAME' | translate"
          (onChange)="editClientName(element, $event)"
          [fontSize]="'null'"
          [editIcon]="true"
          [showOnHover]="true"
          [value]="element?.name">
        </dvtx-title-editable>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="clientContacts">
    <mat-header-cell *matHeaderCellDef>{{ 'CONTACTS.ASSOCIATED_CONTACTS' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isInViewPort.indexOf(element.id) > -1" class="w-100 d-flex flex-column">
        <fivef-item-selector [disabled]="false"
                             [collection]='contacts$ | async'
                             [selected]="element?.contacts"
                             [resourceSelectorKey]="'contactId'"
                             (onSelect)="createContactClient($event, element)"
                             (onDeselect)="removeContactClient($event, element)"></fivef-item-selector>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="projectRooms">
    <mat-header-cell *matHeaderCellDef>
      <div class="d-flex justify-content-center w-100">
        {{ 'PROJECT_ROOM.PROJECT_ROOMS' | translate }}
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isInViewPort.indexOf(element.id) > -1" class="d-flex justify-content-center w-100">
        <ng-template [ngIf]="element?.processCount">
          <strong>{{ element?.processCount }}</strong>
        </ng-template>
      </div>
    </mat-cell>
  </ng-container>

  <!-- name Column -->
  <ng-container matColumnDef="bookmanClient">
    <mat-header-cell *matHeaderCellDef>{{ 'FIBU.FIBU_CLIENT_NAME' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isInViewPort.indexOf(element.id) > -1">
        {{ element?.bookmanClientName }}
      </div>
    </mat-cell>
  </ng-container>

  <!-- createdAt Column -->
  <ng-container matColumnDef="createdAt">
    <mat-header-cell *matHeaderCellDef>{{ 'GENERAL.CREATED_AT' | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf="isInViewPort.indexOf(element.id) > -1">
        {{ element?.createdAt | date:'medium' }}
      </div>
    </mat-cell>
  </ng-container>

  <!-- createdAt Column -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell class="actions-cell" *matCellDef="let element"
              inViewport
              [inViewportOptions]="{ partial: true, threshold: [0] }"
              (inViewportAction)="showItem($event, element.id)">
      <div class="d-flex w-100 justify-content-end" *ngIf="isInViewPort.indexOf(element.id) > -1">
        <button mat-icon-button
                (click)="$event.preventDefault(); deleteClient(element)"
                [disableRipple]="true"
                [disabled]="!element?.canDeleteClient">
          <mat-icon>delete_outline</mat-icon>
        </button>

        <button mat-icon-button (click)="openSettings(element)"
                [matTooltip]="'GENERAL.SHOW_MORE' | translate">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"
           class="ff-pointer"
           (click)="selectClient(row)"></mat-row>
</mat-table>
