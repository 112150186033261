import {NgModule} from '@angular/core';
import {ParticipantRoleDialogComponent} from './containers/participant-role-dialog/participant-role-dialog.component';
import {SharedModule} from 'app/shared/shared.module';
import {RecipientAutocompleteComponent} from './components/recipient-autocomplete/recipient-autocomplete.component';
import {AlertsModule} from '../../../alerts/alerts.module';
import {FivefDialogComponent} from '../../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {InfoBlockModule} from '../../../../five-f/info-block/info-block.module';
import {FivefDatepickerComponent} from '../../../../lib/fivef-ui/input/fivef-datepicker/fivef-datepicker.component';
import {FivefAddAndInviteComponent} from '../../../../lib/fivef-ui/participation/fivef-add-and-invite/fivef-add-and-invite.component';

@NgModule({
  imports: [
    AlertsModule,
    SharedModule,
    FivefDialogComponent,
    InfoBlockModule,
    FivefDatepickerComponent,
    FivefAddAndInviteComponent
  ],
  declarations: [
    ParticipantRoleDialogComponent,
    RecipientAutocompleteComponent
  ],
  exports: [
    SharedModule,
    ParticipantRoleDialogComponent,
    RecipientAutocompleteComponent
  ]
})
export class ParticipantModule {
}
