<div [matTooltip]="'GENERAL.REACTIONS' | translate"
     [matTooltipPosition]="'above'"
     cdk-overlay-origin
     (click)="showDropdown = !showDropdown"
     (mouseenter)="openShowDropdown(true)"
     (mouseleave)="closeShowDropdown(false)"
     #dropdown="cdkOverlayOrigin">
  <button (click)="react()"
          class="fivef-reaction--button">
    <span class="fivef-reactions--smiley-bg"></span>
    <mat-icon>{{ icon }}</mat-icon>

    <span *ngIf="reaction?.performers?.length"
          class="fivef-reaction--button--title">
        ({{ reaction.performers.length }})
      </span>
  </button>

  <ng-template cdk-connected-overlay
               [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-auto-size-backdrop'"
               [cdkConnectedOverlayOrigin]="dropdown"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayOpen]="showDropdown || keepOpen"
               (backdropClick)="showDropdown = false">
    <div class="fivef-reaction--menu"
         (mouseenter)="toggleKeepOpen(true)"
         (mouseleave)="toggleKeepOpen(false)">
      <div class="fivef-reaction--menu--reaction-bar">
        <button mat-icon-button (click)="react('like')">
          <mat-icon class="fivef-reaction--menu--reaction-item">thumb_up</mat-icon>
        </button>
        <button mat-icon-button (click)="react('laugh')">
          <mat-icon class="fivef-reaction--menu--reaction-item">insert_emoticon</mat-icon>
        </button>
        <button mat-icon-button (click)="react('surprised')">
          <mat-icon class="fivef-reaction--menu--reaction-item">sentiment_neutral</mat-icon>
        </button>
        <button mat-icon-button (click)="react('sad')">
          <mat-icon class="fivef-reaction--menu--reaction-item">sentiment_dissatisfied</mat-icon>
        </button>
        <button mat-icon-button (click)="react('angry')">
          <mat-icon class="fivef-reaction--menu--reaction-item">mood_bad</mat-icon>
        </button>
        <button mat-icon-button (click)="react('heart')">
          <mat-icon class="fivef-reaction--menu--reaction-item">favorite</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="reaction?.performers">
        <hr>
        <ul>
          <li *ngFor="let performer of reaction.performers">
            <dvtx-avatar [size]="'xs'" [email]="performer" [showTitle]='showAvatarTitle'></dvtx-avatar>
          </li>
        </ul>
      </ng-container>
    </div>
  </ng-template>
</div>
