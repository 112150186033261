import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppState} from '../../../../reducers';
import {Store} from '@ngrx/store';
import {NaturalPersonSelectors, UserAvatarActions, UserAvatarSelectors} from '../../../../+store';
import {NaturalPerson} from '../../../../models/natural-person.model';
import {UpdateMy} from '../../../../+store/natural-person/natural-person.actions';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Profile} from 'app/shared/modules/user-account/models/profile';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {UserAvatar} from 'app/+store/user-avatar/user-avatar';
import {filter, takeUntil} from 'rxjs/operators';
import {ISimpleRoute} from '../../../../lib/fivef-ui/navigation/fivef-nav-link/fivef-nav-link.interface';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {contactListDtoType} from '../../../../models/contact-list-dto.model';
import {NotificationService} from '../../../../shared/modules/notification/services/notification.service';

@Component({
  selector: 'dvtx-profile-container',
  templateUrl: './profile-container.component.html',
  styleUrls: ['./profile-container.component.scss']
})
export class ProfileContainerComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public naturalPerson: { contactPerson: NaturalPerson, isValid: boolean };
  public completePerson: NaturalPerson;
  public edit = true;
  private step: number = 0;

  // PROFILE IMAGE CHANGE
  @ViewChild('dialogTpl', {static: true})
  private dialogTpl: TemplateRef<any>;
  private dialogRef: MatDialogRef<any>;

  public myAvatarObject: Profile = <Profile>{};
  public profileImageChangedEvent: any = '';
  private croppedProfileImage: string = '';
  public uploadProfileButton: HTMLElement;
  public uploadError;
  private image$: Observable<UserAvatar>;
  public avatar: UserAvatar;
  public routes: ISimpleRoute[];
  public activeLink: string;
  private latestNaturalPerson: { contactPerson: NaturalPerson; isValid: boolean; };

  constructor(private _store: Store<AppState>,
              private _dialog: MatDialog,
              private _notifyService: NotificationService,
              private avatarService: AvatarService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.routes = [{
      title: 'USER_NAVIGATON.PROFILE',
      route: `/user-profile/user/profile`
    }];
    this.activeLink = this.routes[0].title;
    this._store.select(NaturalPersonSelectors.getUsersNaturalPerson).pipe(filter(Boolean), takeUntil(this.onDestroy)).subscribe((my: NaturalPerson) => {
      this.latestNaturalPerson = {contactPerson: my, isValid: true};
      this.naturalPerson = JSON.parse(JSON.stringify({contactPerson: my, isValid: true}));
      this.completePerson = my;
      if (my.mainEmailAddress && my.mainEmailAddress.emailAddress) {
        this.refreshAvatar();
      }
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private refreshAvatar() {
    this.myAvatarObject = this.avatarService.getProfile(this.completePerson.mainEmailAddress.emailAddress);
    this.image$ = this._store.select(UserAvatarSelectors.getOne(this.completePerson.mainEmailAddress.emailAddress))
    this.image$.pipe(filter(Boolean), takeUntil(this.onDestroy)).subscribe((avatar: UserAvatar) => {
      if (avatar && avatar.avatar) {
        this.avatar = avatar.avatar;
      }
      this.cdr.detectChanges();
    });
  }

  private setStep(index: number) {
    this.step = index;
  }

  public save() {
    this.naturalPerson.contactPerson.id = this.completePerson.id;
    this._store.dispatch(new UpdateMy(this.naturalPerson.contactPerson));
    // this._store.dispatch(new UpdateMy({
    //   ...this.completePerson,
    //   ...this.naturalPerson.contactPerson,
    //   mainEmailAddress: this.completePerson.mainEmailAddress
    // }));
    // this.edit = false;
    this.cdr.detectChanges();
  }

  public profileImageChangeRequest() {
    this.uploadProfileButton = document.getElementById('uploadProfileButton') as HTMLElement;
    this.uploadProfileButton.click();
  }

  public profileImageChangeEvent(event) {
    this.uploadError = false;
    this.profileImageChangedEvent = event;
    if (event.srcElement.files[0] && event.srcElement.files[0].size > (1048576 / 2)) {
      this.uploadError = true;
      this._notifyService.error('SIGNATURE.ERRORS.SIZE_ERROR');
      return;
    }
    this.dialogRef = this._dialog.open(this.dialogTpl);
  }

  public profileImageCropEvent(event: ImageCroppedEvent) {
    this.croppedProfileImage = event.base64;
  }

  public profileImageLoaded() {
    console.log('Image loaded');
  }

  public profileImageLoadedFailed() {
    console.error('Image loading failed');
  }

  public onSubmitCroppingClick(): void {
    this.myAvatarObject.image = this.croppedProfileImage;
    const userAvatar = new UserAvatar(this.myAvatarObject.email, this.myAvatarObject.image, this.completePerson.firstName, this.completePerson.lastName, null, this.completePerson.title);
    this._store.dispatch(new UserAvatarActions.Updated(userAvatar));

    this.dialogRef.close();
  }

  public profileImageRemove(): void {
    this.myAvatarObject.image = null;
    const userAvatar = new UserAvatar(this.myAvatarObject.email, this.myAvatarObject.image, this.completePerson.firstName, this.completePerson.lastName, null, this.completePerson.title);
    this._store.dispatch(new UserAvatarActions.Updated(userAvatar));
    this.refreshAvatar();
  }

  public resetChanges() {
    this.naturalPerson = null;
    setTimeout(() => {
      this.naturalPerson = JSON.parse(JSON.stringify(this.latestNaturalPerson));
    }, 400);
    this.cdr.detectChanges();
  }

  // public setEditMode() {
  //   this.edit = true;
  //   this.cdr.detectChanges()
  // }
  //
  // public disableEditMode() {
  //   this.edit = false;
  //   this.resetChanges()
  // }

  protected readonly contactListDtoType = contactListDtoType;
}
