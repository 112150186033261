import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  NavigateToDashboard,
  Publish,
  PublishFail,
  PublishSuccess,
  QuickshareActionTypes,
  SaveDraft,
  SaveDraftFail,
  SaveDraftSuccess,
  UpdateRecipients,
  UpdateRecipientsFail,
  UpdateRecipientsSuccess,
  UpdateWorkers,
  UpdateWorkersFail,
  UpdateWorkersSuccess
} from './quickshare.actions';
import {Quickshare} from './quickshare';
import {QuickshareService} from './quickshare.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessParticipantActions} from 'app/+store/process-participant';

@Injectable()
export class QuickshareEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((quickshare: Quickshare) => {
          return [new CreateSuccess(quickshare)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  publish$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.Publish),
    switchMap((action: Publish) => {
      return this._svc.publish(action.id).pipe(
        first(),
        concatMap((quickshare: Quickshare) => {
          return [new PublishSuccess(quickshare), new NavigateToDashboard(quickshare.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new PublishFail(err));
        }));
    })
  ));

  navigateToDashboard$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.NavigateToDashboard),
    switchMap((action: NavigateToDashboard) => {
      try {
        this._router.navigate([`/workflows/timelines/${action.id}`], {replaceUrl: true})
      } catch (error) {
        console.error(error)
      }
      return of(null);
    })
  ), {dispatch: false});

  updateRecipients$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.UpdateRecipients),
    switchMap((action: UpdateRecipients) => {
      return this._svc.updateRecipients(action.id, action.recipients).pipe(
        first(),
        concatMap((quickshare: Quickshare) => {
          return [new UpdateRecipientsSuccess(quickshare), new ProcessParticipantActions.LoadAllRefresh(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateRecipientsFail(err));
        }));
    })
  ));

  updateWorkers$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.UpdateWorkers),
    switchMap((action: UpdateWorkers) => {
      return this._svc.updateWorkers(action.id, action.workers).pipe(
        first(),
        concatMap((quickshare: Quickshare) => {
          return [new UpdateWorkersSuccess(quickshare), new ProcessParticipantActions.LoadAllRefresh(action.id), new LoadOne(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateWorkersFail(err));
        }));
    })
  ));

  saveDraft$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.SaveDraft),
    switchMap((action: SaveDraft) => {
      return this._svc.saveDraft(action.id, action.params).pipe(
        first(),
        concatMap((quickshare: Quickshare) => {
          return [new SaveDraftSuccess(quickshare)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveDraftFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: Quickshare[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((quickshare: Quickshare) => {
          return [new LoadOneSuccess(quickshare)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: QuickshareService,
              private _router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




