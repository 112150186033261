import {Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {IamProcessActivityActionTypes, LoadAll, LoadAllFail, LoadAllSuccess} from './iam-process-activity.actions';
import {DirectiveService} from 'app/shared/modules/api/services/directive.service';
import {Iam} from 'app/shared/modules/api/models/iam/activity';

@Injectable()
export class IamProcessActivityEffects {
  loadMyNP = createEffect(() => this.actions.pipe(
    ofType(IamProcessActivityActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAuthForRoom(action.payload, null).pipe(
        map((res: Iam.Activity[]) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => of(new LoadAllFail(err))));
    })
  ));

  constructor(private actions: Actions, private _svc: DirectiveService) {
  }
}




