import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {OrganizationProxyBuilder} from './organization-proxy.builder';
import {OrganizationProxy} from './organization-proxy';
import {first} from 'rxjs/operators';
import {FileUtils} from 'app/lib/file_utils/functions';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';

/**
 * The organization proxy service implements Core APIs for the organization.
 *
 * Background
 *
 * The original organization entity is part of the CPP service and responsible for memberships, the bound address book
 * and the admin role. The Core API extends this model by workflows and feature related attributes.
 */
@Injectable()
export class OrganizationProxyService {
  readonly BASE_PATH = 'api/v1/organizations/properties';
  readonly BASE_SETTINGS_PATH = 'api/v1/organizations/settings';
  readonly BASE_PATH_MEMBERSHIPS = 'api/v1/organizations/properties';

  constructor(private _http: ApiResourceService,
              private _httpClient: HttpClient) {
  }

  getOne(id: string): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    return <Observable<OrganizationProxy>>this._http.get<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_PATH}/${id}`);
  }

  update(id: string, organization: OrganizationProxy): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = builder.toRequest(organization);
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_PATH}/${id}`, payload);
  }

  toggleGrantClientsFolderToMembers(id: string, enable: boolean): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          grant_clients_folder_to_members: enable
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/grant_clients_folder_to_members`, payload);
  }

  /**
   * Enables two factor authentication enforcement mode if user is participating at processes of an organization
   * that requires two factor.
   *
   * @param id: string. ID of the organization.
   * @param enable: boolean. enable or disable two-factor authentication mode.
   */
  toggleForceTwoFactorAuthentication(id: string, enable: boolean): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          enforce_two_factor_auth: enable
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/enforce_two_factor_auth`, payload);
  }

  toggleDisableBusinessPartnerDmsContacts(id: string, enable: boolean): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          disable_business_partner_dms_contacts: enable
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/disable_business_partner_dms_contacts`, payload);
  }

  manualContactCleanup(id: string): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    return <Observable<OrganizationProxy>>this._http.post<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/cleanup_contacts`, {});
  }

  toggleShowClientToBusinessPartner(id: string, enable: boolean): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          show_client_to_business_partner: enable
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/show_client_to_business_partner`, payload);
  }

  toggleShowClientInEmail(id: string, enable: boolean): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          show_client_in_email: enable
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/show_client_in_email`, payload);
  }

  togglePrivateDms(id: string, enable: boolean): Observable<OrganizationProxy> {
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          disable_private_dms: enable
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.put<OrganizationProxyBuilder, OrganizationProxy>(builder, `${this.BASE_SETTINGS_PATH}/${id}/disable_private_dms`, payload);
  }

  pruneEmployee(employeeEmail: string, ownerEmail: string): Observable<OrganizationProxy> {
    const apiPath = 'api/v1/organizations/prune_employee';
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          employee_email: employeeEmail,
          owner_email: ownerEmail
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.post<OrganizationProxyBuilder, OrganizationProxy>(builder, apiPath, payload);
  }

  pruneContact(contactEmail: string): Observable<OrganizationProxy> {
    const apiPath = 'api/v1/organizations/prune_contact';
    const builder = new OrganizationProxyBuilder();
    const payload = {
      data: {
        attributes: {
          email: contactEmail
        }
      }
    };
    return <Observable<OrganizationProxy>>this._http.post<OrganizationProxyBuilder, OrganizationProxy>(builder, apiPath, payload);
  }

  exportAddressBook(oid: string, onSuccess: () => {}, onFailure: () => {}): void {
    const apiPath = `api/v1/organizations/address_book/${oid}/export`;
    this._httpClient.post(`${environment.token_service_config.apiBase}/${apiPath}`, {})
      .pipe(first())
      .subscribe(res => {
        onSuccess();
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        FileUtils.saveAsFile(res['data'].attributes.filename, res['data'].attributes.content, mimeType);
      }, err => {
        console.error(err);
        onFailure();
      });
  }
}
