/**
 * Simplified reducer configuration of the application state management (@ngrx/store).
 *
 * References:
 * - See the reference application on https://github.com/ngrx/example-app
 *   https://github.com/ngrx/example-app/blob/1f39f815833b23d96bfd474928b1465d7c547052/src/app/reducers/index.ts
 *   https://github.com/ngrx/example-app/blob/1f39f815833b23d96bfd474928b1465d7c547052/src/app/app.module.ts
 */
import { Action, ActionReducerMap } from '@ngrx/store';
import { ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromUser from './user.reducer';
import * as fromCurrentUser from './current-user.reducer';
import * as fromMainHeader from './main-header.reducer';
import * as fromScreen from './screen.reducer';
import * as fromProcessContext from './process-context.reducer';
import * as fromProcessContexts from './process-contexts.reducer';
import * as fromDraftsProcessContextsReducer from './drafts-process-contexts.reducer';
import * as fromTitle from './title.reducer';
import * as fromApiError from './api-error.reducer';
import * as fromAuditOrders from './audit-orders.reducer';
import * as fromFoxdoxStatus from './foxdox-status.reducer';
import {tfaInitialState, tfaReducer, TfaState} from './tfa.reducer';

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: any, action: Action): any {
    if (action.type === 'USER_LOGOUT_SUCCESS') {
      console.log('CLEARING STATE');
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];

export interface AppState {
  apiErrors: fromApiError.ApiErrorState;
  auditOrders: fromAuditOrders.AuditOrderState;
  currentUser: fromCurrentUser.CurrentUserState;
  mainHeader: fromMainHeader.MainHeaderState;
  processContext: fromProcessContext.ProcessContextState;
  processContexts: fromProcessContexts.ProcessContextStates;
  draftsProcessContexts: fromDraftsProcessContextsReducer.ProcessContextStates;
  screen: fromScreen.ScreenState;
  title: fromTitle.TitleState;
  user: fromUser.UserState;
  tfaState: TfaState,
  foxdoxStatus: fromFoxdoxStatus.FoxdoxStatus,
}

export const reducers: ActionReducerMap<AppState> = {
  apiErrors: fromApiError.apiErrorReducer,
  auditOrders: fromAuditOrders.auditOrderReducer,
  currentUser: fromCurrentUser.currentUserReducer,
  mainHeader: fromMainHeader.mainHeaderReducer,
  screen: fromScreen.screenReducer,
  processContext: fromProcessContext.processContextReducer,
  processContexts: fromProcessContexts.processContextsReducer,
  draftsProcessContexts: fromDraftsProcessContextsReducer.draftsProcessContextsReducer,
  title: fromTitle.titleReducer,
  user: fromUser.userReducer,
  tfaState: tfaReducer,
  foxdoxStatus: fromFoxdoxStatus.foxdoxStatus,

};

export const initialState = {
  auditOrders: fromAuditOrders.initialState,
  user: fromUser.initialState,
  currentUser: fromCurrentUser.initialState,
  mainHeader: fromMainHeader.initialState,
  screen: fromScreen.initialState,
  title: fromTitle.initialState,
  processContext: fromProcessContext.initialState,
  processContexts: fromProcessContexts.initialState,
  draftsProcessContexts: fromDraftsProcessContextsReducer.initialState,
  apiErrors: fromApiError.initialState,
  tfaState: tfaInitialState,
  foxdoxStatus: fromFoxdoxStatus,

};

// TODO: Restore visual debugging with @ngrx/store5
// ==== Left for documentation:
//
// import { compose, combineReducers, ActionReducer, ActionReducerMap } from '@ngrx/store';
// import { storeFreeze } from 'ngrx-store-freeze';
// import { storeLogger } from 'ngrx-store-logger';
// import { environment } from 'environments/environment';
//
// uncomment the storeLogger import and this line
// and comment out the other export default line to turn on
// the store logger to see the actions as they flow through the store
// turned this off by default as i found the logger kinda noisy
// const developmentReducer: ActionReducer<AppState> = compose(storeLogger(), combineReducers)(reducers);
// const developmentReducer: ActionReducer<AppState> = compose(combineReducers)(reducers);

// storeFreeze produces errors in combination with rx > 4
// See open issue: https://github.com/codewareio/ngrx-store-freeze/issues/17
// Remove if solved and enable storeFreece again.
// const developmentReducer: ActionReducer<AppState> = compose(storeFreeze, combineReducers)(reducers);

// const productionReducer: ActionReducer<AppState> = combineReducers(reducers);
//
// export function reducer(state: any, action: any) {
//   if (environment.production) {
//     return productionReducer(state, action);
//   } else {
//     return developmentReducer(state, action);
//   }
// }
