import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorTemplateActionTypes,
  CreateTemplate,
  CreateTemplateFail,
  CreateTemplateSuccess,
  DeleteTemplate,
  DeleteTemplateFail,
  DeleteTemplateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadTemplate,
  LoadTemplateFail,
  LoadTemplateSuccess
} from './collector-template.actions';
import {CollectorTemplate} from './collector-template';
import {CollectorTemplateService} from './collector-template.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import * as QuickCollectorActions from '../quickcollector/quickcollector.actions';
import * as CollectorAttachmentActions from '../collector-attachment/collector-attachment.actions';

@Injectable()
export class CollectorTemplateEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(CollectorTemplateActionTypes.LoadAll),
    switchMap((_action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: CollectorTemplate[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadTemplate$ = createEffect(() => this.actions.pipe(
    ofType(CollectorTemplateActionTypes.LoadTemplate),
    switchMap((action: LoadTemplate) => {
        this._notifyService.info('COLLECTOR.TEMPLATE_LOADING');
      return this._svc.loadTemplate(action.collectorId, action.id).pipe(
        first(),
        concatMap((template: CollectorTemplate) => {
          return [
            new LoadTemplateSuccess(template),
            new QuickCollectorActions.LoadOne(action.collectorId),
            new QuickCollectorActions.LoadDataNodes(action.collectorId),
            new CollectorAttachmentActions.LoadAll(action.collectorId)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadTemplateFail(err));
        }));
    })
  ));

  deleteTemplate$ = createEffect(() => this.actions.pipe(
    ofType(CollectorTemplateActionTypes.DeleteTemplate),
    switchMap((action: DeleteTemplate) => {
      this._notifyService.info('COLLECTOR.TEMPLATE_DELETED')
      return this._svc.delete(action.id).pipe(
        first(),
        concatMap((template: CollectorTemplate) => {
          return [
            new DeleteTemplateSuccess(template)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new DeleteTemplateFail(err));
        }));
    })
  ));

  createTemplate$ = createEffect(() => this.actions.pipe(
    ofType(CollectorTemplateActionTypes.CreateTemplate),
    switchMap((action: CreateTemplate) => {
        this._notifyService.info('COLLECTOR.TEMPLATE_CREATING');
      return this._svc.create(action.id, action.payload).pipe(
        first(),
        concatMap((template: CollectorTemplate) => {
          return [
            new CreateTemplateSuccess(template)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateTemplateFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: CollectorTemplateService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




