<!-- Favorite star placeholder on loading -->
<ng-template [ngIf]="loading$ | async" [ngIfElse]="showFavoriteStateTpl">
  <button mat-icon-button [disabled]="true" (click)="$event.stopPropagation()">
    <mat-icon>star</mat-icon>
  </button>
</ng-template>

<!-- Active favorite toggle -->
<ng-template #showFavoriteStateTpl>
  <ng-container *ngIf="(favorite$ | async) as favorite">
    <button [matTooltip]="'QUICKSHARE.UNMARK_FAVORITE' | translate" mat-icon-button (click)="remove($event, favorite)">
      <mat-icon>star</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="!(favorite$ | async)">
    <button [matTooltip]="'QUICKSHARE.MARK_FAVORITE' | translate" mat-icon-button (click)="add($event)">
      <mat-icon>star_border</mat-icon>
    </button>
  </ng-container>
</ng-template>
