import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadAll, LoadAllFail, LoadAllSuccess, ProcessTreeActionTypes} from './process-tree.actions';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessTreeService} from './process-tree.service';
import {ProcessTreeNode} from './process-tree';

@Injectable()
export class ProcessTreeEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessTreeActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.activeProcessId).pipe(
        first(),
        concatMap((nodes: ProcessTreeNode[]) => {
          return [new LoadAllSuccess(nodes)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessTreeService,
              private _router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




