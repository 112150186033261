
import {of as observableOf, Observable, of} from 'rxjs';
import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import {DocumentPreviewDocument} from 'app/+store/document-preview-document/document-preview-document';
import {ProcessArtifact} from "../../../../../../+store/process-artifact/process-artifact";
import {switchMap} from "rxjs/operators";
import {Net} from "../../../../../../lib/net/uuid";
import {ProcessArtifactSelectors} from "../../../../../../+store/process-artifact";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../reducers";
import {AvatarService} from "../../../../../../shared/modules/user-account/components/avatar/avatar.service";

@Component({
  selector: 'dvtx-preview-browser-imagelist',
  templateUrl: './preview-browser-imagelist.component.html',
  styleUrls: ['./preview-browser-imagelist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PreviewBrowserImagelistComponent implements OnInit {
  @Input()
  public documents: DocumentPreviewDocument[] = [];

  @Output()
  public onDocumentClicked: EventEmitter<DocumentPreviewDocument> = new EventEmitter<DocumentPreviewDocument>();

  artifact$(id): Observable<ProcessArtifact> {
    return observableOf(id)
      .pipe(
        switchMap(_id => {
          if (!Net.validUUID(_id)) return of(null);
          return this._store.select(ProcessArtifactSelectors.getProcessArtifactById(_id));
        }));
  }

  constructor(private _store: Store<AppState>,
              public avatarService: AvatarService) {
  }

  ngOnInit() {
  }

  public onImageClick(document: DocumentPreviewDocument): void {
    this.onDocumentClicked.emit(document);
  }

  public calculateImageHeightPercent(document: DocumentPreviewDocument): number {
    if (document.previews.length === 0) {
      return 180;
    }
    const preview = document.previews[0];
    if (!preview.width || !preview.height || preview.width === 0 || preview.height === 0) {
      return 180;
    }
    return preview.height / preview.width * 100;
  }

  public isNotFoundDocument(document: DocumentPreviewDocument): boolean {
    return document.pages === 0
      && document.displayName === 'Document Not Found'
      && document.fileName === ''
      && document.mimeType === null;
  }
}
