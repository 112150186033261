import {NgModule} from '@angular/core';
import {TreeLibModule} from '../tree-lib/tree-lib.module';
import {DmsFilePickerComponent} from './containers/dms-file-picker/dms-file-picker.component';
import {DmsFilePickerSelectorDirective} from './directives/dms-file-picker-selector.directive';
import {DmsFilePickerDialogComponent} from './containers/dms-file-picker-dialog/dms-file-picker-dialog.component';
import {FileTransferOngoingDialogComponent} from './components/file-transfer-ongoing-dialog/file-transfer-ongoing-dialog.component';
import {FolderModule} from '../workflow-engine/modules/folder/folder.module';

@NgModule({
  imports: [
    TreeLibModule,
    FolderModule,
    // FivefFolderTreeComponent
  ],
  declarations: [
    DmsFilePickerComponent,
    DmsFilePickerSelectorDirective,
    DmsFilePickerDialogComponent,
    FileTransferOngoingDialogComponent
  ],
  exports: [
    FolderModule,
    DmsFilePickerComponent,
    DmsFilePickerSelectorDirective
  ]
})
export class FilePickerModule {
}
