import {first, filter, takeUntil, map} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {GetCurrentUser} from '../../../../actions/current-user-actions';
import {AppState} from '../../../../reducers';
import {Store} from '@ngrx/store';
import {TwoFactorAuthService} from 'app/+store/two-factor-auth/two-factor-auth.service';
import {User} from '../../../../models/user';
import {SimplePhoneNumber} from '../../../../+store/contact/legacy/models/contact.interface';
import {NaturalPersonSelectors} from '../../../../+store';
import {NaturalPerson} from '../../../../models/natural-person.model';
import {FivefConfirm} from '../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-add-authentification',
  templateUrl: './add-authentification.component.html',
  styleUrls: ['./add-authentification.component.scss']
})
export class AddAuthentificationComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  @Input() twoFactorAuthEnabled: boolean;

  @ViewChild('phoneNumber') phoneNumber;

  mobileNumber: Observable<String>;
  email: Observable<String>;

  autocompleteValue;

  _activateEnable: boolean;

  step: number = 0;
  innerValue: SimplePhoneNumber = new SimplePhoneNumber();

  @Output() activated: EventEmitter<boolean> = new EventEmitter();

  options = [];


  checkDataForm: UntypedFormGroup;
  backendVerificationForm: UntypedFormGroup;

  PhoneNumberValue;

  submitted: boolean = false;

  SMSIsActivated: boolean = false;
  showQR: boolean = false;
  MobileIsActivated: boolean = false;
  codeMissMatch: string;

  _currentUSer: User;

  authenticationStatus: {
    emailSuccessfull?: Observable<boolean>,
    mobile?: {
      mobileSuccessfull: boolean,
      mobileImage: string
    },
    smsSuccessfull?: boolean,
  } = {};

  @Input() set activateStep(value: number) {
    this.step = value;
    if (value === 5) {
      this.verifyPhoneNumber();
    }
  }

  get activateStep() {
    return this.step;
  }


  constructor(private _fb: UntypedFormBuilder,
              private authService: TwoFactorAuthService,
              private _store: Store<AppState>) {
    this.checkDataForm = this._fb.group({});
    this.backendVerificationForm = this._fb.group({});

    this._store.pipe(map(state => state['currentUser']), takeUntil(this.onDestroy)).subscribe((currentUser: User) => {
      if (currentUser) {
        this._currentUSer = currentUser;
        this.checkDataForm.addControl('email', new UntypedFormControl(currentUser.email));
        this.checkDataForm.patchValue({email: currentUser.email})
        return currentUser ? currentUser.otpRequiredForLogin : false;
      }

    });

    this.userLoad();

    this.checkDataForm.addControl('phone', new UntypedFormControl('', Validators.required));
    this.checkDataForm.addControl('SMSConfirmation', new UntypedFormControl('', Validators.required));
    this.checkDataForm.addControl('MobileConfirmation', new UntypedFormControl('', Validators.required));
  }

  ngOnInit() {

    this._store.select(NaturalPersonSelectors.getUsersNaturalPerson).pipe(filter(Boolean)).subscribe((my: NaturalPerson) => {
      if (my.mainPhoneNumber.phoneNumber) {
        this.options.push({value: my.mainPhoneNumber})
      }
      this._store.select(NaturalPersonSelectors.getById(my.id)).subscribe((person) => {
        if (person.phoneNumbers) {
          for (let i = 0; i < person.phoneNumbers.length; i++) {
            this.options.push({value: person.phoneNumbers[i]})
          }
          this.autoCompleteFunction(this.options[0])
        } else {
          this.autoCompleteFunction('')
        }
      });
    });
  }

  enableSMSAuthentication() {
    let phoneNumber;
    if (this.phoneNumber && this.phoneNumber.innerValue) {
      if (this.phoneNumber.innerValue.number && this.phoneNumber.innerValue.number.phoneNumber) {
        phoneNumber = this.phoneNumber.innerValue.number.phoneNumber;
      } else if (this.phoneNumber.innerValue.number) {
        phoneNumber = this.phoneNumber.innerValue.number;
      } else if (this.phoneNumber.innerValue.phoneNumber) {
        phoneNumber = this.phoneNumber.innerValue.phoneNumber;
      } else if (!this.phoneNumber.innerValue.number) {
        phoneNumber = this.phoneNumber.innerValue;
      }

      this.checkDataForm.patchValue({'phone': phoneNumber})
      this.PhoneNumberValue = phoneNumber;
    }

    if (this.checkDataForm.controls.phone.value) {
      this.authService.enableSMS(this.checkDataForm.controls.phone.value).pipe(takeUntil(this.onDestroy)).subscribe((res) => {
        this.PhoneNumberValue = phoneNumber;
        this.step = 2;
      });
    }
  }


  verifyPhoneNumber() {
    this.authService.requestOTPBySMS(this.checkDataForm.value.email).pipe(takeUntil(this.onDestroy)).subscribe((response) => {
      this.step = 5;
    })
  }

  verifySMSAuthentication() {
    this.authService.verify_phone(this.checkDataForm.value.SMSConfirmation).pipe(takeUntil(this.onDestroy)).subscribe((res) => {
      this.step = 3;
    });
  }

  enableAuthentication(step) {
    this.authService.enable2FA().pipe(
      takeUntil(this.onDestroy))
      .subscribe(() => {
        this.step = step;
        this.verifyPhoneNumber();
        if (step === 0) {
          this.activated.emit(true);
        }
      })
  }

  enablMobileAuthentication() {
    this.authService.enable2FA().pipe(
      takeUntil(this.onDestroy))
      .subscribe(() => {
        this.authService.enableMobileAuthenticator(this.checkDataForm.value.MobileConfirmation).pipe(
          takeUntil(this.onDestroy)).subscribe((response) => {
          if (response.status === 200) {
            this.authenticationStatus.mobile = {mobileSuccessfull: true, mobileImage: response.error.text};
            this.step = 6;
          }
        });
      })
  }

  done() {
    this.authService.enable2FA().pipe(
      takeUntil(this.onDestroy))
      .subscribe(() => {
        this.step = 0;
        this.activated.emit(true);
        this.userLoad();
      })
  }

  editPhoneNumber() {
    this.checkDataForm.reset();
    this.step = 1;
  }

  @FivefConfirm({
    message: 'AUTH.DEACTIVATE_2FA_MESG',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'AUTH.DEACTIVATE_2FA_TITLE'
  })
  removeAuthentication() {
    const value = this.checkDataForm.value;
    const otp = value['firstInput'] + value['secondInput'] + value['thirdInput'] +
      value['fourthInput'] + value['fifthInput'] + value['sixthInput'];
    this.authService.disable2FA(otp).pipe(map((resp) => {
      if (resp.status === 401) {
        if (resp.error.errors) {
          for (let i = 0; i < resp.error.errors.length; i++) {
            if (resp.error.errors[i].code === 'api.auth.otp.error.code_mismatch') {
              this.codeMissMatch = resp.error.errors[i].title;
              break;
            }
          }
        }
        this.userLoad();
      } else if (!resp.status) {
        this.step = 0;
        this.activated.emit(false);
        this.checkDataForm.reset();
        this.userLoad();
      }
    }), first(),).subscribe()
  }

  cancelAction() {
    this.step = 0;
    this.checkDataForm.reset();
    this.activated.emit(null);
  }

  autoCompleteFunction(value) {
    if (value && value.target) {
      this.autocompleteValue = value.target.value.phoneNumber;
      this.checkDataForm.patchValue({'phone': value.target.value});
    } else {
      const valueOfNumber = {
        countryCode: '',
        locationOrType: 'undefined',
        phoneNumber: value,
      }
      this.PhoneNumberValue = value;
      this.checkDataForm.patchValue({'phone': value});
    }
  }

  userLoad() {
    const currentUser$ = this._store.pipe(map(state => state['currentUser']));

    this.mobileNumber = currentUser$.pipe(map((currentUser: User | undefined) => {
      return currentUser ? currentUser.phoneNumber : '';
    }));
    this.email = currentUser$.pipe(map((currentUser: User) => {
      return currentUser ? currentUser.email : '';
    }));
    this._store.dispatch(new GetCurrentUser());
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
