import { ICommand } from './commands/icommand';
import {IResource} from './resource.interface';
import {IContact} from '../../../../+store/contact/legacy/models/contact.interface';

export interface IProcessStatus {
  code: string;

  infoLevel: string;

  icon: string;

  message: string;

  isClosed(): boolean;

  isOpen(): boolean;
}

/**
 * Public interface for the <tt>ProcessContext</tt>.
 */
export interface IProcessContext extends IResource {
  /**
   * Values: Symbol/value mapping of process variables.
   */
  values: any;

  config: any;

  workflow: string;

  revision: string;

  id: string;

  title: string;

  subtitle: string;

  description: string;

  dueDate: string;

  createdAt: string;

  updatedAt: string;

  status: IProcessStatus;

  parentId: string;

  artifacts: any;

  assignee: IContact;

  getValue(code: string): any;

  /**
   * Adds a command to the event queue identified by <tt>hook</tt>.
   * Returns the altered process context.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param {ICommand} command
   * @returns {IProcessContext}
   */
  addCommand(hook: CommandQueue.CommandQueueName, command: ICommand): IProcessContext;

  /**
   * Runs the commands in the event queue identified by <tt>hook</tt>.
   * The iterated calling stops if a command returns false.
   *
   * Returns the remaining commands in queue.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<ICommand>}
   */
  runCommands(hook: CommandQueue.CommandQueueName): Array<ICommand>;

  /**
   * Sets a new event queue for commands of the type <tt>hook</tt>.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param {Array<ICommand>} queue
   */
  setQueue(hook: CommandQueue.CommandQueueName, queue: Array<ICommand>): void;

  /**
   * Returns the commands in the event queue identified by <tt>hook</tt>.
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<ICommand>}
   */
  getQueue(hook: CommandQueue.CommandQueueName): Array<ICommand>;

  /**
   * Returns a JSON represenatation of the process context for transmissions.
   * Commands are not included.
   *
   * @returns {any}
   */
  toJSON(): any;

  /**
   * Returns a deep copy of the process context.
   * Exceptional the commands which are immutables and do not alter an internal state.
   *
   * @returns {IProcessContext}
   */
  clone(): IProcessContext;
}

/**
 * Static type and utility information for the handling of command
 * queues providing hooks for API driven control flows.
 */
export namespace CommandQueue {
  /**
   * Contains all event hook names delivered by the API and
   * used as names in the command queues.
   */
  export type CommandQueueName = 'on_init'
    | 'after_validation'
    | 'on_click'
    | 'on_create'
    | 'on_go_back'
    | 'on_submit'
    | 'on_update'
    | 'after_submit';

  /**
   * Returns the command queue hook names as iterable list.
   *
   * @returns {Array<CommandQueue.CommandQueueName>}
   */
  export function defaultQueues(): Array<CommandQueue.CommandQueueName> {
    return ['on_init', 'after_validation', 'on_click', 'on_create',
      'on_go_back', 'on_submit', 'on_update', 'after_submit'];
  }
}
