<ul *ngIf="profiles" class="fivef-avatar-group--listing">
  <li *ngFor="let profile of profiles | slice:0:maxToShow"
      class="fivef-avatar-group--list-item">
    <dvtx-avatar [size]="avatarSizeString"
                 [email]="profile?.email"
                 [matTooltip]="profile?.name || profile.email">
    </dvtx-avatar>
  </li>

  <li [class]="itemsClass" *ngIf="maxToShow < profiles.length && showMore">
    <span class="fivef-avatar-group--more-indicator"
          [style.color]="profiles[0].fgColor"
          [style.background-color]="profiles[0].bgColor"
          [style.border-color]="profiles[0].bgColor"
          [style.width.px]="avatarSizeNumber"
          [style.height.px]="avatarSizeNumber"
          [style.lineHeight.px]="avatarSizeNumber"
          (click)="openTooltip($event)"
          [matMenuTriggerFor]="displayMenu">
    +{{ profiles.length - maxToShow }}
  </span>
    <mat-menu #displayMenu="matMenu"
              class="fivef-avatar-group--menu">
      <div *ngIf="contentTooltipAvatar"
           [innerHTML]="contentTooltipAvatar">
      </div>
    </mat-menu>
  </li>
</ul>
