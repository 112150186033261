import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {BoxComponent} from './components/box/box.component';
import {SimpleDashboardPanelComponent} from './components/simple-dashboard-panel/simple-dashboard-panel.component';
import {BarChartModule, PieChartModule} from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    BarChartModule,
    PieChartModule
  ],
  declarations: [
    BoxComponent,
    SimpleDashboardPanelComponent,
  ],
  exports: [
    BoxComponent,
    SimpleDashboardPanelComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DashboardPanelsModule {
}
