<mat-table [dataSource]="dataSource" [trackBy]="trackBy"
           class="dvtx-process-listing fivef-table">
  <ng-container matColumnDef="icon" sticky>
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <dvtx-process-icon [workflowType]="element?.processType"></dvtx-process-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="title" sticky>
    <mat-header-cell *matHeaderCellDef>
      {{ 'GENERAL.TITLE' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="w-100 d-flex flex-column">
        <div [matTooltip]="element?.title" class="w-100 text-truncate">
          {{ element.title ? element.title : ('GENERAL.NO_TITLE' | translate) }}
        </div>
        <div class="w-100 text-truncate">
          <small><span
            class="dvtx-fg-color dvtx-bg-fivef-blue-300">{{ element?.humanReadableProcessType | translate }}</span></small>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="favorite">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class="w-100 d-flex justify-content-center">
        <dvtx-favorite-toggle [id]="element.id"></dvtx-favorite-toggle>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="notifications">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div (click)="$event.stopPropagation()" *ngIf="element">
        <button mat-icon-button (click)="openNotificationPanel(element)"
                [matTooltip]="'NOTIFICATION.NOTIFICATIONS' | translate">
          <mat-icon>notifications</mat-icon>
        </button>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="client">
    <mat-header-cell *matHeaderCellDef>
      {{ 'CLIENT.CLIENT' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf="element?.clientName">
        <div [matTooltip]="element?.clientName" class="w-100 text-truncate">
          <div class="w-100 text-truncate"
               inViewport
               [inViewportOptions]="{ partial: true, threshold: [0] }"
               (inViewportAction)="showItem($event, element.id)">
            {{ element?.clientName }}
          </div>
        </div>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="client-with-id">
    <mat-header-cell *matHeaderCellDef>
      {{ 'CLIENT.CLIENT' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <dvtx-client-cell [clientId]="element?.clientId"></dvtx-client-cell>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="ident">
    <mat-header-cell *matHeaderCellDef>
      {{ 'GENERAL.IDENT' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div [matTooltip]="element?.identifier" class="w-100 text-truncate">
        {{ element?.identifier }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef [class.small-screen-size]="smallScreenSize$ | async">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        {{ 'GENERAL.STATUS' | translate }}
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"
              [class.small-screen-size]="smallScreenSize$ | async">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <fivef-status-selector [matTooltip]="element.status?.title | translate"
                               [selected]="element.status"
                               [statuses]="FiveFStatuses"
                               [disabled]="true"
                               [iconOnly]="smallScreenSize$ | async"
                               class="w-100 h-100">
        </fivef-status-selector>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="creator">
    <mat-header-cell *matHeaderCellDef> {{ 'GENERAL.CREATED_BY' | translate }}</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"
              inViewport
              [inViewportOptions]="{ partial: true, threshold: [0] }"
              (inViewportAction)="showItem($event, element.id)">
      <ng-container *ngIf="isInViewPort.indexOf(element.id) > -1">
        <div class="d-flex w-100 justify-content-center"
             matTooltip="{{ element?.creatorName }} ({{ element?.creatorEmail }})">
          <dvtx-avatar [size]="'xs'"
                       [email]="element?.creatorEmail"
                       [showTitle]="false">
          </dvtx-avatar>
        </div>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="owner">
    <mat-header-cell *matHeaderCellDef>
      <div class="d-flex w-100 justify-content-center text-center">{{ 'ROLE.OWNER' | translate }}</div>
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
      <ng-container *ngIf="isInViewPort.indexOf(element.id) > -1">
        <div class="d-flex w-100 justify-content-center"
             matTooltip="{{ element?.ownerName }} ({{ element?.ownerEmail }})">
          <dvtx-avatar [size]="'xs'" [email]="element?.ownerEmail" [showTitle]="false">
          </dvtx-avatar>
        </div>
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="available-until">
    <mat-header-cell *matHeaderCellDef>
      {{ 'GENERAL.AVAILABILITY' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <dvtx-remaining-days-counter [retentionDays]="retentionPeriod"
                                   [startDate]="element?.trashedAt"></dvtx-remaining-days-counter>
      <!-- <small>{{element.trashedAt | date: 'mediumDate' }}</small> -->
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="updated-at">
    <mat-header-cell *matHeaderCellDef>
      {{ 'GENERAL.LAST_UPDATED' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.updatedAt | date: 'mediumDate' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="created-at">
    <mat-header-cell *matHeaderCellDef> {{ 'GENERAL.CREATED_AT' | translate }}</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"> {{ element?.createdAt | date:'mediumDate' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="spacer">
    <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element"></mat-cell>
  </ng-container>

  <ng-container matColumnDef="published">
    <mat-header-cell mat-header-cell *matHeaderCellDef>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <dvtx-table-text-and-info [title]="'PARTNER_LINK.PUBLISH'"
                                  [tooltip]="'PARTNER_LINK.PUBLISH_INFO'"></dvtx-table-text-and-info>
      </div>
    </mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element">
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element" class="last-cell">
      <div class="w-100 d-flex justify-content-end pr-2">
        <div *ngIf="element?.status?.id === 'STATUS.DRAFT'">
          <button mat-icon-button
                  (click)="removeDraft($event, element)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div>
          <button (click)="navigateTo(element)" mat-icon-button>
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="trash-actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell mat-cell *matCellDef="let element" class="last-cell">
      <div class="w-100 d-flex justify-content-end pr-2">
        <div>
          <button mat-icon-button
                  (click)="restoreProcessFromTrash($event, element)">
            <mat-icon>settings_backup_restore</mat-icon>
          </button>
        </div>

        <div>
          <fivef-process-deletion-dialog [userContext]="FiveFRoleContext.ContainerResponsibility"
                                         [buttonStyle]="FiveFMatButtonStyle.IconButton"
                                         (onSuccess)="onDeleteProcess.emit($event)"
                                         [process]="element"></fivef-process-deletion-dialog>
        </div>

        <div>
          <button mat-icon-button
                  (click)="showDetails($event, element)">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"
           class="ff-pointer p-0"
           [style.border-left-color]="row?.color"
           [style.border-left-style]="row?.color? 'solid' : 'none'"
           [style.border-left-width]="'5px'"></mat-row>
</mat-table>
