import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {IFivefConfirmData} from './fivef-confirm.interface';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';

/**
 * Simple confirm dialog.
 *
 * Use it directly or by decorator on methods.
 * See data interface for all options.
 *
 * Example for decorator usage:
 *
 * @FivefConfirm({
 *   message: 'LABELS.DELETE_LABEL',
 *   icon: 'warning',
 *   color: 'warn',
 *   confirmAction: 'GENERAL.DELETE_ACTION'
 * })
 * deleteLabel(label) {
 *   this._store.dispatch(new LabelActions.Remove(label.id));
 * }
 */
@Component({
  selector: 'fivef-confirm-dialog',
  standalone: true,
  imports: [CommonModule, FivefDialogComponent, MatButtonModule, MatDialogModule, TranslateModule, MatIconModule],
  templateUrl: './fivef-confirm.component.html',
  styleUrls: ['./fivef-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefConfirmComponent {
  public color: 'warn' | 'primary' = 'primary';

  /**
   * Optional title of dialog.
   * Per default only the message should be used to keep it small.
   */
  public dialogTitle: string = null;

  /**
   * Main message of the confirm dialog.
   */
  public message: string;

  /**
   * Button text of the confirm action.
   * Default is 'GENERAL.CONFIRM_ACTION'.
   */
  public confirmAction: string;

  /**
   * Secondary action title.
   * Button is shown only if present.
   * The return value is the string value.
   * Default null.
   */
  public secondaryAction: string;

  /**
   * Material icon on top of text, e.g. warning on deletion.
   */
  public icon: string;

  /**
   * Optional border color. Should only be used for large dialogs.
   * Will default to setting 'transparent'.
   */
  public borderColor: string;

  /**
   * Disables the cancel button e.g. if main action is just close.
   */
  public disableCancelButton = false;

  /**
   * Alignment of content.
   */
  public textAlign: 'center' | 'left' = 'center';

  constructor(@Inject(MAT_DIALOG_DATA) private data: IFivefConfirmData) {
    this.dialogTitle = data.title;
    this.message = data.message;
    this.confirmAction = data.confirmAction ? data.confirmAction : 'GENERAL.CONFIRM_ACTION';
    this.secondaryAction = data.secondaryAction;
    this.color = data.color ? data.color : 'primary';
    this.icon = data.icon;
    this.disableCancelButton = data.disableCancelButton;
    this.textAlign = data.textAlign ? data.textAlign : 'center';

    switch (data.borderColor) {
      case 'primary':
        this.borderColor = 'var(--fivef-color-action)';
        break;
      case 'warn':
        this.borderColor = 'var(--fivef-color-warn)';
        break;
      default:
        this.borderColor = 'transparent';
    }
  }
}
