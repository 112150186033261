<ng-container [ngSwitch]="_action" *ngIf="!isDisabled">
  <!-- Button to open the dialog -->
  <ng-container *ngSwitchCase="ClientActionType.CreateButton">
    <button mat-icon-button type="button" [disabled]="readonly" (click)="$event.preventDefault(); openDialog()"
            [disableRipple]="true">
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.CreateRaisedButton">
    <button mat-raised-button type="button" color="primary" [disabled]="readonly"
            (click)="$event.preventDefault(); openDialog()" [disableRipple]="true">
      <mat-icon matPrefix>add</mat-icon>
      {{ 'CLIENT.CREATE_CLIENT' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.CreateBasicButton">
    <button mat-button type="button" color="primary" [disabled]="readonly"
            (click)="$event.preventDefault(); openDialog()" [disableRipple]="true">
      <mat-icon matPrefix>add</mat-icon>
      {{ 'CLIENT.CREATE_CLIENT' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.EditButton">
    <button mat-icon-button type="button" (click)="$event.preventDefault(); openDialog()"
            [disabled]="readonly"
            [disableRipple]="true">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="ClientActionType.DeleteButton">
    <button mat-icon-button
            (click)="$event.preventDefault(); deleteClient(client?.id)"
            [disabled]="readonly || !client?.canDeleteClient">
      <mat-icon>delete_outline</mat-icon>
    </button>
  </ng-container>

  <!-- Embeddable view to be used inside the settings dialog -->
  <ng-container *ngSwitchCase="ClientActionType.Form">
    <div class="d-flex flex-column" style="width: 100%">
      <ng-container [ngTemplateOutlet]="clientCreationForm">
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #clientCreationForm>
  <form [formGroup]="form" novalidate>
    <input type="hidden" formControlName="contactId"/>
    <input type="hidden" formControlName="legalForm"/>

    <div class="fivef-icon-content fivef-icon-content--floating-labels">
      <mat-icon svgIcon="clients"></mat-icon>
      <div class="fivef-icon-content--content">
        <mat-form-field>
          <mat-label>{{'CLIENT.NAME' | translate}}</mat-label>
          <input matInput formControlName="name" [readonly]="readonly"/>
        </mat-form-field>
      </div>
    </div>

    <div class="fivef-icon-content fivef-icon-content--floating-labels">
      <mat-icon svgIcon="datev"></mat-icon>
      <div class="fivef-icon-content--content">
        <mat-form-field>
          <mat-label>{{'CLIENT.CLIENT_ID' | translate}}</mat-label>
          <input matInput formControlName="clientId" [readonly]="readonly"/>

          <button matSuffix mat-icon-button [matTooltip]="'CLIENT.CLIENT_ID_INFO' | translate">
            <mat-icon>info</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #clientCreationDialogTpl>
  <fivef-dialog [title]="dialogTitle" [isCallToAction]="true" *ngIf="showForm">
    <ng-container [ngTemplateOutlet]="clientCreationForm">
    </ng-container>
    <!-- TODO: unclear? -->
    <!--    <ng-container *ngIf="enableSaveButton">-->
    <!--      <div class="row mt-2 mb-3">-->
    <!--        <div class="col-12">-->
    <!--          <div class="d-flex justify-content-end">-->
    <!--            <button mat-button class="mr-2"-->
    <!--                    *ngIf="client?.canDeleteClient"-->
    <!--                    (click)="$event.preventDefault(); deleteClient(client?.id, true)"-->
    <!--                    [disabled]="readonly">-->
    <!--              <mat-icon matPrefix>delete_outline</mat-icon>-->
    <!--              {{ 'CLIENT.DELETE_CLIENT' | translate }}-->
    <!--            </button>-->

    <!--            <button mat-raised-button-->
    <!--                    color="primary"-->
    <!--                    [disabled]="form.pristine || form.invalid || submitOngoing"-->
    <!--                    (click)="submit()">-->
    <!--              <mat-icon matPrefix>save</mat-icon>-->
    <!--              {{ saveButtonTitle | translate }}-->
    <!--            </button>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </ng-container>-->

    <div fivef-dialog-footer>
      <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>

      <button mat-raised-button
              color="primary"
              [disabled]="form.pristine || form.invalid || submitOngoing"
              (click)="submit()">
        <mat-icon matPrefix>save</mat-icon>
        {{ saveButtonTitle | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>
