import {NgModule} from '@angular/core';
import {ApiModule} from '../api/api.module';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';
import {FileUploadDirective} from './directives/file-upload.directive';
import {FileUploadModalComponent} from './containers/file-upload-modal/file-upload-modal.component';
import {ListingModule} from '../listing/listing.module';
import {FivefDialogComponent} from '../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';

@NgModule({
    imports: [
        ApiModule,
        ListingModule,
        BaseFormElementsModule,
        FivefDialogComponent
    ],
    declarations: [
        FileUploadDirective,
        FileUploadModalComponent,
    ],
    exports: [
        FileUploadDirective,
        FileUploadModalComponent
    ]
})
export class FilesUploadModule {}
