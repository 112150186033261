import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {SimplePhoneNumber} from '../../../../../../+store/contact/legacy/models/contact.interface';
import {DvtxControlValueAccessor} from '../DvtxControlValueAccessor';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {FivefL10n} from '../../../../../../lib/country/country-code';
import CountryCode = FivefL10n.CountryCode;

/*
We use "control: any" instead of "control: FormControl" to silence:
"Property 'nativeElement' does not exist on type 'FormControl'".
This happens because I've expanded control with nativeElement via
'NativeElementInjectorDirective' to get an access to the element.
More about this approach and reasons for this:
https://github.com/angular/angular/issues/18025
https://stackoverflow.com/a/54075119/1617590
*/
const phoneNumberValidator = (control: any) => {
  if (!control.value) {
    return;
  }
  // Find <input> inside injected nativeElement and get its "id".
  const el: HTMLElement = control.nativeElement as HTMLElement;
  const inputBox: HTMLInputElement | any = el
    ? el.querySelector('input[type="tel"]')
    : undefined;
  if (inputBox) {
    const id = inputBox.id;
    const isCheckValidation = inputBox.getAttribute('validation');
    if (isCheckValidation === 'true') {
      const isRequired = control.errors && control.errors.required === true;
      const error = {validatePhoneNumber: {valid: false}};

      inputBox.setCustomValidity('Invalid field.');

      let number: lpn.PhoneNumber;

      try {
        number = lpn.PhoneNumberUtil.getInstance().parse(
          control.value.number,
          control.value.countryCode
        );
      } catch (e) {
        if (isRequired) {
          return error;
        } else {
          inputBox.setCustomValidity('');
        }
      }

      if (control.value) {
        // @ts-ignore
        if (!number) {
          return error;
        } else {
          if (
            !lpn.PhoneNumberUtil.getInstance().isValidNumberForRegion(
              number,
              control.value.countryCode
            )
          ) {
            return error;
          } else {
            inputBox.setCustomValidity('');
          }
        }
      }
    } else if (isCheckValidation === 'false') {
      inputBox.setCustomValidity('');

      control.clearValidators();
    }
  }
  return;
};

@Component({
  selector: 'dvtx-int-telephone-input',
  templateUrl: './int-telephone-input.component.html',
  styleUrls: ['./int-telephone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntTelephoneInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useValue: phoneNumberValidator,
      multi: true,
    }
  ]
})

export class IntTelephoneInputComponent extends DvtxControlValueAccessor implements OnInit, ErrorStateMatcher, OnChanges {

  @Input() value = '';
  @Input() preferredCountries: Array<string> = [];
  @Input() enablePlaceholder = true;
  @Input() cssClass = 'form-control';
  @Input() onlyCountries: Array<string> = [];
  @Input() enableAutoCountrySelect = false;
  @Input() isDisabled = false;
  @Input() phoneNumber: SimplePhoneNumber = new SimplePhoneNumber();
  @Input() options = [];
  @Input() phoneType = '0';
  @Input() disableInputHints = false;

  @ViewChild('focusable') focusable: ElementRef;
  @Output() autoCompleteFun: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  allCountries: Array<Country> = [];
  preferredCountriesInDropDown: Array<Country> = [];
  selectedCountry: Country = {
    dialCode: '49',
    flagClass: 'de',
    iso2: 'de',
    name: 'Germany (Deutschland)',
    placeHolder: '+49 30 123456',
    priority: 0,
    areaCodes: undefined,
  };

  phoneUtil = lpn.PhoneNumberUtil.getInstance();
  private countryCodeData: CountryCode = new CountryCode();

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  constructor(protected injector: Injector,
              private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.fetchCountryData();
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach(iso2 => {
        const preferredCountry = this.allCountries.filter((c) => {
          return c.iso2 === iso2;
        });
        this.preferredCountriesInDropDown.push(preferredCountry[0]);
      });
    }
    if (this.onlyCountries.length) {
      this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
    }
    if (this.preferredCountriesInDropDown.length) {
      this.selectedCountry = this.preferredCountriesInDropDown[0];
    } else {
      this.selectedCountry = this.allCountries[0];
    }
  }

  public onPhoneNumberChange(): void {
    this.value = this.phoneNumber.phoneNumber;
    let number: lpn.PhoneNumber;
    let countryCode
    try {
      number = this.phoneUtil.parse(this.phoneNumber.phoneNumber, this.selectedCountry.iso2.toUpperCase());
      countryCode = this.selectedCountry.dialCode;
    } catch (e) {
    }
    // auto select country based on the extension (and areaCode if needed) (e.g select Canada if number starts with +1 416)
    if (this.enableAutoCountrySelect) {
      countryCode = number && number.getCountryCode()
        ? this.getCountryIsoCode(number.getCountryCode(), number)
        : this.selectedCountry.dialCode;
      if (countryCode !== this.selectedCountry.dialCode) {
        const newCountry = this.allCountries.find(c => c.dialCode === countryCode);
        if (newCountry) {
          this.selectedCountry = newCountry;
        }
      }
    }
    countryCode = countryCode ? countryCode : this.selectedCountry.dialCode;
    this.phoneNumber.isValid = !this.isErrorState();
    this.phoneNumber.countryCode = countryCode;
    this.phoneNumber.phoneNumber = number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : '';

    if (!this.isErrorState() && this.phoneNumber.phoneNumber) {
      this.autoCompleteFun.emit(this.phoneNumber.phoneNumber);
    } else {
      this.autoCompleteFun.emit(null);
    }

    this.propagateChange({
      isValid: !this.isErrorState(),
      number: this.value,
      internationalNumber: number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : '',
      nationalNumber: number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.NATIONAL) : '',
      countryCode: this.selectedCountry.iso2.toUpperCase()
    });
  }

  public onCountrySelect(country: Country, el): void {
    this.selectedCountry = country;
    if (this.phoneNumber.phoneNumber.length > 0) {
      this.value = this.phoneNumber.phoneNumber;

      let number: lpn.PhoneNumber;
      try {
        number = this.phoneUtil.parse(this.phoneNumber.phoneNumber, this.selectedCountry.iso2.toUpperCase());
        this.phoneNumber.isValid = !this.isErrorState();
        this.phoneNumber.countryCode = this.selectedCountry.dialCode;
        this.phoneNumber.phoneNumber = number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : '';
      } catch (e) {
      }
      this.propagateChange({
        isValid: !this.isErrorState(),
        number: this.value,
        internationalNumber: number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.INTERNATIONAL) : '',
        nationalNumber: number ? this.phoneUtil.format(number, lpn.PhoneNumberFormat.NATIONAL) : '',
        countryCode: this.selectedCountry.iso2.toUpperCase()
      });
    }

    if (!this.isErrorState() && this.phoneNumber.phoneNumber) {
      this.autoCompleteFun.emit(this.phoneNumber.phoneNumber);
    } else {
      this.autoCompleteFun.emit(null);
    }
    el.focus();
  }

  public onInputKeyPress(event): void {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
    this.onPhoneNumberChange();
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach(c => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: c[4] as string[] || undefined,
        flagClass: c[1].toString().toLocaleLowerCase(),
        placeHolder: ''
      };
      if (this.enablePlaceholder) {
        country.placeHolder = this.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
      }
      this.allCountries.push(country);
    });
  }

  protected getPhoneNumberPlaceHolder(countryCode: string): string {
    try {
      return this.phoneUtil.format(this.phoneUtil.getExampleNumber(countryCode), lpn.PhoneNumberFormat.INTERNATIONAL);
    } catch (e) {
      return '';
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(obj: any): void {
    if (obj && typeof obj === 'object') {
      this.phoneNumber = obj;
      if (this.phoneNumber.locationOrType) {
        this.phoneNumber.locationOrType = this.phoneNumber.locationOrType === undefined || this.phoneNumber.locationOrType === 'undefined' ? this.phoneType : this.phoneNumber.locationOrType;
      }
      if (this.phoneNumber.countryCode) {
        const selectedCountry = this.allCountries.find(i => i.dialCode === this.phoneNumber.countryCode || '+' + i.dialCode === this.phoneNumber.countryCode);
        if (selectedCountry) {
          this.selectedCountry = selectedCountry;
        }
      }
      setTimeout(() => {
        this.onPhoneNumberChange();
        this.changeDetector.detectChanges();
      }, 1);
    }
  }

  private getCountryIsoCode(countryCode: number, number: lpn.PhoneNumber): string | undefined {
    // Will use this to match area code from the first numbers
    const rawNumber = number.values_['2'].toString();
    // List of all countries with countryCode (can be more than one. e.x. US, CA, DO, PR all have +1 countryCode)
    const countries = this.allCountries.filter(c => c.dialCode === countryCode.toString());
    // Main country is the country, which has no areaCodes specified in country-code.ts file.
    const mainCountry = countries.find(c => c.areaCodes === undefined);
    // Secondary countries are all countries, which have areaCodes specified in country-code.ts file.
    const secondaryCountries = countries.filter(c => c.areaCodes !== undefined);
    let matchedCountry = mainCountry ? mainCountry.dialCode : undefined;
    /*
        Interate over each secondary country and check if nationalNumber starts with any of areaCodes available.
        If no matches found, fallback to the main country.
    */
    secondaryCountries.forEach(country => {
      country.areaCodes.forEach(areaCode => {
        if (rawNumber.startsWith(areaCode)) {
          matchedCountry = country.dialCode;
        }
      });
    });
    return matchedCountry;
  }

  autoCompleteFunction(value: MatAutocompleteSelectedEvent) {
    const phoneNumber = new SimplePhoneNumber();
    phoneNumber.phoneNumber = value.option.value;
    this.phoneNumber = phoneNumber;
    if (!this.isErrorState() && this.phoneNumber.phoneNumber) {
      this.autoCompleteFun.emit(this.phoneNumber.phoneNumber);
    } else {
      this.autoCompleteFun.emit(null);
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.phoneType && changes.phoneType.currentValue) {
      this.phoneNumber.locationOrType = changes.phoneType.currentValue;
    }
  }

  isErrorState(): boolean {
    return this.errors !== null && this.errors.validatePhoneNumber !== undefined && this.errors.validatePhoneNumber.valid === false && !this.isDisabled && (this.phoneNumber.phoneNumber !== '' && this.phoneNumber.phoneNumber !== undefined && this.phoneNumber.phoneNumber !== null);
  }
}

export interface Country {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes?: string[];
  flagClass: string;
  placeHolder: string;
}
