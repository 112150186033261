import {ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {Organization} from '../../../models/organization.model';
import {
  ClientActions,
  ContactActions,
  LabelActions,
  LicenceAssignmentActions,
  MembershipActions,
  OrganizationActions,
  OrganizationSelectors
} from '../../../+store';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {LoadMy} from 'app/+store/membership/membership.actions';
import {LicenceControlService} from 'app/services/licence-control.service';
import {Router} from '@angular/router';
import {MotdActions} from 'app/+store/motd';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'dvtx-organization-banner-element',
  templateUrl: './banner-element.component.html',
  styleUrls: ['./banner-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationBannerElementComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public searchTerm: string;
  @ViewChild('searchFieldRef') searchFieldRef;

  public selectedOrganization: Observable<Organization>;

  private searchTerm$ = new BehaviorSubject<string>(null);

  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  };

  public allOrganizations: Organization[] = [];

  public filteredOrgs: Organization[] = [];

  constructor(private store: Store<any>,
              private licenceControl: LicenceControlService,
              private router: Router,
              private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.searchTerm$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(term => {
        if (term !== undefined && term != null) {
          this.filteredOrgs = this.allOrganizations.filter(c => c.name.toLowerCase().indexOf(term.toLowerCase()) >= 0);
        }
      });

    this.store.select(OrganizationSelectors.getAllOrganizations)
      .pipe(filter(org => !!org), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(orgs => {
        this.allOrganizations = orgs;
        this.filteredOrgs = JSON.parse(JSON.stringify(orgs));
      })

    this.selectedOrganization = this.store.select(OrganizationSelectors.getSelected);
    this.selectedOrganization
      .pipe(filter(org => !!org), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe((org) => {
        if (org) {
          this.ngZone.runOutsideAngular(() => {
            this.store.dispatch(new LoadOfOrganization(org.id));
            this.store.dispatch(new LoadMy(org.id));
            this.store.dispatch(new MembershipActions.LoadAll(org.id));
            this.store.dispatch(new ContactActions.LoadAll(org));
            this.store.dispatch(new LicenceAssignmentActions.LoadAll(org.id));
            this.store.dispatch(new LicenceAssignmentActions.LoadAttachedOrganizations);
            // loaded now by OrganizationActionTypes.SelectSuccess
            // this.store.dispatch(new FeatureActions.LoadAll());
            this.store.dispatch(new MotdActions.LoadOne(org.id));
            this.store.dispatch(new LabelActions.LoadAll());
            setTimeout(_ => this.store.dispatch(new ClientActions.LoadAll(true)));
          });
        }
      });

    this.currentUser = this.licenceControl._currentUser;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.searchTerm$.complete();
  }

  public async handleOrganizationClick(id: string) {
    this.store.dispatch(new OrganizationActions.Select(id));
    this.router.navigate([`/organization/${id}/navigation`], {queryParams: {lc: false}});
  }

  public applySearch($event) {
    this.searchTerm$.next($event);
  }

  public clearSearch() {
    this.searchTerm = '';
    this.searchTerm$.next('');
    setTimeout(() => {
      this.searchFieldRef.nativeElement.focus();
    }, 100);
  }
}
