import {AfterViewInit, Component, ElementRef, forwardRef, Injector, Input, NgZone, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SimpleAddress} from '../../../../../../+store/contact/legacy/models/contact.interface';
import {DvtxControlValueAccessor} from '../DvtxControlValueAccessor';

declare var $;

@Component({
  selector: 'dvtx-address-input-cva',
  templateUrl: './address-input-cva.component.html',
  styleUrls: ['./address-input-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputCvaComponent),
      multi: true,
    }
  ]
})
export class AddressInputCvaComponent extends DvtxControlValueAccessor implements AfterViewInit, OnChanges {
  form: UntypedFormGroup;

  @Input()
  enableLocationOrType: boolean;

  @Input()
  address: SimpleAddress = new SimpleAddress();

  @ViewChild('autocomplete', {read: ElementRef, static: true}) autocomplete;
  @Input() label: string;

  constructor(private ngZone: NgZone,
              private _fb: UntypedFormBuilder,
              protected injector: Injector) {
    super();
    this.form = this._fb.group({
      full: ['']
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      if (this.isDisabled) {
        this.form.get('full').disable();
      } else {
        this.form.get('full').enable();
      }
    }
  }

  onFocus(event) {
    const addressInputElement = event.target;
    const jAddressInputElement = $(addressInputElement);
    const pacContainer = $('.pac-container');
    $(jAddressInputElement.parent()).append(pacContainer);
  }

  ngAfterViewInit() {
    const input = this.autocomplete.nativeElement;

    // this.mapsAPILoader.load().then(() => {
    //   const autocomplete = new google.maps.places.Autocomplete(input, {
    //     types: ['address']
    //   });
    //   autocomplete.addListener('place_changed', () => {
    //     this.ngZone.run(() => {
    //       const currentAddress = autocomplete.getPlace();
    //       for (const item of currentAddress.address_components) {
    //         switch (item['types'][0]) {
    //           case 'street_number':
    //             this.address.streetNo = item['long_name'];
    //             break;
    //           case 'zip':
    //             this.address.zip = item['long_name'];
    //             break;
    //           case 'country':
    //             this.address.countryName = item['long_name'];
    //             break;
    //           case 'locality':
    //             this.address.city = item['long_name'];
    //             break;
    //           case 'postal_town':
    //             this.address.city = item['long_name'];
    //             break;
    //           case 'route':
    //             this.address.street = item['long_name'];
    //             break;
    //         }
    //       }
    //     });
    //   });
    // });
  }

  writeValue(obj: any): void {
    if (obj) {
      this.address = obj;
    }
  }

}
