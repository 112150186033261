<div class="fivef-sidebar-navigation--wrapper {{sidebarNavigationClassStyle}} {{classList}}">
  <div class="fivef-page-sidebar--title fivef-page-sidebar--sticky-title"
       *ngIf="title">&nbsp;{{title | translate}}</div>

  <dvtx-workflow-info *ngIf="process"
                      [process]="process"
                      [hasAccessibleParent]="hasAccessibleParent"></dvtx-workflow-info>

  <div *ngIf="process && (this.sidebar$ | async)?.externalPortal">
    <ng-template [cdkPortalOutlet]="(this.sidebar$ | async)?.externalPortal"></ng-template>
    <br>
  </div>

  <nav>
    <ul class="fivef-sidebar-navigation--link-listing">
      <ng-container *ngFor="let route of routes">
        <li class="active">
          <fivef-nav-link [icon]="route?.icon"
                          [count]="route?.icon === 'tasks' ? tasksRouteCount : route?.count"
                          [title]="route?.title"
                          [infoLevel]="route?.infoLevel"
                          [path]="route?.path"
                          [orientation]="route?.orientation"
                          [callback]="route?.callback"></fivef-nav-link>
        </li>
      </ng-container>
    </ul>
  </nav>
</div>
