import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {FeatureSelectors} from 'app/+store/feature';
import {filter, first} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {Router} from '@angular/router';

@Directive({
  selector: '[fivefFeatureAuthorization]',
  standalone: true
})
export class FivefFeatureAuthorizationDirective {
  @Input()
  fivefFeatureAuthorization;

  @Input()
  fivefFeatureRoute;

  @Output()
  public onAction = new EventEmitter();

  @HostListener('click', ['$event, $event.target'])
  onClick(_event, _targetElement) {
    this._store.select(FeatureSelectors.getCurrentFeatureSet)
      .pipe(filter(fs => !!fs), first())
      .subscribe((featureSet: Feature) => {
        if (featureSet[this.fivefFeatureAuthorization]) {
          if (this.fivefFeatureRoute) {
            this._router.navigate(this.fivefFeatureRoute);
          }
        } else {
          alert('Feature not booked');
          this.onAction.emit();
        }
      });
  }

  constructor(private _store: Store<AppState>, private _router: Router) { }
}
