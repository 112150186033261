import {Component, Input, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {MatDialog} from '@angular/material/dialog';
import {Invitation} from 'app/+store/invitation/invitation.model';
import {OrganizationSelectors} from 'app/+store';
import {CreateInvitation} from 'app/+store/invitation/invitation.actions';
import {first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {contactListDtoType} from '../../../../../models/contact-list-dto.model';
import {LicenceControlService} from 'app/services/licence-control.service';
import {EmailValidator} from 'app/lib/validator';
import {FivefConfirm} from '../../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'dvtx-membership-invitation',
  templateUrl: './membership-invitation.component.html',
  styleUrls: ['./membership-invitation.component.scss']
})
export class MembershipInvitationComponent implements OnDestroy {
  private onDestroy = new Subject<void>();
  invitations: Invitation[];
  pendingInviteesEmails = [];
  employeesEmails = [];
  inviteeEmail: string = '';
  contactListDtoType = contactListDtoType;

  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  }
  // in-viewport-rendering setup
  isInViewPort: string[] = [];

  constructor(private store: Store<AppState>,
              private _translateService: TranslateService,
              private _notifyService: NotificationService,
              private _dialog: MatDialog,
              private licenceControl: LicenceControlService
  ) {
    this.currentUser = this.licenceControl._currentUser;
  }

  openMemberInvitationDialog() {
    const inviteeEmail = this.inviteeEmail.trim().toLowerCase();
    if (this.employeesEmails.includes(inviteeEmail)) {
      this.userAlreadyMemberInfo();
    } else if (this.pendingInviteesEmails.includes(inviteeEmail)) {
      this.resentInvitation(inviteeEmail);
    } else {
      this.sendMemberInvitation(inviteeEmail);
    }
  }

  @FivefConfirm({
    message: 'INVITATIONS.USER_IS_MEMBER_CONTENT',
    icon: 'person',
    confirmAction: 'GENERAL.OK_ACTION',
    disableCancelButton: true
  })
  private userAlreadyMemberInfo() {
  }

  @FivefConfirm({
    message: 'INVITATIONS.RESEND_DIALOG_CONTENT',
    icon: 'mail'
  })
  private resentInvitation(inviteeEmail) {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      this.store.dispatch(new CreateInvitation({orgaId: orgaId, inviteeEmail: inviteeEmail}));
      this._translateService.get('INVITATIONS.INVITATION_SENT').pipe(first()).subscribe(
        (message: string) => this._notifyService.info(message)
      );
    });
  }


  @FivefConfirm({
    message: 'INVITATIONS.INVITE_MEMBER_DIALOG_CONTENT',
    icon: 'mail'
  })
  private sendMemberInvitation(inviteeEmail) {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      if (this.inviteeEmail !== '') {
        this.store.dispatch(new CreateInvitation({
          orgaId: orgaId,
          inviteeEmail: inviteeEmail.trim().toLowerCase()
        }));
        this.inviteeEmail = '';
        this._dialog.closeAll();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public isInviteeEmailValid() {
    if (!this.inviteeEmail || !this.inviteeEmail.length)
      return false;
    return EmailValidator.emailValid(this.inviteeEmail);
  }
}
