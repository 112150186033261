import {Component, forwardRef, Injector, Input, OnInit, ChangeDetectorRef, EventEmitter, Output, OnChanges} from '@angular/core';
import {SimplePhoneNumber} from '../../../../../../+store/contact/legacy/models/contact.interface';
import {DvtxControlValueAccessor} from '../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'dvtx-telephone-input',
  templateUrl: './telephone-input.component.html',
  styleUrls: ['./telephone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelephoneInputComponent),
      multi: true,
    }
  ]

})
export class TelephoneInputComponent extends DvtxControlValueAccessor implements OnChanges {
  @Input()
  innerValue: SimplePhoneNumber = new SimplePhoneNumber();

  @Input()
  options;

  @Input()
  matAutocomplete;

  @Output()
  autoCompleteFun: EventEmitter<any> = new EventEmitter();

  @Input()
  isType: boolean;

  @Input()
  isFullWidth: boolean = false;

  constructor(protected injector: Injector,
              private changeDetector: ChangeDetectorRef) {
    super();
  }

  autoCompleteFunction(value) {
    this.autoCompleteFun.emit(value);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.innerValue = obj;
    }
  }

  ngOnChanges(changes) {
    this.changeDetector.detectChanges();
    if (changes && changes.innerValue && changes.innerValue.currentValue) {
      this.innerValue = changes.innerValue.currentValue;
    }
  }


}
