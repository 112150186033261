import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FivefConfirm} from '../../../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';

@Component({
  selector: 'dvtx-message-panel-action-dropdown',
  templateUrl: './message-panel-action-dropdown.component.html',
  host: {class: 'fivef-message-panel-action-dropdown'},
  styleUrls: ['./message-panel-action-dropdown.component.scss']
})
export class MessagePanelActionDropdownComponent implements OnInit, AfterViewInit {


  @Output() onEditMode = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onReply = new EventEmitter();
  @Input() enableReplyButton = false;
  @Input() isAuthor = false;
  @Input() isClosed = true;
  @Input() enableReply = true;

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  @FivefConfirm({
    message: 'GENERAL.DELETE_COMMENT_QUESTION', // title: 'GENERAL.DELETE_COMMENT'
    icon: 'chat_bubble_outline',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_ACTION'
  })
  public deleteComment($event) {
    this.onDelete.emit($event);
  }
}
