import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {LabelActions, MembershipSelectors, OrganizationSelectors} from 'app/+store';
import {Label} from 'app/+store/label/label';
import {GLOBAL_LABELS, LabelScope} from 'app/+store/label/label.interface';
import {Organization} from 'app/models/organization.model';
import {distinctUntilChanged, filter, first, takeUntil} from 'rxjs/operators';
import {Membership} from 'app/models/membership.model';
import {ActivatedRoute} from '@angular/router';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {LabelService} from '../../../../../+store/label/label.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'dvtx-labels-global-settings-frame',
  templateUrl: './labels-global-settings-frame.component.html',
  styleUrls: ['./labels-global-settings-frame.component.scss']
})
export class LabelsGlobalSettingsFrameComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public scope = LabelScope;
  myMembership: Observable<Membership | undefined>;
  labels$: Observable<Label[]>;

  public gloablLabels = GLOBAL_LABELS;
  public organizationLabels$: BehaviorSubject<Label[]> = new BehaviorSubject<Label[]>(undefined);
  public userLabels$: BehaviorSubject<Label[]> = new BehaviorSubject(undefined);

  public organization$: Observable<Organization>;
  public returnUrl: string;

  selectedTab: any = 0;

  organization;

  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  searchTerm: string = '';

  constructor(private store: Store<AppState>,
              private labelsSvc: LabelService,
              private route: ActivatedRoute) {
    this.myMembership = this.store.select(MembershipSelectors.getMyMembership);
    this.labels$ = this.labelsSvc.sortedLabels;
  }

  ngOnInit(): void {
    this.orgId = this.route.snapshot.params.id;

    this.organization$ = this.store.select(OrganizationSelectors.getSelected).pipe(
      filter(organization => !!organization),
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    )
    const currentUser$ = this.store.select('currentUser').pipe(
      filter(user => !!user),
      first()
    );

    combineLatest(this.organization$, currentUser$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([organization, user]) => {
        if (organization.id && user.uid) {
          this.organization = organization;
          this.store.dispatch(new LabelActions.Reset());
          this.store.dispatch(new LabelActions.LoadAll());
        }
      });

    this.routes = [{
      title: 'LABELS.ORGANIZATOIN_LABELS',
      route: `/organization/${this.orgId}/labels-settings/organization`
    }, {
      title: 'LABELS.5F_GLOBAL_LABELS',
      route: `/organization/${this.orgId}/labels-settings/global`,
    }];
    this.activeLink = this.routes[1].title;

    this.labels$.pipe(
      filter(labels => !!labels),
      distinctUntilChanged(),
      takeUntil(this.onDestroy)
    ).subscribe(labels => {
      const organizationLabels = [];
      const userLabels = [];
      labels.forEach(label => {
        switch (label.scope) {
          case LabelScope.ORGANIZATIONAL:
            organizationLabels.push(label);
            break;
        }
        this.organizationLabels$.next(organizationLabels);
        this.userLabels$.next(userLabels);

        this.routes = [{
          title: 'LABELS.ORGANIZATOIN_LABELS',
          route: `/organization/${this.orgId}/labels-settings/organization`,
          count: organizationLabels.length
        }, {
          title: 'LABELS.5F_GLOBAL_LABELS',
          route: `/organization/${this.orgId}/labels-settings/global`,
        }];
      })
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.organizationLabels$.complete();
    this.userLabels$.complete();
  }

  public search($event) {
    this.searchTerm = $event;
  }
}
