import 'froala-editor/js/plugins.pkgd.min.js'
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiListingDropdownComponent} from './components/api-listing-dropdown/api-listing-dropdown.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {DateAdapter, MatNativeDateModule} from '@angular/material/core';
import {MatOptionModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {ApiModule} from '../api/api.module';
import {ApiAutocompleteDropdownComponent} from './components/api-autocomplete-dropdown/api-autocomplete-dropdown.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {DatepickerEmitterComponent} from './components/datepicker-emitter/datepicker.component';
import {RadioGroupComponent} from './components/radio-group/radio-group.component';
import {AddressAutocompleteComponent} from './components/address-autocomplete/address-autocomplete.component';
import {HintComponent} from './components/hint/hint.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {ToggleComponent} from './components/slide-toggle/slide-toggle.component';
import {InputComponent} from './components/input/input.component';
import {InputPasswordComponent} from './components/password/password.component';
import {NumberComponent} from './components/number/number.component';
import {TextComponent} from './components/text/text.component';
import {WeekdayCounterComponent} from './components/weekday-counter/weekday-counter.component';
import {EmailAddressComponent} from './components/email-address/email-address.component';
import {PhoneNumberComponent} from './components/phone-number/phone-number.component';
import {PersonNameComponent} from './components/person-name/person-name.component';
import {ApiAutocompletedInputSelectComponent} from './components/api-autocompleted-input-select/api-autocompleted-input-select.component';
import {EmailAddressGroupComponent} from './components/email-address-group/email-address-group.component';
import {PhoneNumberGroupComponent} from './components/phone-number-group/phone-number-group.component';
import {AddressAutocompleteGroupComponent} from './components/address-autocomplete-group/address-autocomplete-group.component';
import {BaseProcessInfoComponent} from './components/base-process-info/base-process-info.component';
import {ContactPersonAutocompleteComponent} from './components/contact-person-autocomplete/contact-person-autocomplete.component';
import {FileUploadModule} from 'ng2-file-upload';
import {WysiwygComponent} from './components/wysiwyg-editor/wysiwyg.component';
import {OrganizationInfoComponent} from './components/organization-info/organization-info.component';
import {IbanInputComponent} from './components/1_control-value-accessor-components/iban-input/iban-input.component';
import {BicInputComponent} from './components/1_control-value-accessor-components/bic-input/bic-input.component';
import {ApiSelectCvaComponent} from './components/1_control-value-accessor-components/api-select-cva/api-select-cva.component';
import {TextInputCvaComponent} from './components/1_control-value-accessor-components/1_atoms/text-input-cva/text-input-cva.component';
import {RadioCvaComponent} from './components/1_control-value-accessor-components/1_atoms/radio-cva/radio-cva.component';
import {DatepickerCvaComponent} from './components/1_control-value-accessor-components/1_atoms/datepicker-cva/datepicker-cva.component';
import {ListbuilderCvaComponent} from './components/1_control-value-accessor-components/1_atoms/listbuilder-cva/listbuilder-cva.component';
import {CheckboxCvaComponent} from './components/1_control-value-accessor-components/1_atoms/checkbox-cva/checkbox-cva.component';
import {NumberInputCvaComponent} from './components/1_control-value-accessor-components/1_atoms/number-input-cva/number-input-cva.component';
import {TelephoneGroupCvaComponent} from './components/1_control-value-accessor-components/telephone-group-cva/telephone-group-cva.component';
import {TelephoneInputComponent} from './components/1_control-value-accessor-components/telephone-input/telephone-input.component';
import {AddressInputCvaComponent} from './components/1_control-value-accessor-components/address-input-cva/address-input-cva.component';
import {AddressInputGroupCvaComponent} from './components/1_control-value-accessor-components/address-input-group-cva/address-input-group-cva.component';
import {EmailAddressInputComponent} from './components/1_control-value-accessor-components/email-address-input/email-address-input.component';
import {EmailAddressGroupCvaComponent} from './components/1_control-value-accessor-components/email-address-group-cva/email-address-group-cva.component';
import {ContactSelectComponent} from './components/1_control-value-accessor-components/contact-select/contact-select.component';
import {ContactChipsCvaComponent} from './components/1_control-value-accessor-components/1_atoms/contact-chips-cva/contact-chips-cva.component';
import {DefaultDisabledStateDirective, DisabledToggleDirective} from './directives/disabled-toggle.directive';
import {WysiwygEditorCvaComponent} from './components/wysiwyg-editor-cva/wysiwyg-editor-cva.component';
import {ClipboardModule} from 'ngx-clipboard';
import {SearchComponent} from './components/search/search.component';
import {GermanDateAdapter} from './services/german-date-adapter.service';
import {OverlayModule} from '@angular/cdk/overlay';
import {IconComponent} from './components/icon/icon.component';
import {DescriptionEditorComponent} from './components/description-editor/description-editor.component';
import {ErrorWysiwygComponent} from './components/wysiwyg-editor-cva/error-wysiwyg/error-wysiwyg.component';
import {IntTelephoneInputComponent} from './components/1_control-value-accessor-components/int-telephone-input/int-telephone-input.component';
import {UserAccountModule} from '../user-account/user-account.module';
import {DescriptionEditorV2Component} from './components/description-editor-v2/description-editor-v2.component';
import {DatepickerButtonComponent} from './components/datepicker-button/datepicker-button.component';
import {ListChoiceSelectComponent} from './components/list-choice-select/list-choice-select.component';
import {AddressInputFormComponent} from './components/address-input-form/address-input-form.component';
import {FivefDisableInputHintsWrapperDirective} from '../../../lib/fivef-ui/input/fivef-disable-input-hints-wrapper.directive';

const materialMods = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  OverlayModule,
  UserAccountModule
];

const components = [
  AddressAutocompleteComponent,
  AddressAutocompleteGroupComponent,
  AddressInputCvaComponent,
  AddressInputGroupCvaComponent,
  ApiAutocompletedInputSelectComponent,
  ListChoiceSelectComponent,
  ApiAutocompleteDropdownComponent,
  ApiListingDropdownComponent,
  ApiSelectCvaComponent,
  BaseProcessInfoComponent,
  BicInputComponent,
  CheckboxComponent,
  CheckboxCvaComponent,
  ContactChipsCvaComponent,
  ContactPersonAutocompleteComponent,
  DatepickerComponent,
  DatepickerButtonComponent,
  DatepickerCvaComponent,
  DatepickerEmitterComponent,
  DescriptionEditorComponent,
  DescriptionEditorV2Component,
  EmailAddressComponent,
  EmailAddressInputComponent,
  EmailAddressGroupComponent,
  EmailAddressGroupCvaComponent,
  HintComponent,
  IbanInputComponent,
  IconComponent,
  InputComponent,
  InputPasswordComponent,
  ListbuilderCvaComponent,
  NumberComponent,
  NumberInputCvaComponent,
  OrganizationInfoComponent,
  PersonNameComponent,
  PhoneNumberComponent,
  PhoneNumberGroupComponent,
  RadioCvaComponent,
  RadioGroupComponent,
  TelephoneGroupCvaComponent,
  TelephoneInputComponent,
  TextComponent,
  TextInputCvaComponent,
  ToggleComponent,
  WeekdayCounterComponent,
  WysiwygComponent,
  WysiwygEditorCvaComponent,
  ContactSelectComponent,
  DisabledToggleDirective,
  DefaultDisabledStateDirective,
  SearchComponent,
  ErrorWysiwygComponent,
  IntTelephoneInputComponent,
  AddressInputFormComponent
];

@NgModule({
    imports: [
        CommonModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule,
        FileUploadModule,
        ...materialMods,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        FivefDisableInputHintsWrapperDirective,
    ],
  declarations: components,
  exports: [
    CommonModule,
    ClipboardModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    ...components,
    ...materialMods,
  ],
  providers: [
    {provide: DateAdapter, useClass: GermanDateAdapter},
  ]
})
export class BaseFormElementsModule {
}
