import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {InViewportModule} from 'ng-in-viewport';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {FastDocsActionsComponent} from './components/fastdocs-actions/fastdocs-actions.component';
import {FastdocsDatepickerComponent} from './components/fastdocs-datepicker/fastdocs-datepicker.component';
import {FastdocsIndicatorsComponent} from './components/fastdocs-indicators/fastdocs-indicators.component';
import {FastdocsActionsService} from './services/fastdocs-listing-actions.service';
import {FastdocsListingComponent} from './container/fastdocs-listing/fastdocs-listing.component';
import {FasdocsStatusBarComponent} from './components/fastdocs-status-bar/fastdocs-status-bar.component';
import {FivefStatusSelectorComponent} from '../../../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.component';

@NgModule({
  imports: [
    SharedModule,
    InViewportModule,
    TooltipModule,
    FivefStatusSelectorComponent
  ],
  declarations: [
    FastdocsListingComponent,
    FastDocsActionsComponent,
    FastdocsDatepickerComponent,
    FastdocsIndicatorsComponent,
    FasdocsStatusBarComponent
  ],
  exports: [
    FastdocsListingComponent
  ],
  providers: [
    FastdocsActionsService
  ]
})
export class FastdocsListingModule {
}
