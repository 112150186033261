import {Store} from '@ngrx/store';
import {ContactSelectors, EmailToNameSelectors, ProcessParticipantSelectors} from 'app/+store';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {AppState} from 'app/reducers';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import Tribute from 'tributejs';
import {CacheFroala} from '../../+store/cache-froala/cache-froala';
import {CacheFroalaActions} from '../../+store/cache-froala';
import {User} from '../../models/user';


interface IProcessParticipantDto {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
}

interface IMentionedContact {
  key: string;
  value: string;
  firstName: string;
  lastName: string;
  isMember: boolean;
  hasAccount: boolean;
}

export class FroalaRepository {
  private onDestroy = new Subject();
  public tribute: Observable<any>;
  private processId$ = new BehaviorSubject<string>(null);
  private currentUser: User;
  private returnedCachedValue$ = new BehaviorSubject<string>(null);
  public returnedCachedValue = this.returnedCachedValue$.asObservable();
  private froalaId$ = new BehaviorSubject<string>(null);

  constructor(private _store: Store<AppState>) {
    //TODO: froala mention and cache features disabled till now for later release
    // this._initRepository();
  }

  public destroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
    this.froalaId$.complete();
  }

  public set processId(pid) {
    this.processId$.next(pid);
  }

  public set froalaId(froalaId) {
    this.froalaId$.next(froalaId);
  }

  private _initRepository() {
    this._store.select('currentUser').pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        this.currentUser = user;
      });
    const processParticipants$ = this.processId$.pipe(distinctUntilChanged(), takeUntil(this.onDestroy), switchMap(id => {
      return this._store.select(ProcessParticipantSelectors.getParticipantsByProcessId(id));
    }));
    const emailToName$ = this._store.select(EmailToNameSelectors.getEmailNameMap)
    const refreshNameByNameStore$ = this.refreshNameByNameStore$(processParticipants$, emailToName$);
    const membersContactMap$ = this._store.select(ContactSelectors.getMembersOfSelectedOrgMap);
    const tributeParticipants$ = this.mapParticipantTribute(refreshNameByNameStore$, membersContactMap$);
    this.tribute = this.manageTribute(tributeParticipants$);
  }

  private manageTribute(tributeParticipants) {
    const self = this;
    return tributeParticipants.pipe(takeUntil(this.onDestroy), distinctUntilChanged(), map(
      (participantsTribute: IMentionedContact[]) => {
        if (participantsTribute && participantsTribute.length > 0) {
          return new Tribute({
            values: participantsTribute,
            menuItemTemplate: function (item) {
              return self._tributeMenuTemplate(item);
            },
            selectTemplate: function (item) {
              if (item && item.original) {
                return self._tributeSelectTemplate(item);
              }
            },
            allowSpaces: true,
            noMatchTemplate() {
              return null
            }
          })
        } else {
          return null;
        }
      }))
  }

  private refreshNameByNameStore$(processParticipants: Observable<ProcessParticipant[]>, EmailToName: Observable<any>): Observable<IProcessParticipantDto[]> {
    return combineLatest(processParticipants, EmailToName).pipe(takeUntil(this.onDestroy), distinctUntilChanged(), map(([participants, emailToNameMap]) => {
      if (!participants || !participants.length) return [];
      return participants.map(p => {
        const emailToNameMatch = emailToNameMap[p.email];
        const processParticipant: IProcessParticipantDto = {
          email: p.email,
          firstName: emailToNameMatch ? emailToNameMatch.firstName : p.firstName,
          lastName: emailToNameMatch ? emailToNameMatch.lastName : p.lastName,
          name: emailToNameMatch ? emailToNameMatch.name : p.name
        };
        return processParticipant;
      })
    }));
  }

  private mapParticipantTribute(refreshNameByNameStore: Observable<IProcessParticipantDto[]>, members: Observable<any>): Observable<IMentionedContact[]> {
    return combineLatest(refreshNameByNameStore, members).pipe(takeUntil(this.onDestroy), distinctUntilChanged(), map(([participants, members]) => {
      if (!participants || !participants.length) return [];
      return participants.map(p => {
        const membersMatch = members[p.email];
        const mentionedContact: IMentionedContact = {
          value: p.email,
          key: membersMatch ? membersMatch.name : p.name,
          firstName: membersMatch ? membersMatch.firstName : p.firstName,
          lastName: membersMatch ? membersMatch.lastName : p.lastName,
          isMember: membersMatch ? membersMatch.isMember : false,
          hasAccount: membersMatch ? membersMatch.hasAccount : false
        }
        return mentionedContact;
      })
    }));
  }

  public _tributeMenuTemplate(item) {
    if (item && item.original) {
      const hasName = item.original.firstName && item.original.lastName;
      if (item.original.isMember) {
        return this._isMemberTemplate(item, hasName);
      } else if (item.original.hasAccount && !item.original.isMember) {
        return this._hasAccountTemplate(item, hasName);
      } else {
        return this._notMemberTemplate(item, hasName);
      }
    }
  }

  private _isMemberTemplate(item, hasName) {
    return `<div class="d-inline-block"
    data-mention-avatar="${hasName ? item.original.firstName[0].toUpperCase() : item.original.value[0].toUpperCase()}${hasName ? item.original.lastName[0].toUpperCase() : item.original.value[0].toUpperCase()}"></div>
    &nbsp;&nbsp;${item.original.key}
     <div class="mention-check-icon mention-verification member-check">check_circle</div>`;
  }

  private _hasAccountTemplate(item, hasName) {
    return `<div class="d-inline-block"
    data-mention-avatar="${hasName ? item.original.firstName[0].toUpperCase() : item.original.value[0].toUpperCase()}${hasName ? item.original.lastName[0].toUpperCase() : item.original.value[0].toUpperCase()}"></div>
    &nbsp;&nbsp;${item.original.key}
     <div class="mention-check-icon mention-verification check">check_circle</div>`;
  }

  private _notMemberTemplate(item, hasName) {
    return `<div class="d-inline-block"
    data-mention-avatar="${hasName ? item.original.firstName[0].toUpperCase() : item.original.value[0].toUpperCase()}${hasName ? item.original.lastName[0].toUpperCase() : item.original.value[0].toUpperCase()}"></div>
    &nbsp;&nbsp;${item.original.key}`;
  }

  public _tributeSelectTemplate(item) {
    return `<span class="fr-deletable fr-tribute" data-mentioned-email="${item.original.value}" contenteditable="false">${item.original.key}</a></span>`;
  }

  // add , remove, and load Cache Froala text Section
  public cacheFroalaText(textValue) {
    if (this.currentUser?.uid && this.froalaId$.value) {
      const cacheFroala = new CacheFroala(this.currentUser.uid + '/' + this.froalaId$.value, textValue);
      this._store.dispatch(new CacheFroalaActions.Create(cacheFroala));
    }
  }

  public initCachedText() {
    //TODO: froala mention and cache features disabled till now for later release
    return;
    // if (this.currentUser.uid && this.froalaId$.value) {
    //   this._store.select(CacheFroalaSelectors.getOne(this.currentUser.uid + '/' + this.froalaId$.value)).pipe(first()).subscribe((value) => {
    //     if (value && value.text) {
    //       this.returnedCachedValue$.next(value.text);
    //     }
    //   });
    // }
  }

  public RemoveCachedFroala() {
    if (this.currentUser?.uid && this.froalaId$.value) {
      this._store.dispatch(new CacheFroalaActions.Remove(this.currentUser.uid + '/' + this.froalaId$.value))
    }
  }
}
