import {first} from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Subject} from 'rxjs/internal/Subject';
import {TwoFactorUserWhitelistService} from 'app/modules/organization/services/two-factor-user-whitelist.service';
import {UserTwoFactorWhitelist} from 'app/shared/modules/api/models/userTwoFactorWhitelist';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {FivefConfirmParticipantDialogComponent} from '../../../../../lib/fivef-ui/participation/fivef-confirm-participant-dialog/fivef-confirm-participant-dialog.component';

@Component({
  selector: 'dvtx-user-whitelist-two-factor-dialog',
  templateUrl: './user-whitelist-two-factor-dialog.component.html',
  styleUrls: ['./user-whitelist-two-factor-dialog.component.scss']
})
export class UserWhitelistTwoFactorDialogComponent implements OnDestroy {
  private onDestroy = new Subject();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('dialogTpl', {static: true})
  private dialogTpl: TemplateRef<any>;
  private dialogRef: MatDialogRef<any>;

  // For the autocomplete to exclude current participants.
  public excludedIds = [];
  public newParticipant = new UntypedFormControl(null);
  public usersWhiteListed: UserTwoFactorWhitelist[] = [];

  constructor(private _dialog: MatDialog,
              private _notifyService: NotificationService,
              private _whitelistSvc: TwoFactorUserWhitelistService,
              private _cdr: ChangeDetectorRef,
              private avatarService: AvatarService) {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.loading$.complete();
  }

  private getAllWhitelistedUsers() {
    this.loading$.next(true);
    this._whitelistSvc.getAll().pipe(first()).subscribe((whitelisted: UserTwoFactorWhitelist[]) => {
      this.usersWhiteListed = [];
      this.excludedIds = [];
      this.usersWhiteListed = whitelisted;
      if (whitelisted && whitelisted.length > 0) {
        this.excludedIds = whitelisted.map(a => a.email);
      }
      this.loading$.next(false);
      this._cdr.detectChanges();
    }, error => {
      console.error(error);
      this._notifyService.error('HTTP_ERROR.DEFAULT')
    });
  }

  public addUserToWhitelist(participant) {
    if (!participant) return;

    this._dialog.open(FivefConfirmParticipantDialogComponent, {
      data: {
        title: 'AUTH.EXCLUDE_USER_TWO_FACTOR',
        message: 'GENERAL.ADD_PARTICIPANT_WHITELIST',
        submitButtonTitle: 'GENERAL.CONFIRM_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        email: participant?.email,
        onSubmitAction: () => {
          this._whitelistSvc.create(participant.email).pipe(first()).subscribe((user: UserTwoFactorWhitelist) => {
            this.getAllWhitelistedUsers();
            this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
            this._cdr.detectChanges();
          }, error => {
            console.error(error);
            this._notifyService.error('HTTP_ERROR.DEFAULT')
          })
        },
        onCancelAction: () => {
        }
      }
    });
  }

  public openWhiteListTwoFactorAuthDialog() {
    this.getAllWhitelistedUsers();
    this.dialogRef = this._dialog.open(this.dialogTpl, {
      autoFocus: false
    });
  }

  public openRemoveUserTwoFactorDialog(participant: ProcessParticipant) {
    if (!participant || !participant.email) return;

    this._dialog.open(FivefConfirmParticipantDialogComponent, {
      data: {
        title: 'AUTH.EXCLUDE_USER_TWO_FACTOR',
        message: 'GENERAL.REMOVE_PARTICIPANT_WHITELIST',
        submitButtonTitle: 'GENERAL.REMOVE_ACTION',
        cancelButtonTitle: 'GENERAL.CANCEL_ACTION',
        color: 'warn',
        remove: true,
        email: participant.email,
        onSubmitAction: () => {
          this._whitelistSvc.remove(participant.email).pipe(first()).subscribe((user: UserTwoFactorWhitelist) => {
            this.getAllWhitelistedUsers();
            this._notifyService.success('GENERAL.UPDATE_ACTION_DONE');
            this._cdr.detectChanges();
          }, error => {
            console.error(error);
            this._notifyService.error('HTTP_ERROR.DEFAULT')
          });
        },
        onCancelAction: () => {

        }
      }
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
