<div class="fivef-telephone-group-cva">
  <div *ngFor="let number of telephonenumbers" class="fivef-telephone-group-cva--phone">
    <dvtx-telephone-input
      [innerValue]="number"
      [isDisabled]="disabled"
      [isType]="true"
    ></dvtx-telephone-input>
    <div class="d-flex align-items-end" *ngIf="!disabled">
      <button mat-icon-button (click)="add(number)" [disabled]="disabled || !number.isValid || !number.phoneNumber"
              *ngIf="!number.id">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button (click)="remove(number)" [disabled]="disabled">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="!disabled && showAddPhone">
    <button mat-icon-button type="button" [disabled]="disabled" (click)="addNew()"
            class="fivef-telephone-group-cva--add-button"
            disableRipple="true">
      <mat-icon>add_circle_outline</mat-icon>
      <span>{{ 'CONTACTS.ADD_PHONE' | translate }}</span>
    </button>
  </div>
</div>
