import {Component, Input, EventEmitter, Output, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {UserAccountModule} from '../../../../shared/modules/user-account/user-account.module';
import {FIVEF_ICON_TYPE_MAPPING} from './fivef-reaction.interface';
import {CommentReactionType} from '../../../../+store/comment/comment.interface';

@Component({
  selector: 'fivef-reaction',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    UserAccountModule
  ],
  templateUrl: './fivef-reaction.component.html',
  styleUrls: ['./fivef-reaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefReactionComponent {
  public showDropdown = false;
  public keepOpen = false;

  @Input()
  reaction;

  @Input()
  icon;

  @Input()
  showAvatarTitle = true;

  @Output()
  onReaction = new EventEmitter<CommentReactionType>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  public toggleKeepOpen($event) {
    this.keepOpen = $event;
    this.cdr.detectChanges();
  }

  public closeShowDropdown($event) {
    setTimeout(() => {
      this.showDropdown = $event;
      this.cdr.detectChanges();
    }, 100);
  }

  public openShowDropdown($event) {
    this.showDropdown = $event;
  }

  public react(type: CommentReactionType = null) {
    this.showDropdown = false;
    this.keepOpen = false;
    this.cdr.detectChanges();
    let _type = type;
    if (!_type) {
      _type = FIVEF_ICON_TYPE_MAPPING[this.icon];
    }
    this.onReaction.emit(_type);
  }
}
