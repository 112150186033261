import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FileUtils} from 'app/lib/file_utils/functions';
import {of} from 'rxjs';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {ExcelExportActionTypes, GenerateExcel, GenerateExcelFail, GenerateExcelSuccess} from './excel-export.actions';
import {ExcelExportService} from './excel-export.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ExcelExportEffects {
  exportThirdParty$ = createEffect(() => this.actions.pipe(
    ofType(ExcelExportActionTypes.GenerateExcel),
    switchMap((action: GenerateExcel) => {
      return this._svc.generateExcel(action.excelExport).pipe(
        first(),
        concatMap((response: any) => {
          const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          FileUtils.saveAsFile(response.data.attributes.filename, response.data.attributes.content, mimeType);
          this._notifyService.success('The file is generated successfully.');
          return [new GenerateExcelSuccess(response.data)];
        }),
        catchError(err => {
          console.error(err);
          return of(new GenerateExcelFail(err));
        }));
    })
  ));

  constructor(
    private actions: Actions,
    private _svc: ExcelExportService,
    private _notifyService: NotificationService
  ) {
  }
}
