import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {ContactPersonActions, ContactPersonSelectors} from 'app/+store/contact-person';
import {ContactPerson} from 'app/models/contact-person.model';
import {RxjsBaseComponent} from 'app/shared/base-classes/rxjs-base-component';
import {first, map, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {ContactPersonBaseForm, contactPersonBaseFormFromContact, newContactBaseForm} from '../../../../../organization/models/person-contact-base-form';
import {FeatureSelectors, InvitationSelectors, MembershipSelectors, OrganizationSelectors, PartnerLinkParticipationActions, PartnerLinkParticipationSelectors} from 'app/+store';
import {LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {InviteService} from 'app/modules/organization/components/organization-view/invite.service';
import {Invitation, InvitationStatus, InvitationType} from 'app/+store/invitation/invitation.model';
import {Membership} from 'app/models/membership.model';
import {Feature} from 'app/+store/feature/feature';

@Component({
  selector: 'dvtx-edit-contact-person-dialog',
  templateUrl: './edit-contact-person-dialog.component.html',
  styleUrls: ['./edit-contact-person-dialog.component.scss']
})
export class EditContactPersonDialogComponent extends RxjsBaseComponent implements OnInit {
  filterType$ = new BehaviorSubject<InvitationType>(undefined);
  InvitationStatus = InvitationStatus;
  invitationStatus = null;
  invitationHasSent = false;
  editmode: boolean = true;
  personContact?: ContactPerson = undefined;
  personContactForm: ContactPersonBaseForm = newContactBaseForm();
  assignedOrgaNames: Observable<string[]>;
  description: string = 'CONTACTS.MASTER_DATA_DESCRIPTION';
  currentUser: any;
  administrationRights: Observable<Membership>;
  featureSet$: Observable<Feature>;
  partnerLinkId: string = null;

  private contactSubscription: Subscription;
  private organizationsSubscription: Subscription;
  invitationDate: Date;
  @Input() contactId;
  @Input() createdBy;
  @Output() onSave = new EventEmitter();

  constructor(
    private store: Store<any>,
    private inviteService: InviteService,
    private _cdr: ChangeDetectorRef
  ) {
    super(); // call to super class
  }

  ngOnInit() {
    const contact$ = this.store.select(ContactPersonSelectors.getPersonContactById(this.contactId));

    this.currentUser = this.store.select('currentUser');
    this.administrationRights = this.store.select(MembershipSelectors.getMyMembership);
    const partnerLinkMap$ = this.store.select(PartnerLinkParticipationSelectors.getPartnerLinkMapOfSelectedOrg);
    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);

    // Fetch Partner Link activation state if present for contact person entry
    combineLatest(contact$, partnerLinkMap$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([contact, partnerLinkMap]) => {
        if (contact && contact.mainEmailAddress && contact.mainEmailAddress.emailAddress) {
          this.partnerLinkId = partnerLinkMap[contact.mainEmailAddress.emailAddress.toLowerCase()];
        } else {
          this.partnerLinkId = null
        }
        this._cdr.detectChanges();
      });

    this.filterType$.next(InvitationType.ContactPerson);
    this.store.dispatch(new ContactPersonActions.LoadOne(this.contactId));

    if (this.contactSubscription) {
      this.contactSubscription.unsubscribe();
    }
    this.contactSubscription = contact$.pipe(
      takeUntil(this.onDestroy)
    ).subscribe((ps: ContactPerson) => {
      this.personContact = ps;
      this.personContactForm = contactPersonBaseFormFromContact(ps);

      combineLatest(this.store.select(InvitationSelectors.selectOrgasInvitations), this.filterType$)
        .pipe(
          map(([invitations, filterType]: [Invitation[], InvitationType]) => {
            if (this.personContact && this.personContact.mainEmailAddress.emailAddress) {
              return invitations.filter((invitation) => invitation.type === filterType && this.personContact.mainEmailAddress.emailAddress === invitation.invitee_email);
            }

          }),
          takeUntil(this.onDestroy)
        )
        .subscribe(invis => {
          if (invis && invis.length > 0) {
            this.invitationStatus = invis[0].current_status;
            this.invitationDate = invis[0].created_at;
          }
        });

    });
    this.store.dispatch(new ContactPersonActions.LoadAssignedOrganizations(this.contactId));
    this.assignedOrgaNames = this.store.select(ContactPersonSelectors.getAssignedOrganizations(this.contactId))

    // This is needed, so that the hasAccount property of an organization is properly updated.
    // this.dialogRef.afterClosed().pipe(
    //   first()
    // ).subscribe(() => {
    //     this.store.select(OrganizationSelectors.getSelected).pipe(
    //       first()
    //     ).subscribe((orga: Organization) => {
    //       this.store.dispatch(new ContactActions.LoadAll(orga))
    //     })
    // });
    // Removed since not needed , we only need to fetch by id
  }

  tabClick(event) {
    switch (event.index) {
      case 0: {
        this.description = 'CONTACTS.MASTER_DATA_DESCRIPTION'
        break;
      }
      case 1: {
        this.description = 'CONTACTS.ADDITIONAL_DATA_DESCRIPTION'
        break;
      }
      case 2: {
        this.description = 'CONTACTS.ASSOCIATED_DATA_DESCRIPTION'
        break;
      }
      case 3: {
        this.description = 'CONTACTS.FURTHER_DATA_DESCRIPTION'
        break;
      }
      default: {
        // statements;
        break;
      }
    }
  }

  saveData() {
    this.personContact = {
      ...this.personContact,
      ...this.personContactForm.contactPerson
    };
    this.store.dispatch(new ContactPersonActions.EditContact(this.personContact));
    // this.editmode = false;
    // this.onSave.emit(this.personContact);
  }

  cancelEdit() {
    // this.refreshSubject.next();
    this.editmode = false;
  }

  sendMemberInvitation() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      if (this.personContact.mainEmailAddress.emailAddress !== '') {
        this.inviteService.inviteBusinessPartner(this.personContact.mainEmailAddress.emailAddress).subscribe(() => {
          this.store.dispatch(new LoadOfOrganization(orgaId));
          this.invitationHasSent = true;
          this.onSave.emit(this.personContact);
        });
      }
    });
  }

  authorizePartner($event) {
    if (!this.personContact) return;

    if ($event.checked) {
      this.store.dispatch(new PartnerLinkParticipationActions.Create(this.personContact.mainEmailAddress.emailAddress));
    } else if (this.partnerLinkId) {
      this.store.dispatch(new PartnerLinkParticipationActions.Remove(this.partnerLinkId));
    }
  }
}
