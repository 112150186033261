import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Destroy,
  DestroyFail,
  DestroySuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  ProcessAttributeActionTypes,
  UpdateAttribute,
  UpdateAttributeFail,
  UpdateAttributeSuccess,
} from './process-attribute.actions';
import {ProcessAttributeService} from './process-attribute.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessAttribute} from './process-attribute';

@Injectable()
export class ProcessAttributeEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessAttributeActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((res: ProcessAttribute[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ProcessAttributeActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.processId, action.id).pipe(
        first(),
        concatMap((attribute: ProcessAttribute) => {
          return [new LoadOneSuccess(attribute)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(ProcessAttributeActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.id, action.params).pipe(
        first(),
        concatMap((attribute: ProcessAttribute) => {
          return [new CreateSuccess(attribute)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  updateAttribute$ = createEffect(() => this.actions.pipe(
    ofType(ProcessAttributeActionTypes.UpdateAttribute),
    switchMap((action: UpdateAttribute) => {
      return this._svc.update(action.processId, action.id, action.params).pipe(
        first(),
        concatMap((attribute: ProcessAttribute) => {
          return [new UpdateAttributeSuccess(attribute)];
        }),
        catchError(err => {
          console.error(err);
          return of(new UpdateAttributeFail(err));
        }));
    })
  ));

  destroy$ = createEffect(() => this.actions.pipe(
    ofType(ProcessAttributeActionTypes.Destroy),
    switchMap((action: Destroy) => {
      return this._svc.destroy(action.processId, action.id).pipe(
        first(),
        concatMap((attribute: ProcessAttribute) => {
          return [new DestroySuccess(attribute)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DestroyFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessAttributeService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




