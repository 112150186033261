<ng-container *ngIf="(_processId || uploadType === 'accessToken' && !!token) && url && uploader">
  <!-- Appearance type: Widget. Used at project room and sidebars -->
  <ng-template [ngIf]="appearance === 'widget'">
    <!-- Dropzone template: Valid if canUpload by process policy and no element locking -->
    <div class="fivef-upload--appearance-widget">
      <ng-template [ngIf]="canUpload && !locked">
        <div class="fivef-upload--dropzone ff-drag-n-drop"
             ng2FileDrop
             [uploader]="uploader"
             [ngClass]="{'file-over': hasBaseDropZoneOver, 'ff-drag-n-drop':!locked , 'ff-drag-n-drop--lock':locked}"
             (fileOver)="fileOverDropzone($event)">
          <div class="fivef-upload--icon">
            <mat-icon aria-label="upload">file_upload</mat-icon>
          </div>

          <div class="ff-drag-n-drop--button">
            <ng-template [ngIf]="resourceId" [ngIfElse]="processUploader">
              <label
                class="fivef-upload--file-upload-label btn-outline waves-effect"
                for="fivef-upload--label-{{_processId}}-{{resourceId}}-{{role}}-{{labelId}}">
                <input
                  type="file"
                  class="fivef-upload--button-outside"
                  ng2FileSelect
                  [uploader]="uploader"
                  multiple
                  id="fivef-upload--label-{{_processId}}-{{resourceId}}-{{role}}-{{labelId}}"/>
                <span>{{title | translate}}</span>
              </label>
            </ng-template>

            <ng-template #processUploader>
              <label
                class="fivef-upload--file-upload-label btn-outline waves-effect"
                for="fivef-upload--label-{{_processId}}">
                <input
                  type="file"
                  class="fivef-upload--button-outside"
                  ng2FileSelect
                  [uploader]="uploader"
                  multiple
                  id="fivef-upload--label-{{_processId}}"/>
                <span>{{title | translate}}</span>
              </label>
            </ng-template>

            <div class="fivef-upload--info">
              <small>
                <span [innerHtml]="uploadInformation | translate"></span>
                <br />
                <span [innerHTML]="'UPLOAD.PLACEHOLDER_UPLOAD_INFORMATION_MAX_FILE_SIZE' | translate:{fileSize: (maxFileSize / 1000)}"></span>
              </small>

              <div *ngIf="uploadInfoTooltip">
                <mat-icon [matTooltip]="('UPLOAD.SUPPORTED_TYPES' | translate) + supportedFileTypes?.join(', ')">info</mat-icon>
              </div>
            </div>
          </div>
        </div>

        <!-- Upload progress bar -->
        <div class="d-flex align-items-center"
             [class.invisible]="!uploader?.isUploading">
          <div style="flex: 1;">
            <mat-progress-bar mode="determinate"
                              [value]="uploader?.progress"></mat-progress-bar>
          </div>
          <div>
            <button type="button" mat-icon-button (click)="uploader?.cancelAll()" [disabled]="!uploader?.isUploading">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </div>
      </ng-template>

      <!-- Locked view: Valid if canUpload by process policy and with element locking -->
      <ng-template [ngIf]="canUpload && locked">
        <div class="fivef-upload--dropzone ff-drag-n-drop--lock">
          <div class="ff-drag-n-drop--icon">
            <mat-icon [matTooltip]="'UPLOAD.ELEMENT_LOCK_SUCCESSFULLY' | translate">lock</mat-icon>
          </div>
          <p>
            <span [innerHTML]="'UPLOAD.ELEMENT_LOCKED' | translate"></span>
          </p>
        </div>
      </ng-template>

      <!-- Disabled upload process state (by policy) -->
      <ng-template [ngIf]="!canUpload">
        <h3 class="fivef-upload--dropzone--disabled">
          <mat-icon>file_upload</mat-icon>
          {{ 'GENERAL.UPLOADS_DISABLED' | translate }}
        </h3>
      </ng-template>
    </div>
  </ng-template>

  <!-- Appearance type: Cell. Used at document listing and Collecto dashboard -->
  <ng-template [ngIf]="appearance === 'cell'">
    <div class="fivef-upload--appearance-cell"
         *ngIf="uploader && canUpload">
      <div class="fivef-upload--dropzone"
           ng2FileDrop
           [uploader]="uploader"
           [ngClass]="{'fivef-upload--file-over': hasBaseDropZoneOver, 'ff-drag-n-drop': !locked , 'ff-drag-n-drop--lock': locked}"
           (fileOver)="fileOverDropzone($event)">
        <div class="ff-drag-n-drop--button">
          <label class="fivef-upload--file-upload-label btn-outline-lock" [ngClass]="{'waves-effect btn-outline': !locked}"
                 for="fivef-upload--label-{{role}}-{{_processId}}-{{resourceId}}-mini-{{labelId}}">
            <input type="file"
                   class="fivef-upload--button-outside"
                   ng2FileSelect
                   [uploader]="uploader"
                   [disabled]="locked"
                   multiple
                   id="fivef-upload--label-{{role}}-{{_processId}}-{{resourceId}}-mini-{{labelId}}"/>
            <mat-icon *ngIf="locked" aria-label="upload"
                      class="dvtx-fg-color dvtx-fg-fivef-blue-300"
                      [matTooltip]="'UPLOAD.ELEMENT_LOCK_SUCCESSFULLY' | translate">lock</mat-icon>
            <mat-icon *ngIf="!locked" aria-label="upload">file_upload</mat-icon>
          </label>
        </div>
      </div>

      <div class="fivef-upload--loading-state" *ngIf="uploader.isUploading">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    </div>
  </ng-template>
</ng-container>
