import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {OrganizationSelectors} from 'app/+store/organization';
import {Organization} from 'app/models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {InvitationType} from 'app/+store/invitation/invitation.model';
import {debounceTime, distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {ClientSelectors, ContactSelectors, MembershipSelectors} from 'app/+store';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Client} from 'app/+store/client/client';
import {FiveFCardProfile} from 'app/five-f/organization-card/containers/organization-card/organization-card.component';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {WaitingDialogComponent} from 'app/five-f/confirm-dialog/components/waiting-dialog/waiting-dialog.component';
import {OrganizationProxyService} from 'app/+store/organization/organization-proxy.service';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {PERSON_FILTER_OPTIONS} from '../../../../address-book/modules/address-book-table/containers/address-book-table/address-book-table.constants';
import {Membership} from 'app/models/membership.model';
import {ImportContactsComponent} from '../../../../address-book/modules/address-book-table/components/import-contacts/import-contacts.component';
import {ContactTypes} from '../../../../address-book/modules/address-book-table/containers/address-book-table/address-book-table.model';
import {SettingsHeaderComponent} from '../../../../../five-f/organization-card/containers/settings-header/settings-header.component';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'dvtx-organization-addressbook-frame',
  templateUrl: './addressbook-frame.component.html',
  styleUrls: ['./addressbook-frame.component.scss']
})
export class AddressbookFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  private tabs = ['contacts', 'clients'];

  public selectedTab: any = 0;

  public organization$: Observable<Organization>;
  public orgId;
  public InvitationType = InvitationType;
  returnUrl: string;
  clientCount$: Observable<number>;
  contactsCount: number;
  clientCount: number = null;

  FiveFCardProfile = FiveFCardProfile;
  routes: ITabNavRoute[] = [];
  activeLink: string;

  // Generic search bar
  searchTerm: string = null;
  contactType = ContactTypes.All;

  // Filters
  filterOptions = PERSON_FILTER_OPTIONS;
  filters: any = {
    clients: [],
    options: [this.filterOptions.find(a => a.id === 0)]
  };
  // Form for filters search
  searchForm: UntypedFormGroup;

  clients$: Observable<Client[]>;
  filterByOptions = [];
  filterByClients = [];
  clearAllFilters = null;
  public filterForm: UntypedFormGroup;
  filterFor: { typeFilter: ContactTypes, generalFilter: string };

  @ViewChild('callToActionRef', {static: true}) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  @ViewChild('globalActionRef', {static: true}) globalActionRef: TemplateRef<any>;
  globalActionPortal: Portal<any>;

  @ViewChild('settingsHeaderRef') settingsHeaderRef: SettingsHeaderComponent;

  administrationRights$: Observable<Membership>;
  isAdmin = false;

  constructor(private store: Store<AppState>,
              private _route: ActivatedRoute,
              private router: Router,
              private _organizationProxySvc: OrganizationProxyService,
              protected _dialog: MatDialog,
              private _fb: UntypedFormBuilder,
              private _viewContainerRef: ViewContainerRef,
              private _cdr: ChangeDetectorRef) {
    this.filterForm = this._fb.group({
      typeFilter: ['all'],
      generalFilter: [''],
    });
    this.filterFor = this.filterForm.value;
    this.searchForm = this._fb.group({
      searchTerm: [null]
    });
  }

  ngOnInit(): void {
    this.administrationRights$ = this.store.select(MembershipSelectors.getMyMembership);
    this.administrationRights$
      .pipe(filter(p => !!p), takeUntil(this.onDestroy))
      .subscribe(perms => {
        this.isAdmin = perms.hasAdministrationRights;
      });

    this.clients$ = this.store.select(ClientSelectors.getClientsOfSelectedOrg);

    this.orgId = this._route.snapshot.params.id;

    this.updateNavigation();
    this.activeLink = this.routes[0].title;

    this.clientCount$ = this.store.select(ClientSelectors.getClientCount);
    this.clientCount$.pipe(takeUntil(this.onDestroy))
      .subscribe(count => {
        this.clientCount = count;
        this.updateNavigation();
      });

    if (this._route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this._route.snapshot.queryParams['returnUrl'];
    }
    this.store.select(ContactSelectors.getContactsOfSelectedOrg)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(contacts => {
        if (contacts) {
          this.contactsCount = contacts.length;
          this.updateNavigation();
        }
      });

    this._route.params.pipe(
      filter(params => !!params && params['tab']),
      distinctUntilKeyChanged('tab'),
      takeUntil(this.onDestroy)
    ).subscribe(tabs => {
      const routingTab = tabs.tab;
      this.selectedTab = this.tabs.indexOf(routingTab);
      if (this.selectedTab < 0) {
        this.selectedTab = 0;
      }
      this._cdr.detectChanges();
    })

    this.organization$ = this.store.select(OrganizationSelectors.getSelected);

    this.filterForm.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.onDestroy)
      )
      .subscribe((filterFor: { typeFilter: ContactTypes, generalFilter: string }) => {
        this.filterFor = filterFor;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
      this.globalActionPortal = this._createPortal(this.globalActionPortal, this.globalActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  private updateNavigation() {
    this.routes = [{
      title: 'CONTACTS.CONTACTS_TITLE',
      route: `/organization/${this.orgId}/addressbook/contacts`,
      count: this.contactsCount
    }, {
      title: 'CLIENT.CLIENTS',
      route: `/organization/${this.orgId}/addressbook/clients`,
      count: this.clientCount
    }];
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    this.router.navigate([`/organization/${this.orgId}/addressbook/${this.tabs[event.index]}`]);
  }

  navigateBack() {
    if (this.returnUrl)
      this.router.navigateByUrl(this.returnUrl);
    else
      this.router.navigate([`/organization/${this.orgId}/navigation`]);
  }

  search($event: string) {
    this.searchTerm = $event;
  }

  public exportAddressBook() {
    this._dialog.open(WaitingDialogComponent, {
      data: {
        title: 'CONTACTS.ADDRESS_BOOK_EXPORT',
        headline: 'GENERAL.DOWNLOAD_STARTS_SHORTLY',
        callback: (dialogRef) => {
          const _dialogRef = dialogRef;
          this._organizationProxySvc.exportAddressBook(this.orgId, () => _dialogRef.close(), () => _dialogRef.close());
        }
      }
    });
  }

  public importContacts() {
    this._dialog.open(ImportContactsComponent);
  }

  public onFilterByClients(event) {
    if (event && event.length > 0) {
      let selectedClientIds = [];
      selectedClientIds = event.map(c => c.id);
      this.filters.clients = event;
      this.filterByClients = selectedClientIds;
    } else {
      this.filters.clients = [];
      this.filterByClients = [];
    }
    this.onFilterByOptions(this.filters.options);
    this._cdr.detectChanges();
  }

  public onFilterByOptions(options) {
    if (options && options.length > 0) {
      const contactType = options[options.length - 1].value;
      this.filterForm.controls.typeFilter.patchValue(contactType);
      this.filters.options = [options[options.length - 1]];
      this.contactType = contactType;
    } else {
      this.filterForm.controls.typeFilter.patchValue('');
      this.filters.options = [];
      this.contactType = null;
    }
    this.filterByOptions = this.filters.options;
  }

  public onClearAllFilters() {
    this.clearAllFilters = {clear: true};
    this.contactType = ContactTypes.All;
    this.searchTerm = '';
    this.searchForm.reset();
    this.filterForm.reset();
    this.filters.clients = [];
    this.onFilterByOptions([this.filterOptions.find(a => a.id === 0)]);
    this.settingsHeaderRef.clearSearch();
    this._cdr.detectChanges();
  }
}
