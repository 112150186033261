import {IResource} from '../../../shared/modules/api/models/resource.interface';


export enum GroupContextType {
  STANDARD_TASK_STATUS = 'standard_task_status',
  APPROVAL_TASK_STATUS = 'approval_task_status',
  COLLECTO_STATUS = 'collecto_status',
  PRIORITY = 'priority',
  DOCUMENT_UNSORTED = 'document_unsorted',
  TASK_UNSORTED = 'task_unsorted',
  NO_CONTEXT = 'no_context',
  COLLECTO_GROUP = 'collecto_group'
}

export enum GroupContextTypeTitle {
  standard_task_status = 'GENERAL.STATUS',
  approval_task_status = 'GENERAL.STATUS',
  collecto_status = 'GENERAL.STATUS',
  priority = 'GENERAL.PRIORITY',
  collecto_group = 'KANBAN.COLLECTO_GROUP',
  document_unsorted = '',
  task_unsorted = '',
  no_context = ''
}

export interface IKanbanGroup extends IResource {
  order: number;
  labelId: string;
  title: string;
  color: string;
  itemsOrder: string[];
  isExpanded: boolean;
  isContextual: boolean;
  contextType: GroupContextType;
  referenceId?: string;
}

export interface IKanbanGroupParams {
  order?: number;
  labelId?: string;
  title?: string;
  color?: string;
  itemsOrder?: string[];
  isExpanded?: boolean;
  isContextual?: boolean;
  contextType?: GroupContextType;
  referenceId?: string;
}
