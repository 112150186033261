<div class="fivef-icon-message-block"
     [class.fivef-icon-message-block--horizontally]="alignment === 'horizontal'">
  <ng-container [ngSwitch]="icon">
    <mat-icon *ngSwitchCase="'third_party'" svgIcon="third_party" class="fivef-icon-message-block--icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'process'" svgIcon="process" class="fivef-icon-message-block--icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'tasks'" svgIcon="tasks" class="fivef-icon-message-block--icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'hide_image'" svgIcon="hide_image" class="fivef-icon-message-block--icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'engineering'" svgIcon="engineering" class="fivef-icon-message-block--icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchCase="'recycling'" svgIcon="recycling" class="fivef-icon-message-block--icon-{{size}}"></mat-icon>
    <mat-icon *ngSwitchDefault class="fivef-icon-message-block--icon-{{size}}">{{icon}}</mat-icon>
  </ng-container>

  <h3 class="fivef-icon-message-block--headline-{{size}}">{{message | translate}}</h3>
</div>
