import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {DmsDocumentService} from 'app/+store/dms-document/dms-document.service';
import {switchMap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

/**
 * Document file preview component using an image blob and angular img [src].
 *
 *
 * For later: Animation on exchanging the image:
 * === In image tag:
 *   [@fade]="state"
 *   (@fade.done)="onDone($event)"
 * === Import
 *   import {animate, state, style, transition, trigger} from '@angular/animations';
 * === Component
 *   animations: [
 *     trigger('fade', [
 *       state('in', style({ 'opacity': '1' })),
 *       state('out', style({ 'opacity': '0' })),
 *       transition('* <=> *', [
 *         animate(1000)
 *         ])
 *     ])
 *   ],
 * === Tracking state change.
 *   state = 'in';
 *   onDone($event) {
 *     console.error('done begin', this.state)
 *     console.error('done src', this.src)
 *     this.state = this.src === this.defaultImage && this.state === 'in' ? 'out' : 'in';
 *     console.error('done end', this.state)
 *   }
 * === References
 * - https://www.freecodecamp.org/news/how-to-use-animation-with-angular-6-675b19bc3496/
 * - https://stackoverflow.com/questions/47538186/animate-an-image-swap-in-angular-4-was-ng-animate-swap-in-angularjs
 */
@Component({
  selector: 'fivef-artifact-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fivef-artifact-preview.component.html',
  styleUrls: ['./fivef-artifact-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefArtifactPreviewComponent implements OnInit, OnChanges, OnDestroy {
  readonly defaultImage = '/assets/img/default.png';

  public src = this.defaultImage;

  private src$ = new BehaviorSubject(this.src);
  dataUrl$;

  @Input()
  set url(url) {
    this.src = url;
    this.src$.next(url);
  }

  constructor(private documentSvc: DmsDocumentService) { }

  ngOnInit() {
    this.dataUrl$ = this.src$.pipe(switchMap(url => this.documentSvc.preview(url)));
  }

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  ngOnDestroy() {
    this.src$.complete();
  }
}
