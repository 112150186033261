import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CollectorCategoryActionTypes,
  Copy,
  CopyFail,
  CopySuccess,
  Create,
  CreateFail,
  CreateSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess,
  Status,
  StatusFail
} from './collector-category.actions';
import {CollectorCategory} from './collector-category';
import {CollectorCategoryService} from './collector-category.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {CollectorItemService} from '../collector-item/collector-item.service';
import * as itemLookupActions from '../collector-item-lookup/collector-item-lookup.actions';
import {BomDataNodeService} from '../bom/bom-data-node.service';
import {QuickCollectorActions} from '../quickcollector';

@Injectable()
export class CollectorCategoryEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(CollectorCategoryActionTypes.Create),
    switchMap((action: Create) => {
      return this.dataNodeSvc.create(action.processId, action.params).pipe(
        first(),
        concatMap((category: CollectorCategory) => {
          return [new CreateSuccess(category)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  save$ = createEffect(() => this.actions.pipe(
    ofType(CollectorCategoryActionTypes.Save),
    switchMap((action: Save) => {
      return this.dataNodeSvc.updateAttributes(action.processId, action.id, action.params).pipe(
        first(),
        concatMap((category: CollectorCategory) => {
          return [new SaveSuccess(category), new itemLookupActions.ResetAll()];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  status$ = createEffect(() => this.actions.pipe(
    ofType(CollectorCategoryActionTypes.Status),
    switchMap((action: Status) => {
      return this.dataNodeSvc.updateStatus(action.processId, action.id, action.status).pipe(
        first(),
        concatMap((_node) => {
          return [new QuickCollectorActions.UpsertDataNodes(action.processId, action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new StatusFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(CollectorCategoryActionTypes.Remove),
    switchMap((action: Remove) => {
      return this.dataNodeSvc.remove(action.processId, action.id).pipe(
        first(),
        concatMap((category: CollectorCategory) => {
          return [new RemoveSuccess(category)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  copy$ = createEffect(() => this.actions.pipe(
    ofType(CollectorCategoryActionTypes.Copy),
    switchMap((action: Copy) => {
      return this.dataNodeSvc.copy(action.processId, action.id).pipe(
        first(),
        map((category: CollectorCategory) => {
            this._notifyService.success('COLLECTOR.GROUP_COPIED');
          return category;
        }),
        concatMap(category => {
          this.scrollToBottom();
          return [
            new CopySuccess(category),
            new QuickCollectorActions.UpsertDataNodes(action.processId, category.id)
          ];
        }),
        catchError(err => {
          console.error(err);
          return of(new CopyFail(err));
        }));
    })
  ));

  scrollToBottom() {
    const scrollDown = () => {
      const documentSideNavContent = document.querySelector('mat-sidenav-content');
      if (documentSideNavContent) {
        documentSideNavContent.scrollTo({
          top: documentSideNavContent.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    };
    setTimeout(() => {
      scrollDown();
    }, 2000);
    setTimeout(() => {
      scrollDown();
    }, 2500);
  }

  constructor(private actions: Actions,
              private _svc: CollectorCategoryService,
              private dataNodeSvc: BomDataNodeService,
              private _itemSvc: CollectorItemService,
              private _notifyService: NotificationService) {
  }
}
