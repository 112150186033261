import {Directive, HostBinding} from '@angular/core';

@Directive({
  selector: '[fivefSuffixButton]',
  standalone: true
})
export class FivefSuffixButtonDirective {

  @HostBinding('class')
  suffixClass = 'fivef-suffix-button'
}
