<ng-template #callToActionRef>
  <ng-container *ngIf="!loading && !(account$ | async) && form">
    <button type="submit"
            mat-raised-button color="primary"
            class="wide"
            (click)="connect()"
            [disabled]="form.invalid">{{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>
  </ng-container>
</ng-template>

<dvtx-settings-header [routes]="routes"
                      [activeLink]="activeLink"
                      [callToActionPortal]="callToActionPortal"
                      [toOverview]="true"
                      [title]="'USER_NAVIGATON.BOOKMAN_COCKPIT_SETTINGS'"></dvtx-settings-header>

<div class="w-100 pl-3 pt-3 d-flex dvtx-bookman-admin-panel">
  <div class="dvtx-bookman-admin-panel__content-container">
    <ng-container *ngIf="!loading && !(account$ | async) && form">
      <div class="row w-100 mx-0">
        <div class="d-flex mt-1 mb-2">
          <div>
            <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
          </div>
          <p [innerHTML]="'FIBU.CONNECT_TO_FIBU_ACCOUNT_TITLE' | translate" class="mb-0"></p>
        </div>
      </div>
    </ng-container>

    <div class="w-100 d-flex" style="margin-left: -23px;">
      <img src="./assets/img/bookman_connect.png" width="200px"/>
    </div>

    <div style="padding: 14px; height: 100%;" class="dvtx-loading-indicator" *ngIf="loading || !form">
      <div class="d-flex align-items-center">
        <mat-spinner [diameter]="20" style="margin-right: 15px;"></mat-spinner>
        {{ 'GENERAL.LOADING' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
    </div>

    <div class="w-100 m-0 d-flex" *ngIf="!loading">
      <ng-template [ngIf]="(account$ | async)">
        <div class="d-flex w-100 flex-column">
          <h3 class="mt-1">{{ 'GENERAL.STATUS' | translate }}</h3>

          <div class="my-1 w-100 h4 d-flex align-items-center">
            <span class="mr-2">
              <mat-icon class="fivef-color-status-success">check_circle</mat-icon>
            </span>
            {{ 'FIBU.YOUR_5F_ACCOUNT_IS_CONNECTED_TO_BOOKMAN_ACOUNT' | translate }}
          </div>

          <div class="mb-2 w-100 d-flex">
            {{ 'FIBU.YOUR_BOCKMAN_ACCOUNT_USERNAME' | translate }}:
            <strong class="ml-1">{{ (account$ | async).email }}</strong>
          </div>
          <!-- <div class="mb-4 w-100 d-flex">-->
          <!--   {{ 'GENERAL.CREATED_AT' | translate }}:-->
          <!--   <strong class="ml-1">{{ (account$ | async).createdAt | date:'medium' }}</strong>-->
          <!-- </div>-->

          <hr>

          <h3>{{ 'FIBU.DISCONNECT_FROM_FIBU_ACCOUNT_HEADLINE' | translate }}</h3>

          <div class="mb-2 w-100 d-flex">
            <button mat-button color="warn" (click)="unlinkAccount()">
              {{ 'FIBU.DISCONNECT_FROM_FIBU_ACCOUNT' | translate }}
            </button>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="!(account$ | async) && form">
        <div class="w-100 d-flex flex-column">
          <div class="mb-2 w-100 d-flex" *ngIf="credentialError$ | async">
            <dvtx-alert [icon]="'warning'" [color]="'warning'" [text]="'AUTH.INVALID_CREDENTIALS' | translate"
                        class="w-100"></dvtx-alert>
          </div>

          <div class="mb-2 w-100 d-flex" *ngIf="accountLockedError">
            <dvtx-alert [icon]="'warning'" [color]="'warning'" [text]="'FIBU.ACCOUNT_LOCKED_ERROR' | translate"
                        class="w-100"></dvtx-alert>
          </div>
          <div class="w-100 d-flex">
            <div style="width: 100%; max-width: 400px;">
              <div class="mb-2 d-flex">
                <form autocomplete="new-password" class="w-100" novalidate [formGroup]="form" (ngSubmit)="connect()">
                  <input autocomplete="new-password" name="hidden" type="text" style="display:none;">

                  <mat-form-field class="w-100 mb-1">
                    <input matInput [placeholder]="'FIBU.YOUR_BOOKMAN_EMAIL' | translate"
                           email
                           formControlName="username"
                           autocomplete="new-password"/>
                  </mat-form-field>

                  <mat-form-field class="w-100">
                    <input matInput type="password" [placeholder]="'FIBU.YOUR_BOOKMAN_PASSWORD' | translate"
                           formControlName="password"
                           autocomplete="new-password"/>
                  </mat-form-field>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
