import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Load, LoadFail, LoadSuccess, TwoFactorSessionActionTypes} from './two-factor-session.actions';
import {TwoFactorSession} from './two-factor-session';
import {TwoFactorSessionService} from './two-factor-session.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class TwoFactorSessionEffects {
  load$ = createEffect(() => this.actions.pipe(
    ofType(TwoFactorSessionActionTypes.Load),
    switchMap((action: Load) => {
      return this._svc.verify().pipe(
        first(),
        concatMap((session: TwoFactorSession) => {
          return [new LoadSuccess(session)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TwoFactorSessionService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
