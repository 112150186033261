import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {distinctUntilChanged, filter, first, switchMap, takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import * as api from 'app/+store/iam/process-policy.interface';
import * as iam from 'app/+store/iam/permission-policy-manager';
import {AngularTokenService} from 'angular-token';
import {Subject} from 'rxjs/internal/Subject';
import {MessageSettingsV2} from 'app/+store/message-settings/message-settings';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FivefConfirm} from '../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';

@Component({
  selector: 'dvtx-timeline-notification-panel',
  templateUrl: './timeline-notification-panel.component.html',
  styleUrls: ['./timeline-notification-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineNotificationPanelComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  public policy: BehaviorSubject<api.Iam.IProcessPolicy> = new BehaviorSubject<api.Iam.IProcessPolicy>(api.Iam.DEFAULT_PROCESS_POLICY);

  private processId$ = new BehaviorSubject<string>(null);
  public _process: { [id: string]: string };

  public showDropdown = false;

  public overrideGlobalSettings = false;

  /**
   * Notification type filters.
   * Currently we support five categories for notifiication types + the unread flag.
   * "notify_comments",
   * "notify_uploads",
   * "notify_assignments",
   * "notify_updates",
   * "notify_confirmations"
   */
  public readonly activityOptions = [{
    title: 'INBOX.COMMENTS',
    id: 'notify_comments',
    color: '#579bfc'
  }, {
    title: 'INBOX.DOCUMENTS',
    color: '#2CAAAF',
    id: 'notify_uploads'
  }, {
    title: 'INBOX.ASSIGNMENTS',
    color: '#784bd1',
    id: 'notify_assignments'
  }, {
    title: 'INBOX.UPDATES',
    color: '#ff642e',
    id: 'notify_updates'
  }, {
    title: 'INBOX.ACKNOWLEDGEMENTS',
    color: '#9cd326',
    id: 'notify_confirmations'
  }];
  public selectedActivities = [];

  private id: string;

  public globalSettings: MessageSettingsV2 = null;
  public resourceSettings: MessageSettingsV2 = null;

  public disableEmailNotification: boolean = false;
  public disableInboxNotification: boolean = false;

  @Input()
  isModal: boolean = true;

  @Input()
  private set process(p: { [id: string]: string }) {
    if (p) {
      this._process = p;
      this.processId$.next(p ? p.id : null);
    }
  }

  constructor(private tokenSvc: AngularTokenService,
              private participationSvc: ProcessParticipantService,
              private notifyService: NotificationService,
              private cdr: ChangeDetectorRef,
              @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    if (data && data.process) {
      this._process = data.process;
      this.processId$.next(data.process.id ? data.process.id : null);
    }
  }

  ngOnInit() {
    // Update the process policy as stream on process ID change.
    const policy$ = this.processId$
      .pipe(
        filter(pid => !!pid),
        distinctUntilChanged(),
        takeUntil(this.onDestroy),
        switchMap((processId) => {
          const uid = this.tokenSvc.currentAuthData.uid;
          const context = new iam.Iam.ProcessContext(uid, processId);
          const iamProcessManager = <iam.Iam.ProcessPermissionManager>(
            iam.Iam.PermissionManager.getInstance(context)
          );
          return iamProcessManager.policy$;
        }));

    policy$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((policy: api.Iam.IProcessPolicy) => {
        this.policy.next(policy);
      });

    this.processId$.pipe(distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe((id) => {
      this.id = id;
      this.participationSvc.getAllNotificationSettings(id, false)
        .pipe(first())
        .subscribe((settings: MessageSettingsV2[]) => {
          this.updateLocalsFromApi(settings);
        });
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
  }

  public updateSelectedActivities(event) {
    this.selectedActivities = [...event];
    const newSettings = Object.assign({}, this.resourceSettings) as MessageSettingsV2;
    newSettings.activities = this.selectedActivities.map(a => a.id);
    this.updateNotificationSettings(newSettings, false);
  }

  public updateSettings(property: 'byEmail' | 'byInbox', event: MatSlideToggleChange) {
    if (!this.id) {
      return;
    }
    const newSettings = Object.assign({}, this.resourceSettings) as MessageSettingsV2;
    newSettings[property] = event.checked;
    this.updateNotificationSettings(newSettings, false);
  }


  @FivefConfirm({
    message: 'NOTIFICATION.APPLY_SETTINGS_TO_SUB_WORKFLOWS',
    icon: 'info',
    color: 'primary'
  })
  public applySettingsToSubworkflow() {
    const newSettings = Object.assign({}, this.resourceSettings) as MessageSettingsV2;
    this.updateNotificationSettings(newSettings, true);
  }

  public onOverrideGlobalSettings(event: MatCheckboxChange): void {
    const newSettings = Object.assign({}, this.resourceSettings) as MessageSettingsV2;
    this.overrideGlobalSettings = event.checked;
    this.updateNotificationSettings(newSettings, false);
  }

  private updateNotificationSettings(newSettings: MessageSettingsV2, recursive) {
    newSettings.globalOverride = this.overrideGlobalSettings;
    this.participationSvc.updateNotificationSettings(newSettings, recursive)
      .pipe(first())
      .subscribe(settings => {
        this.updateLocalsFromApi(settings);
        this.notifyService.success('NOTIFICATION.UPDATE_SETTINGS_SUCCESS');
      }, err => {
        console.error(err);
        this.notifyService.error('NOTIFICATION.UPDATE_SETTINGS_ERROR');
      });
  }

  private validateDisableToggles() {
    if (!this.resourceSettings) return;

    this.disableInboxNotification = !this.resourceSettings.byInbox;

    if (!this.resourceSettings.byInbox) {
      this.disableEmailNotification = true;
    } else {
      this.disableEmailNotification = false;
    }

    if (this.resourceSettings.byEmail) {
      this.disableInboxNotification = true;
    } else {
      this.disableInboxNotification = false;
    }
  }

  private updateLocalsFromApi(settings: MessageSettingsV2[]) {
    settings.forEach((setting: MessageSettingsV2) => {
      if (setting.global) {
        this.globalSettings = setting;
      } else {
        const selectedActivities = [];
        this.activityOptions.forEach(option => {
          if (setting.activities && setting.activities.includes(option.id)) {
            selectedActivities.push(option);
          }
        });
        this.selectedActivities = selectedActivities;
        this.overrideGlobalSettings = setting.globalOverride;
        this.resourceSettings = setting;
        // in case for dialog view (participants page)
        if (this.data && this.data.onSubmitAction) {
          this.data.onSubmitAction(setting);
        }
      }
    });
    this.validateDisableToggles();
    this.cdr.detectChanges();
  }
}
