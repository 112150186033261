import {NgModule} from '@angular/core';
import {QuickstartDirective} from './directives/quick-start.directive';
import {SharedModule} from '../../shared/shared.module';
import {QuickstartBarComponent} from './containers/quickstart-bar/quickstart-bar.component';
import {ProjectRoomQuickstarterComponent} from './components/project-room-quickstarter/project-room-quickstarter.component';
import {QuickshareQuickstarterComponent} from './containers/quickshare-quickstarter/quickshare-quickstarter.component';
import {FilePickerModule} from '../file-picker/file-picker.module';
import {CollectorQuickstarterComponent} from './containers/collector-quickstarter/collector-quickstarter.component';
import {ProjectQuickstarterComponent} from './containers/project-quickstarter/project-quickstarter.component';
import {FibuQuickstarterComponent} from './containers/fibu-quickstarter/fibu-quickstarter.component';
import {ProjectQuickstarterFromTemplateComponent} from './containers/project-quickstarter-from-template/project-quickstarter-from-template.component';
import {FivefMenuItemComponent} from '../../lib/fivef-ui/navigation/fivef-menu-item/fivef-menu-item.component';
import {FivefSignatureSelectionComponent} from '../../lib/fivef-ui/sign/fivef-signature-selection/fivef-signature-selection.component';

@NgModule({
  imports: [
    SharedModule,
    FilePickerModule,
    FivefMenuItemComponent,
    FivefSignatureSelectionComponent
  ],
  declarations: [
    QuickstartDirective,
    ProjectRoomQuickstarterComponent,
    QuickstartBarComponent,
    QuickshareQuickstarterComponent,
    CollectorQuickstarterComponent,
    ProjectQuickstarterComponent,
    FibuQuickstarterComponent,
    ProjectQuickstarterFromTemplateComponent
  ],
  providers: [],
  exports: [
    QuickstartBarComponent,
    QuickstartDirective
  ]
})
export class QuickstartModule {
}
