<!-- HEADING -->
<div class="button-row mb-2">
  <dvtx-backlink [title]="'GENERAL.BACK_ACTION' | translate"></dvtx-backlink>
</div>
<div class="row mb-3 mt-2">
  <div class="col-12 col-md-6">
    <h2 class="m-0">
      {{ title | translate }}
    </h2>

    <h5 class="mt-1" *ngIf="subTitle">
      {{ subTitlePrefix | translate }} {{ subTitle | translate }}
    </h5>
  </div>

  <div class="col-12 col-md-6 d-inline-flex justify-content-end">
    <dvtx-project-overview-stats [stats]="chartData$ | async"
                                 (onResetFilter)="showAll()"
                                 (onSelectFilter)="selectFilterByStats($event)"></dvtx-project-overview-stats>
  </div>
</div>

<!-- <Search and filters> -->
<div class="fivef-toolbar fivef-toolbar--end">
  <fivef-search [align]="'end'"
                (onChange)="applySearch($event)"></fivef-search>

  <button mat-icon-button
          [matTooltip]="'FILTER.RESET_FILTER' | translate"
          [disableRipple]="true"
          (click)="onClearAllFilters()"
          *ngIf="showReset" class="mat-icon-button-with-text">
    <mat-icon svgIcon="filter_disable"></mat-icon>
  </button>

  <dvtx-filters-container [selectedCount]="selectedWorkflowTypes.length + selectedClients.length + selectedStatusSelectorTypes.length + selectedOrganizations.length + selectedParticipants.length"  #filtersContainerComponent>
    <!-- Filter buttons setup -->
    <div class="fivef-toolbar fivef-toolbar--space-between fivef-toolbar--content-spacing">
      <fivef-search (onChange)="applyFilterSearch($event)"></fivef-search>

      <button *ngIf="showReset"
              mat-icon-button
              [matTooltip]="'FILTER.RESET_FILTER' | translate"
              (click)="onClearAllFilters()">
        <mat-icon svgIcon="filter_disable"></mat-icon>
      </button>
    </div>

    <!-- Filter container setup -->
    <div class="fivef-filter-dialog--content">
      <div class="fivef-filter-listing" *ngIf="enableClientFilter && !disableClientFilter && allClients?.length > 0">
        <dvtx-filter-list-selection-clients
          [title]="'CLIENT.CLIENT' | translate"
          [options]="clients$ | async"
          [selectedOptions]="selectedClients"
          [search]="filterSearchTerm$ | async"
          (onChangeSelection)="selectClient($event)">
        </dvtx-filter-list-selection-clients>
      </div>

      <div class="fivef-filter-listing" *ngIf="allParticipants?.length > 0">
        <dvtx-filter-list-selection-profiles
          [title]="'TASK.PERSONS' | translate"
          [options]="participants$ | async"
          [selectedOptions]="selectedParticipants"
          [search]="filterSearchTerm$ | async"
          (onChangeSelection)="selectParticipant($event)">
        </dvtx-filter-list-selection-profiles>
      </div>

      <div class="fivef-filter-listing" *ngIf="workflowsSelectorTypes && workflowsSelectorTypes.length">
        <dvtx-filter-list-selection
          [title]="'GENERAL.TYPE' | translate"
          [options]="workflowsSelectorTypes"
          [selectedOptions]="selectedWorkflowTypes"
          [search]="filterSearchTerm$ | async"
          (onChangeSelection)="selectWorkflow($event)">
        </dvtx-filter-list-selection>
      </div>

      <div class="fivef-filter-listing" *ngIf="(organizations$ | async)?.length > 1 && enableOrganizationFilter">
        <dvtx-filter-list-selection-organizations
          [title]="'ORGANIZATION.ORGANIZATION' | translate"
          [options]="organizations$ | async"
          [selectedOptions]="selectedOrganizations"
          [search]="filterSearchTerm$ | async"
          (onChangeSelection)="selectOrganization($event)">
        </dvtx-filter-list-selection-organizations>
      </div>

      <div class="fivef-filter-listing" *ngIf="statusSelectorTypes && statusSelectorTypes.length">
        <dvtx-filter-list-selection-colored
          [title]="'GENERAL.STATUS' | translate"
          [options]="statusSelectorTypes"
          [selectedOptions]="selectedStatusSelectorTypes"
          [search]="filterSearchTerm$ | async"
          (onChangeSelection)="selectStatus($event)">
        </dvtx-filter-list-selection-colored>
      </div>
    </div>
  </dvtx-filters-container>
</div>
<!-- </Search and filters> -->

<div class="mt-1">
  <dvtx-process-listing [data]="data$ | async"
                        [displayedColumns]="displayedColumns"
                        (onNavigateTo)="navigateTo($event)"
                        (onRemoveDraft)="removeDraft($event)"></dvtx-process-listing>

  <ng-container *ngIf="loading$ | async; else more">
    <div class="w-100" style="height: 2px">
      <div class="dvtx-fg-color dvtx-fg-fivef-blue-300 dvtx-loading-indicator">
        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </ng-container>

  <ng-template #more>
    <div class="w-100 d-flex justify-content-center">
      <ng-container *ngIf="page < pages">
        <div class="infinite-scroll-zone"
             inViewport [inViewportOptions]="{ threshold: 0 }"
             (inViewportAction)="loadMore($event)">&#160;</div>
      </ng-container>
    </div>
  </ng-template>
</div>

<div *ngIf="!(loading$ | async) && (data$ | async)?.length === 0"
     class="w-100 d-flex justify-content-center">
  <dvtx-info-icon-message-block [size]="'md'"
                                [icon]="'process'"
                                [message]="'PROJECT_ROOM.NO_ITEMS_MATCH_SEARCH'"></dvtx-info-icon-message-block>
</div>
