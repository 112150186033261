import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FeatureSelectors, MembershipSelectors, OrganizationSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {Membership} from 'app/models/membership.model';
import {LoadMy} from 'app/+store/membership/membership.actions';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {EnvService} from '../../../../shared/modules/api-resource/services/env.service';
import {User} from '../../../../models/user';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'dvtx-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  // CPP Service membership. Only present on members, undefined for Business Partner.
  private myMembership: Observable<Membership | undefined>;

  public featureSet$: Observable<Feature>;

  public hasMobileAppAccess = false;

  constructor(private env: EnvService,
              private store: Store<AppState>,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.store.select(OrganizationSelectors.getSelectedId)
      .pipe(filter(o => !!o), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(id => this.store.dispatch(new LoadMy(id)));

    this.myMembership = this.store.select(MembershipSelectors.getMyMembership);

    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);
    const user$ = this.store.select('currentUser').pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))
    combineLatest(this.featureSet$, user$)

      .subscribe(([feature, user]: [Feature, User]) => {
        this.hasMobileAppAccess = !!user && user.hasMobileAccess() || !!feature && feature.hasMobileSupport;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  isStagingEnv() {
    return this.env.isStagingEnv();
  }
}
