import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertsModule} from 'app/modules/alerts/alerts.module';
import {ProcessToolbarComponent} from './components/upload-dialog/process-toolbar.component';
import {ProcessDialogModule} from '../workflow-engine/modules/process-dialog/process-dialog.module';
import {ProcessToCalendarModule} from '../workflows/modules/process-to-calendar/process-to-calendar.module';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {FivefProcessActionsModule} from '../../lib/fivef-ui/process/fivef-process-state-actions/fivef-process-actions.module';
import {TimelineNotificationPanelComponent} from '../timeline/components/timeline-notification-panel/timeline-notification-panel.component';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {TableUtilsModule} from '../../shared/modules/table-utils/table-utils.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormsModule} from '@angular/forms';
import {GrantThorntonModule} from '../grant-thornton/grant-thornton.module';
import {FivefDialogComponent} from '../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    AlertsModule,
    ProcessDialogModule,
    TooltipModule,
    ProcessToCalendarModule,
    FivefProcessActionsModule,
    FiltersContainerModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    TableUtilsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FormsModule,
    GrantThorntonModule,
    FivefDialogComponent,
    MatDialogModule
  ],
  declarations: [
    ProcessToolbarComponent,
    TimelineNotificationPanelComponent
  ],
  exports: [
    ProcessToolbarComponent,
    TimelineNotificationPanelComponent
  ]
})
export class ProcessToolbarModule {
}
