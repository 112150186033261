<div style="max-height: 450px;overflow: auto;">
  <mat-table [dataSource]="dataSource" class="w-100 dvtx-search-navbar-listing-table" multiTemplateDataRows>
    <ng-container matColumnDef="project">
      <mat-header-cell *matHeaderCellDef>
        <div class="fw-400 c-black">
          {{ 'GENERAL.RECENTLY_VIEWED_ISSUES' | translate }}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="w-100 d-flex" [matTooltip]="element.title">
          <dvtx-process-icon style="color:#7b8490;" [workflowType]="element?.processType"></dvtx-process-icon>
          &nbsp;
          <div class="w-100 text-truncate">
            <div class="d-flex flex-column">
              <div class="ml-1 mb-0 d-flex align-items-center">
                {{ element.title ? element.title : ('GENERAL.NO_TITLE' | translate) }}
              </div>
              <div class="ml-1 d-flex">
                <div style="font-weight: 300;" class="text-truncate">{{ element?.clientName }}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>
        <div class="fw-400 c-black">
          {{ 'GENERAL.IDENT' | translate }}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="fw-400 w-100 text-truncate">
          {{ element?.identifier }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="view">
      <mat-header-cell mat-header-cell *matHeaderCellDef>
        <div class="fw-400 c-black">
          {{ 'GENERAL.STATUS' | translate }}
        </div>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <div class="fw-400">
          {{ element.status?.title | translate }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="ff-pointer p-0"
             [style.border-left-color]="row?.color" [style.border-left-style]="row?.color? 'solid' : 'none'"
             [style.border-left-width]="'5px'" (click)="navigateToProcess(row)" [style.margin]="'5px'">
    </mat-row>
  </mat-table>

  <div *ngIf="loading$ | async; else more">
    <div class="w-100" style="height: 2px">
      <div class="dvtx-fg-color dvtx-fg-fivef-blue-300 dvtx-loading-indicator">
        {{ 'GENERAL.LOAD_ITEMS' | translate }}<span>.</span><span>.</span><span>.</span>
      </div>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
  </div>
  <ng-template #more>
    <div class="w-100 d-flex justify-content-center">
      <ng-container *ngIf="page < pages">
        <div class="infinite-scroll-zone" inViewport [inViewportOptions]="{ threshold: 0 }"
             (inViewportAction)="loadMore($event)">&#160;
        </div>
      </ng-container>
    </div>
  </ng-template>
  <div *ngIf="!(loading$ | async) && (data$ | async)?.length === 0" class="w-100 dvtx-no-items">
    <div class="no-items-found">
      <mat-icon svgIcon="process"></mat-icon>
      <h3>{{ 'PROJECT_ROOM.NO_ITEMS_MATCH_SEARCH' | translate }}</h3>
    </div>
  </div>
</div>
<hr>
<div class="d-flex" style="justify-content: space-between;">
  <mat-checkbox (change)="_loadPage(1)" [(ngModel)]="excludeClosedStatus">
    <span>{{ 'STATUS.EXCLUDE_CLOSED_WORKFLOWS' | translate }}</span></mat-checkbox>
  <button mat-button (click)="navigateToSearchPage()">
    <mat-icon matPrefix aria-label="back">search</mat-icon>
    {{ 'GENERAL.ADVANCED_SEARCH' | translate }}
    <mat-icon matPrefix aria-label="back">arrow_forward</mat-icon>
  </button>
</div>
