<dvtx-int-telephone-input
  name="phone"
  [(ngModel)]="innerValue"
  [enablePlaceholder]="true"
  [enableAutoCountrySelect]="true"
  [isDisabled]="isDisabled"
  [preferredCountries]="['de']"
  [options]="options"
  (autoCompleteFun)="autoCompleteFunction($event)"
  [phoneType]="innerValue.locationOrType">
</dvtx-int-telephone-input>

<ng-container *ngIf="isType">
  <dvtx-api-select-cva
    class="type-or-location ml-2"
    [elementName]="'ORGANIZATION.CREATION.TYPE' | translate"
    listingPath="name"
    listingType="typed_location-or-phone-type"
    [(ngModel)]="innerValue.locationOrType"
    [isDisabled]="isDisabled">
  </dvtx-api-select-cva>
</ng-container>
