<div class="button-row mb-2">
  <a mat-button routerLink="/organization/{{organizationId}}/addressbook/clients">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>
</div>

<mat-card appearance="outlined" class="p-0 dvtx-client-details dvtx-client-details__workflows no-background"
          *ngIf="(client$ | async) as client">
  <mat-card-title>
    <span class="small-title">{{ 'CLIENT.CLIENT' | translate }}</span>
    <h3 class="p-0 m-0">
      {{ client?.name }} {{ client?.clientId ? '(' + client?.clientId + ')' : '' }}
    </h3>
  </mat-card-title>
  <mat-card-content>
    <dvtx-client-nav-bar [routes]="routes" [activeLink]="activeLink"></dvtx-client-nav-bar>

    <div class="w-100" *ngIf="client$ | async">
      <!-- TODO: Remove all occurences then remove component -->
      <!--      <dvtx-process-table-->
      <!--        class="w-100"-->
      <!--        [highlight]="true"-->
      <!--        (onClick)="click($event)"-->
      <!--        [status]="'open'"-->
      <!--        [disableClientFilter]="true"-->
      <!--        [processData]="processes$ | async"-->
      <!--        (onRefresh)="refresh()"-->
      <!--        [loading]="loading"-->
      <!--      ></dvtx-process-table>-->
      <dvtx-project-overview [title]="null"
                             [enableClientFilter]="false"
                             [enableOrganizationFilter]="false"
                             [client]="client$ | async"></dvtx-project-overview>
    </div>
  </mat-card-content>
</mat-card>
