<fivef-dialog [color]="borderColor"
              [title]="dialogTitle"
              [isUtilityDialog]="true">
  <div class="fivef-confirm--content fivef-confirm--content--align-{{textAlign}}">
    <div *ngIf="icon"
         class="fivef-confirm--content--icon">
      <mat-icon aria-hidden="true">{{ icon }}</mat-icon>
    </div>

    <span class="p-1" [innerHTML]="message | translate"></span>
  </div>

  <div fivef-dialog-footer>
    <button *ngIf="!disableCancelButton"
            mat-button
            matDialogClose>{{ 'GENERAL.CANCEL_ACTION' | translate }}
    </button>

    <button *ngIf="secondaryAction"
            mat-raised-button
            [mat-dialog-close]="secondaryAction">{{ secondaryAction | translate }}
    </button>

    <button mat-raised-button
            autofocus
            [color]="color"
            [mat-dialog-close]="true">{{ confirmAction | translate }}
    </button>
  </div>
</fivef-dialog>
