import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, mergeMap, switchMap} from 'rxjs/operators';
import {DatevClientService} from './datev-client.service';
import {NotificationService} from '../../../shared/modules/notification/services/notification.service';
import {
  DatevClientActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  SyncAll,
  SyncAllFail,
  SyncAllSuccess,
  SyncOne, SyncOneFail, SyncOneSuccess
} from './datev-client.actions';
import {of} from 'rxjs/internal/observable/of';
import {DatevClient} from './datev-client';


@Injectable()
export class DatevClientEffects {
  loadAll$ = createEffect(() => this.actions$.pipe(
    ofType(DatevClientActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: DatevClient[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  syncAll$ = createEffect(() => this.actions$.pipe(
    ofType(DatevClientActionTypes.SyncAll),
    switchMap((action: SyncAll) => {
      return this._svc.syncAll().pipe(
        first(),
        concatMap((res: DatevClient[]) => {
          return [new SyncAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SyncAllFail(err));
        }));
    })
  ));

  syncOne$ = createEffect(() => this.actions$.pipe(
    ofType(DatevClientActionTypes.SyncOne),
    switchMap((action: SyncOne) => {
      return this._svc.syncOne(action.client.id).pipe(
        first(),
        concatMap((res: DatevClient) => {
          res.synced = true;
          return [new SyncOneSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SyncOneFail(err));
        }));
    })
  ));


  constructor(private actions$: Actions,
              private _svc: DatevClientService,
              private _notifyService: NotificationService) {
  }
}
