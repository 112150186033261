import {Injectable} from '@angular/core';
import {catchError, concatMap, filter, first, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Edit,
  EditFail,
  EditSuccess,
  KanbanBoardActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Select,
  SelectSuccess
} from './kanban-board.actions';
import {KanbanBoard} from './kanban-board';
import {KanbanBoardService} from './kanban-board.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {KanbanBoardSelectors} from 'app/+store';
import {IKanbanBoardParams} from './kanban-board.interface';

@Injectable()
export class KanbanBoardEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(KanbanBoardActionTypes.Create),
    concatMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((kanbanBoard: KanbanBoard) => {
          return [new CreateSuccess(kanbanBoard)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  edit$ = createEffect(() => this.actions.pipe(
    ofType(KanbanBoardActionTypes.Edit),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [Edit, KanbanBoard]) => {
      return this._svc.update(board.id, action.params).pipe(
        first(),
        concatMap((kanbanBoard: KanbanBoard) => {
          return [new EditSuccess(kanbanBoard)];
        }),
        catchError(err => {
          console.error(err);
          return of(new EditFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(KanbanBoardActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.processId).pipe(
        first(),
        concatMap((res: KanbanBoard[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(KanbanBoardActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((kanbanBoard: KanbanBoard) => {
          return [new LoadOneSuccess(kanbanBoard)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(KanbanBoardActionTypes.Remove),
    concatMap((action: Remove) => {
      return this._svc.remove(action.id).pipe(
        first(),
        concatMap((kanbanBoard: KanbanBoard) => {
          return [new RemoveSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  select$ = createEffect(() => this.actions.pipe(
    ofType(KanbanBoardActionTypes.Select),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getAll).pipe(
      filter(boards => !!boards)
    )),
    concatMap(([action, boards]: [Select, KanbanBoard[]]) => {
      const currentSelectedBoard = boards.find((board) => board.isSelected);
      let updateSuccessAction;
      if (currentSelectedBoard) {
        currentSelectedBoard.isSelected = false;
        updateSuccessAction =new EditSuccess(currentSelectedBoard);
      }
      const toBeSelected = boards.find((board) => board.title === action.board);
      if (toBeSelected) {
        toBeSelected.isSelected = true;
        return this._svc.update(toBeSelected.id, toBeSelected).pipe(
          first(),
          concatMap((kanbanBoard: KanbanBoard) => {
            return [new SelectSuccess(action.board), updateSuccessAction];
          }),
          catchError((err) => {
            console.error(err);
            return of();
          })
        );
      }
      const kanbanBoardParams: IKanbanBoardParams = {
        title: action.board,
        processId: action.processId,
        boardType: action.boardType,
        isSelected: true
      }
      return [new Create(kanbanBoardParams), new SelectSuccess(action.board)];
    })
  ));

  constructor(
    private actions: Actions,
    private _store: Store<AppState>,
    private _svc: KanbanBoardService,
    private _router: Router,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService
  ) {
  }
}
