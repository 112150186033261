<div *ngIf="!(memberShipLoading$ | async)">
  <table class="organization-employee-table" cellpadding="0" cellspacing="0" border="0">
    <thead>
    <tr>
      <th class="organization-employee-table__head-cell avatar-cell">
      </th>

      <th class="organization-employee-table__head-cell name-cell">
        {{ 'CONTACTS.NAME' | translate }}
      </th>

      <th class="organization-employee-table__head-cell email-cell d-none d-md-flex">
        {{ 'CONTACTS.EMAIL' | translate }}
      </th>

      <th class="organization-employee-table__head-cell fa2-cell d-none d-lg-flex">
        <dvtx-table-text-and-info [title]="'PROCESS_MANAGEMENT.TWO_FA_ENABLED'"
                                  [tooltip]="'PROCESS_MANAGEMENT.TWO_FA_ENABLED_TOOLTIP'"></dvtx-table-text-and-info>
      </th>

      <th class="organization-employee-table__head-cell role-cell d-none d-lg-flex">
        <dvtx-table-text-and-info [title]="'ROLE.ADMINISTRATOR'" [tooltip]="'AUTH.ADMIN_DESC'">
        </dvtx-table-text-and-info>
      </th>

      <th class="organization-employee-table__head-cell admin-cell d-none d-xl-flex">
        <dvtx-table-text-and-info [title]="'PROCESS_MANAGEMENT.ADMINISTRATE_PROJECT_ROOMS'"
                                  [tooltip]="'PROCESS_MANAGEMENT.PROJECT_ROOM_MANAGEMENT_TOOLTIP'"></dvtx-table-text-and-info>
      </th>

      <!-- Disabled for now. Currently unclear if valuable first class information. see also <td>s-->
      <!-- <th class="organization-employee-table__head-cell workflow-cell d-none d-xxl-flex">-->
      <!--   {{'PROCESS_MANAGEMENT.WORKFLOW_AND_SUBWORKFLOWS' | translate}}-->
      <!-- </th>-->
      <!-- <th class="organization-employee-table__head-cell workflow-cell d-none d-xxl-flex">-->
      <!--   {{'PROCESS_MANAGEMENT.ONLY_SUBWORKFLOWS' | translate}}-->
      <!-- </th>-->

      <th class="organization-employee-table__head-cell actions-cell">
      </th>
    </tr>
    </thead>
  </table>

  <div class="loader" *ngIf="!(members$ | async) || (members$ | async)?.length === 0" style="top: 125px;">
    <span>
      {{ 'GENERAL.NO_ENTRIES_FOUND' | translate }}
    </span>
  </div>

  <cdk-virtual-scroll-viewport *ngIf="(members$ | async)?.length" [itemSize]="55" autosize>
    <table class="organization-employee-table" cellpadding="0" cellspacing="0" border="0">
      <tbody>
      <tr *cdkVirtualFor="let member of members$ | async; index as i; templateCacheSize: 0"
          class="organization-employee-table__row">

        <td class="organization-employee-table__cell avatar-cell">
          <div class="icon-container">
            <dvtx-avatar [isMember]="true" [size]="'sm'"
                         [email]="member?.email"></dvtx-avatar>
          </div>
        </td>

        <td class="organization-employee-table__cell name-cell">
          <ng-container>
            {{ member.name | slice:0:40 }}{{ member.name.length > 40 ? '...' : '' }}
          </ng-container>
          <ng-container *ngIf="member.isMe">
            ({{ "GENERAL.YOU" | translate }})
          </ng-container>
        </td>

        <td class="organization-employee-table__cell email-cell d-none d-md-flex">
          {{ member.email }}
        </td>

        <td class="organization-employee-table__cell fa2-cell d-none d-lg-flex">
          <!-- Currently disabled: We prefer for now text over icon -->
          <!--<ng-container *ngIf="member2FAStatusMap[member?.mainEmailAddress?.emailAddress]">
            <mat-icon class="fivef-color-status-success">security</mat-icon>
          </ng-container>-->

          <ng-template [ngIf]="member2FAStatusMapLoading$ | async" [ngIfElse]="twoFaStatusTpl">
            <div class="d-flex">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
          </ng-template>

          <ng-template #twoFaStatusTpl>
            {{ member.has2Fa ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate) }}
          </ng-template>
        </td>

        <td class="organization-employee-table__cell role-cell d-none d-lg-flex">
          {{ member.isAdmin ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate) }}
          <!-- <dvtx-edit-role-toggle [data]="member"></dvtx-edit-role-toggle> -->
        </td>

        <td class="organization-employee-table__cell admin-cell d-none d-xl-flex">
          <div class="w-100 d-flex justify-content-center">
            <!-- Project Room administration is a role on top of being administrator (because you also need organizational DMS access, it is not an independent role) -->
            <!-- To not confuse the user we also show the toggle only enabled if the user is already admin -->
            {{ member.isProjectRoomAdmin ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate) }}
          </div>
        </td>

        <!-- Disabled for now, see also <th>s-->
        <!-- <td class="organization-employee-table__cell workflow-cell d-none d-xxl-flex">-->
        <!--   {{!!(membershipPermission[member?.membershipId])?.WorkflowCreationEnabled ? ('GENERAL.YES' | translate) : ('GENERAL.NO'-->
        <!--   | translate) }}-->
        <!-- </td>-->
        <!-- <td class="organization-employee-table__cell workflow-cell d-none d-xxl-flex">-->
        <!--   {{!!(membershipPermission[member?.membershipId])?.SubWorkflowCreationEnabled -->
        <!--     && !(membershipPermission[member?.membershipId])?.WorkflowCreationEnabled  ? ('GENERAL.YES' | translate) : ('GENERAL.NO'-->
        <!--   | translate) }}-->
        <!-- </td>-->

        <td class="organization-employee-table__cell actions-cell">
          <button mat-icon-button [disabled]="member.isMe"
                  [matTooltip]="'GENERAL.REMOVE_ACTION' | translate"
                  (click)="openRemovalDialog(member.email, member.id, member.membershipId)">
            <mat-icon matPrefix>delete</mat-icon>
          </button>
          <button mat-icon-button [matTooltip]="'GENERAL.SHOW_MORE' | translate"
                  (click)="openShowMoreDialog(member)">
            <mat-icon matPrefix>arrow_forward</mat-icon>
          </button>
          <!--<button mat-raised-button (click)="handleManageEmployeeClick(member.id)">{{ 'GENERAL.MANAGE_ACTION' | translate }}</button>-->
        </td>
      </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<fivef-loading-indicator class="fivef-organization-employee--loading-indicator" *ngIf="(memberShipLoading$ | async)"
                         [appearance]="'spinnerOnly'"></fivef-loading-indicator>

<ng-template #dialogTpl>
  <fivef-dialog [infoColor]="'warn'" [title]="'ORGANIZATION.REMOVE_MEMBER'">
    <div class="w-100 d-flex flex-column dvtx-organization-employee-removal-dialog">

      <h4>{{ 'ORGANIZATION.REMOVE_MEMBER_DIALOG_TITLE' | translate }}</h4>
      <p>{{ 'ORGANIZATION.REMOVE_MEMBER_DIALOG_SUB_TITLE' | translate }}</p>

      <div class="d-flex flex-column mb-2">
        <div class="d-flex align-items-center">
          <div>
            <mat-icon class="fivef-color-status-success dvtx-inline-valign mr-2">check</mat-icon>
          </div>
          <div>{{ 'ORGANIZATION.MEMBER_DELETION_INFO_1' | translate }}</div>
        </div>

        <div class="d-flex align-items-center">
          <div>
            <mat-icon class="fivef-color-status-success dvtx-inline-valign mr-2">check</mat-icon>
          </div>
          <div>{{ 'ORGANIZATION.MEMBER_DELETION_INFO_2' | translate }}</div>
        </div>

        <div class="d-flex align-items-center">
          <div>
            <mat-icon class="fivef-color-status-success dvtx-inline-valign mr-2">check</mat-icon>
          </div>
          <div>{{ 'ORGANIZATION.MEMBER_DELETION_INFO_3' | translate }}</div>
        </div>
      </div>

      <div class="d-flex mb-2" *ngIf="amIProjectRoomAdmin$ | async">
        <button mat-button color="primary" (click)="toMemberProjectRooms(selectedMemberEmailForRemoval)">
          {{ 'PROJECT_ROOM.TO_PROJECT_ROOMS' | translate }}
          <mat-icon matPrefix>arrow_forward</mat-icon>
        </button>
      </div>

      <div class="d-flex mb-1">
        <h4>{{ 'ORGANIZATION.SELECT_NEW_PROCESS_OWNER' | translate }}</h4>
      </div>
      <div class="w-100 d-flex dvtx-organization-employee-removal-dialog__owner-selection">
        <mat-form-field style="width: 100%; max-width: 400px;">
          <mat-icon matPrefix>person</mat-icon>
          <mat-select placeholder="{{ 'ORGANIZATION.SELECT_NEW_OWNER' | translate }}"
                      (selectionChange)="selectNewOwner($event)" class="persons--filter"
                      (openedChange)="ownerSelectionChanged($event)" [value]="selectedOwner">
            <mat-select-trigger *ngIf="selectedOwner" class="persons--filter--selected">
              {{ selectedOwner?.name }} <span class="dvtx-fg-color dvtx-fg-fivef-blue-300">({{
                selectedOwner?.mainEmailAddress?.emailAddress
              }})</span>
            </mat-select-trigger>

            <div class="persons-filter-search d-flex px-3 align-items-center">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput class="mat-select-search-input" [placeholder]="'GENERAL.SEARCH' | translate" name="search"
                     [formControl]="ownerSearchField" autocomplete="new-search" #searchSelectInput
                     (keydown)="handleKeydown($event)" (keyup)="onInputChange($event.target.value)"/>
              <mat-icon matSuffix *ngIf="searchSelectInput?.value" (click)="resetPersonSearch()">close
              </mat-icon>
            </div>
            <hr class="m-0"/>
            <mat-option *ngFor="let member of (getMembersWithoutSelected(selectedMemberEmailForRemoval) | async)"
                        [value]="member" class="persons-filter-option"
                        style="line-height: 1em !important; height: 3em !important;">
              {{ member?.name }}
              <br>
              <span class="dvtx-fg-color dvtx-fg-fivef-blue-300"><small>{{
                  member?.mainEmailAddress.emailAddress
                }}</small></span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div fivef-dialog-footer>
      <button mat-button (click)="closeRemovalDialog($event)">
        {{ 'GENERAL.CANCEL_ACTION' | translate }}
      </button>
      <button mat-raised-button color="warn"
              (click)="removeMember()"
              [disabled]="!selectedOwner">
        {{ 'GENERAL.CONFIRM_ACTION' | translate }}
      </button>
    </div>
  </fivef-dialog>
</ng-template>

<ng-template #detailsDialogTpl>
  <fivef-context-sidebar>
    <div fivef-context-sidebar-header>
      <div class="d-flex" style="align-items: flex-end">
        <dvtx-avatar [size]="'lg'" [email]="selectedMember?.email"></dvtx-avatar>
        &nbsp;&nbsp;<h1>{{ selectedMember?.name }}</h1>
      </div>
    </div>

    <mat-tab-group mat-stretch-tabs
                   fivefContextSidebarContent>
      <mat-tab [label]="'TASK.DETAILS' | translate">
        <ng-template matTabContent>
          <div *ngIf="!showResetPassword" style="margin-top: -20px; margin-left: -5px;" class="d-flex mb-2 mt-1">
            <div>
              <button mat-button (click)="showResetPass()" [disabled]="selectedMember?.isOwner || selectedMember?.isMe">
                <mat-icon matPrefix>vpn_key</mat-icon>
                {{ 'FAQ.TUTORIAL_RESET_PASSWORD' | translate }}
              </button>
            </div>

            <div class="ml-2">
              <button mat-button color="warn"
                      [disabled]="selectedMember?.isMe"
                      (click)="openRemovalDialog(selectedMember?.email, selectedMember?.id, selectedMember?.membershipId)">
                <mat-icon matPrefix>delete</mat-icon>
                {{ 'ORGANIZATION.ACTION.REMOVE_EMPLOYEE_ACTION' | translate }}
              </button>
            </div>
          </div>

          <!-- Password reset block -->
          <div *ngIf="showResetPassword">
            <div class="pl-3 mb-2">
              <a mat-button (click)="showResetPassword = false;">
                <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
                {{ 'GENERAL.BACK_ACTION' | translate }}
              </a>
            </div>

            <div class="pl-3">
              <h3>{{ 'AUTH.RESET_PASSWORD_FOR' | translate: {name: selectedMember?.name} }}</h3>
              <dvtx-info-block [text]="'AUTH.RESET_PASSWORD_INFO'"></dvtx-info-block>
              <div class="d-flex align-items-center justify-content-between reset-password-in-form">
                <div style="position: relative">
                  <mat-form-field>
                    <!--  fake fields are a workaround for chrome/opera autofill getting the wrong fields -->
                    <input id="username" style="display:none" type="text" name="fakeusernameremembered">
                    <input id="password" style="display:none" type="password" name="fakepasswordremembered">
                    <!-- <input autocomplete="nope"> turns off autocomplete on many other browsers that don't respect
                    the form's "off", but not for "password" inputs.-->
                    <!--<input type="password" autocomplete="new-password" will turn it off for passwords everywhere-->
                    <input autocomplete="new-password"
                           #passwordInput
                           [placeholder]="'AUTH.PASSWORD' | translate"
                           [(ngModel)]="passwordResetValue"
                           matInput [type]="showFirstPass"
                           id="reset-password-id">
                    <mat-icon class="dvtx-fg-color dvtx-fg-cool-grey-400" *ngIf="showFirstPass === fieldType.Password"
                              style="position: absolute;top: 4px;right: -10px;"
                              (click)="showFirstPassword();">visibility
                    </mat-icon>
                    <mat-icon class="dvtx-fg-color dvtx-fg-cool-grey-400" *ngIf="showFirstPass === fieldType.Text"
                              style="position: absolute;top: 4px;right: -10px;"
                              (click)="hideFirstPassword();">visibility_off
                    </mat-icon>
                  </mat-form-field>
                  <div
                    *ngIf="!!passwordInput.value && verifyPasswordStrength(passwordInput.value) && verifyPasswordStrength(passwordInput.value).level !== 'success'">
                    <span
                      class="dvtx-fg-color dvtx-fg-fivef-blue-300">{{ verifyPasswordStrength(passwordInput.value)?.message | translate }}</span>
                  </div>
                  <button mat-icon-button
                          style="position: absolute; top: -4px; right: -3rem;"
                          [matTooltip]="'PASSWORD.STRONG_PASSWORD_POLICY' | translate">
                    <mat-icon>info</mat-icon>
                  </button>
                </div>

                <div>
                  <button style="float:right;" mat-raised-button
                          color="primary"
                          [disabled]="passwordChangeOngoing || isPasswordInvalid(passwordInput.value)"
                          class="wide" (click)="changePassword()">
                    {{ 'AUTH.RESET_PASSWORD_ACTION' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Permission toggle block -->
          <div *ngIf="!showResetPassword" class="pl-3">
            <ng-template [ngIf]="!!selectedMember" [ngIfElse]="noAssignedLicence">
              <div class="d-flex justify-content-between mb-2 pr-5">
                <div>
                  <p class="font-weight-bold">{{ 'CONTACTS.EMAIL' | translate }}</p>
                  <div class="w-100 d-flex align-items-center">
                    <span>{{ selectedMember?.email }}</span>
                  </div>
                </div>

                <div class="pr-3">
                  <p class="font-weight-bold">{{ 'PROCESS_MANAGEMENT.TWO_FA_ENABLED' | translate }}</p>
                  <div class="w-100 d-flex align-items-center">
            <span
              class="text-truncate">{{
                selectedMember.has2Fa ? ('GENERAL.YES'
                  | translate) : ('GENERAL.NO' | translate)
              }}</span>
                  </div>
                </div>
              </div>

              <hr class="mt-2 mb-1">

              <div>
                <dvtx-edit-role-toggle [data]="selectedMember.person"></dvtx-edit-role-toggle>
              </div>

              <hr class="mt-1 mb-1">

              <div>
                <h4 class="mb-1">{{ 'PROCESS_MANAGEMENT.PROJECT_ROOM_MANAGEMENT' | translate }}</h4>

                <!-- Only the organizational owner is allowed to change the project room management role -->
                <!-- Project Room administration is a role on top of being administrator (because you also need organizational DMS access, it is not an independent role) -->
                <!-- To not confuse the user we also show the toggle only enabled if the user is already admin -->
                <mat-slide-toggle [labelPosition]="'after'" color="primary"
                                  [checked]="selectedMember.isProjectRoomAdmin"
                                  [disabled]="!(memberShip$ | async)?.isOrganizationalOwner || !selectedMember.isAdmin"
                                  (change)="toggleProcessManagementAdmin($event, selectedMember?.email, selectedMember?.projectRoomAdminRoleId)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'PROCESS_MANAGEMENT.PROJECT_ROOM_ADMINISTRATOR'"
                                            [tooltip]="'PROCESS_MANAGEMENT.PROJECT_ROOM_MANAGEMENT_TOOLTIP'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>

              <hr class="mt-2 mb-1">

              <div class="d-flex flex-column">
                <h4 class="mb-1">{{ 'PROCESS_MANAGEMENT.WORKFLOW_CREATION' | translate }}</h4>

                <div class="mb-2">
                  <mat-slide-toggle [labelPosition]="'after'" color="primary"
                                    [checked]="selectedMember.isWorkflowCreationEnabled || selectedMember.isSubWorkflowCreationEnabled"
                                    (change)="updateWorkflows($event, selectedMember?.membershipId);">
                    <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                              [h4]="true"
                                              [title]="'PROCESS_MANAGEMENT.WORKFLOW_CREATION_PRIVILEGES'"
                                              [tooltip]="'PROCESS_MANAGEMENT.WORKFLOW_CREATION_INFO'">
                    </dvtx-table-text-and-info>
                  </mat-slide-toggle>
                </div>

                <div>
                  <mat-radio-group
                    *ngIf="selectedMember.isWorkflowCreationEnabled || selectedMember.isSubWorkflowCreationEnabled"
                    style="display: inline-flex; flex-direction: column;"
                    [(ngModel)]="selectedWorkflow"
                    (ngModelChange)="updateWorkflows(null, selectedMember?.membershipId)">
                    <mat-radio-button style="margin-bottom: 5px" value="workflow">
                      <h4 class="mb-0">{{ 'PROCESS_MANAGEMENT.WORKFLOW_AND_SUBWORKFLOWS' | translate }}</h4>
                    </mat-radio-button>
                    <mat-radio-button value="subworkflow">
                      <h4 class="mb-0">{{ 'PROCESS_MANAGEMENT.ONLY_SUBWORKFLOWS' | translate }}</h4></mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <!-- Fastdocs salary dashboard must only be accessible on fastdocs flag on the organization: -->
              <!-- Then the organization is allowed to distribute dashboard access to employees. -->
              <div *ngIf="(featureSet$ | async)?.hasFastdocsSettings">
                <hr class="mt-2 mb-1">

                <h4 class="mb-1">{{ 'FASTDOCS.DASHBOARD.TITLE' | translate }} (fastdocs)</h4>

                <mat-slide-toggle [labelPosition]="'after'"
                                  color="primary"
                                  [(ngModel)]="fastdocsToggleState"
                                  (change)="toggleSalaryAccess($event, selectedMember)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'FASTDOCS.ACTION.SALARY_REPORTS_ACCESS'"
                                            [tooltip]="'FASTDOCS.ACTION.SALARY_REPORTS_ACCESS_INFO'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>
              <div *ngIf="(featureSet$ | async)?.hasDigitalbarSettings">
                <hr class="mt-2 mb-1">

                <h4 class="mb-1">Digitalbar</h4>

                <mat-slide-toggle [labelPosition]="'after'"
                                  color="primary"
                                  [(ngModel)]="digitalbarToggleState"
                                  (change)="toggleDigitalbar($event, selectedMember)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'DIGITALBAR.DASHBOARD_STARTER_TITLE'"
                                            [tooltip]="'DIGITALBAR.DASHBOARD_STARTER_TITLE_INFO'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>

              <!-- hasFivefDesktop enables the feature on the organization.  hasFivefDesktopSettings enables the settings/PIN-panel
                   for the user -->
              <div *ngIf="(featureSet$ | async)?.hasFivefDesktop">
                <hr class="mt-2 mb-1">

                <h4 class="mb-1">{{ 'FIVE_F.FIVEF_DESKTOP.FIVEF_DESKTOP' | translate }}</h4>

                <mat-slide-toggle [labelPosition]="'after'"
                                  color="primary"
                                  [(ngModel)]="fivefDesktopToggleState"
                                  (change)="toggleFivefDesktop($event, selectedMember)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'FIVE_F.FIVEF_DESKTOP.DASHBOARD_STARTER_TITLE'"
                                            [tooltip]="'FIVE_F.FIVEF_DESKTOP.DASHBOARD_STARTER_TITLE_INFO'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>

              <div *ngIf="(featureSet$ | async)?.hasFivefAgentSettings">
                <hr class="mt-2 mb-1">

                <h4 class="mb-1">5FAgent</h4>

                <mat-slide-toggle [labelPosition]="'after'"
                                  color="primary"
                                  [(ngModel)]="fivefAgentToggleState"
                                  (change)="toggleFivefAgent($event, selectedMember)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'FIVEF_AGENT.DASHBOARD_STARTER_TITLE'"
                                            [tooltip]="'FIVEF_AGENT.DASHBOARD_STARTER_TITLE_INFO'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>

              <div *ngIf="(featureSet$ | async)?.hasDatevSettings">
                <hr class="mt-2 mb-1">

                <h4 class="mb-1">{{ 'DATEV.DATEV_TITLE' | translate }}</h4>

                <mat-slide-toggle [labelPosition]="'after'"
                                  color="primary"
                                  [(ngModel)]="datevToggleState"
                                  (change)="toggleDatev($event, selectedMember)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'DATEV.DASHBOARD_STARTER_TITLE'"
                                            [tooltip]="'DATEV.DASHBOARD_STARTER_TITLE_INFO'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>

              <div *ngIf="(featureSet$ | async)?.hasBookman">
                <hr class="mt-2 mb-1">

                <h4 class="mb-1">{{ 'FIBU.BOOKMAN_SETTINGS' | translate }}</h4>

                <mat-slide-toggle [labelPosition]="'after'"
                                  color="primary"
                                  [(ngModel)]="bookmanToggleState"
                                  (change)="toggleBookman($event, selectedMember)">
                  <dvtx-table-text-and-info [alignmentPosition]="'start'"
                                            [h4]="true"
                                            [title]="'FIBU.BOOKMAN_SETTINGS_ACTIVATE'"
                                            [tooltip]="'FIBU.BOOKMAN_SETTINGS_ACTIVATE_INFO'">
                  </dvtx-table-text-and-info>
                </mat-slide-toggle>
              </div>
            </ng-template>

            <ng-template #noAssignedLicence>
              <h3 class="mt-3">{{ 'SALES.NO_ASSIGNED_LICENCE_INFO' | translate }}</h3>
            </ng-template>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div fivef-context-sidebar-footer>
      <button mat-button (click)="closeDialog()">
        {{ 'GENERAL.CLOSE_ACTION' | translate }}
      </button>
    </div>
  </fivef-context-sidebar>
</ng-template>
