import {SimpleAddress, SimpleEmailAddress, SimplePhoneNumber} from '../../../+store/contact/legacy/models/contact.interface';
import {ContactPerson, createNewContactPerson} from '../../../models/contact-person.model';

export interface ContactPersonBaseForm {
  discriminator: 'ContactPersonBaseForm',
  isValid: boolean,
  contactPerson: ContactPerson
}

export function contactPersonBaseFormFromContact(contact: ContactPerson): ContactPersonBaseForm {
  return {
    discriminator: 'ContactPersonBaseForm',
    isValid: false,
    contactPerson: contact
  }
}

export function newContactBaseForm(): ContactPersonBaseForm {
  return {
    discriminator: 'ContactPersonBaseForm',
    isValid: false,
    contactPerson: createNewContactPerson()
  }
}
