<div class="row">
  <div class="col-12 mt-1" *ngIf="(quickshareRecipients$ | async)?.length > 0">
    <ul class="recipients-list">
      <li *ngFor="let recipient of quickshareRecipients$ | async">
        <div class="d-flex align-items-center justify-content-between w-100 mb-1 flex-wrap">
          <div class="d-flex align-items-center" matTooltip="{{ recipient.email }}">
            <dvtx-avatar [size]="'xs'" [email]="recipient" [isVerified]="recipient?.isVerified"
                         [isMember]="recipient?.isMember"></dvtx-avatar>
            <ng-template [ngIf]="recipient?.firstName && recipient?.lastName" [ngIfElse]="showEmail">
              {{ recipient.firstName + ' ' + recipient.lastName }}
            </ng-template>

            <ng-template #showEmail>
              {{ recipient.email }}
            </ng-template>
          </div>
          <div class="d-flex align-items-center">
            <small *ngIf="getContact(recipient.email)?.hasAccount" style="color: #7b8490" class="mr-2">(Project
              Room)</small>

            <button *ngIf="!notifyBySmsOnly && !getContact(recipient.email)?.hasAccount"
                    [matTooltip]="'QUICKSHARE.CREATE_FORM.PHONE_KEY_TOOLTIP' | translate" mat-icon-button type="button"
                    name="button" (click)="onSMSOptionClick.emit()">
              <mat-icon [class.warning]="!recipient.notifyBySms || !passwordEnabled"
                        [class.sucess]="recipient.notifyBySms && passwordEnabled">vpn_key
              </mat-icon>
            </button>

            <ng-container *ngIf="!notifyBySmsOnly && !getContact(recipient.email)?.hasAccount">
              <dvtx-int-telephone-input name="phone" [disableInputHints]="true"
                                        [(ngModel)]="recipient.simplePhoneNumber" [enablePlaceholder]="true"
                                        [enableAutoCountrySelect]="true" [isDisabled]="isDisabled"
                                        [preferredCountries]="['de']" (onBlur)="update(recipient)">
              </dvtx-int-telephone-input>
            </ng-container>

            <button mat-icon-button type="button" name="button" *ngIf="removable" (click)="remove(recipient)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <!-- <div class="d-flex align-items-center w-100" *ngIf="passwordEnabled && recipient.notifyBySms">
          <ng-container *ngIf="!notifyBySmsOnly && !getContact(recipient.email)?.hasAccount">
            <dvtx-int-telephone-input name="phone" [(ngModel)]="recipient.simplePhoneNumber" [enablePlaceholder]="true" [enableAutoCountrySelect]="true" [isDisabled]="isDisabled" [preferredCountries]="['de']" (onBlur)="update(recipient)">
            </dvtx-int-telephone-input>
          </ng-container>
          <ng-container *ngIf="getContact(recipient.email)?.hasAccount">
            <mat-form-field>
              <input matInput type="search" [disabled]="true" placeholder="-- Project Room -- ">
            </mat-form-field>
          </ng-container>
        </div> -->
      </li>
    </ul>
  </div>

  <div class="col-12" *ngIf="!notifyBySmsOnly">
    <dvtx-quickshare-recipient-autocomplete elementName="E-Mail" [value]="recipient" (onSelect)="add($event)"
                                            [excludedIds]="[]" [valueIsEmail]="true" #recipientAutocompleteComponent>
    </dvtx-quickshare-recipient-autocomplete>
  </div>
</div>
