import {Injectable} from '@angular/core';
import {catchError, concatMap, first} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Load, LoadFail, LoadSuccess, Remove, ProcessFolderActionTypes} from './process-folder.actions';
import {ProcessFolderService} from './process-folder.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessFolder} from './process-folder';

@Injectable()
export class ProcessFolderEffects {
  load$ = createEffect(() => this.actions.pipe(
    ofType(ProcessFolderActionTypes.Load),
    concatMap((action: Load) => {
      if (!action.id) {
        const msg = 'Prevented to load a folder without ID (id == null)';
        console.error(msg);
        return of(new LoadFail(msg));
      }
      return this._svc.getOne(action.id, action.preventPrivateDmsResult).pipe(
        first(),
        concatMap((folder: ProcessFolder) => {
          return [new LoadSuccess(folder)];
        }),
        catchError(err => {
          if (err.status === 404) {
            return of(new Remove(action.id));
          }
          console.error(err);
          return of(new LoadFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessFolderService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




