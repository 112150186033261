import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadAllForTask, LoadAllForTaskFail, LoadAllForTaskSuccess, TaskEventActionTypes} from './task-event.actions';
import {TaskEventService} from './task-event.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TaskEvent} from './task-event';

@Injectable()
export class TaskEventEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(TaskEventActionTypes.LoadAllForTask),
    switchMap((action: LoadAllForTask) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((res: TaskEvent[]) => {
          return [new LoadAllForTaskSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('TASK.ERRORS.LOAD_ACTIVITIES_ERROR')
          return of(new LoadAllForTaskFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TaskEventService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
