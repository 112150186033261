import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {SplitViewComponent} from './split-view/split-view.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {SplitViewContainerDialogComponent} from './split-view-container-dialog/split-view-container-dialog.component';
import {FivefDialogComponent} from '../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    TranslateModule,
    RouterModule,
    FivefDialogComponent
  ],
  declarations: [
    SplitViewComponent,
    SplitViewContainerDialogComponent
  ],
  exports: [
    SplitViewComponent
  ]
})
export class PageContainerModule {
}
