import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DashboardModule} from '../two-factor-auth/dashboard.module';
import {FoxdoxModule} from '../foxdox/foxdox.module';
import {BrowserSupportModule} from '../browser-support/browser-support.module';
import {TruncatedContentModule} from 'app/shared/modules/truncated-content/truncated-content.module';
import {InfoBlockModule} from '../../five-f/info-block/info-block.module';
import {PageHeaderModule} from '../../shared/modules/page-header/page-header.module';
import {FivefSessionComponent} from './containers/fivef-session/fivef-session.component';
import {FivefSessionLoginComponent} from './components/fivef-session-login/fivef-session-login.component';
import {FivefSessionPasswordResetComponent} from './components/fivef-session-password-reset/fivef-session-password-reset.component';
import {FivefSessionPasswordResetFormComponent} from './components/fivef-session-password-reset-form/fivef-session-password-reset-form.component';
import {LoginRouterDirective} from './directive/login-router.directive';
import {FivefSessionService} from './containers/fivef-session/fivef-session.service';
import {FivefSessionTfaComponent} from './components/fivef-session-tfa/fivef-session-tfa.component';
import {FivefSessionRegistrationComponent} from './components/fivef-session-registration/fivef-session-registration.component';
import {FivefSessionRegistrationFormComponent} from './components/fivef-session-registration-form/fivef-session-registration-form.component';
import {FivefSessionRegistrationService} from './components/fivef-session-registration/fivef-session-registration.service';
import {FivefSessionRegistrationCompleteComponent} from './components/fivef-session-registration-complete/fivef-session-registration-complete.component';
import {FivefCarouselComponent} from '../../lib/fivef-ui/dashboard/fivef-carousel/fivef-carousel.component';
import {FivefLoadingIndicatorComponent} from '../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefSafeHtmlPipe} from '../../lib/fivef-ui/util/fivef-safe-html-pipe';
import {CountdownComponent} from 'ngx-countdown';
import {FivefPlatformErrorComponent} from '../../shared/components/fivef-platform-error/fivef-platform-error.component';
import {FivefDisableInputHintsWrapperDirective} from '../../lib/fivef-ui/input/fivef-disable-input-hints-wrapper.directive';

@NgModule({
  imports: [
    ReactiveFormsModule,
    DashboardModule,
    FoxdoxModule,
    BrowserSupportModule,
    TruncatedContentModule,
    InfoBlockModule,
    PageHeaderModule,
    FivefCarouselComponent,
    FivefLoadingIndicatorComponent,
    FivefSafeHtmlPipe,
    CountdownComponent,
    FivefPlatformErrorComponent,
    FivefDisableInputHintsWrapperDirective
  ],
  declarations: [
    FivefSessionComponent,
    FivefSessionLoginComponent,
    FivefSessionPasswordResetComponent,
    FivefSessionPasswordResetFormComponent,
    LoginRouterDirective,
    FivefSessionTfaComponent,
    FivefSessionRegistrationComponent,
    FivefSessionRegistrationFormComponent,
    FivefSessionRegistrationCompleteComponent
  ],
  exports: [],
  providers: [FivefSessionService, FivefSessionRegistrationService]
})
export class FivefSessionModule {

}

