import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ApiResourceService} from 'app/shared/modules/api-resource/services/api-resource.service';
import {
  ParticipatingProcessBuilder,
  ProcessParticipantBuilder,
  ProcessParticipationBuilder
} from './process-participant.builder';
import {ParticipatingProcess, ProcessParticipant, ProcessParticipation} from './process-participant';
import {MessageSettingsV2Builder} from '../message-settings/message-settings.builder';
import {MessageSettingsV2} from '../message-settings/message-settings';
import {IProcessParticipantInviteParams, IProcessParticipantParams, IProcessParticipantUpdateParams} from './process-participant.interface';

@Injectable()
export class ProcessParticipantService {
  readonly BASE_PATH = 'api/v1/workflow_engine/processes';
  readonly BASE_V3_PATH = 'api/v3/workflow_engine/processes';
  readonly PARTICIPATIONS_BASE_PATH = 'api/v1/organizations/process_participant_details'

  constructor(private _http: ApiResourceService) {
  }

  getAll(id: string, recursive = false): Observable<ProcessParticipant[]> {
    const builder = new ProcessParticipantBuilder(id);
    return <Observable<ProcessParticipant[]>>this._http.get<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_V3_PATH}/${id}/participants?recursive=${recursive}`);
  }

  getAllExternal(id: string): Observable<ProcessParticipant[]> {
    const builder = new ProcessParticipantBuilder(id);
    return <Observable<ProcessParticipant[]>>this._http.get<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_PATH}/${id}/external_participants`);
  }

  create(params: IProcessParticipantParams): Observable<ProcessParticipant> {
    const builder = new ProcessParticipantBuilder(params.processId);
    const payload = {
      data: {
        attributes: {
          email: params.email,
          first_name: params.firstName,
          last_name: params.lastName,
          admin: !!params.admin,
          recursive: !!params.recursive,
          can_delegate: params.canDelegate,
          expires_at: params.expiresAt
        }
      }
    }
    return <Observable<ProcessParticipant>>this._http.post<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_V3_PATH}/${params.processId}/participants?update_root_id=${params.cableUpdateRootId}`, payload);
  }

  update(params: IProcessParticipantUpdateParams): Observable<ProcessParticipant> {
    const builder = new ProcessParticipantBuilder(params.processId);
    const payload = {
      data: {
        attributes: {
          admin: params.admin,
          recursive: params.recursive,
          can_delegate: params.canDelegate,
          expires_at: params.expiresAt
        }
      }
    };
    return <Observable<ProcessParticipant>>this._http.put<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_V3_PATH}/${params.processId}/participants/${params.id}?update_root_id=${params.cableUpdateRootId}`, payload);
  }

  public inviteAndAdd(params: IProcessParticipantInviteParams): Observable<ProcessParticipant> {
    // TODO: @deprecated API.
    // const builder = new ProcessParticipantBuilder(params.processId);
    // const payload = {
    //   data: {
    //     attributes: {
    //       email: params.email,
    //       first_name: params.firstName,
    //       last_name: params.lastName,
    //       admin: params.admin,
    //       recursive: params.recursive,
    //       can_delegate: params.canDelegate,
    //       expires_at: params.expiresAt
    //     }
    //   }
    // };
    // return <Observable<ProcessParticipant>>this._http.post<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_PATH}/${params.processId}/invitations?update_root_id=${params.cableUpdateRootId}`, payload);
    return this.create(params);
  }

  delete(processId: string, participantId: string, recursive: boolean, cableUpdateRootId = null): Observable<ProcessParticipant> {
    const builder = new ProcessParticipantBuilder(processId);
    const skipRecursive = !recursive;
    return <Observable<ProcessParticipant>>this._http.del<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_PATH}/${processId}/participants/${participantId}?skip_recursive=${skipRecursive}&update_root_id=${cableUpdateRootId}`);
  }

  deleteExternal(participant: ProcessParticipant): Observable<ProcessParticipant> {
    const builder = new ProcessParticipantBuilder(participant.processId);
    return <Observable<ProcessParticipant>>this._http.del<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_PATH}/${participant.processId}/external_participants/${participant.id}`);
  }

  inviteExternalToProjectRoom(processId: string, id: string | number, email: string, firstName: string, lastName: string): Observable<ProcessParticipant> {
    const builder = new ProcessParticipantBuilder(processId);
    const payload = {
      data: {
        attributes: {
          email: email,
          first_name: firstName,
          last_name: lastName
        }
      }
    };
    return <Observable<ProcessParticipant>>this._http.post<ProcessParticipantBuilder, ProcessParticipant>(builder, `${this.BASE_PATH}/${processId}/external_participants/${id}/invite`, payload);
  }

  /**
   * Returns all process participations of the current organization.
   */
  getProcessParticipations(): Observable<ProcessParticipation[]> {
    const builder = new ProcessParticipationBuilder();
    return <Observable<ProcessParticipation[]>>this._http.get<ProcessParticipationBuilder, ProcessParticipation>(builder, this.PARTICIPATIONS_BASE_PATH);
  }

  getAllParticipatingProcess(id: string): Observable<ParticipatingProcess[]> {
    const builder = new ParticipatingProcessBuilder();
    return <Observable<ParticipatingProcess[]>>this._http.get<ParticipatingProcessBuilder, ParticipatingProcess>(builder, `${this.BASE_V3_PATH}/${id}/participating_processes`);
  }

  getAllNotificationSettings(id: string, recursive = false): Observable<MessageSettingsV2[]> {
    const builder = new MessageSettingsV2Builder();
    return <Observable<MessageSettingsV2[]>>this._http.get<MessageSettingsV2Builder, MessageSettingsV2>(builder, `${this.BASE_V3_PATH}/${id}/notification_settings?recursive=${recursive}`);
  }

  updateNotificationSettings(setting: MessageSettingsV2, recursive: any): Observable<MessageSettingsV2[]> {
    const builder = new MessageSettingsV2Builder();
    const payload = builder.toRequest(setting);
    return <Observable<MessageSettingsV2[]>>this._http.putAll<MessageSettingsV2Builder, MessageSettingsV2>(builder, `${this.BASE_V3_PATH}/${setting.id}/notification_settings?recursive=${recursive}`, payload);
  }
}
