import {Injectable} from '@angular/core';
import {catchError, concatMap, filter, first, switchMap, withLatestFrom} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  AddLabel,
  AddLabelFail,
  AddLabelSuccess,
  Create,
  CreateFail,
  CreateSuccess,
  KanbanItemActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveLabel,
  RemoveLabelFail,
  RemoveLabelSuccess,
  RemoveSuccess,
  UpdateGroup,
  UpdateGroupFail,
  UpdateGroupSuccess
} from './kanban-item.actions';
import {KanbanItem} from './kanban-item';
import {KanbanItemService} from './kanban-item.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {KanbanBoardSelectors} from 'app/+store';
import {KanbanBoard} from 'app/+store/kanban-store/kanban-board/kanban-board';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class KanbanItemEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [Create, KanbanBoard]) => {
      return this._svc.create(board.id, action.groupId, action.params).pipe(
        first(),
        concatMap((kanbanItem: KanbanItem) => {
          return [new CreateSuccess(kanbanItem)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [LoadAll, KanbanBoard]) => {
      return this._svc.getAll(board.id, action.groupId).pipe(
        first(),
        concatMap((items: KanbanItem[]) => [new LoadAllSuccess(items)]),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((kanbanItem: KanbanItem) => {
          return [new LoadOneSuccess(kanbanItem)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    concatMap((action: Remove) => {
      return this._svc.remove(action.id).pipe(
        first(),
        concatMap((kanbanItem: KanbanItem) => {
          return [new RemoveSuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  addLabel$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    concatMap((action: AddLabel) => {
      return this._svc.addLabel(action.referenceId, action.labelId).pipe(
        first(),
        concatMap((kanbanItem: KanbanItem) => [new AddLabelSuccess(kanbanItem)]),
        catchError(err => {
          console.error(err);
          return of(new AddLabelFail(err));
        }));
    })
  ));

  removeLabel$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    concatMap((action: RemoveLabel) => {
      return this._svc.removeLabel(action.referenceId, action.labelId).pipe(
        first(),
        concatMap((kanbanItem: KanbanItem) => [new RemoveLabelSuccess(kanbanItem)]),
        catchError(err => {
          console.error(err);
          return of(new RemoveLabelFail(err));
        }));
    })
  ));

  UpdateGroupLabel$ = createEffect(() => this.actions.pipe(
    ofType(KanbanItemActionTypes.Create),
    withLatestFrom(this._store.select(KanbanBoardSelectors.getSelectedBoard).pipe(
      filter(board => !!board)
    )),
    concatMap(([action, board]: [UpdateGroup, KanbanBoard]) => {
      return this._svc.updateGroup(board.id, action.groupId, action.itemId, action.newGroupLabelId).pipe(
        first(),
        concatMap((kanbanItem: KanbanItem) => [new UpdateGroupSuccess(kanbanItem)]),
        catchError(err => {
          console.error(err);
          return of(new UpdateGroupFail(err));
        }));
    })
  ));

  constructor(
    private actions: Actions,
    private _store: Store<AppState>,
    private _svc: KanbanItemService
  ) {
  }
}
