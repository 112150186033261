<div class="fivef-session-registration--form">

  <!--  Welcome message of tenant if available -->
  <div *ngIf="(fivefLoginSettings$ | async)?.loginSecondaryLogoUrl"
       class="fivef-session-registration--welcome-logo">
    <dvtx-page-header-logo [logo]="(fivefLoginSettings$ | async)?.loginSecondaryLogoUrl"></dvtx-page-header-logo>
  </div>

  <!--  Headline: Registration or creation status overview -->
  <ng-container [ngSwitch]="state">
    <h2 *ngSwitchCase="RegistrationState.Password"
        class="fivef-session-registration--headline">{{ 'REGISTRATION.COMPLETE_REGISTRATION_TITLE' | translate }}</h2>

    <ng-container *ngSwitchCase="RegistrationState.Setup">
      <div class="fivef-session-registration--headline">
        <ng-container *ngIf="!(accountCompleted$ | async) || !(dmsAccountPresent$ | async)">
          <h2>{{ 'REGISTRATION.YOUR_ACCOUNT_WILL_BE_CREATED' | translate }}</h2>

          <div class="ml-3">
            <mat-spinner [diameter]="20" aria-label="loading..." style="margin: 0 !important;"></mat-spinner>
          </div>
        </ng-container>

        <h2 *ngIf="(accountCompleted$ | async) && (dmsAccountPresent$ | async)">
          {{ 'REGISTRATION.YOUR_ACCOUNT_WAS_CREATED' | translate }}
        </h2>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="RegistrationState.InvalidToken">
      <h2 class="fivef-session-registration--headline">
        {{ 'PASSWORD.CONFIRMATION_TOKEN_INVALID' | translate }}
      </h2>

      <h4 class="fivef-session-registration--invalid-token">{{ 'PASSWORD.ALREADY_REGISTERED_QUESTION' | translate }}&nbsp;
        <a routerLink="/session/sign-in"
           class="anchor-text">{{ 'GENERAL.BACK_TO_LOGIN' | translate }}</a>
      </h4>
    </ng-container>
  </ng-container>

  <ng-container [ngSwitch]="state">
    <!-- Step 1: Form -->
    <fivef-session-registration-form *ngSwitchCase="RegistrationState.Password"
                                     [user]="user"
                                     (onNext)="toSetupPage($event)"></fivef-session-registration-form>

    <!-- Step 2: Registration completion and status -->
    <fivef-session-registration-complete *ngSwitchCase="RegistrationState.Setup"
                                         [user]="user"
                                         (onBack)="toPasswordPage()"
                                         (onNext)="completeSetup()"
                                         [accountCompleted]="!!(accountCompleted$ | async)"
                                         [dmsAccountPresent]="!!(dmsAccountPresent$ | async)"></fivef-session-registration-complete>
  </ng-container>
</div>




