import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import {PageHeaderComponent} from './containers/page-header/page-header.component';
import {ApiModule} from '../api/api.module';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';
import {LocaleSwitcherComponent} from './components/locale-switcher/locale-switcher.component';
import {OrganizationBannerElementComponent} from 'app/modules/organization/banner-element/banner-element.component';
import {NotificationBellComponent} from './components/notification-bell/notification-bell.component';
import {MessageBellComponent} from './components/message-bell/message-bell.component';
import {ParticipantRemovedComponent} from './components/participant-removed/participant-removed.component';
import {PageHeaderLogoComponent} from './containers/page-header-logo/page-header-logo.component';
import {SearchNavbarComponent} from './components/search-navbar/search-navbar.component';
import {InViewportModule} from 'ng-in-viewport';
import {ProcessTableModule} from 'app/modules/workflow-engine/modules/process-table/process-table.module';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    ApiModule,
    BaseFormElementsModule,
    SharedModule,
    InViewportModule,
    ProcessTableModule,
    InViewportModule
  ],
  declarations: [
    PageHeaderComponent,
    LocaleSwitcherComponent,
    OrganizationBannerElementComponent,
    NotificationBellComponent,
    MessageBellComponent,
    ParticipantRemovedComponent,
    PageHeaderLogoComponent,
    SearchNavbarComponent
  ],
  exports: [
    PageHeaderComponent,
    OrganizationBannerElementComponent,
    LocaleSwitcherComponent,
    PageHeaderLogoComponent
  ]
})
export class PageHeaderModule {
}
