import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppState } from 'app/reducers';
import { Store } from '@ngrx/store';
import { TfaIsWrong } from 'app/actions/tfa.actions';
import { TwoFactorAuthService } from 'app/+store/two-factor-auth/two-factor-auth.service';
import { NaturalPersonSelectors } from 'app/+store';
import { NaturalPerson } from 'app/models/natural-person.model';
import {takeUntil, filter, first, take} from 'rxjs/operators';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'dvtx-two-factor-verification',
  templateUrl: './two-factor-verification.component.html',
  styleUrls: ['./two-factor-verification.component.scss']
})
export class TwoFactorVerificationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private onDestroy: Subject<void> = new Subject<void>();
  private isInitialised = false;

  @Input()
  showTitle = true;

  @Input()
  form: UntypedFormGroup;

  @Input()
  error: string;  // receives the title of the error

  @ViewChild('firstInput', { static: true })
  firstInput: ElementRef;
  @ViewChild('secondInput', { static: true })
  secondInput: ElementRef;
  @ViewChild('thirdInput', { static: true })
  thirdInput: ElementRef;
  @ViewChild('fourthInput', { static: true })
  fourthInput: ElementRef;
  @ViewChild('fifthInput', { static: true })
  fifthInput: ElementRef;
  @ViewChild('sixthInput', { static: true })
  sixthInput: ElementRef;

  constructor(private _store: Store<AppState>,
              private _authService: TwoFactorAuthService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.form.addControl('firstInput', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('secondInput', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('thirdInput', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('fourthInput', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('fifthInput', new UntypedFormControl('', [Validators.required]));
    this.form.addControl('sixthInput', new UntypedFormControl('', [Validators.required]));

    this.form.get('firstInput').valueChanges.pipe(
      takeUntil(this.onDestroy),
      filter(value => !!value)
    ).subscribe((value) => {
      this.isInitialised = true;
      this.secondInput.nativeElement.focus();
      this._cdr.detectChanges();
    });
    this.form.get('secondInput').valueChanges.pipe(
      takeUntil(this.onDestroy),
      filter(value => !!value)
    ).subscribe((value) => {
      this.thirdInput.nativeElement.focus();
      this._cdr.detectChanges();
    });
    this.form.get('thirdInput').valueChanges.pipe(
      takeUntil(this.onDestroy),
      filter(value => !!value)
    ).subscribe((value) => {
      this.fourthInput.nativeElement.focus();
      this._cdr.detectChanges();
    });
    this.form.get('fourthInput').valueChanges.pipe(
      takeUntil(this.onDestroy),
      filter(value => !!value)
    ).subscribe((value) => {
      this.fifthInput.nativeElement.focus();
      this._cdr.detectChanges();
    });
    this.form.get('fifthInput').valueChanges.pipe(
      takeUntil(this.onDestroy),
      filter(value => !!value)
    ).subscribe((value) => {
      this.sixthInput.nativeElement.focus();
      this._cdr.detectChanges();
    });
    this.focusHandler();
  }


  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    if (!this.isInitialised) {
      setTimeout(() => {
        this.firstInput.nativeElement.focus();
        this._cdr.detectChanges();
      }, 200);
    }
  }

  focusHandler() {
    if (this.form.valid) {
      this.resetForm();
      this._store.dispatch(new TfaIsWrong(false));
    }
  }

  resetForm() {
    this.form.get('firstInput').reset();
    this.form.get('secondInput').reset();
    this.form.get('thirdInput').reset();
    this.form.get('fourthInput').reset();
    this.form.get('fifthInput').reset();
    this.form.get('sixthInput').reset();
    this.firstInput.nativeElement.focus();
    this._cdr.detectChanges();
  }

  ngOnChanges(changes) {
    if (this.firstInput && changes && changes.error && changes.error.currentValue && changes.error.currentValue.length > 0) {
      this.firstInput.nativeElement.focus();
    }
  }

  sendSMS() {
    if (this.form.get('email')) {
      const email = this.form.get('email').value;
      this._authService.requestOTPBySMS(email).pipe(
        first()
      ).subscribe(res => {
        this._notifyService.success('AUTH.OTP_SENT_BY_SMS')
      }, err => console.error(err));
    } else {
      this._store.select(NaturalPersonSelectors.getUsersNaturalPerson)
        .pipe(filter(p => !!p), takeUntil(this.onDestroy), take(1))
        .subscribe((my: NaturalPerson) => {
          const email = my.mainEmailAddress.emailAddress;
          this._authService.requestOTPBySMS(email).pipe(
            first()
          ).subscribe(res => {
            this._notifyService.success('AUTH.OTP_SENT_BY_SMS')
          }, err => console.error(err));
      });
    }

    this.resetForm();
  }

  sendMail() {
    const email = this.form.get('email').value;
    this._authService.requestOTPByEmail(email).pipe(takeUntil(this.onDestroy)).subscribe((response) => {
    });
  }

  public onBackspace(event, el: number) {
    if (event.target.value) return;
    switch (el) {
      case 2:
        this.form.get('firstInput').reset();
        this.firstInput.nativeElement.focus();
        break;
      case 3:
        this.form.get('secondInput').reset();
        this.secondInput.nativeElement.focus();
        break;
      case 4:
        this.form.get('thirdInput').reset();
        this.thirdInput.nativeElement.focus();
        break;
      case 5:
        this.form.get('fourthInput').reset();
        this.fourthInput.nativeElement.focus();
        break;
      case 6:
        this.form.get('fifthInput').reset();
        this.fifthInput.nativeElement.focus();
        break;
    }
    this._cdr.detectChanges();
  }

  setCaretPosition(event) {
    const ctrl = event.target;
    const pos = event.target.value.length;
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
      // IE8 and below
    } else if (ctrl.createTextRange) {
      const range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }
}
