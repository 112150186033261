import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {XOrganizationInterceptor} from 'app/services/x-organization.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiErrorHandler} from './models/api-error-handler';
import {AuthGuard} from './services/auth-guard.service';
import {ExternalAccessService} from './services/external-access.service';
import {ListingService} from './services/listing.service';
import {OrganizationService} from './services/organization.service';
import {NavigationMenuService} from './services/navigation-menu.service';
import {NavigationMenuCommandFactory} from './services/navigation-menu-command-factory.service';
import {ProcessContextBuilder} from './builders/process-context.builder';
import {FolderService} from './services/folder.service';
import {TimelineCommentsService} from './services/timeline-comments.service';
import {PartnerService} from './services/partner.service';
import {ProcessGroupService} from './services/process-group.service';
import {ApiErrorService} from './services/api-error.service';
import {TaskService} from './services/task.service';
import {MessageService} from './services/message.service';
import {LicenceService} from './services/licence.service';
import {ProfileService} from './services/profile.service';
import {TaskAssigneeService} from './services/task-assignee.service';
import {I18nService} from 'app/shared/modules/api/services/i18n.service';
import {TranslateModule} from '@ngx-translate/core';
import {DirectiveService} from './services/directive.service';
import {FoxdoxStatusService} from './services/foxdox-status.service';
import {LicenceAssignmentService} from './services/licence-assignment.service';
import {DmsPdfExportService} from './services/dms-pdf-export.service';
import {PublishDocumentService} from './services/publish-document.service';
import {SyncApiProfileService} from 'app/shared/modules/api/services/sync-api-profile.service';
import {FilesService} from './services/files.service';
import {CanActivateFibu} from './services/can-activate-fibu.service';
import {CanActivateAdminRights} from './services/can-activate-admin-rights.service';
import {CanActivateLabels} from './services/can-activate-labels.service';
import {DatevEnabledGuard} from './services/datev-enabled-guard.service';
import {IsStagingEnv} from './services/is-staging-env.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  providers: [
    AuthGuard,
    CanActivateFibu,
    CanActivateAdminRights,
    CanActivateLabels,
    DatevEnabledGuard,
    IsStagingEnv,
    ApiErrorService,
    DmsPdfExportService,
    ExternalAccessService,
    I18nService,
    ListingService,
    NavigationMenuCommandFactory,
    NavigationMenuService,
    OrganizationService,
    ProcessContextBuilder,
    FolderService,
    FilesService,
    TimelineCommentsService,
    PartnerService,
    ProcessGroupService,
    TaskService,
    TaskAssigneeService,
    MessageService,
    LicenceService,
    LicenceAssignmentService,
    ProfileService,
    DirectiveService,
    FoxdoxStatusService,
    PublishDocumentService,
    SyncApiProfileService,
    {provide: ErrorHandler, useClass: ApiErrorHandler},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XOrganizationInterceptor,
      multi: true,
    },
  ],
  exports: [
    CommonModule,
    TranslateModule
  ],
  declarations: []
})
export class ApiModule { }
