import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  QuickshareRecipientActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess
} from './quickshare-recipient.actions';
import {QuickshareRecipient} from './quickshare-recipient';
import {QuickshareRecipientService} from './quickshare-recipient.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class QuickshareRecipientEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareRecipientActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.id, action.recipient).pipe(
        first(),
        concatMap((recipient: QuickshareRecipient) => {
          return [new CreateSuccess(recipient)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  removeRecipient$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareRecipientActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.id, action.recipientId).pipe(
        first(),
        concatMap((recipient: QuickshareRecipient) => {
          return [new RemoveSuccess(recipient)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareRecipientActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.id, action.recipient).pipe(
        first(),
        concatMap((recipient: QuickshareRecipient) => {
          recipient.simplePhoneNumber = action.recipient.simplePhoneNumber;
          recipient.simplePhoneNumber.phoneNumber = action.recipient.phoneNumber;
          return [new SaveSuccess(recipient)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(QuickshareRecipientActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        concatMap((res: QuickshareRecipient[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: QuickshareRecipientService,
              private _router: Router,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
