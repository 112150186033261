import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Destroy,
  DestroyFail,
  DestroySuccess,
  DmsDocumentActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllOfProcessFolder,
  LoadAllOfProcessFolderFail,
  LoadAllOfProcessFolderSuccess,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Rename
} from './dms-document.actions';
import {DmsDocument} from './dms-document';
import * as processArtifactActions from '../process-artifact/process-artifact.actions'
import {DmsDocumentService} from './dms-document.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class DmsDocumentEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(DmsDocumentActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.folderId, action.accountType).pipe(
        first(),
        concatMap((res: DmsDocument[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadAllOfProcessFolder$ = createEffect(() => this.actions.pipe(
    ofType(DmsDocumentActionTypes.LoadAllOfProcessFolder),
    switchMap((action: LoadAllOfProcessFolder) => {
      return this._svc.getProcessFolderDocuments(action.processId).pipe(
        first(),
        concatMap((documents: DmsDocument[]) => {
          return [new LoadAllOfProcessFolderSuccess(documents)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllOfProcessFolderFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(DmsDocumentActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((document: DmsDocument) => {
          return [new LoadOneSuccess(document)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  rename$ = createEffect(() => this.actions.pipe(
    ofType(DmsDocumentActionTypes.Rename),
    switchMap((action: Rename) => {
      return this._svc.rename(action.id, action.fileName).pipe(
        first(),
        concatMap((document: any) => {
            this._notifyService.success('GENERAL.DOCUMENT_RENAME_SUCCESSFULLY');
          return [new LoadOneSuccess(document)];
        }),
        catchError(err => {
            this._notifyService.error('GENERAL.DOCUMENT_RENAME_FAIL')
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(DmsDocumentActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.processId, action.id).pipe(
        first(),
        concatMap(_res => {
          return [new RemoveSuccess(action.id), new processArtifactActions.LoadAll(action.processId)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  destroy$ = createEffect(() => this.actions.pipe(
    ofType(DmsDocumentActionTypes.Destroy),
    switchMap((action: Destroy) => {
      return this._svc.destroy(action.id, action.accountType).pipe(
        first(),
        concatMap(_res => {
          return [new DestroySuccess(action.id)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DestroyFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: DmsDocumentService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




