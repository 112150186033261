import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output
} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Net} from 'app/lib/net/uuid';
import {first} from 'rxjs/operators';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {
  QES_FEATURE_TOGGLE,
  SETTINGS_ID__QES_SIGN_ME,
  SETTINGS_ID__QES_SIGN_ME_DTRUST,
  SignatureAccount
} from 'app/+store/document-signature/document-signature';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

export enum FpSignFormMode {
  Login = 'Login',
  Register = 'Register'
}

export enum FpSignAccountStatus {
  Undefined = 'Undefined',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Unauthorized = 'Unauthorized',
  Failure = 'Failure'
}

@Component({
  selector: 'dvtx-document-signature-user-account-dialog',
  templateUrl: './document-signature-user-account-dialog.component.html',
  styleUrls: ['./document-signature-user-account-dialog.component.scss']
})
export class DocumentSignatureUserAccountDialogComponent implements OnDestroy, OnInit {
  private onDestroy = new Subject<void>();

  public readonly QES_FEATURE_TOGGLE = QES_FEATURE_TOGGLE;

  @Input()
  menuItemButtonText = 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE';

  @Input()
  menuItemButtonDisabled = false;

  @Output()
  onAccountLinked = new EventEmitter<FpSignAccountStatus>();

  _processId;

  @Input()
  disabled = false;

  featureSet$: Observable<Feature>;

  FpSignAccountStatus = FpSignAccountStatus;

  sendOngoing = false;

  public accountStatus = FpSignAccountStatus.Undefined;
  public FpSignFormMode = FpSignFormMode;
  public formMode = FpSignFormMode.Login;

  form: UntypedFormGroup;
  account$ = new BehaviorSubject<SignatureAccount>(null);
  registerForm: UntypedFormGroup;
  registerError = null;
  accountCreated = false;
  credentialError = false;
  qesAvailable = false;
  qesSettingsLoading = true;

  @Input() set processId(id: string) {
    this._processId = id;
    if (Net.validUUID(id)) {
    }
  }

  get processId(): string {
    return this._processId;
  }

  constructor(private _store: Store<AppState>,
              private _signSvc: DocumentSignatureService,
              private _fb: UntypedFormBuilder,
              private _cdr: ChangeDetectorRef) {
    this.form = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.registerForm = this._fb.group({
      username: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.fetchAccountInfo();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.account$.complete();
  }

  fetchAccountInfo() {
    this.accountCreated = false;
    this.credentialError = false;
    this.qesSettingsLoading = true;
    this._signSvc.getMyAccount().pipe(first()).subscribe((account: SignatureAccount) => {
      this.account$.next(account);
      this.accountStatus = FpSignAccountStatus.Connected;

      this._signSvc.getMySignatureSettings().pipe(first()).subscribe(settings => {
        this.qesAvailable = !!settings.find(s => s.id === SETTINGS_ID__QES_SIGN_ME || s.id === SETTINGS_ID__QES_SIGN_ME_DTRUST);
        this.qesSettingsLoading = false;
        this._cdr.detectChanges();
      }, err => {
        this.qesSettingsLoading = true;
      })

      this.form.patchValue({
        username: account.id,
        password: null
      });
      this.accountStatus = FpSignAccountStatus.Connected;
      this.onAccountLinked.emit(FpSignAccountStatus.Connected);

      this.form.markAsPristine();
      this.sendOngoing = false;
      this.credentialError = false;
    }, err => {
      this.account$.next(null);
      if (err.status && err.status === 404) {
        this.accountStatus = FpSignAccountStatus.Disconnected;
      } else if (err.status && err.status === 401) {
        this.accountStatus = FpSignAccountStatus.Unauthorized;
      } else {
        this.accountStatus = FpSignAccountStatus.Failure;
      }
      this.sendOngoing = false;
    });
  }

  unlinkAccount() {
    this._signSvc.unlinkAccount()
      .pipe(first())
      .subscribe(_acc => {
        this.accountStatus = FpSignAccountStatus.Undefined;
        this.fetchAccountInfo();
      }, err => {
        console.error(err);
        this.accountStatus = FpSignAccountStatus.Undefined;
        this.fetchAccountInfo();
      });
  }

  update() {
    this.credentialError = false;
    if (this.formMode === FpSignFormMode.Login) {
      this.updateAccount();
    }

    if (this.formMode === FpSignFormMode.Register) {
      this.registerAccount()
    }
  }

  updateAccount() {
    if (this.form.invalid) return;
    this.accountCreated = false;

    this.sendOngoing = true;
    const value = this.form.value;
    this._signSvc.updateAccount(value.username, value.password)
      .pipe(first())
      .subscribe(_acc => {
        this.accountStatus = FpSignAccountStatus.Undefined;
        this.fetchAccountInfo();
      }, err => {
        console.error(err);
        this.form.patchValue({
          password: null
        });
        if (err.status && err.status === 404) {
          this.accountStatus = FpSignAccountStatus.Disconnected;
        } else if (err.status && err.status === 401) {
          this.accountStatus = FpSignAccountStatus.Unauthorized;
          this.credentialError = true;
        } else {
          this.accountStatus = FpSignAccountStatus.Failure;
        }
        this.sendOngoing = false;
      });
  }

  registerAccount() {
    if (this.registerForm.invalid) return;

    this.accountCreated = false;
    this.sendOngoing = true;
    const value = this.registerForm.value;
    this._signSvc.registerAccount(value.username)
      .pipe(first())
      .subscribe(_acc => {
        this.registerError = null;
        this.accountCreated = true;
        this.sendOngoing = false;
        this.formMode = FpSignFormMode.Login;
      }, err => {
        console.error(err);
        this.registerError = err;
        this.sendOngoing = false;
        // 0151 628 26 735
      });
  }


  toggleFormMode() {
    this.credentialError = false;
    this.accountCreated = false;
    if (this.formMode === FpSignFormMode.Login) {
      this.registerError = null;
      this.formMode = FpSignFormMode.Register;
    } else {
      this.formMode = FpSignFormMode.Login;
    }
    this._cdr.detectChanges();
  }

  backToSelection() {
    this.accountCreated = false;
    this.credentialError = false;
    this.accountStatus = FpSignAccountStatus.Disconnected;
    this.formMode = FpSignFormMode.Login;
    this._cdr.detectChanges();
  }
}
