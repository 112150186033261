import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Client} from 'app/+store/client/client';
import {MatTableDataSource} from '@angular/material/table';
import {ClientActionType} from '../../../../../../lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';
import {InViewportMetadata} from 'ng-in-viewport';
import {Feature} from 'app/+store/feature/feature';
import {ClientService} from 'app/+store/client/client.service';
import {catchError, finalize, first} from 'rxjs/operators';
import {ClientActions, ContactSelectors} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/internal/Observable';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EditClientDialogComponent} from '../edit-client-dialog/edit-client-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {DatevOauthService} from 'app/+store/datev-oauth/datev-oauth.service';
import {FivefConfirm} from '../../../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';

@Component({
  selector: 'dvtx-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: ['./client-table.component.scss']
})
export class ClientTableComponent implements OnInit {
  ClientActionType = ClientActionType;
  _clients: Client[];
  clientListing = new MatTableDataSource<Client>();
  displayedColumns = ['icon', 'name', 'id', 'clientContacts', 'projectRooms', 'createdAt', 'actions'];
  selectedContact$: Observable<any>;

  contacts$: any;
  selectedContact: any[];
  searchTerm: any;
  dialogRef: MatDialogRef<EditClientDialogComponent>;
  @Input() organization;
  @Output() onSearch = new EventEmitter();
  @Output() onSearchReset = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onEditSuccess = new EventEmitter();

  // in-viewport-rendering setup
  isInViewPort: string[] = [];

  @Input() set featureSet(featureSet: Feature) {
    if (featureSet && featureSet.hasBookman) {
      this.displayedColumns.splice(5, 0, 'bookmanClient');
    }
  }

  @Input() set clients(s: Client[]) {
    this._clients = s;
    this.clientListing.data = s;
    if (this.clientListing.data && this.clientListing.data.length > 0) {
      this.getContactsByClientId();
    }
  }

  constructor(private _clientSvc: ClientService, private _store: Store<AppState>,
              private _translateSvc: TranslateService,
              private _cdr: ChangeDetectorRef,
              private _route: ActivatedRoute,
              private _router: Router,
              private _notifyService: NotificationService,
              private _dialog: MatDialog,
              private datevOauthService: DatevOauthService) {


  }

  ngOnInit() {
    this.contacts$ = this._store.select(ContactSelectors.getContactsOfSelectedOrg);
    // since there is pagination the sidebar should not open depending on url
    // this._route.queryParamMap.pipe(first()).subscribe(async (params) => {
    //   const id = params.get('clientid');
    //   const tab = Number(params.get('tab'));
    //   if (Net.validUUID(id)) {
    //     const client = this._clients.find(c => c.id === id);
    //     if (!this.dialogRef) {
    //       this.openSettings(client, id, tab);
    //     }
    //   }
    // })
  }

  applyFilter(filterValue: string) {
    this.clientListing.filter = filterValue.trim().toLowerCase();
  }

  selectClient(client) {
  }

  public showItem(event, itemId) {
    const {[InViewportMetadata]: {entry}, target, visible} = event;
    if (!visible) {
      this.isInViewPort = this.isInViewPort.filter(id => id !== itemId);
      return;
    }
    this.isInViewPort.push(itemId);
  }

  @FivefConfirm({
    message: 'GENERAL.CONFIRM_PROCEED',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_CONFIRM_ACTION'
  })
  public deleteClient(client) {
    this.onDelete.emit(client);
  }

  public editSuccess($event) {
    this.onEditSuccess.emit($event);
  }

  editClientName(client, name) {
    if (name) {
      this.clientListing.data.forEach(element => {
        if (element.id === client.id) {
          element.name = name;
          this._clientSvc.update(client).pipe(first()).subscribe((_client: Client) => {
            this._notifyService.success('CONTACTS.EDIT_CLIENT_NAME')
            this._store.dispatch(new ClientActions.SaveSuccess(_client));
            this._cdr.detectChanges();
          }), catchError(err => {
            this._notifyService.error('CONTACTS.EDIT_CLIENT_NAME_FAIL')
            console.error(err);
            return of();
          });
        }
      });
    }
  }

  loadMapping(data) {
    this.clientListing.data.forEach(element => {
      const contacts = data.filter(a => a.clientId === element.id);
      element.contacts = contacts;
    });
    this._cdr.detectChanges();
  }

  getContactsByClientId() {
    this._clientSvc.getContactClientMappings().pipe(first()).subscribe(data => {
      this.loadMapping(data);
    }), catchError(err => {
      console.error(err);
      return of();
    });
  }

  createContactClient(contact, client, roleName = '') {
    this._clientSvc.createContactClient(client.id, contact.id, roleName).pipe(first()).subscribe(data => {
      this.getContactsByClientId();
      this._notifyService.success('CONTACTS.CONTACT_CLIENT_ASSIGN')
    }), catchError(err => {
      console.error(err);
      this._notifyService.error('CONTACTS.CONTACT_CLIENT_ASSIGN_FAIL')
      return of();
    });
  }

  removeContactClient(contact, client) {
    const element = this.clientListing.data.find(a => a.id === client.id);
    if (element) {
      const contactData = element.contacts.find(a => a.contactId === contact.id)
      if (contactData) {
        this._clientSvc.removeContactClient(client.id, contactData.relationId).pipe(first()).subscribe(data => {
          this.getContactsByClientId();
          this._notifyService.success('CONTACTS.CONTACT_CLIENT_REMOVE')
        }), catchError(err => {
          console.error(err);
          this._notifyService.error('CONTACTS.CONTACT_CLIENT_REMOVE_FAIL')
          return of();
        });
      }
    } else {
      this._notifyService.error('CONTACTS.CONTACT_CLIENT_REMOVE_FAIL')
    }
  }

  openSettings(client, clientId = null, tab = 0) {
    this.dialogRef = this._dialog.open(EditClientDialogComponent, {
      data: {
        client,
        clientId,
        isClient: true,
        openedTab: tab,
        onSubmitAction: (result) => {
          if (result) {
            this.clientListing.data.forEach(element => {
              if (element.id === result.id) {
                element.clientId = result.clientId;
                element.name = result.name;
              }
            })
          }
        },
        onBookmanSave: (result) => {
          if (result) {
            this.clientListing.data.forEach(element => {
              if (element.id === result.id) {
                element.bookmanClientId = result.bookmanClientId;
                element.bookmanClientName = result.bookmanClientName;
              }
            })
          }
        },
        onCloseAction: (data) => {
          if (data) {
            this.loadMapping(data);
          }
        }
      }
    });
    this.dialogRef.afterClosed().pipe(
      finalize(() => this.dialogRef = undefined)
    );
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        clientid: client?.id ?? clientId,
      },
      queryParamsHandling: 'merge',
    });
  }
}
