import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Comment} from 'app/+store/comment/comment';
import {takeUntil} from 'rxjs/operators';
import {TranslateModule} from '@ngx-translate/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {CommonModule} from '@angular/common';
import {FivefMessageEditorComponent} from '../../input/fivef-message-editor/fivef-message-editor.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FivefCommentsRepository} from './fivef-comments.repository';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefCommentComponent} from '../fivef-comment/fivef-comment.component';
import {ExportCommentsModule} from '../../../../five-f/export-comments/export-comments.module';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CommentReactionType, CommentResourceType} from '../../../../+store/comment/comment.interface';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {ExportCommentsComponent} from '../../../../five-f/export-comments/components/export-comments/export-comments.component';
import {ProcessOrganizationSelectorService} from '../../../../+store/process/process-organization-selector.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'fivef-comments',
  standalone: true,
  host: {'class': 'fivef-node-comments'},
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatProgressSpinnerModule,
    FivefMessageEditorComponent,
    FivefIconMessageBlockComponent,
    FivefCommentComponent,
    ExportCommentsModule,
    MatButtonModule,
    MatFormFieldModule,
    FivefLoadingIndicatorComponent,
  ],
  providers: [
    FivefCommentsRepository
  ],
  templateUrl: './fivef-comments.component.html',
  styleUrls: ['./fivef-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefCommentsComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  // @ViewChild('exportCommentsComponentRef')
  // private exportCommentsComponentRef: ExportCommentsComponent;

  public loading$: Observable<boolean>;

  public sendingLoading$: Observable<boolean>;
  public comments$: Observable<Comment[]>;
  public comment = '';
  @ViewChild('exportCommentsComponentRef') exportCommentsComponentRef: ExportCommentsComponent;

  @Input()
  public canCreateComment = false;

  @Input()
  public commentPrefix = null;

  // added for document preview download comments
  @Input()
  public currentProcessArtifact = null

  public _resourceType: CommentResourceType = 'process';
  public isMember$: Observable<boolean>;
  public processId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  @Input()
  set resourceType(type: CommentResourceType) {
    this._resourceType = type;
    this.repo.resourceType = type;
  }

  @Input()
  set resourceId(rid: string) {
    this.repo.resourceId = rid;
  }

  @Input()
  set processId(id: string) {
    this.repo.processId = id;
    this.processId$.next(id);
  }

  constructor(private repo: FivefCommentsRepository,
              private cdr: ChangeDetectorRef, private _processOrganizationService: ProcessOrganizationSelectorService) {
  }

  ngOnInit() {
    this.loading$ = this.repo.loading;
    this.comments$ = this.repo.comments;
    this.isMember$ = this._processOrganizationService.isMember$()
    // Restore the message on error cases.
    this.repo.restoreMessageOnFailure
      .pipe(takeUntil(this.onDestroy))
      .subscribe(msg => {
        if (msg && typeof msg === 'string' && msg.length > 0) {
          this.comment = msg;
          this.cdr.detectChanges();
        }
      })
  }

  public react(commentId: string, reactionType: CommentReactionType) {
    this.repo.react(commentId, reactionType);
  }

  /**
   * Send comment with current process/item context.
   * @param message
   * @param replyToId
   */
  public send(message: string, replyToId: string = null) {
    this.repo.send(message, replyToId);
  }

  public saveComment(commentId: string, message: string) {
    this.repo.saveComment(commentId, message);
  }

  public deleteComment(commentId: string) {
    this.repo.deleteComment(commentId);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
  }

  // openCommentDownloadDialog() {
  //   if (this.exportCommentsComponentRef) {
  //     this.exportCommentsComponentRef.openCommentDialog();
  //   }
  // }

  public trackByFn = (_index, comment: Comment) => {
    return comment.trackBy;
  }

  openCommentDownloadDialog() {
    if (this.exportCommentsComponentRef) {
      this.exportCommentsComponentRef.openCommentDialog();
    }
  }
}
