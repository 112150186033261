import {NgModule} from '@angular/core';
import {AddressBookTableComponent} from './containers/address-book-table/address-book-table.component';
import {ImportContactsComponent} from './components/import-contacts/import-contacts.component';
import {EditContactPersonDialogComponent} from './components/edit-contact-person-dialog/edit-contact-person-dialog.component';
import {AlertsModule} from '../../../alerts/alerts.module';
import {CsvImportModule} from 'app/shared/modules/csv-import/csv-import.module';
import {ContactVisibilityChooserComponent} from './components/contact-visibility-chooser/contact-visibility-chooser.component';
import {CreateContactDialogComponent} from './components/create-contact-dialog/create-contact-dialog.component';
import {CustomAttributesComponent} from './components/custom-attributes/custom-attributes.component';
import {ContactDirective} from './directives/contact.directive';
import {BatchDialogModule} from '../batch-dialog/batch-dialog.module';
import {InViewportModule} from 'ng-in-viewport';
import {MemberDeletionDialogComponent} from './components/member-deletion-dialog/member-deletion-dialog.component';
import {ContactDeletionDialogComponent} from './components/contact-deletion-dialog/contact-deletion-dialog.component';
import {FiltersContainerModule} from 'app/five-f/filters/filters-container.module';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {ScrollingModule} from '@angular/cdk-experimental/scrolling';
import {FivefDialogComponent} from '../../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefItemSelectorComponent} from '../../../../lib/fivef-ui/input/fivef-item-selector/fivef-item-selector.component';

@NgModule({
  imports: [
    AlertsModule,
    BatchDialogModule,
    CsvImportModule,
    InViewportModule,
    FiltersContainerModule,
    TooltipModule,
    ScrollingModule,
    FivefDialogComponent,
    FivefItemSelectorComponent
  ],
  declarations: [
    ContactVisibilityChooserComponent,
    CustomAttributesComponent,
    ImportContactsComponent,
    AddressBookTableComponent,
    CreateContactDialogComponent,
    EditContactPersonDialogComponent,
    ContactDirective,
    MemberDeletionDialogComponent,
    ContactDeletionDialogComponent
  ],
  exports: [
    AlertsModule,
    BatchDialogModule,
    AddressBookTableComponent,
    ContactDirective,
    EditContactPersonDialogComponent
  ]
})
export class AddressBookTableModule {
}
