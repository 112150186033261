import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {OrganizationSelectors} from '../../../../../+store/organization';
import {Observable, Subject} from 'rxjs';
import {Organization} from '../../../../../models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../reducers';
import {TaskStatusScheme} from 'app/+store/task-status-scheme/task-status-scheme';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {TaskStatusActions, TaskStatusSchemeActions, TaskStatusSchemeSelectors} from 'app/+store';
import {TaskColors, TaskType, TaskTypeTitle} from 'app/+store/task/task.interface';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TaskStatus} from 'app/+store/task-status/task-status';
import {MatDialog} from '@angular/material/dialog';
import {StringValidator} from 'app/lib/validator';
import {EditStatusDialogComponent} from '../tasks-settings-standard-frame/tasks-settings-standard-frame.component';
import {ITabNavRoute} from '../../../../../five-f/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {FivefConfirm} from "../../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator";

@Component({
  selector: 'dvtx-tasks-settings-approval-frame',
  templateUrl: './tasks-settings-approval-frame.component.html',
  styleUrls: ['./tasks-settings-approval-frame.component.scss']
})
export class TasksSettingsApprovalFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject();
  readonly TASK_TYPE = TaskType.InvoiceApproval;
  selectdNewColor;

  taskTypes = TaskType;
  taskTypeTitle = TaskTypeTitle;
  taskColors = TaskColors;

  public organization$: Observable<Organization>;
  public statusesSchemas$: Observable<TaskStatusScheme[]>;
  public defaultStatusesScheme$: Observable<TaskStatusScheme>;
  public statusesSchemas: { [id: string]: TaskStatusScheme } = {};
  public execludedColors: { [id: string]: string[] } = {};
  public execludedTitles: { [id: string]: string[] } = {};

  public forms: { [id: string]: UntypedFormGroup } = {};
  public statusForms: { [id: string]: UntypedFormGroup } = {};

  public orgId;
  public routes: ITabNavRoute[] = [];
  public activeLink: string;

  @ViewChild('callToActionRef', {static: true}) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  constructor(private store: Store<AppState>,
              public fb: UntypedFormBuilder,
              public dialog: MatDialog,
              private _route: ActivatedRoute,
              private _viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.organization$ = this.store.select(OrganizationSelectors.getSelected)
      .pipe(filter(organization => !!organization), distinctUntilChanged());
    this.organization$.pipe(takeUntil(this.onDestroy)).subscribe((organization) => {
      if (organization && organization.id) {

        this.store.dispatch(new TaskStatusSchemeActions.LoadAll(true));
        this.store.dispatch(new TaskStatusSchemeActions.LoadDefault);

        this.defaultStatusesScheme$ = this.store.select(TaskStatusSchemeSelectors.getDefaultStatusSchemes());
        this.statusesSchemas$ = this.store.select(TaskStatusSchemeSelectors.getAllTaskStatusSchemes);
        this.statusesSchemas$.pipe(takeUntil(this.onDestroy)).subscribe((schemes) => {
          this.execludedColors = {};
          this.execludedTitles = {};

          const forms: { [id: string]: UntypedFormGroup } = {};
          Object.keys(this.taskTypes).forEach((key) => {
            forms[this.taskTypes[key]] = this.fb.group({
              title: [null, [Validators.required, StringValidator.noWhitespaceValidator]],
              taskType: [this.taskTypes[key], Validators.required],
              description: [null],
              isDefault: [true],
              isPublished: [true]
            });
            this.statusForms[this.taskTypes[key]] = this.fb.group({
              title: [
                null, [
                  Validators.required,
                  StringValidator.noWhitespaceValidator,
                  (control) => StringValidator.notExistedIn(control.value, this.execludedTitles[this.taskTypes[key]])
                ]
              ],
              color: [null, Validators.required],
              statusScheme: [null, Validators.required]
            });
          });

          schemes.map((scheme: TaskStatusScheme) => {
            if (forms[scheme.taskType]) {
              this.statusesSchemas[scheme.taskType] = scheme;
              forms[scheme.taskType] = this.fb.group({
                title: [
                  scheme.title, [
                    Validators.required,
                    (control) => StringValidator.notExistedIn(control.value, this.execludedTitles[scheme.taskType])
                  ]
                ],
                taskType: [scheme.taskType, Validators.required],
                description: [scheme.description],
                isDefault: [true],
                isPublished: [true]
              });

              this.statusForms[scheme.taskType].get('statusScheme').setValue(scheme.id);

              scheme.statuses.forEach(status => {
                if (!this.execludedColors[scheme.taskType]) {
                  this.execludedColors[scheme.taskType] = [];
                }
                if (!this.execludedTitles[scheme.taskType]) {
                  this.execludedTitles[scheme.taskType] = [];
                }
                this.execludedColors[scheme.taskType].push(status.color);
                this.execludedTitles[scheme.taskType].push(status.title);
              });
            }
          });

          this.forms = forms;
        });
      }
    })

    this.orgId = this._route.snapshot.params.id;
    this.routes = [{
      title: 'TASK.TASK_TYPES.STANDARD',
      route: `/organization/${this.orgId}/tasks-settings/standard`
    }, {
      title: 'TASK.TASK_TYPES.INVOICE_APPROVAL',
      route: `/organization/${this.orgId}/tasks-settings/approval`,
    }];
    this.activeLink = this.routes[1].title;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  saveScheme(form: UntypedFormGroup, scheme: TaskStatusScheme = null) {
    if (!scheme) {
      this.store.dispatch(new TaskStatusSchemeActions.Create(form.value));
      return;
    }
    const control = new UntypedFormControl(scheme.id);
    form.addControl('id', control);
    this.store.dispatch(new TaskStatusSchemeActions.Edit(form.value));
  }

  addStatus(form: UntypedFormGroup) {
    this.store.dispatch(new TaskStatusActions.Create(form.value));
    form.get('title').reset();
    form.get('color').reset();
    this.selectdNewColor = null;
    setTimeout(_ => {
      this.store.dispatch(new TaskStatusSchemeActions.LoadOne(form.get('statusScheme').value));
    }, 500);
  }


  @FivefConfirm({
    message: 'TASK.DELETE_STATUS',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_CONFIRM_ACTION'
  })
  deleteStatus(status: TaskStatus) {
    this.store.dispatch(new TaskStatusActions.Delete(status));
    setTimeout(_ => {
      this.store.dispatch(new TaskStatusSchemeActions.LoadOne(status.statusScheme));
    }, 500);
  }


  editStatus(status: TaskStatus, scheme) {
    const dialogRef = this.dialog.open(EditStatusDialogComponent, {
      data: {
        status: status,
        execludedColors: this.execludedColors[scheme],
        execludedTitles: this.execludedTitles[scheme],
        onSaveChanges: (form: UntypedFormGroup) => {
          this.store.dispatch(new TaskStatusActions.Edit(form.value));
          setTimeout(_ => {
            this.store.dispatch(new TaskStatusSchemeActions.LoadOne(form.get('statusScheme').value));
          }, 500);
          dialogRef.close();
        }
      }
    })
  }

  selectNewColor(color, form: UntypedFormGroup) {
    if (color && color.includes('#')) {
      color = color.split('#')[1];
    }
    this.selectdNewColor = color;
    form.get('color').setValue(color);
  }
}
