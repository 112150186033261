<div class="fivef-filter-listing--header" *ngIf="showFilterCounter">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</div>

<div class="fivef-filter-listing--container fivef-filter-listing--colored">
  <ng-container *ngFor="let option of filterdOptions$ | async">
    <button [matTooltip]="((option?.title | translate)?.length > 22) ? (option.title | translate) : null"
            type="button"
            class="fivef-filter-listing--item"
            [class.active]="isSelected(option)"
            [ngStyle]="isSelected(option) ? getActiveStyle(option) : getNormalStyle(option)"
            (click)="changeSelection(option)"
            [disabled]="loading || disabled">
      <mat-icon *ngIf="option?.icon && (!option?.isSVGIcon && !option?.isSvgIcon)"
                class="mr-1">{{option?.icon}}</mat-icon>
      <mat-icon *ngIf="option?.icon && (option?.isSVGIcon || option?.isSvgIcon)" [svgIcon]="option?.icon"
                class="mr-1"></mat-icon>
      <span class="text-truncate">
          {{option.title | translate}}
        </span>
    </button>
  </ng-container>
</div>
