import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {DocumentModule} from '../document/document.module';
import {ProcessFolderSharingInfoComponent} from './containers/process-folder-sharing-info/process-folder-sharing-info.component';
import {FolderInfoComponent} from './components/folder-info/folder-info.component';
import {DmsFolderInfoComponent} from './containers/dms-folder-info/dms-folder-info.component';
import {PersonAutocompleteComponent} from './components/person-autocomplete/person-autocomplete.component';
import {ProcessTableModule} from '../process-table/process-table.module';
import {FolderTreeIconComponent} from './components/folder-tree-icon/folder-tree-icon.component';
import {DocumentStructureComponent} from './containers/documents-structure/document-structure.component';
import {ProcessTreeNodeComponent} from './components/process-tree-node/process-tree-node.component';
import {ProcessTreeSingleSelectComponent} from './components/process-tree-single-select/process-tree-single-select.component';
import {MatTreeModule} from '@angular/material/tree';
import {AlertsModule} from '../../../alerts/alerts.module';
import {FolderSelectionDialogComponent} from './components/folder-selection-dialog/folder-selection-dialog.component';
import {FolderSelectorComponent} from './components/folder-selector/folder-selector.component';
import {FolderTreeComponent} from './containers/folder-tree/folder-tree.component';
import {InfoBlockModule} from '../../../../five-f/info-block/info-block.module';
import {FivefFileSizePipe} from '../../../../lib/fivef-ui/util/fivef-file-size.pipe';
import {FivefMenuItemComponent} from '../../../../lib/fivef-ui/navigation/fivef-menu-item/fivef-menu-item.component';
import {FivefArtifactTableComponent} from '../../../../lib/fivef-ui/artifact/fivef-artifacts-table/fivef-artifact-table.component';

@NgModule({
  imports: [
    SharedModule,
    ProcessTableModule,
    DocumentModule,
    MatTreeModule,
    AlertsModule,
    InfoBlockModule,
    FivefFileSizePipe,
    FivefMenuItemComponent,
    FivefArtifactTableComponent
  ],
  declarations: [
    ProcessFolderSharingInfoComponent,
    FolderInfoComponent,
    DmsFolderInfoComponent,
    PersonAutocompleteComponent,
    FolderTreeIconComponent,
    ProcessTreeNodeComponent,
    ProcessTreeSingleSelectComponent,
    DocumentStructureComponent,
    FolderSelectionDialogComponent,
    FolderSelectorComponent,
    FolderTreeComponent
  ],
  exports: [
    ProcessFolderSharingInfoComponent,
    DmsFolderInfoComponent,
    DocumentStructureComponent,
    FolderSelectionDialogComponent,
    FolderSelectorComponent
  ]
})
export class FolderModule {
}
