import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FileUtils} from 'app/lib/file_utils/functions';
import {of} from 'rxjs';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {CollectorExportActionTypes, Download, DownloadFail, DownloadSuccess} from './collector-export.actions';
import {CollectorExportService} from './collector-export.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class CollectorExportEffects {
  download$ = createEffect(() => this.actions.pipe(
    ofType(CollectorExportActionTypes.Download),
    switchMap((action: Download) => {
      return this._svc.download(action.collector).pipe(
        first(),
        concatMap((response: any) => {
          const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          FileUtils.saveAsFile(response.data.attributes.filename, response.data.attributes.content, mimeType);
          this._notifyService.success('The file is generated successfully.');
          return [new DownloadSuccess(response.data)];
        }),
        catchError(err => {
          console.error(err);
          return of(new DownloadFail(err));
        }));
    })
  ));

  constructor(
    private actions: Actions,
    private _svc: CollectorExportService,
    private _notifyService: NotificationService
  ) {
  }
}
