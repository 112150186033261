import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import * as currentUserActions from 'app/actions/current-user-actions';
import {AngularTokenService} from 'angular-token';
import {GetCurrentUser} from 'app/actions/current-user-actions';
import {OrganizationSelectors} from 'app/+store';
import * as store from 'store';
import {AutoLogoutService} from '../../../../services/auto-logout.service';

@Injectable()
export class AuthGuard {
  constructor(private _store: Store<AppState>,
              private _authTokenService: AngularTokenService,
              private _router: Router,
              private logoutSvc: AutoLogoutService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // If valid session is (synchronously) available return true (angular-token/localstorage).
    // Verifies asynchronously in the background if session is
    // "really" valid: GetCurrentUser action verifies available tokens with the API
    // and signs out automatically if the tokens could not be verified.
    if (this._authTokenService.userSignedIn()) {
      // NOTE: Userlane is currently disabled.
      //       Probably used in future. See also commented code snippet below.
      // this._setupUserLane(this._authTokenService.currentAuthData.uid);
      this._store.dispatch(new GetCurrentUser());

      if (!localStorage.getItem('selectedOrganizationId')) {
        this._store.select(OrganizationSelectors.getSelected).subscribe((org) => {
          if (org)
            localStorage.setItem('selectedOrganizationId', org.id);
        });
      }
      return true;
    } else {
      //  else case: No valid session:
      if (!AuthGuard.isSessionRoute(state.url)
        && !AuthGuard.isThirdPartyContribution(state.url)
        && !AuthGuard.isExternalAccess(state.url)
        && !AuthGuard.isFileInbox(state.url)
        && !AuthGuard.isCavContribution(state.url)) {
        this._router.navigate(['/session/sign-in'],
          {queryParams: {returnUrl: state.url}});
      }
      this._store.dispatch(new currentUserActions.ResetCurrentUser());
      this._store.dispatch({type: null});

      this.logoutSvc.clearLocalStorage();

      return false;
    }
  }

  static isTfaWizardRoute(state: string) {
    return /\/session\/tfa-setup/.test(state);
  }

  static isSessionRoute(state: string) {
    return /\/session/.test(state);
  }

  static isFileInbox(state: string) {
    return /\/briefkasten/.test(state);
  }

  static isThirdPartyContribution(state: string) {
    return /\/workflows\/audit\/jap-bestaetigung-dritte\/confirm/.test(state);
  }

  static isExternalAccess(state: string) {
    return /\/access\/external/.test(state);
  }

  static isCavContribution(state: string) {
    return /\/workflows\/audit\/kap\/verification/.test(state);
  }

  // 2019-02-12: Userlane is currrently disabled.
  // private _setupUserLane(userId) {
  //   if (environment.production) {
  //     (<any>window).userlaneSettings = {
  //       app_id: 31843,
  //       user: {
  //         id: '' + userId
  //       }
  //     };
  //     const userlaneTag = document.createElement('script');
  //     userlaneTag.type = 'text/javascript';
  //     userlaneTag.src = '//cdn.userlane.com/userlane.js';
  //     document.body.appendChild(userlaneTag);
  //   }
  // }
}

// Reference:
// - http://jasonwatmore.com/post/2016/12/08/angular-2-redirect-to-previous-url-after-login-with-auth-guard
