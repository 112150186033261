import * as apiErrorActions from '../actions/api-error.actions';
import {ApiError} from '../shared/modules/api/models/api-error';

export type ApiErrorState = ApiError[];

export const initialState: ApiErrorState = [];

export function apiErrorReducer(state: ApiErrorState = initialState, action: apiErrorActions.Actions): ApiErrorState {
  switch (action.type) {
    case apiErrorActions.CLEAR_API_ERRORS:
      console.log('CLEARING');
      return [];
    case apiErrorActions.ADD_API_ERROR:
      state.push(action.payload);
      return state;
    case apiErrorActions.REMOVE_API_ERROR:
      state = state.filter(error => error !== action.payload);
      return state;
    default: {
      return state;
    }
  }
};

