import {
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {first} from 'rxjs/operators';
import {ProcessArtifactActions} from 'app/+store/process-artifact';
import {ProcessArtifactService} from 'app/+store/process-artifact/process-artifact.service';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Subject} from 'rxjs/internal/Subject';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';

export interface IDeletableResource {
  id: string;
}

/**
 * Deletion dialog for artifacts.
 * TODO: Revise: It seems this dialog is never used, now.
 */
@Component({
  selector: 'fivef-delete-artifact',
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule, MatIconModule, MatTooltipModule, FivefDialogComponent, MatCheckboxModule, FormsModule],
  templateUrl: './fivef-delete-artifact.component.html',
  styleUrls: ['./fivef-delete-artifact.component.scss']
})
export class FivefDeleteArtifactComponent implements OnDestroy {
  private onDestroy = new Subject<void>();

  @ViewChild('dialogTpl', {static: true})
  dialogTpl: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  submitOnGoing = false;

  removeWithoutDelete = false;
  deleteDocument = false;
  processLocked = false;

  @Input()
  process: IDeletableResource;

  @Input()
  artifact: any;

  @Input()
  disabled = false;

  @Input()
  hidden = false;

  @Input()
  buttonStyle = 'icon';

  @Input()
  titleBtn = 'DMS.DELETE_ACTION';

  @Output()
  onDeleteSuccess: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _processArtifactService: ProcessArtifactService,
              private _notifyService: NotificationService) {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  openDeleteDocumentDialog() {
    if (!this.process || !this.artifact || this.disabled) {
      return;
    }

    this.dialogRef = this._dialog.open(this.dialogTpl);
  }

  closeDialog() {
    try {
      if (this.dialogRef) {
        this.dialogRef.close();
        this.onDeleteSuccess.emit();
      }
    } catch (err) {
      console.error('Cannot close dialog', err);
    }
    this.submitOnGoing = false;
    this.removeWithoutDelete = false;
  }

  submit($event) {
    $event.stopPropagation();

    if (this.process && this.artifact) {
      this.submitOnGoing = true;

      let artifactId = this.artifact.id;
      if (this.artifact && this.artifact.artifact && this.artifact.artifact.dmsDocumentId) {
        artifactId = this.artifact.artifact.dmsDocumentId
      }
      this._processArtifactService
        .remove(this.process.id, artifactId, !!this.deleteDocument)
        .pipe(first())
        .subscribe(artifact => {
          this.submitOnGoing = false;
          this.closeDialog();
          this._notifyService.success('DMS.DOCUMENT_DELETED')
          this._store.dispatch(new ProcessArtifactActions.DeleteSuccess(artifactId));
        }, (err) => {
          if (err && err.status === 412) {
            this.removeWithoutDelete = true;
            this._store.dispatch(new ProcessArtifactActions.DeleteSuccess(artifactId));
          }
          this.submitOnGoing = false;
          console.error(err)
        });
    }
  }
}
