import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Net} from 'app/lib/net/uuid';
import {Router} from '@angular/router';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {ProcessArtifact} from 'app/+store/process-artifact/process-artifact';
import {
  DocumentPreview, DocumentSignatureRequestProfileType,
  SignatureAccount,
  SignatureSettings
} from 'app/+store/document-signature/document-signature';
import {first} from 'rxjs/operators';
import {ProcessService} from 'app/+store/process/process.service';
import {DocumentSignatureService} from 'app/+store/document-signature/document-signature.service';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {FivefDisabledToggleDirective} from '../../util/fivef-disabled-toggle.directive';
import {FivefMenuItemComponent} from '../../navigation/fivef-menu-item/fivef-menu-item.component';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {FivefStatusListModule} from '../../common/fivef-status-list/fivef-status-list.module';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {IFivefProcessResource} from '../../bom/fivef-node-details/fivef-node-dialog.interface';

export enum DocumentSignatureSelectionMenuViewType {
  Embedded = 'Embedded',
  Button = 'Button',
  Custom = 'Custom',
  MenuItem = 'MenuItem',
  IconButton = 'IconButton',
  ListItem = 'ListItem',
  Quickstart = 'Quickstart'
}

@Component({
  selector: 'fivef-signature-selection',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, TranslateModule, MatIconModule, FivefDisabledToggleDirective, FivefMenuItemComponent, FivefDialogComponent, FivefStatusListModule, FivefLoadingIndicatorComponent],
  templateUrl: './fivef-signature-selection.component.html',
  styleUrls: ['./fivef-signature-selection.component.scss']
})
export class FivefSignatureSelectionComponent implements OnDestroy, OnInit {
  public readonly DocumentSignatureSelectionMenuViewType = DocumentSignatureSelectionMenuViewType;
  public readonly DocumentSignatureRequestProfileType = DocumentSignatureRequestProfileType;

  private onDestroy = new Subject<void>();

  @ViewChild('dialogTpl', {static: true})
  private dialogTpl: TemplateRef<any>;
  private dialogRef: MatDialogRef<any>;
  public dialogTitle = 'SIGNATURE.LOADING_SIGNATURE_WORKFLOW';

  public workflowFlowIsLoading = false;

  @Input()
  menuItemButtonText = 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE';

  @Input()
  menuItemButtonDisabled = false;

  @Input()
  action = null;

  _process: IFivefProcessResource;
  createStandaloneSignatureProcess = true;

  supported = false;

  provideUpload = true;
  _document: ProcessArtifact;

  private _processId = null;

  @Input()
  disabled = true;

  featureSet$: Observable<Feature>;

  process$ = new BehaviorSubject<IFivefProcessResource>(null);
  processLoading = true;

  document$ = new BehaviorSubject<DocumentPreview>(null);
  documentLoading = true;

  account$ = new BehaviorSubject<SignatureAccount>(null);
  accountLoading = true;
  settingsLoading = true;
  settingsLoadingError = false;
  accountLoadingError = false;

  pdfLoading = true;
  pdfIsAvailable = false;

  pdf$ = new BehaviorSubject<DocumentPreview>(null);

  mySettings$ = new BehaviorSubject<SignatureSettings[]>([]);

  profile: DocumentSignatureRequestProfileType = null;

  creatingWorkflowRequest = false;

  @Input()
  set process(process: IFivefProcessResource) {
    this._process = process;
    if (process && process.id) {
      this._processId = process.id;
    }
    this.createStandaloneSignatureProcess = !this._processId;
  }

  @Input()
  set processId(processId: string) {
    this._processId = processId;
    this.createStandaloneSignatureProcess = !processId;
  }

  get processId(): string {
    if (this._processId) return this._processId;
    if (!this._process) return null;
    return this._process.id;
  }

  @Input()
  set document(document: IResource) {
    this._document = <ProcessArtifact>document;
    this.provideUpload = !this._document;

    if (this._document) {
      this.supported = FivefSignatureSelectionComponent.supportedDocument(<ProcessArtifact>this._document)
    }
  }

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _processSvc: ProcessService,
              private _signSvc: DocumentSignatureService,
              private _router: Router,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.process$.complete();
    this.document$.complete();
    this.account$.complete();
    this.pdf$.complete();
    this.mySettings$.complete();
  }

  private _resetDialog() {
    this.account$.next(null);
    this.accountLoading = true;
    this.settingsLoading = true;
    this.pdf$.next(null);
    this.document$.next(null);
    this.pdfLoading = true;
    this.pdfIsAvailable = false;
    this.mySettings$.next(null);
    this.process$.next(null);

    this.workflowFlowIsLoading = false;
    this.dialogTitle = 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE';
    this._cdr.detectChanges();
  }

  openWorkflowDialog() {
    if (!this.supported && !this.provideUpload) return;

    this._resetDialog();

    this.creatingWorkflowRequest = false;

    this.dialogRef = this._dialog.open(this.dialogTpl);
  }

  startSignature(profile) {
    this.workflowFlowIsLoading = true;
    this.profile = profile;
    this.dialogTitle = 'SIGNATURE.LOADING_SIGNATURE_WORKFLOW';

    if (this.processId && Net.validUUID(this.processId)) {
      this.loadProcess(this.processId);
    }

    if (this._document) {
      this.loadDocument(this._document.id);
    }

    this.loadMyAccount();
  }

  closeDialog() {
    try {
      this.dialogRef.close();
    } catch (e) {
      console.error('Could not close the signature menu dialog. Exit...', e)
    }
  }

  static supportedDocument(artifact: ProcessArtifact) {
    if (artifact) {
      const fileName = artifact.fileName;
      const title = artifact.title;
      let ext: string = '';
      if (fileName && fileName.includes('.') && fileName.substr(fileName.lastIndexOf('.') + 1)) {
        ext = fileName.substr(fileName.lastIndexOf('.') + 1);
      } else if (title && title.includes('.') && title.substr(title.lastIndexOf('.') + 1)) {
        ext = title.substr(title.lastIndexOf('.') + 1);
      }
      if (ext) {
        const _ext = ext.toLocaleLowerCase();
        switch (_ext) {
          case 'pdf':
          case 'txt':
          case 'rtf':
          case 'fodt':
          case 'doc':
          case 'docx':
          case 'odt':
          case 'xls':
          case 'xlsx':
          case 'ods':
          case 'ppt':
          case 'pptx':
          case 'odp':
            return true;
        }
      }
    }
    return false;
  }

  private loadProcess(processId: string) {
    if (Net.validUUID(processId)) {
      this._processSvc.getOne(processId)
        .pipe(first())
        .subscribe(process => {
          this.processLoading = false;
          this.process$.next(process);

          this.maybeCreateRequestWorkflow();
        });
    }
  }

  private loadDocument(documentId: string) {
    if (Net.validUUID(documentId)) {
      this._signSvc.checkDocumentAccess(documentId)
        .pipe(first())
        .subscribe(document => {
          this.documentLoading = false;
          this.document$.next(document);
          this.loadPDF(documentId);
        });
    }
  }

  private loadPDF(documentId) {
    if (Net.validUUID(documentId)) {
      this._signSvc.getDocumentPreview(documentId)
        .pipe(first())
        .subscribe(document => {
          this.pdfIsAvailable = true;
          this.pdfLoading = false;
          this.pdf$.next(document);

          this.maybeCreateRequestWorkflow();
        }, _err => {
          this.pdfIsAvailable = false;
          this.pdfLoading = false;
        });
    }
  }

  private loadMyAccount() {
    this.accountLoadingError = false;
    this._signSvc.getMyAccount()
      .pipe(first())
      .subscribe(account => {
        this.accountLoading = false;
        this.account$.next(account);
        this.loadSettings();
      }, err => {
        console.error('loadMyAccount', err);
        this.accountLoading = false;
        this.accountLoadingError = true;
      });
  }

  private loadSettings() {
    this.settingsLoadingError = false;
    this._signSvc.getMySignatureSettings()
      .pipe(first())
      .subscribe(settings => {
        this.mySettings$.next(settings);
        if (settings.length === 0) {
          this.settingsLoadingError = true;
        }
        this.settingsLoading = false;

        if (!this.settingsLoadingError) {
          this.maybeCreateRequestWorkflow();
        }
      }, err => {
        console.error('loadSettings', err);
        this.settingsLoadingError = true;
      });
  }

  /**
   * True if critieria to start the workflow are unmet.
   */
  private unmetCriteriaForWorkflowCreation(): boolean {
    return this.creatingWorkflowRequest
      || this.accountLoading
      || this.settingsLoading
      || !this.createStandaloneSignatureProcess && this.processLoading
      || !this.provideUpload && (this.documentLoading || this.pdfLoading || !this.pdfIsAvailable);
  }

  /*
   * Create signature workflow if all criteria are met.
   */
  private maybeCreateRequestWorkflow() {
    if (this.unmetCriteriaForWorkflowCreation()) {
      return;
    }

    this.createRequestWorkflow();
  }

  /**
   * Creates the siganture process and navigates to the wizard form.
   */
  public createRequestWorkflow(): void {
    this.creatingWorkflowRequest = true;
    this.workflowFlowIsLoading = true;
    this.dialogTitle = 'SIGNATURE.START_WORKFLOW_DIALOG.CREATE_SIGNATURE';

    // Loader does show up without change detection here.
    this._cdr.detectChanges();

    const dialogRef = this._dialog;

    const documentId = this._document ? this._document.id : null;
    const processId = this.processId;

    this._signSvc.createWorkflowRequest(processId, documentId, this.profile)
      .pipe(first())
      .subscribe(workflowRequest => {
        console.error(workflowRequest);
        this._router.navigate(['signature', 'request', 'new', workflowRequest.id]);

        // Close all outer start dialogs, e.g. also the document preview browser.
        setTimeout(_ => {
          dialogRef.closeAll();
        }, 2000);
      }, err => {
        console.error(err);
        this.creatingWorkflowRequest = false;
      });
  }

  navigateToSettings() {
    this.closeDialog();
    this._router.navigate(['user-profile', 'signature', 'fp-sign-settings']);
  }
}
