// tslint:disable no-use-before-declare
import { IContact, IContactAddress, IContactPhoneNumber, IContactEmailAddress } from './contact.interface';
import { IRole } from '../../../../models/role.interface';

export abstract class AbstractContact implements IContact {
  id: number;
  abstract type: string;

  abstract name;
  abstract icon;

  private _address;
  private _phoneNumber;
  private _emailAddress;

  roles: IRole[] = [];

  public addresses: IContactAddress[];
  public phoneNumbers: IContactPhoneNumber[];
  public emailAddresses: IContactEmailAddress[];

  constructor(id: number, public visibility: string, public note: string) {
    this.id = +id;
    this._address = null;
    this._phoneNumber = null;
    this._emailAddress = null;
    this.addresses = [];
    this.emailAddresses = [];
    this.phoneNumbers = [];
  }

  toLowerCase() {
    this.name.toLowerCase();
  }

  get address(): IContactAddress {
    return this._address ? this._address : this.addresses[0];
  }

  set address(address: IContactAddress) {
    this._address = address;
  }

  get phoneNumber(): IContactPhoneNumber {
    return this._phoneNumber ? this._phoneNumber : this.phoneNumbers[0];
  }

  set phoneNumber(phoneNumber: IContactPhoneNumber) {
    this._phoneNumber = phoneNumber;
  }

  get emailAddress() {
    return this._emailAddress ? this._emailAddress : this.emailAddresses[0];
  }

  set emailAddress(emailAddress: IContactEmailAddress) {
    this._emailAddress = emailAddress;
  }

  abstract toForm();

  static fromForm(values) {
    let contact;
    switch (values.type) {
      case 'contact_organizations':
        contact = ContactOrganization.fromForm(values);
        console.log('org', contact);
        break;
      case 'contact_people':
        contact = ContactPerson.fromForm(values);
        console.log('person', contact);
        break;
      case 'organization_members':
        contact = OrganizationMember.fromForm(values);
        console.log('member', contact);
        break;
      case 'business_partners':
        contact = BusinessPartner.fromForm(values);
        console.log('business_partner', contact);
        break;
    }
    return contact._addAttributes(values);
  }

  _addAttributes(values) {
    this.address = ContactAddress.fromForm(values.address);
    this.addresses = values.addresses.map(address => ContactAddress.fromForm(address));
    this.emailAddress =  ContactEmailAddress.fromForm(values.email_address);
    this.emailAddresses = values.email_addresses.map(email => ContactEmailAddress.fromForm(email));
    this.phoneNumber = ContactPhoneNumber.fromForm(values.phone_number);
    this.phoneNumbers = values.phone_numbers.map(phone => ContactPhoneNumber.fromForm(phone));
    return this;
  }

  _addFormAttributes(formValues) {
    if (this.address) {
      formValues['address'] = this.address.toForm();
    }
    formValues['addresses'] = this.addresses.map(address => address.toForm());

    if (this.emailAddress) {
      formValues['email_address'] = this.emailAddress.toForm();
    }
    formValues['email_addresses'] = this.emailAddresses.map(email => email.toForm());

    if (this.phoneNumber) {
      formValues['phone_number'] = this.phoneNumber.toForm();
    }
    formValues['phone_numbers'] = this.phoneNumbers.map(phone => phone.toForm());
    return formValues;
  }
}

export class ContactPerson extends AbstractContact {
  type = 'contact_people';
  icon = 'person_outline';

  public organizations: ContactOrganization[];

  constructor(id: number, visibility: string, note: string,
              public gender: string = null, public title: string = null,
              public firstName: string, public lastName: string) {

    super(id, visibility, note);
    this.organizations = [];
  }

  get name() {
    return this.lastName + ' ' + this.firstName;
  }

  static fromForm(values): ContactPerson {
    return new ContactPerson(values.id, values.visibility, values.note || '', values.gender,
                             values.title, values.first_name, values.last_name);
  }

  toForm() {
    const person = {
      id: this.id, type: this.type, visibility: this.visibility, note: this.note || '', gender: this.gender,
      title: this.title, first_name: this.firstName, last_name: this.lastName
    };

    return this._addFormAttributes(person);
  }
}

export class OrganizationMember extends ContactPerson {
  readonly type = 'organization_members';
  icon = 'person';

  static fromForm(values): OrganizationMember {
    return new OrganizationMember(values.id, values.visibility, values.note || '', values.gender,
      values.title, values.first_name, values.last_name);
  }

  toForm() {
    const person = {
      id: this.id, type: this.type, visibility: this.visibility, note: this.note || '', gender: this.gender,
      title: this.title, first_name: this.firstName, last_name: this.lastName
    };

    return this._addFormAttributes(person);
  }
}

export class ContactOrganization extends AbstractContact {
  type = 'contact_organizations';
  icon = 'business';

  public members: ContactPerson[];

  constructor(id: number, visibility: string, note: string,
              public name: string, public abbreviation: string, public legalFormId: number) {
    super(id, visibility, note);
    this.members = [];
  }

  static fromForm(values): ContactOrganization {
    return new ContactOrganization(values.id, values.visibility, values.note || '', values.name,
                                   values.abbreviation, values.legal_form_id);
  }

  toForm() {
    const org = {
      id: this.id, type: this.type, visibility: this.visibility, name: this.name,
      abbreviation: this.abbreviation || '', legal_form_id: this.legalFormId
    };
    return this._addFormAttributes(org);
  };
}

export class BusinessPartner extends ContactOrganization {
  readonly type = 'business_partners';
  readonly icon = 'business';

  public members: ContactPerson[];

  static fromForm(values): BusinessPartner {
    return new BusinessPartner(values.id, values.visibility, values.note || '', values.name,
      values.abbreviation, values.legal_form_id);
  }

  toForm() {
    const org = {
      id: this.id, type: this.type, visibility: this.visibility, name: this.name,
      abbreviation: this.abbreviation || '', legal_form_id: this.legalFormId
    };
    return this._addFormAttributes(org);
  };
}

export class ContactAddress implements IContactAddress {
  readonly type = 'contact_addresses';

  id: number;

  constructor(id: number, public locationOrType: string = '',
              public full: string = '', public street: string, public streetNo: string,
              public city: string, public zip: string, public countryName: string,
              public geometry: any = null, public order: number = null, public mainAddress = false) {
    this.id = +id;
  }

  static fromForm(values) {
    return new ContactAddress(values.id, values.location_or_type, values.full,
                             values.street, values.street_no, values.city, values.zip,
                             values.country_name, values.geometry, values.order || 0,
                             values.main_address);
  }

  toForm() {
    return {
      id: this.id, location_or_type: this.locationOrType, full: this.full, street: this.street, street_no: this.streetNo,
      zip: this.zip, city: this.city, country_name: this.countryName, geometry: this.geometry, order: this.order,
      main_address: this.mainAddress
    };
  }
}

export class ContactEmailAddress implements IContactEmailAddress {
  readonly type = 'contact_email_addresses';

  id: number;

  constructor(id: number, public locationOrType: string, public emailAddress: string,
              public order: number, public mainAddress = false) {
    this.id = +id;
  }

  static fromForm(values) {
    return new ContactEmailAddress(values.id, values.location_or_type, values.email_address,
                                   values.order || 0, values.main_address);
  }

  toForm() {
    return {
      id: this.id , location_or_type: this.locationOrType, email_address: this.emailAddress,
      order: this.order, main_address: this.mainAddress
    };
  }
}

export class ContactPhoneNumber implements IContactPhoneNumber {
  readonly type = 'contact_phone_numbers';

  id: number;

  constructor(id: number, public locationOrType: string, public countryCode: string, public phoneNumber: string,
              public order: number, public mainPhoneNumber = false) {
    this.id = +id;
  }

  static fromForm(values) {
    return new ContactPhoneNumber(values.id, values.location_or_type, values.country_code,
                                  values.phone_number, values.order || 0, values.main_phone_number);
  }

  toForm() {
    return {
      id: this.id, location_or_type: this.locationOrType, country_code: this.countryCode,
      phone_number: this.phoneNumber, order: this.order, main_phone_number: this.mainPhoneNumber
    };
  }
}
