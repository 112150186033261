import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, first, switchMap, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {Net} from 'app/lib/net/uuid';
import {ClientRoute} from '../../components/client-nav-bar/client-nav-bar.component';
import {Client} from 'app/+store/client/client';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ClientActions, ClientSelectors} from 'app/+store/client';
import {ClientActionType} from '../../../../lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';
import {FeatureSelectors, MembershipSelectors, OrganizationSelectors} from 'app/+store';
import {Feature} from 'app/+store/feature/feature';
import {Membership} from 'app/models/membership.model';
import {combineLatest} from 'rxjs';
import {ClientService} from 'app/+store/client/client.service';

@Component({
  selector: 'dvtx-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit, OnDestroy {
  ClientActionType = ClientActionType;
  onDestroy = new Subject();
  routes: ClientRoute[];
  activeLink: string;
  id: string;
  client$: Observable<Client>;
  organizationId: string;
  featureSet$: Observable<Feature>;
  myMembership$: Observable<Membership>;
  clientData: Client;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _clientSvc: ClientService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.myMembership$ = this._store.select(MembershipSelectors.getMyMembership);

    const organization$ = this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(x => !!x), distinctUntilKeyChanged('id'))

    organization$.pipe(takeUntil(this.onDestroy)).subscribe(organization => {
        this.organizationId = organization.id;
      });

    const params = this._route.params.pipe(distinctUntilChanged());
    combineLatest(organization$, params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([organization, data]) => {
        if (organization && data && data['id']) {
          // this._store.dispatch(new ClientActions.LoadOne(data['id']));
          // ATTENTION: There is a race condition on reload with the generic ALL clients call and the local dedicated clients call by ID:
          //            The general does not contain the edit/delete ability. If it returns later to the store, the button
          //            for saving is not shown, because the canEditClient, canDeleteClient is unset on the store.
          //            For this reason we do the call 'sync' by service.
          this._clientSvc.getOne(data['id'])
            .pipe(first())
            .subscribe(client => {
              this._store.dispatch(new ClientActions.LoadOneSuccess(client));
              this.clientData = client;
              this._cdr.markForCheck();
            });
        }
      });

    this.client$ = params.pipe(switchMap((data) => this._store.select(ClientSelectors.getClientById(data['id']))));

    combineLatest(params, this.featureSet$, this.myMembership$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([data, featureSet, myMembership]) => {
        const id = data['id'];
        this.id = id;
        if (Net.validUUID(this.id)) {
          this.routes = [{
            title: 'PROJECT_ROOM.PROJECT_ROOMS',
            route: `/clients/${id}/workflows`
          }, {
            title: 'Details',
            route: `/clients/${id}/details`
          }];

          if (myMembership && myMembership.hasAdministrationRights && featureSet && featureSet.hasBookman) {
            this.routes.push({
              title: 'FIBU.CLIENT_SETTINGS',
              route: `/clients/${id}/fibu-settings`
            })
          }
          this.activeLink = this.routes[1].title;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
