<fivef-dialog [color]="color" [title]="title">
  <div>
    <h4 class="mb-1 dvtx-loading-indicator">{{ headline | translate }}<span>.</span><span>.</span><span>.</span></h4>

    <div class="w-100 d-flex">
      <div class="w-100 d-flex align-items-center pr-1">
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</fivef-dialog>
