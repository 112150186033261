import {map, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as auditOrderActions from '../../actions/audit-order.actions';
import {IAuditOrder} from '../../shared/modules/api/models/audit-order.interface';
import {AuditOrderService} from './audit-order.service';

@Injectable()
export class AuditOrderEffects {
  getAuditOrders$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(auditOrderActions.GET_AUDIT_ORDERS),
      switchMap((action: auditOrderActions.GetAuditOrders) => {
        return this._svc.getAll();
      }),
      map((tasks: IAuditOrder[]) => {
        return new auditOrderActions.GetAuditOrdersSuccess(tasks);
      })));

  getAuditOrder$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(auditOrderActions.GET_AUDIT_ORDER),
      switchMap((action: auditOrderActions.GetAuditOrder) => {
        return this._svc.get(action.payload);
      }),
      map((task: IAuditOrder) => {
        return new auditOrderActions.GetAuditOrdersSuccess(task);
      })));

  constructor(private _svc: AuditOrderService,
              private actions$: Actions) {
  }
}
