import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Process} from 'app/+store/process/process';
import {ProcessProfile} from 'app/+store/process/process.interface';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

interface IWorkflowInfoItem {
  id: string;
  title: string;
  processType: string;
  profile: ProcessProfile
}

@Component({
  selector: 'dvtx-workflow-info',
  host: {class: 'fivef-workflow-info'},
  templateUrl: './workflow-info.component.html',
  styleUrls: ['./workflow-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowInfoComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  @Input()
  hasAccessibleParent = false;

  public process$ = new BehaviorSubject<IWorkflowInfoItem>(null);
  public subtitle: string;

  @Input()
  set process(p: IWorkflowInfoItem) {
    if (p) {
      this.process$.next(p);
    }
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.process$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(process => {
        this.subtitle = this.humanProcessType(process);
        this.cdr.detectChanges();
      })
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.process$.complete();
  }

  private humanProcessType(process: IWorkflowInfoItem) {
    if (!process) {
      return;
    }

    if (process.processType === 'project' && this.hasAccessibleParent) {
      return 'PROJECT.SUB_PROJECT';
    }
    return Process.humanProcessType(process, true);
  }
}
