import {first, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {environment} from 'environments/environment';
import {DocumentTemplate} from 'app/+store/document-template/document-template';
import {OrganizationSelectors, DocumentTemplateActions, DocumentTemplateSelectors} from 'app/+store/index';
import {UploadDialogComponent} from '../../components/upload-dialog/upload-dialog.component';
import {DocumentTemplateBuilder} from 'app/+store/document-template/document-template.builder';
import {SaveDownload} from 'app/+store/document-template/document-template.actions';
import {WebsocketService} from 'app/services/websocket.service';
import {User} from 'app/models/user';
import {FivefConfirm} from '../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

/**
 * Document template listing and upload to customize
 * workflow document generation.
 *
 * <h2>Implementation notes</h2>
 *
 * - Contains a websocket subscription to receive a generated test template
 *   async.
 * - Component should be loaded asynchronously (e.g. ng-template in material tabs)
 *   to only subscribe to the socket channel when visible.
 *
 * @author Andreas Baier
 */
@Component({
  selector: 'dvtx-document-template-container',
  templateUrl: './document-template-container.component.html',
  styleUrls: ['./document-template-container.component.scss']
})
export class DocumentTemplateContainerComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  SUPPORTED_FILE_TYPES: string[] = ['docx'];
  MAX_FILE_SIZE = 500;
  UPLOAD_URL = `${environment.token_service_config.apiBase}/api/v1/templates/documents`;

  @ViewChild(MatSort) sort: MatSort;
  @Output() onRemove = new EventEmitter();

  templates$: Observable<DocumentTemplate[]>;
  columnsToDisplay = ['title', 'filename', 'size', 'published', 'actions'];
  dataSource: MatTableDataSource<DocumentTemplate>;

  // Websocket subscription.
  templateTestSubscription;

  constructor(private _dialog: MatDialog,
              private _store: Store<AppState>,
              private _notificationSvc: NotificationService,
              private _ws: WebsocketService) {
  }

  ngOnInit() {
    this.templates$ = this._store.select(DocumentTemplateSelectors.getAllTemplatesOfOrganization);

    this._store
      .select(OrganizationSelectors.getSelected).pipe(
      takeUntil(this.onDestroy))
      .subscribe(_ => {
        this.refresh();
      });

    this.templates$.pipe(
      takeUntil(this.onDestroy)
    ).subscribe(templates => {
      this.dataSource = new MatTableDataSource(templates);
      this.dataSource.sort = this.sort;
    });

    // only subscribe on valid user
    this._store.select('currentUser').pipe(
      takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        if (user) {
          this.connectTemplateTestChannel(user);
        } else {
          // unsubscribe if user is resetted.
          this.destroyTemplateTestChannel();
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.destroyTemplateTestChannel();
  }

  refresh() {
    this._store.dispatch(new DocumentTemplateActions.LoadAll());
  }

  download(template) {
    this._store.dispatch(new DocumentTemplateActions.Download(template.id));
  }

  downloadSample() {
    this._store.dispatch(new DocumentTemplateActions.DownloadSample());
  }

  testTemplate(template) {
    this._store.dispatch(new DocumentTemplateActions.TestTemplate(template));
  }

  fileSize(template): string {
    if (template && template.fileSize) {
      return `${Math.round((template.fileSize / 1024) * 100) / 100} KB`;
    }
    return '';
  }

  openUploadDialog() {
    this._store.select(OrganizationSelectors.getSelectedId).pipe(
      first())
      .subscribe(id => {
        this._dialog.open(UploadDialogComponent,
          {
            data: {
              options: {
                dialogTitle: 'CUSTOMIZATION.SELECT_AND_UPLOAD_TITLE',
                submitButtonTitle: 'CUSTOMIZATION.UPLOAD_TEMPLATE',
                supportedFileTypes: this.SUPPORTED_FILE_TYPES,
                maxFileSize: this.MAX_FILE_SIZE,
                uploadUrl: this.UPLOAD_URL,
                organizationUUID: id,
                onSuccess: this.onSuccess,
                onItemSuccess: this.onItemSuccess
              }
            }
          });
      });
  }

  publish(element, $event) {
    this._store.dispatch(new DocumentTemplateActions.Publish(element.id, $event.checked));
  }

  @FivefConfirm({
    message: 'CUSTOMIZATION.REMOVE_TEMPLATE_CONFIRM',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'CUSTOMIZATION.REMOVE_TEMPLATE_TITLE'
  })
  remove(logo) {
    this._store.dispatch(new DocumentTemplateActions.Remove(logo));
  }

  onSuccess = () => {
    this._store.dispatch(new DocumentTemplateActions.LoadAll());
  }

  onItemSuccess = (item) => {
    this._notificationSvc.success(`Template "${item.file.name}" successfully uploaded.`);
  }

  connectTemplateTestChannel(user) {
    this.templateTestSubscription = this._ws.subscribe(
      user,
      'Artifact::DocumentTemplateTestChannel',
      'follow',
      {user_id: user.id},
      (data) => {
        console.log('ProcessArtifact::DocumentTemplateTestChannel', data);
        const builder = new DocumentTemplateBuilder();
        const template = builder.fromResponse(data.data);
        this._store.dispatch(new SaveDownload(template));
      });
  }

  destroyTemplateTestChannel() {
    if (this.templateTestSubscription) {
      try {
        this.templateTestSubscription.unsubscribe();
      } catch (error) {
        console.error(error);
      }
    }
  }
}
