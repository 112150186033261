import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FiveFStatuses, FiveFStatusesMap, ProcessListingItemV3} from 'app/+store/process/process';
import {InViewportMetadata} from 'ng-in-viewport';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {UserAgentUtil} from 'app/lib/user-agent/user-agent-utils';
import {takeUntil} from 'rxjs/operators';
import {
  TimelineNotificationPanelComponent
} from 'app/modules/timeline/components/timeline-notification-panel/timeline-notification-panel.component';
import {MatDialog} from '@angular/material/dialog';
import {IProcessListingColumns, IProcessListingItem} from '../../models/process-listing.interface';
import {FiveFMatButtonStyle} from '../../../constants/five-f-style.constants';
import {FiveFRoleContext} from '../../../constants/five-f-role.constants';
import {FivefConfirm} from '../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';

/**
 * Unified process listing table with common styling.
 * The table is fully view based. All data processing must be implemented by the outer container.
 *
 * See also AbstractOrganizationProcessListing and AbstractUserProcessListing as well as the
 * column interface IProcessListingColumns and IProcessListingItem.
 */
@Component({
  selector: 'dvtx-process-listing',
  templateUrl: './process-listing.component.html',
  styleUrls: ['./process-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessListingComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public FiveFStatuses = FiveFStatuses;
  public FiveFStatusesMap = FiveFStatusesMap;
  public FiveFMatButtonStyle = FiveFMatButtonStyle;
  public FiveFRoleContext = FiveFRoleContext;

  @Input()
  public displayedColumns: IProcessListingColumns[];

  public dataSource: MatTableDataSource<IProcessListingItem> = new MatTableDataSource<IProcessListingItem>([]);

  @Output()
  private onNavigateTo = new EventEmitter<ProcessListingItemV3>();

  @Output()
  private onRemoveDraft = new EventEmitter<ProcessListingItemV3>();

  @Output()
  public onDeleteProcess = new EventEmitter<ProcessListingItemV3>();

  @Output()
  private onRestoreFromTrash = new EventEmitter<ProcessListingItemV3>();

  @Output()
  private onShowDetails = new EventEmitter<ProcessListingItemV3>();

  // in-viewport-rendering setup
  public isInViewPort: string[] = [];

  // Browser handling: Screen size, agent.
  smallScreenSize$ = new BehaviorSubject<boolean>(true);
  isSafari;
  isIOS;
  isFirefox;

  @Input()
  public retentionPeriod: number;

  @Input() set data(data: ProcessListingItemV3[]) {
    this.dataSource.data = data;
  }

  constructor(private _store: Store<AppState>,
              public avatarService: AvatarService,
              private _dialog: MatDialog,
              breakpointObserver: BreakpointObserver) {
    this.isSafari = UserAgentUtil.isSafari();
    this.isIOS = UserAgentUtil.isIOS();
    this.isFirefox = UserAgentUtil.isFirefox();

    breakpointObserver
      .observe(['(min-width: 1372px)']).pipe(
      takeUntil(this.onDestroy))
      .subscribe((state: BreakpointState) => {
        this.smallScreenSize$.next(!state.matches);
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.smallScreenSize$.complete();
  }

  public navigateTo(process): void {
    if (!process) return;
    this.onNavigateTo.emit(process);
  }

  public showItem(event, element): void {
    const {[InViewportMetadata]: {entry}, target, visible} = event;
    if (!visible) {
      const index = this.isInViewPort.indexOf(element, 0);
      if (index > -1) {
        this.isInViewPort.splice(index, 1);
      }

      return;
    }
    this.isInViewPort.push(element);
  }

  @FivefConfirm({
    message: 'WORKFLOWS.DELETE_DRAFT_ACTION',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_CONFIRM_ACTION'
  })
  public removeDraft($event, process: ProcessListingItemV3) {
    $event.stopPropagation();
    this.onRemoveDraft.emit(process);
  }

  @FivefConfirm({
    message: 'PROJECT_ROOM.RESTORE_FROM_TRASH_ACTION',
    icon: 'settings_backup_restore',
    color: 'primary',
    confirmAction: 'GENERAL.CONFIRM_ACTION'
  })
  public restoreProcessFromTrash($event, process: ProcessListingItemV3) {
    $event.stopPropagation();
    this.onRestoreFromTrash.emit(process);
  }

  public deleteProcess($event, process: ProcessListingItemV3) {
    $event.stopPropagation();
    this.onDeleteProcess.emit(process);
  }

  public showDetails($event, process: ProcessListingItemV3) {
    $event.stopPropagation();
    this.onShowDetails.emit(process);
  }

  public openNotificationPanel(data): void {
    this._dialog.open(TimelineNotificationPanelComponent, {
      panelClass: ['dvtx-timeline-notification-panel-sm-dialog'],
      data: {
        process: data
      }
    });
  }

  public trackBy(_index: number, item: IProcessListingItem): string {
    return item.trackByKey;
  }
}
