import {BehaviorSubject, combineLatest as observableCombineLatest, Subject} from 'rxjs';

import {startWith, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';

@Component({
  selector: 'dvtx-filter-list-selection',
  templateUrl: './filter-list-selection.component.html',
  styleUrls: ['./filter-list-selection.component.scss']
})
export class FilterListSelectionComponent implements OnInit, OnDestroy {
  protected onDestroy = new Subject();

  public options$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public filterdOptions$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public search$: BehaviorSubject<string> = new BehaviorSubject(null);

  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() selectedOptions: any[] = [];

  /**
   * Shows the filter counter on top of the filter view beside the filter title.
   */
  @Input() showFilterCounter = true;

  @Output() onChangeSelection: EventEmitter<any> = new EventEmitter(null);

  @Input() set options(options: any[]) {
    this.options$.next(options);
    this.filterdOptions$.next(options);
  }

  @Input() set search(search: string) {
    this.search$.next(search);
  }

  constructor(protected _translateSvc: TranslateService, public avatarService: AvatarService) {

  }

  ngOnInit(): void {
    observableCombineLatest(
      this.options$.pipe(startWith([])),
      this.search$.pipe(startWith('')),
      (options, search) => {
        if (!search || search.length === 0) return this.filterdOptions$.next(options);
        options = options.filter(option => (option && option.title && option.title.toLowerCase().search(search.toLowerCase()) > -1) ||
          (option && option.title && this._translateSvc.instant(option.title).toLowerCase().search(search.toLowerCase().trim()) > -1));
        this.filterdOptions$.next(options);
      }
    ).pipe(
      takeUntil(this.onDestroy)
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.options$.complete();
    this.filterdOptions$.complete();
    this.search$.complete();
  }

  changeSelection(option) {
    const found = !!this.selectedOptions.find(s => s.id === option.id);
    if (found) {
      this.selectedOptions = this.selectedOptions.filter(o => o.id != option.id);
    } else {
      this.selectedOptions.push(option);
    }
    this.onChangeSelection.emit(this.selectedOptions);
  }

  isSelected(option) {
    return !!this.selectedOptions && this.selectedOptions.find(o => o.id === option.id);
  }
}
