import {IResource} from '../../shared/modules/api/models/resource.interface';
import * as apiInterface from './cms-slideshow.interface';

export namespace Cms {
  export class Slideshow implements IResource {
    readonly type = 'cms_slideshows';

    public slides: Slide[] = [];

    constructor(public id,
                public profile: apiInterface.Cms.SlideProfile,
                public title: string,
                public description: string = null,
                public createdAt = null,
                public updatedAt = null) {
    }
  }

  /**
   * Image map area configuration e.g. for inside the carousel slides.
   */
  export interface ICmsImageMapArea {
    /**
     * Alt attribute of image map area.
     */
    alt: string;

    /**
     * URL target of image map.
     */
    href: string;

    /**
     * Shape of the area.
     */
    shape: 'rect' | 'circle' | 'polygon';

    /**
     * String of coordinates of clickable area.
     */
    coords: string;
  }

  export class Slide implements IResource {
    readonly type = 'cms_slides';

    /**
     *
     * @param id Slideshow entity ID.
     * @param slideshowSlideId Id of slideshow reference at the slideshow selection.
     * @param profile Visual profile of the slideshow.
     * @param language
     * @param title
     * @param alt
     * @param order
     * @param backgroundImageUrl
     * @param backgroundImageFilename
     * @param content
     * @param contentImageUrl
     * @param contentImageFilename
     * @param contentImageWidth
     * @param contentImageHeight
     * @param contentImageMapAreas
     * @param createdAt
     * @param updatedAt
     */
    constructor(public id,
                public slideshowSlideId: string,
                public profile: apiInterface.Cms.SlideProfile,
                public language: apiInterface.Cms.SlideLanguage,
                public title: string,
                public alt: string = null,
                public order: number = null,
                public backgroundImageUrl: string = null,
                public backgroundImageFilename: string = null,
                public content: string = null,
                public contentImageUrl: string = null,
                public contentImageFilename: string = null,
                public contentImageWidth: number = null,
                public contentImageHeight: number = null,
                public contentImageMapAreas: ICmsImageMapArea[] = [],
                public createdAt = null,
                public updatedAt = null) {
    }
  }
}
