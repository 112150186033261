<ng-template [ngIf]="editable && !disabled" [ngIfElse]="readonlyMessage">
  <fivef-message-editor [saveIcon]="saveIcon"
                        [showCancelAction]="true"
                        [optimizeToolbar]="optimizeToolbar"
                        [autoHeight]="true"
                        [resetOnSave]="resetOnSave"
                        [placeholder]="placeholder"
                        [message]="_text"
                        [commentPrefix]="textPrefix"
                        (onChange)="onChange.emit($event)"
                        (onSave)="save($event)"
                        (onCancel)="cancel($event)"></fivef-message-editor>
</ng-template>

<ng-template #readonlyMessage>
  <div (click)="activateEditMode($event)"
       [froalaView]="_text || (placeholder | translate)"
       [class.fivef-rich-text--readonly-container]="!disabled"
       [class.fivef-rich-text--readonly-container--editor]="!disabled"></div>
</ng-template>
