import {Action} from '@ngrx/store';
import {Organization} from '../../models/organization.model';
import {ContactListDto, ContactVisibility, ImportContactRow} from '../../models/contact-list-dto.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ContactPersonBaseForm} from '../../modules/organization/models/person-contact-base-form';
import {ContactOrganizationBaseForm} from '../../modules/organization/models/contact-organization-base-form';
import {ContactAttribute, ContactAttributeGroup} from '../../models/contact-attributes.model';
import {SimpleAddress, SimpleEmailAddress, SimplePhoneNumber} from './legacy/models/contact.interface';

export enum ContactActionTypes {
  ClearAll = '[Contact] Clear All',
  LoadAll = '[Contact] Load All',
  LoadAllSuccess = '[Contact] Load All Success',
  LoadAllFail = '[Contact] Load All Fail',
  NewContact = '[Contact] New Contact',
  NewContactSuccess = '[Contact] New Contact Success',
  NewContactFail = '[Contact] New Contact Fail',
  DeleteContact = '[Contact] Delete Contact',
  DeleteContactSuccess = '[Contact] Delete Contact Success',
  DeleteContactFail = '[Contact] Delete Contact Fail',
  AddAttributeGroup = '[Contact] Add Attribute Group',
  AddAttributeGroupSuccess = '[Contact] Add Attribute Group Success',
  AddAttribute = '[Contact] Add attribute',
  AddAttributeSuccess = '[Contact] Add attribute Success',
  DeleteAttributeGroup = '[Contact] Delete Attribute Group',
  DeleteAttributeGroupSuccess = '[Contact] Delete Attribute Group Success',
  DeleteAttribute = '[Contact] Delete Attribute',
  DeleteAttributeSuccess = '[Contact] Delete Attribute Success',
  ImportContacts = '[Contact] Import Contacts',
  AddFurtherAddress = '[Contact] Add Further Address',
  AddFurtherAddressSuccess = '[Contact] Add Further Address Success',
  RemoveFurtherAddress = '[Contact] Remove Further Address',
  RemoveFurtherAddressSuccess = '[Contact] Remove Further Address Success',
  AddFurtherPhoneNumber = '[Contact] Add Further Phone Number',
  AddFurtherPhoneNumberSuccess = '[Contact] Add Further Phone Number Success',
  Reload = '[Contact] Reload',
  RemoveFurtherPhoneNumber = '[Contact] Remove Further Phone Number',
  RemoveFurtherPhoneNumberSuccess = '[Contact] Remove Further Phone Number Success',
  AddFurtherEMail = '[Contact] Add Further Email',
  AddFurtherEMailSuccess = '[Contact] Add Further Email Success',
  RemoveFurtherEMail = '[Contact] Remove Further Email',
  RemoveFurtherEMailSuccess = '[Contact] Remove Further Email Success',
  LoadOne = '[Contact] Load One',
  LoadOneSuccess = '[Contact] Load One Success',
  LoadOneFail = '[Contact] Load One Fail'
}

export class ClearAll implements Action {
  readonly type = ContactActionTypes.ClearAll;
}

export class LoadAll implements Action {
  readonly type = ContactActionTypes.LoadAll;

  constructor(public ofOrga: Organization) {

  }
}

export class Reload implements Action {
  readonly type = ContactActionTypes.Reload;

  constructor(public organization: Organization) {
  }
}

// <editor-fold desc="Attributes">
export class AddAttributeGroup implements Action {
  readonly type = ContactActionTypes.AddAttributeGroup;

  constructor(public contactId: string, public groupname: string) {
  }
}

export class AddAttributeGroupSuccess implements Action {
  readonly type = ContactActionTypes.AddAttributeGroupSuccess;

  constructor(public payload: ContactAttributeGroup, public contactId: string) {
  }
}

export class AddAttribute implements Action {
  readonly type = ContactActionTypes.AddAttribute;

  constructor(public contactId: string, public attributeGroupId: string, public key: string, public value: string) {
  }
}

export class AddAttributeSuccess implements Action {
  readonly type = ContactActionTypes.AddAttributeSuccess;

  constructor(public payload: ContactAttribute, public attributeGroupId: string, public contactId: string) {
  }
}


export class DeleteAttributeGroup implements Action {
  readonly type = ContactActionTypes.DeleteAttributeGroup;

  constructor(public contactId: string, public attributeGroupId: string) {
  }
}

export class DeleteAttributeGroupSuccess implements Action {
  readonly type = ContactActionTypes.DeleteAttributeGroupSuccess;

  constructor(public contactId: string, public attributeGroupId: string) {
  }
}

export class DeleteAttribute implements Action {
  readonly type = ContactActionTypes.DeleteAttribute;

  constructor(public contactId: string, public attributeGroupId: string, public attributeId: string) {
  }
}

export class DeleteAttributeSuccess implements Action {
  readonly type = ContactActionTypes.DeleteAttributeSuccess;

  constructor(public contactId: string, public attributeGroupId: string, public attributeId: string) {
  }
}

// </editor-fold>

export class LoadOne implements Action {
  readonly type = ContactActionTypes.LoadOne;

  constructor(public id: string) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = ContactActionTypes.LoadOneSuccess;

  constructor(public contact: ContactListDto) {
  }
}

export class LoadOneFail implements Action {
  readonly type = ContactActionTypes.LoadOneFail;

  constructor(public payload: any = null) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = ContactActionTypes.LoadAllSuccess;

  constructor(public payload: ContactListDto[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = ContactActionTypes.LoadAllFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class NewContact implements Action {
  readonly type = ContactActionTypes.NewContact;

  constructor(public ofOrga: Organization, public contactToCreate: ContactPersonBaseForm | ContactOrganizationBaseForm, public visibility: ContactVisibility) {

  }
}

export class NewContactSuccess implements Action {
  readonly type = ContactActionTypes.NewContactSuccess;

  constructor(public payload: ContactListDto) {
  }
}

export class NewContactFail implements Action {
  readonly type = ContactActionTypes.NewContactFail;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class ImportContacts implements Action {
  readonly type = ContactActionTypes.ImportContacts;

  constructor(public ofOrga: Organization, public contactsToCreate: ImportContactRow[], public visibility: ContactVisibility) {

  }
}

export class DeleteContact implements Action {
  readonly type = ContactActionTypes.DeleteContact

  constructor(public id: string) {
  }
}

export class DeleteContactSuccess implements Action {
  readonly type = ContactActionTypes.DeleteContactSuccess

  constructor(public id: string) {
  }
}

export class DeleteContactFail implements Action {
  readonly type = ContactActionTypes.DeleteContactFail

  constructor(public err: HttpErrorResponse) {
  }
}

export class AddFurtherAddress implements Action {
  readonly type = ContactActionTypes.AddFurtherAddress

  constructor(public payload: {
    contactId: string,
    address: SimpleAddress
  }) {
  }
}

export class AddFurtherAddressSuccess implements Action {
  readonly type = ContactActionTypes.AddFurtherAddressSuccess

  constructor(public payload: {
    contactId: string,
    address: SimpleAddress
  }) {
  }
}

export class RemoveFurtherAddress implements Action {
  readonly type = ContactActionTypes.RemoveFurtherAddress

  constructor(public payload: {
    contactId: string,
    addressId: string
  }) {
  }
}

export class RemoveFurtherAddressSuccess implements Action {
  readonly type = ContactActionTypes.RemoveFurtherAddressSuccess

  constructor(public contactId: string, public addressId: string) {
  }
}

export class AddFurtherEmail implements Action {
  readonly type = ContactActionTypes.AddFurtherEMail

  constructor(public payload: {
    contactId: string,
    emailAddress: SimpleEmailAddress
  }) {
  }
}

export class AddFurtherEmailSuccess implements Action {
  readonly type = ContactActionTypes.AddFurtherEMailSuccess

  constructor(public payload: {
    contactId: string,
    emailAddress: SimpleEmailAddress
  }) {
  }
}

export class RemoveFurtherEmail implements Action {
  readonly type = ContactActionTypes.RemoveFurtherEMail

  constructor(public payload: {
    contactId: string,
    emailAddressId: string
  }) {
  }
}

export class RemoveFurtherEmailSuccess implements Action {
  readonly type = ContactActionTypes.RemoveFurtherEMailSuccess

  constructor(public contactId: string, public emailAddressId: string) {
  }
}

export class AddFurtherPhoneNumber implements Action {
  readonly type = ContactActionTypes.AddFurtherPhoneNumber

  constructor(public payload: {
    contactId: string,
    phoneNumber: SimplePhoneNumber
  }) {
  }
}

export class AddFurtherPhoneNumberSuccess implements Action {
  readonly type = ContactActionTypes.AddFurtherPhoneNumberSuccess

  constructor(public payload: {
    contactId: string,
    phoneNumber: SimplePhoneNumber
  }) {
  }
}

export class RemoveFurtherPhoneNumber implements Action {
  readonly type = ContactActionTypes.RemoveFurtherPhoneNumber

  constructor(public payload: {
    contactId: string,
    phoneNumberId: string
  }) {
  }
}

export class RemoveFurtherPhoneNumberSuccess implements Action {
  readonly type = ContactActionTypes.RemoveFurtherPhoneNumberSuccess

  constructor(public contactId: string, public phoneNumberId: string) {
  }
}

export type ContactActions =
  | ClearAll
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | NewContact
  | NewContactFail
  | NewContactSuccess
  | DeleteContact
  | DeleteContactSuccess
  | DeleteContactFail
  | AddAttributeGroup
  | AddAttributeGroupSuccess
  | AddAttribute
  | AddAttributeSuccess
  | DeleteAttribute
  | DeleteAttributeGroup
  | DeleteAttributeGroupSuccess
  | DeleteAttributeSuccess
  | AddFurtherAddress
  | AddFurtherAddressSuccess
  | RemoveFurtherAddress
  | RemoveFurtherAddressSuccess
  | Reload
  | AddFurtherEmail
  | AddFurtherEmailSuccess
  | RemoveFurtherEmail
  | RemoveFurtherEmailSuccess
  | AddFurtherPhoneNumber
  | AddFurtherPhoneNumberSuccess
  | RemoveFurtherPhoneNumber
  | RemoveFurtherPhoneNumberSuccess
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail;
