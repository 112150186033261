import {ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DEFAULT_SORTING_OPTIONS, IProcessTreeSettings, ProcessTreeNode} from 'app/+store/process-tree/process-tree';
import {ProcessActions} from 'app/+store/process';
import {Net} from 'app/lib/net/uuid';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';
import {distinctUntilChanged, filter, first, takeUntil} from 'rxjs/operators';
import {ProcessTreeService} from 'app/+store/process-tree/process-tree.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatRadioChange} from '@angular/material/radio';
import {AngularTokenService} from 'angular-token';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'dvtx-process-structure',
  templateUrl: './process-structure.component.html',
  styleUrls: ['./process-structure.component.scss']
})
export class ProcessStructureComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  public _id: string;

  public processesNodes$: Observable<ProcessTreeNode[]>;

  private refresh$ = new BehaviorSubject<string>(null);

  public settings: IProcessTreeSettings;
  public processSortingBy = DEFAULT_SORTING_OPTIONS;
  selectedProcessSortingBy = this.processSortingBy[0];

  public expansionPanelExpanded = true;

  @Input() set processId(id: string) {
    this._id = id;
    if (Net.validUUID(id)) {
      this.refresh$.next(id);
    }
  }

  @Input() set refresh(refresh: any) {
    if (refresh) {
      this.updateTree();
    }
  }

  constructor(private _store: Store<AppState>,
              private _svc: ProcessTreeService,
              private _cdr: ChangeDetectorRef,
              private _tokenSvc: AngularTokenService,
              private _ngZone: NgZone) {
    this.refresh$
      .pipe(filter(id => !!id), distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe((id) => {
        if (Net.validUUID(id)) {
          this._fetchNodes(id)
        }
      });
  }

  ngOnInit() {
    this.processesNodes$ = this._svc.processesNodes;
    this._svc.init(this._id);

    this._svc.currentSettings$.pipe(takeUntil(this.onDestroy)).subscribe(settings => {
      this.settings = settings;
      this._cdr.detectChanges();
    });

    const userUid = this._tokenSvc.currentAuthData.uid;
    this._svc.refresh(userUid);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.refresh$.complete();
    this._svc.detach();
  }

  navigateToProcess(node: ProcessTreeNode) {
    if (!node || node && node.id === this._id) return;
    this._store.dispatch(new ProcessActions.RunCommand(node.id, 'index', 'on_click'));
  }

  private _fetchNodes(id) {
    this._svc.refreshProjectStructure(id);
  }

  public updateTree(): void {
    if (this._id) {
      this._fetchNodes(this._id);
    }
  }

  toggleClosed(event: MatCheckboxChange) {
    const s = Object.assign({}, this.settings, {hideClosed: event.checked})
    this._svc.updateSettings(s)
      .pipe(first())
      .subscribe(_settings => {
        this._fetchNodes(this._id);
      });
  }

  changeSortingBy($event: MatRadioChange) {
    const value = DEFAULT_SORTING_OPTIONS.find(opt => opt.id === $event.value);
    const s = Object.assign({}, this.settings, {sortBy: value.sortBy, sortDirection: value.sortDirection})
    this._svc.updateSettings(s)
      .pipe(first())
      .subscribe(_settings => {
        this._fetchNodes(this._id);
      });
  }

  public setExpansionState($event) {
    console.error($event);
    this.expansionPanelExpanded = !!$event;
    this._cdr.detectChanges();
  }
}
