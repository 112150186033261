<div class="button-row mb-2">
  <a mat-button routerLink="/organization/{{organizationId}}/addressbook/clients">
    <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
    {{ 'GENERAL.PREVIOUS_ACTION' | translate }}
  </a>
</div>

<mat-card appearance="outlined" class="p-0 dvtx-client-details" *ngIf="(client$ | async) as client">
  <mat-card-title>
    <span class="small-title">{{ 'CLIENT.CLIENT' | translate }}</span>
    <h3 class="m-0 p-0">
      {{ client?.name }} {{ client?.clientId ? '(' + client?.clientId + ')' : '' }}
    </h3>
  </mat-card-title>

  <mat-card-content>
    <dvtx-client-nav-bar [routes]="routes" [activeLink]="activeLink"></dvtx-client-nav-bar>

    <div class="row m-3">
      <div class="col-12">
        <fivef-create-client [selectedClient]="clientData"
                             [saveButtonTitle]="'GENERAL.APPLY_CHANGE_ACTION'"
                             [action]="ClientActionType.Form"
                             [readonly]="!clientData?.canEditClient"
                             [enableSaveButton]="clientData?.canEditClient"></fivef-create-client>
      </div>
    </div>
  </mat-card-content>
</mat-card>
