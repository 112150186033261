import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {EmailFooterActions, EmailFooterSelectors} from 'app/+store/email-footer';
import {EmailFooter} from 'app/+store/email-footer/email-footer';
import {OrganizationSelectors, NaturalPersonSelectors} from 'app/+store';
import {takeUntil} from 'rxjs/operators';
import {FooterType} from 'app/+store/email-footer/email-footer.interface';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {FivefConfirm} from '../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'dvtx-email-footer-container',
  styleUrls: ['./email-footer-container.scss'],
  templateUrl: './email-footer-container.html',
})
export class EmailFooterContainerComponent implements OnDestroy {
  private onDestroy = new Subject<void>();

  emailFooter: EmailFooter[];
  naturalPerson$: Observable<string>;
  emailFooterInfo: { createdAt: string, updatedAt: string };
  actionCreate: boolean;
  footerSubscription: Subscription;
  FooterType = FooterType;
  returnUrl = null;

  constructor(private _store: Store<AppState>,
              private route: ActivatedRoute,
              private router: Router) {

    this.emailFooterInfo = null;
    this.emailFooter = null;
    this.footerSubscription = null;
    this.loadFooter();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  loadFooter() {
    this._store.select(OrganizationSelectors.getSelected).pipe(
      takeUntil(this.onDestroy))
      .subscribe((x) => {
        if (x) {
          this._store.dispatch(new EmailFooterActions.LoadAll);
          if (this.footerSubscription) {
            this.footerSubscription.unsubscribe();
          }
          this.selectFooter();
        }
      });

  }

  selectFooter() {
    this.footerSubscription = this._store.select(EmailFooterSelectors.getAllEmailFooters)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((emailFooter: EmailFooter[]) => {
        this.emailFooter = emailFooter;
        if (emailFooter.length > 0) {
          this.naturalPerson$ = this._store.select(NaturalPersonSelectors.getNameByEmail(this.emailFooter[0].creatorEmail));
          this.emailFooterInfo = {
            createdAt: emailFooter[0].createdAt,
            updatedAt: emailFooter[0].updatedAt
          }
          this.actionCreate = false;
        } else {
          this.actionCreate = true;
        }
      });
  }

  submit(footer) {
    if (!this.actionCreate) {
      this._store.dispatch(new EmailFooterActions.Updated(footer));
    } else {
      this._store.dispatch(new EmailFooterActions.Create(footer));
      this.selectFooter();
    }

    if (this.route.snapshot.queryParams['returnUrl']) {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl']
      this.router.navigateByUrl(this.returnUrl + '?origin=emailFooter&status=1');
    }
  }

  @FivefConfirm({
    message: 'FOOTER.DELETE_CONTENT',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'FOOTER.DELETE_TITLE'
  })
  public formReset(footer) {
    this._store.dispatch(new EmailFooterActions.Delete(footer.id));
    this.emailFooter = [];
    this.emailFooterInfo = {
      createdAt: null,
      updatedAt: null
    };
    this.actionCreate = true;
  }
}
