import {Injectable} from '@angular/core';
import {catchError, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FeatureActionTypes, LoadAll, LoadAllFail, LoadAllSuccess} from './feature.actions';
import {Feature} from './feature';
import {FeatureService} from './feature.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class FeatureEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(FeatureActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        switchMap((features: Feature[]) => {
          return [new LoadAllSuccess(features)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: FeatureService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
