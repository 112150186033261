<div [formGroup]="createPersonFrom">

  <div class="dvtx-create-contact-person-dialog--icon-content-layout" style="align-items: baseline">
    <mat-icon class="dvtx-create-contact-person-dialog--mat-icon">account_box</mat-icon>
    <div class="dvtx-create-contact-person-dialog--row row">
      <!--      <div class="col-12 col-sm-6 col-md-5">-->
      <!--        <dvtx-api-select-cva-->
      <!--          formControlName="title"-->
      <!--          class="type-or-location"-->
      <!--          listingPath="name"-->
      <!--          listingType="gender"-->
      <!--          [elementName]="'CONTACTS.GENDER' | translate"-->
      <!--        ></dvtx-api-select-cva>-->
      <!--      </div>-->
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="honorific" class="dvtx-create-contact-person-dialog--comp-name"
                             [label]="'CONTACTS.TITLE' | translate"></dvtx-text-input-cva>
      </div>
    </div>
  </div>
  <!--name section-->
  <div class="dvtx-create-contact-person-dialog--icon-content-layout" style="align-items: baseline">
    <div class="dvtx-create-contact-person-dialog--row  row">
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="firstName" class="dvtx-create-contact-person-dialog--comp-name"
                             [requiredField]="true"
                             [label]="'CONTACTS.FIRST_NAME' | translate"></dvtx-text-input-cva>
      </div>
      <div class="col-12 col-sm-6 col-md-5">
        <dvtx-text-input-cva formControlName="lastName" class="dvtx-create-contact-person-dialog--comp-name"
                             [requiredField]="true"
                             [label]="'CONTACTS.LAST_NAME' | translate"></dvtx-text-input-cva>
      </div>
    </div>
  </div>

  <!--  email section-->
  <div class="dvtx-create-contact-person-dialog--icon-content-layout" style="align-items: baseline">
    <mat-icon class="dvtx-create-contact-person-dialog--mat-icon">email</mat-icon>
    <div class="dvtx-create-contact-person-dialog--layout-content">
      <div class="dvtx-create-contact-person-dialog--row row">
        <div class="col-12 col-md-10">
          <dvtx-email-address-input
            formControlName="mainEmailAddress"
            [requiredField]="true"
            [label]="'CONTACTS.EMAIL' | translate"
            [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]"
          ></dvtx-email-address-input>
        </div>
        <div class="col-12">
          <dvtx-email-address-group-cva
            *ngIf="displayFurtherDataFields === true && contactPerson"
            [emailAddresses]="$any(contactPerson).emailAddresses"
            (addEmail)="addFurtherEmail($event)"
            (removeEmail)="removeFurtherEmail($event)"
            [showAddEmail]="false"
            [disabled]="isDisabled"></dvtx-email-address-group-cva>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 my-1 d-flex align-items-center"
       *ngIf="newContactMode && (administrationRights | async)?.hasAdministrationRights && (featureSet$ | async)?.hasPartnerLinks">
    <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200">link</mat-icon>
    <div class="w-100 ml-3">
      <mat-checkbox
        [labelPosition]="'before'"
        formControlName="activatePartnerLinks">
        {{ 'PARTNER_LINK.ACTIVATE_PARTNERLINKS' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <!--telephone section-->
  <div class="dvtx-create-contact-person-dialog--icon-content-layout">
    <mat-icon class="dvtx-create-contact-person-dialog--mat-icon">phone</mat-icon>
    <div class="dvtx-create-contact-person-dialog--layout-content">
      <div class="dvtx-create-contact-person-dialog--row row">
        <div class="col-12 col-md-10">
          <dvtx-telephone-input [formControlName]="'mainPhoneNumber'" [isType]="true"></dvtx-telephone-input>
        </div>
        <div class="col-12">
          <dvtx-telephone-group-cva *ngIf="displayFurtherDataFields && contactPerson"
                                    [telephonenumbers]="$any(contactPerson).phoneNumbers"
                                    (addPhone)="addFurtherPhoneNumber($event)"
                                    (removePhone)="removeFurtherPhoneNumber($event)"
                                    [showAddPhone]="false"
                                    [disabled]="isDisabled">
          </dvtx-telephone-group-cva>
        </div>
      </div>
    </div>
  </div>

  <!--  Address section -->
  <div class="dvtx-create-contact-person-dialog--icon-content-layout">
    <mat-icon class="dvtx-create-contact-person-dialog--mat-icon">place</mat-icon>
    <div class="dvtx-create-contact-person-dialog--layout-content">
      <div class="dvtx-create-contact-person-dialog--row row">
        <div class="col-12 col-md-10">
          <dvtx-address-input-cva formControlName="mainAddress" [elementName]="'CONTACTS.ADDRESS' | translate"
                                  [isDisabled]="isDisabled"></dvtx-address-input-cva>
        </div>
        <div class="col-12">
          <dvtx-address-input-group-cva
            *ngIf="displayFurtherDataFields && contactPerson"
            [enableLocationOrType]="true"
            [addresses]="$any(contactPerson).addresses"
            (addAddress)="addFurtherAddress($event)"
            [showAddAddress]="false"
            (removeAddress)="removeFurtherAddress($event)"
            [disabled]="isDisabled"
          ></dvtx-address-input-group-cva>
        </div>
      </div>
    </div>
  </div>
</div>

