<!-- page header -->
<nav class="navbar dvtx-pageHeader-navbar fixed-top navbar-toggleable-md navbar-dark double-nav py-0" #mainHeader>

  <!-- Navbar-brand / Logo-->
  <dvtx-page-header-logo [homeLink]="homeLink" [logo]="(logo$ | async)?.url"
                         class="navbar-brand"></dvtx-page-header-logo>

  <!-- Global search bar -->
  <div class="d-flex align-items-center" *ngIf="!smallScreen && userSessionEnabled && user" [formGroup]="searchForm">
    <input [formControlName]="'searchTerm'"
           [ngClass]="{'dvtx-search-header-navbar-active': showDropdown, 'dvtx-search-header-navbar__input': true}"
           type="text"
           [placeholder]="'GENERAL.SEARCH' | translate" (click)="_showDropdown()">
    <button mat-icon-button cdk-overlay-origin #dropdown="cdkOverlayOrigin"
            style="position: absolute; visibility: hidden;">
    </button>
    <div class="dvtx-search-header-navbar__container">
      <ng-template cdk-connected-overlay [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
                   [cdkConnectedOverlayOrigin]="dropdown" [cdkConnectedOverlayHasBackdrop]="true"
                   [cdkConnectedOverlayOpen]="showDropdown" (backdropClick)="showDropdown = false">
        <div (click)="$event.stopPropagation()" class="dvtx-search-navbar-container">
          <dvtx-search-navbar [search]="searchForm.get('searchTerm').value"
                              (closeDropdown)="showDropdown = false;searchForm?.controls?.searchTerm?.patchValue('')">
          </dvtx-search-navbar>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Global search bar -->
  <div class="d-flex" *ngIf="!error">
    <div class="user-menu d-flex flex-lg-column align-items-center align-items-lg-end justify-content-end"
         [class.justify-content-between]="user" [class.align-self-end]="user" [class.mr-0]="!user"
         style="align-items: flex-end;">

      <!-- header profile nav -->
      <ul class="navbar-nav user-menu-nav flex-row">

        <!-- INBOX -->
        <li class="nav-item bell" *ngIf="userSessionEnabled && user">
          <a class="nav-link dvtx-notification-bell" routerLink="/messages/updates">
            <dvtx-message-bell [unreadCount]="unreadCount$ | async"></dvtx-message-bell>
          </a>
        </li>

        <!-- Organization switcher -->
        <dvtx-organization-banner-element *ngIf="userSessionEnabled && user"></dvtx-organization-banner-element>

        <!-- Locale switcher -->
        <dvtx-locale-switcher [class.dvtx-locale-switcher]="user">
        </dvtx-locale-switcher>

        <!-- Avatar / User profile menu -->
        <li class="nav-item dropdown" *ngIf="userSessionEnabled && user">
          <a class="nav-link dropdown-toggle account-drop-down" type="button" id="navbarDropdownMenuLink"
             data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <dvtx-avatar *ngIf="!(versionChangeNotify$ | async)" [size]="'xs'" [email]="user?.email"
                         matTooltip="{{ 'BANNER.TOOLTIP.SIGNED_IN_AS' | translate:{ user: user.email } }}"
                         [matTooltipPosition]="'left'"></dvtx-avatar>
            <mat-icon *ngIf="(versionChangeNotify$ | async)" svgIcon="update"></mat-icon>
          </a>

          <ul class="dropdown-menu dropdown-menu-right text-capitalize" aria-labelledby="navbarDropdownMenuLink"
              data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
            <li class="profile--overview d-flex align-items-center">
              <dvtx-avatar [size]="'md'" [email]="user?.email"></dvtx-avatar>
              <span class="text-truncate">
                <p class="text-truncate m-0 text-truncate">{{ user?.name }}</p>
                <span class="text-truncate">{{ 'DASHBOARD.LAST_LOGIN' | translate }} {{
                    user?.lastSignInAt | date:'short'
                  }}</span>
              </span>
            </li>

            <li *ngIf="(versionChangeNotify$ | async)">
              <mat-icon style="color: #D04A26;" svgIcon="update"></mat-icon>
              <a style="color: #D04A26;" class="dropdown-item user-menu-drop" (click)="reloadPage()">{{
                  'GENERAL.UPDATE_AVAILABLE' | translate
                }}</a>
            </li>

            <li *ngIf="(currentOrganization$ | async) as selectedOrg;">
              <mat-icon>perm_contact_calendar</mat-icon>
              <a class="dropdown-item user-menu-drop" (click)="handleOrganizationAddressbookClick(selectedOrg.id)">{{
                  'ORGANIZATION.ADDRESS_BOOK' | translate
                }}</a>
            </li>

            <ng-container *ngIf="(featureSet$ | async)?.hasMyStorage">
              <li *ngIf="(dmsStatus$ | async)?.valid">
                <mat-icon>folder</mat-icon>
                <a class="dropdown-item user-menu-drop" [href]="foxdoxLink">{{
                    'BANNER.LINK.MY_DOCUMENTS_TITLE' |
                      translate
                  }}</a>
              </li>

              <li *ngIf="(dmsStatus$ | async)?.inValid">
                <mat-icon>settings</mat-icon>
                <a class="dropdown-item user-menu-drop" routerLink="/user-profile/dms">{{
                    'BANNER.LINK.DMS_SETTINGS' | translate
                  }}</a>
              </li>
            </ng-container>

            <li>
              <mat-icon>account_circle</mat-icon>
              <a class="dropdown-item user-menu-drop" routerLink="/user-profile/navigation">{{
                  'BANNER.LINK.PERSONAL_SETTINGS' | translate
                }}</a>
            </li>
            <li *ngIf="selectedOrganization?.id">
              <mat-icon>domain</mat-icon>
              <a class="dropdown-item user-menu-drop" (click)="handleOrganizationClick(selectedOrganization.id)">{{
                  'BANNER.LINK.ORGANIZATION_SETTINGS' | translate
                }}</a>
            </li>
            <li>
              <mat-icon svgIcon="logout"></mat-icon>
              <a class="dropdown-item user-menu-drop" href="#" (click)="signOut()">{{
                  'BANNER.LINK.LOGOUT_TITLE' |
                    translate
                }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
