<div class="fivef-email-address-group-cva">
  <div *ngFor="let emailAddress of emailAddresses" class="fivef-email-address-group-cva--email">
    <dvtx-email-address-input
      [innerValue]="emailAddress"
      [isDisabled]="emailAddress.id || disabled"
    ></dvtx-email-address-input>

    <div class="d-flex align-items-end" *ngIf="!disabled">
      <button mat-icon-button [disabled]="disabled" (click)="saveEmail(emailAddress)" *ngIf="!emailAddress.id">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button [disabled]="disabled" (click)="deleteEmail(emailAddress)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="!disabled && showAddEmail">
    <button mat-icon-button [disabled]="disabled" color="primary" type="button" (click)="addNewEmailField()"
            class="fivef-email-address-group-cva--add-button" [disableRipple]="true">
      <mat-icon>add_circle_outline</mat-icon>
      {{ 'CONTACTS.ADD_EMAIL' | translate }}
    </button>
  </div>
</div>
