import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {WaitingDialogComponent} from './components/waiting-dialog/waiting-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FivefDialogComponent} from '../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';

@NgModule({
  declarations: [
    WaitingDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    FivefDialogComponent
  ],
  exports: [
    WaitingDialogComponent
  ]
})
export class ConfirmDialogModule {
}
