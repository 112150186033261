import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownContentModule} from 'app/five-f/dropdown-content/dropdown-content.module';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {TranslateModule} from '@ngx-translate/core';
import {FivefLabelComponent} from '../../lib/fivef-ui/label/fivef-label/fivef-label.component';
import {BaseFormElementsModule} from 'app/shared/modules/base-form-elements/base-form-elements.module';
import {LabelsFilterComponent} from './components/labels-filter/labels-filter.component';
import {FivefColorPickerComponent} from '../../lib/fivef-ui/util/fivef-color-picker/fivef-color-picker.component';
import {FivefIconPickerComponent} from '../../lib/fivef-ui/util/fivef-icon-picker/fivef-icon-picker.component';

@NgModule({
  imports: [
    CommonModule,
    DropdownContentModule,
    TranslateModule,
    TooltipModule,
    MatIconModule,
    BaseFormElementsModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    FivefColorPickerComponent,
    FivefIconPickerComponent,
    FivefLabelComponent,
  ],
  declarations: [
    LabelsFilterComponent
  ],
  exports: [
    LabelsFilterComponent
  ]
})
export class LabelsModule {
}
