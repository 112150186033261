import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

/**
 * 5F input label.
 *
 * Example:
 *    <fivef-input-label>{{ 'CONTACTS.FIRST_NAME' | translate }}</fivef-input-label>
 *    <mat-form-field>
 *      <input matInput formControlName="firstName"/>
 *    </mat-form-field>
 */
@Component({
  selector: 'fivef-input-label',
  host: {class: 'fivef-input-label'},
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './fivef-input-label.component.html',
  styleUrls: ['./fivef-input-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefInputLabelComponent {
  @Input()
  title: string = null;
}
