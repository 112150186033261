import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  Edit,
  EditFail,
  EditSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  TaskStatusActionTypes
} from './task-status.actions';
import {TaskStatusService} from './task-status.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TaskStatus} from './task-status';

@Injectable()
export class TaskStatusEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.schemeId).pipe(
        first(),
        concatMap((res: TaskStatus[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_STATUSES_ERROR')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.status).pipe(
        first(),
        concatMap((res: TaskStatus) => {
          return [new CreateSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.CREATE_STATUS_ERROR')
          return of(new CreateFail(err));
        }));
    })
  ));

  edit$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusActionTypes.Edit),
    switchMap((action: Edit) => {
      return this._svc.update(action.status).pipe(
        first(),
        concatMap((res: TaskStatus) => {
          return [new EditSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.UPDATE_STATUS_ERROR')
          return of(new EditFail(err));
        }));
    })
  ));

  delete$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.status).pipe(
        first(),
        switchMap((res: TaskStatus) => {
          this._notifyService.success('TASK.DELETED_STATUS_SUCCESSFULLY')
          return [new DeleteSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.DELETED_STATUS_FAILURE')
          return of(new DeleteFail(err))
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TaskStatusService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
