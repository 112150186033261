import {IResource} from '../../shared/modules/api/models/resource.interface';


export type LabelItemScope = 'artifacts' | 'tasks' | 'collector_node';

export interface IItemLabels extends IResource {
  itemId: string;
  processId?: string;
  labelIds: string[];
  itemType: ItemType;
}

export interface IItemLabelsParams {
  itemId?: string;
  labelId?: string;
  processId?: string;
  itemType?: ItemType
}

export enum ItemType {
  TASK_ITEM = 'kanban_tasks_board',
  DOCUMENT_ITEM = 'kanban_documents_board',
  COLLECTOR_ITEM = 'kanban_collector_items_board'
}

export enum ItemTypeValueAsKey {
  task_item = 'TASK_ITEM' ,
  document_item = 'DOCUMENT_ITEM',
  collector_item = 'COLLECTOR_ITEM'
}

export enum ItemTypeTitles {
  TASK_ITEM = 'TASK.TASK',
  DOCUMENT_ITEM = 'MILESTONE.DOCUMENT',
  COLLECTOR_ITEM = 'COLLECTOR.TITLE'
}
