import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  ProcessActionActionTypes,
  Run, RunFail,
  RunSuccess,
} from './process-action.actions';
import {ProcessActionService} from './process-action.service';
import {ApiCommandService} from 'app/+store/process-action/api-command.service';
import * as ProcessParticipantActions from '../process-participant/process-participant.actions';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ProcessActionEffects {
  runCmd$ = createEffect(() => this.actions.pipe(
    ofType(ProcessActionActionTypes.Run),
    switchMap((action: Run) => {
      return this._apiCmdSvc.execute(action.command).pipe(
        first(),
        concatMap(result => {
          const id = result.id;
          return [
            new RunSuccess(result),
            new ProcessParticipantActions.LoadAllRefresh(id),
            // new ProcessEventActions.LoadAll(id)
          ]
        }),
        catchError(err => {
          console.error(err);
          return of(new RunFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessActionService,
              private _apiCmdSvc: ApiCommandService,
              private _notifyService: NotificationService) {
  }
}




