import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FivefReactionComponent} from '../fivef-reaction/fivef-reaction.component';
import {CommentReactionType} from '../../../../+store/comment/comment.interface';

@Component({
  selector: 'fivef-reactions',
  standalone: true,
  host: {'class': 'fivef-reactions'},
  imports: [
    CommonModule,
    FivefReactionComponent
  ],
  templateUrl: './fivef-reactions.component.html',
  styleUrls: ['./fivef-reactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefReactionsComponent {
  @Input()
  public reactions = [];

  @Output()
  public onReaction = new EventEmitter<CommentReactionType>();
}
