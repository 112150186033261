import {
  ControlValueAccessor,
  UntypedFormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
  ValidationErrors
} from '@angular/forms';
import {Directive, Injector, Input} from '@angular/core';
import {ErrorMapper} from './DvtxErrorMapper';
import { ErrorStateMatcher } from '@angular/material/core';

@Directive()
export abstract class DvtxControlValueAccessor implements ControlValueAccessor {
  private onChangeListeners: Function[] = [];
  private onTouchListeners: Function[] = [];
  errorStateMatcher: ErrorStateMatcher;

  protected injector: Injector;

  @Input()
  public isDisabled: boolean;

  @Input()
  public elementName: string;

  @Input()
  errorMapper: ErrorMapper[];

  constructor() {
    this.errorStateMatcher = new DvtxCvaErrorStateMatcher(this);
  }

  abstract writeValue(obj: any): void;

  public registerOnChange(fn: Function): void {
    this.onChangeListeners.push(fn);
  }

  public notifyOnChange(obj: any) {
    for (const changeListener of this.onChangeListeners) {
      changeListener(obj);
    }
  }

  public registerOnTouched(fn: Function): void {
    this.onTouchListeners.push(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public notifyOnTouch() {
    for (const touchListener of this.onTouchListeners) {
      touchListener();
    }
  }

  public get errors(): ValidationErrors | null {
    return this.injector.get(NgControl).errors;
  }

  public get dirty(): boolean {
    try {
      const control = this.injector.get(NgControl);
      return control.dirty || (control.control && control.control.dirty);
    } catch {
      return false;
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class DvtxCvaErrorStateMatcher implements ErrorStateMatcher {
  constructor(private cva: DvtxControlValueAccessor) {
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.cva.dirty && !!this.cva.errors
  }
}
