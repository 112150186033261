import {IProcessParticipant, IProcessParticipantRole, ProcessParticipantType} from './process-participant.interface';
import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {StringUtils} from '../../lib/string_utils';

export class ProcessParticipantRole implements IProcessParticipantRole {
  constructor(public symbol: string) {
  }

  get name() {
    switch (this.symbol) {
      case 'contributor':
      case 'delegator':
        return 'ROLE.MEMBER';
      case 'moderator':
        return 'ROLE.MODERATOR';
      case 'admin':
        return 'ROLE.ADMIN';
      case 'owner':
        return 'ROLE.OWNER';
      case 'creator':
        return 'ROLE.CREATOR';
      case 'subscriber':
        return 'ROLE.SUBSCRIBER';
      case 'auditor':
        return 'ROLE.AUDITOR';
      default:
        return 'ROLE.MEMBER';
    }
  }
}

export class ProcessParticipant implements IProcessParticipant {
  type: ProcessParticipantType;
  isMember = false;
  contactId;
  roles: ProcessParticipantRole[] = [];
  hasAccount: boolean;
  isAdmin: boolean;
  isCreator: boolean;
  isOwner: boolean;
  canDelegate: boolean;
  expiresAt: Date;
  createdAt?: Date;
  twoFactorEnabled: boolean;
  processOrganizationMember: boolean;
  trackBy: string;

  constructor(public id: string,
              public processId: string,
              public name: string = null,
              public email: string = null,
              public gender: string = null,
              public title: string = null,
              public firstName: string = null,
              public lastName: string = null,
              public recursive = false,
              public updatedAt: Date = null) {
    this.trackBy = `${id}|${firstName}|${lastName}|${recursive}|${updatedAt}`;
  }

  getName() {
    if (!this.firstName && !this.lastName) {
      return this.email;
    }
    return this.firstName + ' ' + this.lastName;
  }
}

/**
 * Returns all process participations of the current organization.
 * {
 *   "id": "auditor@dvtx.de",
 *   "type": "process_participations",
 *   "attributes": {
 *     "first_name": "Max",
 *     "last_name": "Richter",
 *     "process_count": 57
 *   }
 * }
 */
export class ProcessParticipation implements IResource {
  readonly type = 'process_participations';

  /**
   * Normalized precalculated email for fast
   * matchings with contacts. Expected to be filled up by
   * StringUtils.normalizeString(incomingEmail)
   */
  public normalizedEmail: string;

  constructor(public id: string,
              public firstName: string,
              public lastName: string,
              public processCount: number) {
    this.normalizedEmail = StringUtils.normalizeString(id);
  }

  get email() {
    return this.id;
  }
}

export class ParticipatingProcess {

  constructor(
    public id: string,
    public title: string,
    public color: string,
    public status: string,
    public type: string,
    public clientName: string,
    public canAddParticipant: boolean,
    public path: []
  ) {
  }

}
