<div class="m-0 d-flex justify-content-between">
  <div class="mr-3">
    <fivef-artifact-preview [url]="document?.previewUrl"></fivef-artifact-preview>
  </div>

  <div>
    <table>
      <tr>
        <td style="text-align: right; padding-right: 15px; min-width: 100px;">{{ 'DMS.DISPLAY_NAME' | translate }}</td>
        <td><strong>{{ document?.name || document?.artifact?.fileName }}</strong></td>
      </tr>
      <tr>
        <td style="text-align: right; padding-right: 15px">{{ 'DMS.FILENAME' | translate }}</td>
        <td><strong>{{ document?.filename || document?.name || document?.artifact?.fileName }}</strong></td>
      </tr>
      <tr>
        <td style="text-align: right; padding-right: 15px">{{ 'DMS.UPLOADED_BY' | translate }}</td>
        <td><strong>{{ document?.uploadedBy }}</strong></td>
      </tr>
      <tr>
        <td style="text-align: right; padding-right: 15px">{{ 'DMS.FILE_TYPE' | translate }}</td>
        <td matTooltip="{{ document?.mimeType }}">{{ document?.mimeType }}</td>
      </tr>
      <tr>
        <td style="text-align: right; padding-right: 15px">{{ 'DMS.SIZE' | translate }}</td>
        <td>{{ document?.size | fivefFileSize }}</td>
      </tr>
      <tr>
        <td style="text-align: right; padding-right: 15px">{{ 'DMS.REVISION' | translate }}</td>
        <td>{{ document?.revision }}</td>
      </tr>
      <!--<tr>-->
      <!--<td style="text-align: right; padding-right: 15px">{{ 'DMS.PAGES' | translate }}</td>-->
      <!--<td>{{ document?.pages || 1 }}</td>-->
      <!--</tr>-->
      <tr>
        <td style="text-align: right; padding-right: 15px">{{ 'GENERAL.CREATED_AT' | translate }}</td>
        <td>{{ document?.createdAt | date:'short' }}</td>
      </tr>
    </table>
  </div>
</div>
