import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PortalModule} from '@angular/cdk/portal';
import {TranslateModule} from '@ngx-translate/core';
import {BaseFormElementsModule} from '../base-form-elements/base-form-elements.module';
import {PageSidebarComponent} from './containers/page-sidebar/page-sidebar.component';
import {MatBadgeModule} from '@angular/material/badge';
import {WorkflowInfoComponent} from './components/workflow-info/workflow-info.component';
import {TooltipModule} from '../../../five-f/tooltip/tooltip.module';
import {WorkflowEngineModule} from 'app/modules/workflow-engine/workflow-engine.module';
import {TasksModule} from 'app/modules/tasks/tasks.module';
import {SidebarNavigationComponent} from './containers/sidebar-navigation/sidebar-navigation.component';
import {FivefNavLinkComponent} from '../../../lib/fivef-ui/navigation/fivef-nav-link/fivef-nav-link.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BaseFormElementsModule,
    MatBadgeModule,
    TranslateModule,
    PortalModule,
    TooltipModule,
    WorkflowEngineModule,
    TasksModule,
    FivefNavLinkComponent
  ],
  declarations: [
    PageSidebarComponent,
    SidebarNavigationComponent,
    WorkflowInfoComponent
  ],
  exports: [
    PageSidebarComponent,
    SidebarNavigationComponent,
    PortalModule,
    WorkflowInfoComponent
  ]
})
export class PageSidebarModule {
}
