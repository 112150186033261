import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {EnvService} from '../../api-resource/services/env.service';

@Injectable()
export class IsStagingEnv  {
  constructor(
    private _env: EnvService,
    private _router: Router) {
  }

  canActivate() {
    return this._env.isStagingEnv();
  }
}

