import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {UserState} from '../../../../reducers/user.reducer';
import {LoginUserRequest} from '../../../../actions/user-actions';
import {AppState} from '../../../../reducers';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {TfaState} from '../../../../reducers/tfa.reducer';
import {Subject} from 'rxjs/internal/Subject';
import {FivefLoginSettingsBuilder} from './fivef-session.builder';
import {ApiResourceService} from '../../../../shared/modules/api-resource/services/api-resource.service';
import {FivefLoginSettings} from './fivef-session';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import * as model from 'app/+store/cms-slideshow/cms-slideshow';

@Injectable()
export class FivefSessionService implements OnDestroy {
  readonly LOGIN_SETTINGS_BASE_PATH = 'api/v1/settings/login_settings';

  private onDestroy = new Subject();

  public tfaState: Observable<TfaState>;
  public userStateInfo: Observable<UserState>;

  private fivefLoginSettings$: BehaviorSubject<FivefLoginSettings> = new BehaviorSubject<FivefLoginSettings>(null);
  public fivefLoginSettings = this.fivefLoginSettings$.asObservable();

  private loginHeader$ = new BehaviorSubject<string>(null);
  public loginHeader = this.loginHeader$.asObservable();
  private loginFooter$ = new BehaviorSubject<string>(null);
  public loginFooter = this.loginFooter$.asObservable();

  private loginSecondaryLogoUrl$ = new BehaviorSubject<string>(null);
  public loginSecondaryLogoUrl = this.loginSecondaryLogoUrl$.asObservable();

  private loginSlideshow$ = new BehaviorSubject<model.Cms.Slide[]>(null);
  public loginSlideshow = this.loginSlideshow$.asObservable();

  private bgColor$ = new BehaviorSubject<string>('transparent');
  public bgColor = this.bgColor$.asObservable();
  private fgColor$ = new BehaviorSubject<string>('inherit');
  public fgColor = this.fgColor$.asObservable();

  constructor(private store: Store<AppState>,
              private http: ApiResourceService,
              private i18n: TranslateService) {
    this.userStateInfo = this.store.select('user');
    this.tfaState = this.store.pipe(map((appstate: AppState) => appstate['tfaState']), map(
      (currState: TfaState) => {
        return currState;
      }
    ));

    this.initLoginConfig();
  }

  public ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.fivefLoginSettings$.complete();
    this.loginHeader$.complete();
    this.loginFooter$.complete();
    this.loginSlideshow$.complete();
    this.loginSecondaryLogoUrl$.complete();
    this.bgColor$.complete();
    this.fgColor$.complete();
  }

  public set loginSettings(value: FivefLoginSettings) {
    this.fivefLoginSettings$.next(value);
  }

  public signInWithAuthToken(event: { email: string, password: string }): void {
    this.store.dispatch(new LoginUserRequest(event));
  }

  /**
   * Returns the login settings.
   *
   * Example response:
   * {
   *   "data": {
   *     "id": "test.5fsoftware.de",
   *     "type": "auth_login_settings",
   *     "attributes": {
   *       "impressum_url": "https://test.5fsoftware.de/impressum",
   *       "login_header": "Hello World!",
   *       "login_footer": "User notice",
   *       "login_header_en": null,
   *       "login_footer_en": null,
   *       "color_theme": "light_theme",
   *       "color_theme_config": {
   *
   *       },
   *       "login_theme": "fivef_blue",
   *       "login_background_image_url": "https://my-background-image-url.com",
   *       "login_slideshow": {
   *         "data": [
   *           {
   *             "id": "95567106-24b5-4c02-add6-ab28b55f1fbd",
   *             "type": "cms_slideshow_slide",
   *             "attributes": {
   *               "title": "My 1st slide",
   *               "alt": null,
   *               "profile": "login_theme",
   *               "language": "de_de",
   *               "order": 0,
   *               "background_image_url": "https://my-images.com/bg.png",
   *               "background_image_filename": "https://my-images.com/bg.png",
   *               "content": null,
   *               "content_image_url": "fg.png",
   *               "content_image_filename": "test.png",
   *               "content_image_width": 300,
   *               "content_image_height": 600,
   *               "content_image_map_areas": [
   *                 {
   *                   "alt": "My inner link",
   *                   "href": "https://example.com",
   *                   "shape": "rect",
   *                   "coords": "34,44,270,350"
   *                 },
   *                 {
   *                   "alt": "My 2nd inner link",
   *                   "href": "https://example.com",
   *                   "shape": "circle",
   *                   "coords": "337,300,44"
   *                 }
   *               ],
   *               "created_at": "2024-08-09T11:23:22.080+02:00",
   *               "updated_at": "2024-08-09T11:23:22.080+02:00"
   *             }
   *           },
   *           {
   *             "id": "24525fe9-8ef4-4422-a6df-41babd00ad02",
   *             "type": "cms_slideshow_slide",
   *             "attributes": {
   *               "title": "My 2nd slide",
   *               "alt": null,
   *               "profile": "login_theme",
   *               "language": "de_de",
   *               "order": 0,
   *               "background_image_url": "https://my-images.com/bg_2.png",
   *               "background_image_filename": "bg_2.png",
   *               "content": "<h2>My content<h/2><p>Some text</p>",
   *               "content_image_url": null,
   *               "content_image_filename": null,
   *               "content_image_width": null,
   *               "content_image_height": null,
   *               "content_image_map_areas": [
   *
   *               ],
   *               "created_at": "2024-08-09T11:23:22.105+02:00",
   *               "updated_at": "2024-08-09T11:23:22.105+02:00"
   *             }
   *           }
   *         ]
   *       }
   *     }
   *   }
   * }
   * @param domain
   */
  getLoginSettings(domain): Observable<FivefLoginSettings> {
    const payload = {
      data: {
        attributes: {
          domain: domain
        }
      }
    };
    const builder = new FivefLoginSettingsBuilder();
    return this.http.post<FivefLoginSettingsBuilder, FivefLoginSettings>(builder, `${this.LOGIN_SETTINGS_BASE_PATH}`, payload);
  }

  /**
   * Sets the welcome/footer message stream depending on language.
   * @private
   */
  private initLoginConfig() {
    const lang$ = this.i18n.onLangChange
      .pipe(
        startWith({lang: this.i18n.currentLang}),
        takeUntil(this.onDestroy),
        map(lang => lang ? lang.lang : 'de'));

    combineLatest(this.fivefLoginSettings$, lang$)
      .pipe(takeUntil((this.onDestroy)))
      .subscribe(([settings, lang]) => {
        if (!settings) {
          return;
        }

        const slidesOfLanguage = {};
        if (settings?.loginSlideshow?.length) {
          settings?.loginSlideshow?.forEach(slide => {
            if (!slidesOfLanguage[slide.language]) {
              slidesOfLanguage[slide.language] = [];
            }
            slidesOfLanguage[slide.language].push(slide);
          })
        }

        if (settings.loginFgColor) {
          this.fgColor$.next(settings.loginFgColor);
        }

        if (settings.loginBgColor) {
          this.bgColor$.next(settings.loginBgColor);
        }

        this.loginSecondaryLogoUrl$.next(settings.loginSecondaryLogoUrl);
        this.loginSecondaryLogoUrl$.next(settings.loginSecondaryLogoUrl);

        if (lang === 'en') {
          this.loginHeader$.next(settings.loginHeaderEN);
          this.loginFooter$.next(settings.loginFooterEN);
          this.loginSlideshow$.next(slidesOfLanguage['en_us']);
        } else {
          this.loginHeader$.next(settings.loginHeader);
          this.loginFooter$.next(settings.loginFooter);
          this.loginSlideshow$.next(slidesOfLanguage['de_de']);
        }
      })
  }
}
