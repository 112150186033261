<!-- NOTE: mousedown and click event propagation prevention is necessary to
     make content editable usable with CDK drag 'n drop component which consume these
     event when blubbling up -->
<span #inputRef
      class="fivef-input-ce--input"
      (mousedown)="$event.stopPropagation()"
      (click)="editContent($event)"
      (keydown.enter)="handleReturnKey($event, inputRef)"
      (keydown.esc)="handleEscKey()"
      (keypress)="handleEnterKey()"
      (focusout)="handleOnBlur($event, inputRef)"
      (blur)="handleOnBlur($event, inputRef)"
      [attr.contenteditable]="editable && !disabled"
      [attr.data-ph]="placeholder | translate"
      [class.fivef-input-ce--editable]="editable && !disabled"
      [class.five-f-content-editable-container]="!editable && !disabled"
      [class.mr-1]="showEditIcon"
      [class.fivef-input-ce--text-truncate]="truncateContent && !editable">
  </span>

<span *ngIf="suffix">&nbsp;{{ suffix }}</span>

<button mat-icon-button
        fivefSuffixButton
        *ngIf="!disabled && showEditIcon && !editable"
        (click)="enableEditing()">
  <mat-icon>edit</mat-icon>
</button>

<button mat-icon-button
        fivefSuffixButton
        *ngIf="!disabled && showRemoveIcon && !editable"
        (click)="remove()">
  <mat-icon >close</mat-icon>
</button>
