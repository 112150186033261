import {DmsAccountType, IDmsFolder} from './dms-folder.interface';
import {Process} from '../process/process';

export const DMS_ACCOUNT_TYPE_MAP = {
  'organization': DmsAccountType.Organization,
  'private': DmsAccountType.Private,
}

export class DmsFolder implements IDmsFolder {
  readonly type = 'dms_foxdox_model_folders';
  children: DmsFolder[] = [];
  userProcessesSize: number;
  userProcessMap: Process[] = [];
  allProcessesSize: number;
  furtherProcessesSize: number;
  users = [];
  ownedBy: string;
  admin = false;
  deletable = false;
  renamable = false;
  auditProofUntil: Date;
  bookmanCockpitEnabled?: boolean;

  constructor(public id: string,
              public name: string,
              public path: string,
              public sourceId: string,
              public dmsAccountType: DmsAccountType,
              public parentId: string,
              public folderCount: number,
              public processCount: number,
              public documentCount: number,
              public shared: boolean,
              public auditProof: boolean,
              public createdAt: Date,
              public updatedAt: Date = null) {
    if (path && path[0] !== '/') {
      this.path = `/${path}`;
    }
    this.processCount = processCount || 0;
    this.folderCount = folderCount;
    this.documentCount = documentCount;
    this.deletable = folderCount !== null && documentCount !== null && (this.documentCount + this.folderCount + this.processCount) === 0;
    this.renamable = name && name.length > 0 && name !== 'Quickshare' && this.path && this.path.length > 1
  }

  static sortByPath = (self: DmsFolder, other: DmsFolder) => {
    if (!self || !other) return -1;
    if (self.path < other.path) {
      return -1;
    }
    if (self.path > other.path) {
      return 1;
    }
    return DmsFolder.sortByName(self, other);
  };

  static sortByName = (self: DmsFolder, other: DmsFolder) => {
    if (!self || !other) return -1;
    return self.name.localeCompare(other.name);
  };
}
