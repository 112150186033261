import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {DocumentTableComponent} from './containers/document-table/document-table.component';
import {DmsDocumentTableComponent} from './containers/dms-document-table/dms-document-table.component';
import {DeleteDocumentAsAdminComponent} from './components/delete-document-as-admin/delete-document-as-admin.component';
import {TooltipModule} from '../../../../five-f/tooltip/tooltip.module';
import {FivefDialogComponent} from '../../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefFileSizePipe} from '../../../../lib/fivef-ui/util/fivef-file-size.pipe';
import {FivefSearchComponent} from '../../../../lib/fivef-ui/input/fivef-search/fivef-search.component';
import {FivefDeleteArtifactComponent} from '../../../../lib/fivef-ui/artifact/fivef-delete-artifact/fivef-delete-artifact.component';
import {FivefArtifactDetailsComponent} from '../../../../lib/fivef-ui/artifact/fivef-artifact-details/fivef-artifact-details.component';
import {FivefArtifactPreviewDialogComponent} from '../../../../lib/fivef-ui/artifact/fivef-artifact-preview-dialog/fivef-artifact-preview-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    TooltipModule,
    FivefDialogComponent,
    FivefFileSizePipe,
    FivefSearchComponent,
    FivefDeleteArtifactComponent,
    FivefArtifactDetailsComponent,
    FivefArtifactPreviewDialogComponent
  ],
  declarations: [
    DmsDocumentTableComponent,
    DocumentTableComponent,
    DeleteDocumentAsAdminComponent,
    DeleteDocumentAsAdminComponent
  ],
  exports: [
    DocumentTableComponent,
    DmsDocumentTableComponent,
    DeleteDocumentAsAdminComponent
  ]
})
export class DocumentModule {
}
