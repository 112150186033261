import { IResource } from 'app/shared/modules/api/models/resource.interface';

export namespace Tenant {
  /**
   * enum color_theme: {
   *   light_theme: 0,
   *   dark_theme: 1,
   *   system_theme: 2,
   *   demo_theme: 500,
   *   colored_fivef_theme: 501,
   *   paperbird_theme: 502,
   *   grant_thornton_theme: 503,
   *   curacon_theme: 504
   * }
   */
  export type FivefAppColorTheme = 'fivef_light_theme' | 'fivef_dark_theme' | 'fivef_system_theme' | 'fivef_demo_theme' | 'colored_fivef_theme' | 'paperbird_theme' | 'grant_thornton_theme' | 'curacon_theme';

  /**
   * Color theme map for select fields.
   */
  export const UI_COLOR_THEMES = [{
    id: 'fivef_light_theme',
    name: '5F Light Color Theme (default)'
  }, {
    id: 'fivef_dark_theme',
    name: '5F Dark Color Theme'
  }, {
    id: 'fivef_system_theme',
    name: '5F System Color Theme (automatic switch dependent on OS settings)'
  }, {
    id: 'fivef_demo_theme',
    name: '5F Demo Theme (Placeholder)'
  }, {
    id: 'colored_fivef_theme',
    name: 'Colored 5F Theme (colored.5fsoftware.com'
  }, {
    id: 'paperbird_theme',
    name: 'Paperbird.Org Theme'
  }, {
    id: 'grant_thornton_theme',
    name: 'Grant Thornton Theme'
  }, {
    id: 'curacon_theme',
    name: 'Curacon Theme'
  }];

  /**
   * Login theme reverse map from symbol to human readable name.
   */
  export const LOGIN_THEME_NAME_MAP = {
    fivef_pure: '5F Pure',
    fivef_blue: '5F Blue',
    fivef_white: '5F White'
  }

  /**
   * Login theme map for UI selections.
   */
  export const LOGIN_THEMES = [{
    id: 'fivef_pure',
    name: LOGIN_THEME_NAME_MAP['fivef_pure']
  }, {
    id: 'fivef_blue',
    name: LOGIN_THEME_NAME_MAP['fivef_blue']
  }, {
    id: 'fivef_white',
    name: LOGIN_THEME_NAME_MAP['fivef_white']
  }]

  /**
   * Microsoft AD Department interface for the tenant administration.
   * Required to set a Microsoft AD department as auto onboarding strategy at sign in of employeed.
   */
  export interface ITenantDepartment extends IResource {
    id: string;
    name: string;
    description: string;
    createdAt: Date;
  }

  /**
   * Basic public tenant interface.
   */
  export interface ITenant extends IResource {
    id: string;
    name: string;
    sender_name: string;
    domain: string;
    email_domain: string;
    favicon_url: string;
    login_header: string;
    login_header_en: string;
    login_footer: string;
    login_footer_en: string;
    imprint_url: string;

    /**
     * Login theme of the tenant.
     */
    login_theme: string;
    login_theme_config: any;

    /**
     * Color theme of the tenant based on main login theme class.
     */
    color_theme: FivefAppColorTheme;
    color_theme_config: any;

    createdAt: Date;
    updatedAt: Date;
  }

  /**
   * Tenant payload interface for API update calls.
   */
  export interface ITenantParams {
    name: string;
    domain: string;
    sender_name?: string;
    email_domain?: string;
    favicon_url?: string;
    login_header?: string;
    login_header_en?: string;
    login_footer?: string;
    login_footer_en?: string;
    imprint_url?: string;

    /**
     * Login theme of the tenant.
     */
    login_theme?: string;
    login_theme_config?: any;

    /**
     * Color theme of the tenant based on main login theme class.
     */
    color_theme?: FivefAppColorTheme;
    color_theme_config?: any;
  }

  /**
   * Basic and public tenant interface for the app configuration on app start.
   * Contains information that must even be available before login, like the favicon, the browser tab title (tenant name)
   * the used theme.
   */
  export interface IPublicTenantConfig {
    name: string;
    faviconUrl: string;
    colorTheme: FivefAppColorTheme;
    colorThemeConfig: any;
  }

  /**
   * Translates the API theme symbol into the internal CSS style class.
   * See type FivefAppColorTheme for all available themes.
   * See lib/styles/themes/platform for all available CSS style collections.
   *
   * @param theme API theme identifier.
   */
  export function themeNameToCssStyleClass(theme: FivefAppColorTheme) {
    switch (theme) {
      case 'colored_fivef_theme':
        return 'fivef-colored-fivef-theme';
      case 'paperbird_theme':
        return 'fivef-paperbird-theme';
      case 'curacon_theme':
        return 'fivef-curacon-theme';
      case 'grant_thornton_theme':
        return 'fivef-grant-thornton-theme';
      default:
        return '';
    }
  }
}
