<div *ngIf="avatarProfile" class="dvtx-avatar dvtx-avatar-{{avatarSizeString}}">
  <div class="dvtx-avatar-icon-placeholder mx-1">
    <ng-template [ngIf]="this.avatarButtonCallback" [ngIfElse]="noActionAvatar">
      <button mat-fab (click)="avatarButtonCallback()" class="dvtx-avatar-icon"
        [style.color]="userAvatar?.avatar ? 'transparent' : avatarProfile?.fgColor"
        [style.background-color]="userAvatar?.avatar ? 'transparent' : avatarProfile?.bgColor"
        [style.border-color]="userAvatar?.avatar ? 'transparent' : avatarProfile?.bgColor"
        [style.width.px]="avatarSizeNumber" [style.height.px]="avatarSizeNumber">

        <img [src]="userAvatar?.avatar" />
      </button>
    </ng-template>

    <ng-template #noActionAvatar>
      <span class="dvtx-avatar-icon dvtx-avatar-size-{{avatarSizeString}} mat-fab mat-elevation-z"
        [style.color]="(userAvatar?.avatar) ? 'transparent' : avatarProfile?.fgColor"
        [style.background-color]="(userAvatar?.avatar) ? 'transparent' : avatarProfile?.bgColor"
        [style.border-color]="(userAvatar?.avatar) ? 'transparent' : avatarProfile?.bgColor">

        <img [src]="userAvatar?.avatar" />
      </span>

      <ng-template [ngIf]="(userStatusIsVerified || (avatarProfile?.hasAccount || isVerified ) && !(isMember || avatarProfile?.isMember || userStatusIsMember )) && showTick">
        <mat-icon  class="dvtx-avatar-verification--icon dvtx-avatar-check">check_circle</mat-icon>
      </ng-template>

      <ng-template [ngIf]="(userStatusIsMember  || (isMember || avatarProfile?.isMember))  && showTick" >
        <mat-icon  class="dvtx-avatar-verification--icon dvtx-avatar-member-check">check_circle</mat-icon>
      </ng-template>
    </ng-template>
  </div>

  <div class="dvtx-avatar-user-info" *ngIf="avatarShowTitle || avatarShowEmail">
    <h4 class="mb-0" *ngIf="avatarShowTitle" mat-line>
      <strong *ngIf="avatarProfile?.firstName || avatarProfile?.lastName || avatarProfile?.name">
        {{ avatarProfile?.firstName && avatarProfile?.lastName ? (avatarProfile?.firstName + ' ' + avatarProfile?.lastName) : avatarProfile?.name }}
      </strong>
      <strong *ngIf="!(avatarProfile?.firstName || avatarProfile?.lastName || avatarProfile?.name) && !avatarShowEmail">
        {{ avatarProfile?.email }}
      </strong>
    </h4>
    <p class="mb-0 email" *ngIf="avatarShowEmail" mat-line>{{ avatarProfile?.email }}</p>
  </div>
</div>
