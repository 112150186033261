import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './user-profile.component';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { ProfileContainerComponent } from './containers/profile-container/profile-container.component';
import { FoxdoxStatusContainerComponent } from './containers/foxdox-container-status/foxdox-container-status.component';
import { AuthGuard } from 'app/shared/modules/api/services/auth-guard.service';
import { DocumentSignatureDefaultSignatureSettingsComponent } from './containers/document-signature-default-signature-settings/document-signature-default-signature-settings.component';
import { DocumentSignatureAccountSettingsComponent } from './containers/document-signature-account-settings/document-signature-account-settings.component';
import { DocumentSignatureFpSignAccountComponent } from './containers/document-signature-fp-sign-account/document-signature-fp-sign-account.component';
import { MobileLoginContainerComponent } from './containers/mobile-login-container/mobile-login-container.component';
import { DataPrivacyContainerComponent } from './containers/data-privacy-container/data-privacy-container.component';
import { UsageTermsContainerComponent } from './containers/usage-terms-container/usage-terms-container.component';
import { ProfileSettingsNavigationContainerComponent } from './containers/profile-settings-navigation-container/profile-settings-navigation-container.component';
import { TwoFactorAuthContainerComponent } from './containers/two-factor-auth-container/two-factor-auth-container.component';
import { PasswordResetContainerComponent } from './containers/password-reset-container/password-reset-container.component';
import { AutoLogoutContainerComponent } from './containers/auto-logout-container/auto-logout-container.component';

const routes: Routes = [{
  path: '',
  component: UserProfileComponent,
  canActivate: [AuthGuard],
  children: [
    { path: 'navigation', component: NavigatorComponent, canActivate: [AuthGuard] },
    {
      path: 'security',
      redirectTo: 'security/two-factor-authentication',
      pathMatch: 'full'
    },
    {
      path: 'security',
      component: ProfileSettingsNavigationContainerComponent,
      canActivate: [AuthGuard],
      children: [
        { path: 'two-factor-authentication', component: TwoFactorAuthContainerComponent, canActivate: [AuthGuard] },
        { path: 'password', component: PasswordResetContainerComponent, canActivate: [AuthGuard] },
        { path: 'auto-logout', component: AutoLogoutContainerComponent, canActivate: [AuthGuard] },
      ]
    },


    {
      path: 'dms',
      redirectTo: 'dms/dms-settings',
      pathMatch: 'full'
    },
    {
      path: 'dms',
      component: ProfileSettingsNavigationContainerComponent,
      canActivate: [AuthGuard],
      children: [
        { path: 'dms-settings', component: FoxdoxStatusContainerComponent, canActivate: [AuthGuard] },
      ]
    },
    {
      path: 'user',
      redirectTo: 'user/profile',
      pathMatch: 'full'
    },
    {
      path: 'user',
      component: ProfileSettingsNavigationContainerComponent,
      canActivate: [AuthGuard],
      children: [
        { path: 'profile', component: ProfileContainerComponent, canActivate: [AuthGuard] },
      ]
    },
    {
      path: 'mobile',
      redirectTo: 'mobile/mobile-login',
      pathMatch: 'full'
    },
    {
      path: 'mobile',
      component: ProfileSettingsNavigationContainerComponent,
      canActivate: [AuthGuard],
      children: [
        { path: 'mobile-login', component: MobileLoginContainerComponent, canActivate: [AuthGuard] },
      ]
    },
    {
      path: 'signature',
      redirectTo: 'signature/account',
      pathMatch: 'full'
    },
    {
      path: 'signature',
      component: ProfileSettingsNavigationContainerComponent,
      canActivate: [AuthGuard],
      children: [
        { path: 'account', component: DocumentSignatureAccountSettingsComponent, canActivate: [AuthGuard] },
        { path: 'defaults', component: DocumentSignatureDefaultSignatureSettingsComponent, canActivate: [AuthGuard] },
        { path: 'fp-sign-settings', component: DocumentSignatureFpSignAccountComponent, canActivate: [AuthGuard] },
      ]
    },
    {
      path: 'privacy',
      redirectTo: 'privacy/data-privacy',
      pathMatch: 'full'
    },
    {
      path: 'privacy',
      component: ProfileSettingsNavigationContainerComponent,
      canActivate: [AuthGuard],
      children: [
        { path: 'data-privacy', component: DataPrivacyContainerComponent, canActivate: [AuthGuard] },
        { path: 'terms', component: UsageTermsContainerComponent, canActivate: [AuthGuard] },
      ]
    }
  ]
}];

export const userProfileRoutes = RouterModule.forChild(routes);
