<button mat-icon-button
        cdk-overlay-origin
        (click)="showDropdown = !showDropdown"
        (mouseenter)="showDropdown = true"
        (mouseleave)="showDropdown = false"
        #dropdown="cdkOverlayOrigin"
        [matTooltip]="!title ? title : ''"
        [matTooltipPosition]="'above'"
        class="fivef-message-panel-info-dropdown--btn"
        [disableRipple]='true'>
  <div class="fivef-message-panel-info-dropdown--icon-container">
    <mat-icon *ngIf="icon && icon === 'supervised_user_circle'" svgIcon="supervised_user_circle"></mat-icon>
    <mat-icon *ngIf="icon && icon !== 'supervised_user_circle'">{{ icon }}</mat-icon>
    <ng-container *ngIf="title && showTitle">
    <span class="text fivef-message-panel-info-dropdown--icon-title">
      {{ title }}
    </span>
    </ng-container>
  </div>

</button>
<ng-template cdk-connected-overlay
             [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-auto-size-backdrop'"
             [cdkConnectedOverlayOrigin]="dropdown"
             [cdkConnectedOverlayHasBackdrop]="true"
             [cdkConnectedOverlayOpen]="showDropdown"
             (backdropClick)="showDropdown = false">
  <div style="background-color: white;" class="mat-elevation-z2 px-3">
    <ul style="margin: 0;">
      <li class="my-1" *ngFor="let person of (_persons | async)">
        <dvtx-avatar [size]="'xs'" [email]="person?.email" [showTitle]='showAvatarTitle'></dvtx-avatar>
      </li>
    </ul>
  </div>
</ng-template>
