import {of as observableOf, throwError as observableThrowError} from 'rxjs';
import {catchError, delay, first, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {RegistrationUser} from "./fivef-session-registration.component";
import {ApiErrorBuilder} from "../../../../shared/modules/api/builders/api-error.builder";

export const PASSWORD_PATTERN = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$';
export const PASSWORD_REGEXP = new RegExp(PASSWORD_PATTERN);

@Injectable()
export class FivefSessionRegistrationService {
  basePath;
  readonly PASSWORD_PATTERN = PASSWORD_PATTERN
  readonly passwordPatternExpr = PASSWORD_REGEXP;

  constructor(private _http: HttpClient) {
    this.basePath = `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}`;
  }

  public verifyEmail(email: String) {
    if (email && email.length > 0) {
      const payload = {data: {attributes: {email: email}}};
      const apiResponse = {success: true, message: ''};
      const apiFailure = {success: false, message: 'REGISTRATION.EMAIL_EXISTS'};
      return this._http.post(`${this.basePath}/auth/verify_email`, payload).pipe(
        map((_res: any) => {
          return apiResponse;
        }),
        catchError((_error: any) => {
          if (_error.status === 200) {
            // return obs that completes;
            return observableOf({success: true, message: apiResponse});		// re-throw
          } else {
            // this.log.debug('re-throwing ');
            return observableOf(apiFailure);		// re-throw
          }
          // return Observable.of(apiFailure);
        }),);
    }

  }

  // √
  public sendTermsAsEmail(email: String) {
    const payload = {data: {attributes: {email: email}}};
    const apiResponse = {success: true, message: `Eine Email wurde an ${email} versandt.`};
    const apiFailure = {
      success: false,
      message: 'Beim Versand ist ein Fehler aufgetreten. Ist die Email-Adresse korrekt?'
    };
    return this._http.post(`${this.basePath}/auth/accept_terms`, payload).pipe(
      map((_res: any) => {
        return apiResponse;
      }),
      catchError((_error: any) => {
        if (_error.status === 200) {
          // return obs that completes;
          return observableOf({success: true, message: apiResponse});		// re-throw
        } else {
          // this.log.debug('re-throwing ');
          return observableOf(apiFailure);		// re-throw
        }
        // return Observable.of(apiFailure);
      }),);
  }

  public verifyToken(token: string) {
    const apiFailure = {success: false, user: null, message: 'PASSWORD.CONFIRMATION_TOKEN_INVALID'};
    return this._http.get(`${this.basePath}/auth/verify_confirmation_token/${token}`).pipe(
      map((res: any) => {
        const response = res.data.attributes;
        const user: RegistrationUser = {
          email: response.email,
          firstName: response.first_name,
          lastName: response.last_name,
          invited: !!response.invited_from,
          terms: !!response.terms_accepted_at,
          password: null,
          passwordConfirm: null
        };
        return {
          success: true,
          user: user,
          message: ''
        };
      }),
      catchError((_error: any) => observableOf(apiFailure)),);
  }

  /**
   * Dependency: Used also by Auth module.
   *
   * @param password
   */
  public verifyPassword(password: string) {
    const apiResponse = FivefSessionRegistrationService.verifyPasswordStrength(password);
    return observableOf(apiResponse).pipe(delay(100));
  }

  // TODO: This must be reworked. The response does not make sense in any way.
  // Refactored from verifyPassword.
  // Why success always true, but returning null else???
  // Affected: Registration form, password reset (also at employees).
  public static verifyPasswordStrength(password) {
    const regexp = PASSWORD_REGEXP;
    let result = null;
    if (new RegExp(/(?=.*?\s)/).test(password)) {
      result = {
        success: true,
        level: 'error',
        message: 'PASSWORD.PASSWORD_CONTAINS_WHITESPACE'
      };
    } else if (password && password.length > 7 && regexp.test(password)) {
      result = {
        success: true,
        level: 'success',
        message: 'PASSWORD.PASSWORD_OK'
      };
    } else if (password && password.length < 8) {
      result = {
        success: true,
        level: 'error',
        message: 'PASSWORD.PASSWORD_TOO_SHORT'
      };
    } else if (password && password.length > 1) {
      result = {
        success: true,
        level: 'warning',
        message: 'PASSWORD.PASSWORD_IS_WEAK'
      };
    }
    return result;
  }

  public update(user: RegistrationUser) {
    const payload = {
      data: {
        attributes: {
          email: user.email,
          natural_person_attributes: {
            first_name: user.firstName,
            last_name: user.lastName
          },
          terms_accepted: user.terms,
          password: user.password,
          password_confirmation: user.passwordConfirm
        }
      }
    };
    return this._http.put(`${this.basePath}/auth/complete_registration/${user.confirmationToken}`, payload)
      .pipe(
        first(),
        map((_res: any) => _res),
        catchError((_error: HttpErrorResponse) => {
          const error = JSON.parse(_error.error);
          return observableOf({
            success: false,
            message: error.errors[0].details
          })
        })
      );
  }

  public completeRegistration(user: RegistrationUser) {
    const payload = {
      data: {
        attributes: {
          email: user.email,
          confirmation_token: user.confirmationToken,
          natural_person_attributes: {
            first_name: user.firstName,
            last_name: user.lastName,
            gender: user.gender
          },
        }
      }
    };

    const apiResponse = {success: true, message: ''};
    return this._http.post(`${this.basePath}/auth/complete_registration`, payload)
      .pipe(map((_res: any) => apiResponse))
  }

  public async saveAccountData(user: RegistrationUser) {
    const payload = {
      data: {
        attributes: {
          email: user.email,
          confirmation_token: user.confirmationToken,
          natural_person_attributes: {
            first_name: user.firstName,
            last_name: user.lastName,
            gender: user.gender
          },
        }
      }
    };

    const apiResponse = {success: true, message: ''};
    return this._http.post(`${this.basePath}/auth/complete_registration`, payload).pipe(
      map((_res: any) => apiResponse),
      catchError((_error: HttpErrorResponse) => {
        const error = JSON.parse(_error.error);
        return observableOf({
          success: false,
          message: error.errors[0].details
        });
      }),);
  }

  private handleError(error: HttpErrorResponse) {
    console.log('RegistrationService#handleError', error);
    const errors = ApiErrorBuilder.fromResponse(error);
    return observableThrowError(errors);
  }
}
