import * as cmsModel from 'app/+store/cms-slideshow/cms-slideshow'
import {IResource} from '../../../../shared/modules/api/models/resource.interface';
import {FivefLoginTheme} from '../../../../lib/fivef-ui/theme/fivef-login-theme/fivef-login-theme.interface';

/**
 * 5F login configuration.
 *
 * Example response
 *
 * {
 *   "data": {
 *     "id": "test.5fsoftware.de",
 *     "type": "auth_login_settings",
 *     "attributes": {
 *       "impressum_url": "https://test.5fsoftware.de/impressum",
 *       "login_header": "Hello World!",
 *       "login_footer": "User notice",
 *       "login_header_en": null,
 *       "login_footer_en": null,
 *       "color_theme": "light_theme",
 *       "color_theme_config": {
 *
 *       },
 *       "login_theme": "fivef_blue",
 *       "login_background_image_url": "https://my-background-image-url.com",
 *       "login_slideshow": {
 *         "data": [
 *           {
 *             "id": "95567106-24b5-4c02-add6-ab28b55f1fbd",
 *             "type": "cms_slideshow_slide",
 *             "attributes": {
 *               "title": "My 1st slide",
 *               "alt": null,
 *               "profile": "login_theme",
 *               "language": "de_de",
 *               "order": 0,
 *               "background_image_url": "https://my-images.com/bg.png",
 *               "background_image_filename": "https://my-images.com/bg.png",
 *               "content": null,
 *               "content_image_url": "fg.png",
 *               "content_image_filename": "test.png",
 *               "content_image_width": 300,
 *               "content_image_height": 600,
 *               "content_image_map_areas": [
 *                 {
 *                   "alt": "My inner link",
 *                   "href": "https://example.com",
 *                   "shape": "rect",
 *                   "coords": "34,44,270,350"
 *                 },
 *                 {
 *                   "alt": "My 2nd inner link",
 *                   "href": "https://example.com",
 *                   "shape": "circle",
 *                   "coords": "337,300,44"
 *                 }
 *               ],
 *               "created_at": "2024-08-09T11:23:22.080+02:00",
 *               "updated_at": "2024-08-09T11:23:22.080+02:00"
 *             }
 *           },
 *           {
 *             "id": "24525fe9-8ef4-4422-a6df-41babd00ad02",
 *             "type": "cms_slideshow_slide",
 *             "attributes": {
 *               "title": "My 2nd slide",
 *               "alt": null,
 *               "profile": "login_theme",
 *               "language": "de_de",
 *               "order": 0,
 *               "background_image_url": "https://my-images.com/bg_2.png",
 *               "background_image_filename": "bg_2.png",
 *               "content": "<h2>My content<h/2><p>Some text</p>",
 *               "content_image_url": null,
 *               "content_image_filename": null,
 *               "content_image_width": null,
 *               "content_image_height": null,
 *               "content_image_map_areas": [
 *
 *               ],
 *               "created_at": "2024-08-09T11:23:22.105+02:00",
 *               "updated_at": "2024-08-09T11:23:22.105+02:00"
 *             }
 *           }
 *         ]
 *       }
 *     }
 *   }
 * }
 */
export class FivefLoginSettings implements IResource {
  readonly type = 'auth_login_settings';

  constructor(public id: string,
              public imprintUrl: string,
              public colorTheme: string,
              public colorThemeConfig: any,
              public loginTheme: FivefLoginTheme,
              public loginBgColor: string,
              public loginFgColor: string,
              public loginHeader: string,
              public loginFooter: string,
              public loginHeaderEN: string,
              public loginFooterEN: string,
              public loginBackgroundImageUrl: string,
              public loginSecondaryLogoUrl: string,
              public loginSlideshow: cmsModel.Cms.Slide[] = []) {
  }
}
