import {catchError, first, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadCurrent,
  LoadCurrentFail,
  LoadCurrentSuccess,
  OrganizationLogoActionTypes,
  Publish,
  PublishFail,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './organization-logo.actions';
import {OrganizationLogoService} from 'app/+store/organization-logo/organization-logo.service';
import {OrganizationLogo} from 'app/+store/organization-logo/organization-logo';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {Store} from '@ngrx/store';

@Injectable()
export class OrganizationLogoEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(OrganizationLogoActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        map((res: OrganizationLogo[]) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => of(new LoadAllFail(err))));
    })
  ));

  loadCurrent$ = createEffect(() => this.actions.pipe(
    ofType(OrganizationLogoActionTypes.LoadCurrent),
    switchMap((action: LoadCurrent) => {
      return this._svc.getCurrent(action.organization).pipe(
        map((res: OrganizationLogo) => {
            return new LoadCurrentSuccess(res);
          }
        ),
        catchError(err => of(new LoadCurrentFail(err))));
    })
  ));

  publish$ = createEffect(() => this.actions.pipe(
    ofType(OrganizationLogoActionTypes.Publish),
    switchMap((action: Publish) => {
      return this._svc.update(action.id, action.checked).pipe(first()).pipe(
        switchMap((_res: OrganizationLogo) => {
          this._notifyService.success('CUSTOMIZATION.UPDATE_LOGO_SUCCESS');
          return [new LoadAll(), new LoadCurrent()];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.UPDATE_LOGO_FAILURE');
          return of(new PublishFail(err))
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(OrganizationLogoActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.logo).pipe(first()).pipe(
        switchMap((res: OrganizationLogo) => {
            this._notifyService.success('CUSTOMIZATION.REMOVE_LOGO_SUCCESS')
          return [new RemoveSuccess(res), new LoadCurrent()];
        }),
        catchError(err => {
          this._notifyService.error('CUSTOMIZATION.REMOVE_LOGO_FAILURE');
          return of(new RemoveFail(err))
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: OrganizationLogoService,
              private store: Store<any>,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




