import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExternalAccessDispatcherComponent} from './containers/external-access-dispatcher/external-access-dispatcher.component';
import {SubmitSuccessComponent} from './components/submit-success/submit-success.component';
import {AccessTokenNotFoundComponent} from './components/access-token-not-found/access-token-not-found.component';
import {SharedModule} from 'app/shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {
  SubmissionRecipientComponent
} from './containers/audit-submission-of-confirmation-receipt/audit-submission-of-confirmation-receipt.component';
import {SubmissionRecipientFormComponent} from './components/submission-recipient-form/submission-recipient-form.component';
import {ExternalAccessService} from 'app/shared/modules/api/services/external-access.service';
import {InfoTextComponent} from './components/info-text/info-text.component';
import {InfoComponent} from './containers/info/info.component';
import {QuickshareComponent} from './components/quickshare/quickshare.component';
import {QuickshareContainerComponent} from './containers/quickshare-container/quickshare-container.component';
import {IsAuthenticatedDialogComponent} from './components/is-authenticated-dialog/is-authenticated-dialog.component';
import {QuickshareService} from './services/quickshare.service';
import {PasswordDialogComponent} from './containers/password-dialog/password-dialog.component';
import {InfoBlockModule} from '../../five-f/info-block/info-block.module';
import {FivefUploadComponent} from '../../lib/fivef-ui/upload/fivef-upload/fivef-upload.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        InfoBlockModule,
        FivefUploadComponent,
    ],
    declarations: [
        ExternalAccessDispatcherComponent,
        QuickshareComponent,
        QuickshareContainerComponent,
        SubmitSuccessComponent,
        AccessTokenNotFoundComponent,
        SubmissionRecipientComponent,
        SubmissionRecipientFormComponent,
        InfoTextComponent,
        InfoComponent,
        IsAuthenticatedDialogComponent,
        PasswordDialogComponent
    ],
    providers: [
        ExternalAccessService,
        QuickshareService
    ]
})
export class ExternalAccessModule {
}
