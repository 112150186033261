<div class="fivef-filter-listing--header">
  {{title}}
  <span
    *ngIf="selectedOptions?.length"
    class="d-inline-flex align-items-center justify-items-center"
    [matBadge]="selectedOptions?.length"
    matBadgeOverlap="false"
    color="primary">
  </span>
</div>

<div class="fivef-filter-listing--container">
  <ng-container *ngFor="let option of filterdOptions$ | async">
      <button
      *ngIf="option?.id && option?.name"
       [matTooltip]="((option?.name | translate)?.length > 22) ? (option.name | translate) : null"
        type="button"
      class="fivef-filter-listing--item"
        [class.active]="isSelected(option)"
        (click)="changeSelection(option)">
        <span class="text-truncate">{{ option.name }}</span>
      </button>
  </ng-container>
</div>
