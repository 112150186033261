<fivef-context-sidebar *ngVar="(client$ | async) as client"
                       [nodeId]="client?.id"
                       [title]="client?.name"
                       [subtitle]="client?.clientId">
  <mat-tab-group mat-stretch-tabs
                 fivefContextSidebarContent
                 disablePagination
                 [selectedIndex]="selectedTab"
                 (selectedIndexChange)="selectedTabChanged($event)">
    <mat-tab [label]="'SETTINGS.SETTINGS' | translate">
      <ng-template matTabContent>
        <fivef-create-client *ngIf="isClient"
                             [position]="'start'"
                             [readonly]="!client?.canEditClient"
                             (onEditSuccess)="editSuccess($event)"
                             [action]="'Form'"
                             [selectedClient]="client"
                             (onFormUpdate)="updateActionButtons($event)"
                             #clientSelectionComponent></fivef-create-client>

        <dvtx-edit-contact-person-dialog
          *ngIf="contact && isContact && contact?.type === contactListDtoType.naturalPersonContact"
          [contactId]="contact?.id"
          [createdBy]="contact?.createdBy"
          (onSave)="closeSidebar($event)"></dvtx-edit-contact-person-dialog>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template matTabLabel>
        <span *ngIf="isContact">{{ 'CONTACTS.ASSOCIATED_CLIENTS' | translate }}</span>
        <span *ngIf="isClient">{{ 'CONTACTS.ASSOCIATED_CONTACTS' | translate }}</span>
      </ng-template>
      <ng-template matTabContent>
        <dvtx-participant-client-link [selectedClient]="client" [selectedContact]="contact" [isContact]="isContact"
                                      [isClient]="isClient"
                                      (updateMapping)="updateMapping($event)"></dvtx-participant-client-link>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="showFibu && isClient"
             [label]="'FIBU.CLIENT_SETTINGS' | translate">
      <ng-template matTabContent>
        <dvtx-client-bookman (onSave)="updateBookmanSettings($event)" [id]="client?.id"></dvtx-client-bookman>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="(featureSet$ | async)?.hasDatev && isClient"
             [label]="'DATEV.AUTHORIZE_CLIENT_TITLE' | translate">
      <ng-template matTabContent>
        <dvtx-client-datev-login *ngIf="client$ | async" [client]="client$ | async"></dvtx-client-datev-login>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="showMobileActivation() | async"
             [label]="'ADDRESSBOOK.MOBILE_ACTIVATION.TITLE' | translate">
      <ng-template matTabContent>
        <dvtx-mobile-activation-panel [userEmail]="contact?.email"></dvtx-mobile-activation-panel>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div fivef-context-sidebar-footer>
    <button *ngIf="isClient && selectedTab === 0" mat-raised-button
            color="primary"
            [disabled]="form?.pristine || form?.invalid"
            (click)="saveClient()">
      <mat-icon matPrefix>save</mat-icon>
      {{ 'GENERAL.SAVE_ACTION' | translate }}
    </button>

    <button *ngIf="!(isContact && selectedTab === 0)" type="button" mat-button mat-dialog-close>
      {{ 'GENERAL.CLOSE_ACTION' | translate }}
    </button>
  </div>
</fivef-context-sidebar>
