import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as environment from 'environments/environment';

@Component({
  selector: '' +
    'dvtx-page-header-logo',
  templateUrl: './page-header-logo.component.html',
  styleUrls: ['./page-header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderLogoComponent implements OnInit {
  @Input()
  public homeLink: string = '/session/sign-in';

  @Input()
  public logo: string = null;

  private OBS_BASE_PATH = 'https://obs-prod-01.obs.eu-de.otc.t-systems.com/tenant/logo';
  private OBS_BASE_PATH_STAGING = 'https://obs-staging-rw.obs.eu-de.otc.t-systems.com/tenant/logo';

  public defaultLogoUrl: string;

  constructor() {
    const isStagingOrDev = environment.environment.envName && environment.environment.envName === 'staging'
    this.defaultLogoUrl = `${isStagingOrDev ? this.OBS_BASE_PATH_STAGING : this.OBS_BASE_PATH}/${window.location.hostname}.png`;
  }

  ngOnInit() {
  }
}
