import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DmsDocument} from 'app/+store/dms-document/dms-document';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FivefFileSizePipe} from '../../util/fivef-file-size.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FivefArtifactPreviewComponent} from '../fivef-artifact-preview/fivef-artifact-preview.component';

@Component({
  selector: 'fivef-artifact-details',
  standalone: true,
  imports: [CommonModule, TranslateModule, FivefFileSizePipe, MatTooltipModule, FivefArtifactPreviewComponent],
  templateUrl: './fivef-artifact-details.component.html',
  styleUrls: ['./fivef-artifact-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefArtifactDetailsComponent {
  @Input()
  document: DmsDocument;
}
