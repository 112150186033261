import {NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {InViewportModule} from 'ng-in-viewport';
import {ListingTasksComponent} from './container/listing-tasks/listing-tasks.component';
import {TaskTypeIconComponent} from './components/task-type-icon/task-type-icon.component';
import {ListingTasksService} from './services/listing-tasks.service';
import {TaskIndicatorsComponent} from './components/task-indicators/task-indicators.component';
import {TaskActionsService} from './services/task-actions.service';
import {TooltipModule} from 'app/five-f/tooltip/tooltip.module';
import {TaskActionsComponent} from './components/task-actions/task-actions.component';
import {LabelsModule} from 'app/five-f/labels/labels.module';
import {FivefStatusSelectorComponent} from '../../../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.component';
import {FivefLabelsBarComponent} from '../../../../lib/fivef-ui/label/fivef-labels-bar/fivef-labels-bar.component';
import {FivefItemSelectorComponent} from '../../../../lib/fivef-ui/input/fivef-item-selector/fivef-item-selector.component';
import {FivefDatepickerComponent} from '../../../../lib/fivef-ui/input/fivef-datepicker/fivef-datepicker.component';
import {FivefDisableInputHintsWrapperDirective} from '../../../../lib/fivef-ui/input/fivef-disable-input-hints-wrapper.directive';
import {FivefInputCeComponent} from '../../../../lib/fivef-ui/input/fivef-input-ce/fivef-input-ce.component';

@NgModule({
  imports: [
    SharedModule,
    InViewportModule,
    TooltipModule,
    LabelsModule,
    FivefStatusSelectorComponent,
    FivefLabelsBarComponent,
    FivefItemSelectorComponent,
    FivefDatepickerComponent,
    FivefDisableInputHintsWrapperDirective,
    FivefInputCeComponent
  ],
  declarations: [
    ListingTasksComponent,
    TaskTypeIconComponent,
    TaskIndicatorsComponent,
    TaskActionsComponent
  ],
  exports: [
    ListingTasksComponent
  ],
  providers: [
    ListingTasksService,
    TaskActionsService
  ]
})
export class ListingTasksModule {
}
