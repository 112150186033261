<div class="button-row mb-2" *ngIf="(organization$ | async) as o">
  <div *ngIf="!workflowBackNavigation">
    <ng-template [ngIf]="toOverview" [ngIfElse]="backLink">
      <a mat-button [routerLink]="'/organization/' + o?.id + '/navigation'">
        <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
        {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
      </a>
    </ng-template>

    <ng-template #backLink>
      <a mat-button *ngIf="!Administration" (click)="back()">
        <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
        {{ 'GENERAL.BACK_ACTION' | translate }}
      </a>
      <a mat-button *ngIf="Administration" [routerLink]="'/5fadmin/overview'" >
        <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
        {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
      </a>
    </ng-template>
  </div>
  <div *ngIf="workflowBackNavigation" class="button-row mb-2">
    <dvtx-backlink [title]="'GENERAL.BACK_ACTION' | translate"></dvtx-backlink>
    <!-- <button mat-button (click)="navigateToOverview()">
      <mat-icon matPrefix aria-label="back">arrow_back</mat-icon>
      <ng-container [ngSwitch]="_process?.processType">

        <ng-container *ngSwitchCase="'project'">
          {{ 'PROJECT_ROOM.TO_PROJECT_ROOM' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'quickcollector'">
          {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'cac'">
          {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'cac_request'">
          {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'quickstart'">
          {{ 'PROJECT_ROOM.TO_PROJECT_ROOM' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'quickshare_v2'">
          {{ 'PROJECT_ROOM.TO_PROJECT_ROOM' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'claim_balance'">
          {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="'liability_balance'">
          {{ 'GENERAL.NAV_TO_OVERVIEW' | translate }}
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ 'PROJECT_ROOM.TO_PROJECT_ROOM' | translate }}
        </ng-container>
      </ng-container>
    </button> -->
  </div>
</div>

<div class="w-100 pl-3 mb-1 d-flex justify-content-between"
     [ngClass]="Administration ? 'borderLeftViolet' : ''">
  <div>
    <h1>{{ title | translate }}</h1>
    <h5 *ngIf="subtitle">{{ subtitle }}</h5>
    <h5 *ngIf="Administration">Administration</h5>
    <h5 *ngIf="!Administration && !showProjectName" class="mt-0 d-flex align-items-center">
      <span style="font-size: 12px;"><mat-icon class="mr-1">domain</mat-icon></span>
      {{ (organization$ | async)?.name }}
      <ng-container *ngIf="(organization$ | async)?.legalFormId | legalFormNameById | async as legalFormName">
        <ng-container *ngIf="legalFormName !== 'Sonstige' ">
          {{ legalFormName.indexOf('(') > -1 ? legalFormName : '(' + legalFormName + ')' }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="_process">
        &nbsp;・&nbsp;
        <span class="mr-1" style="font-size: 12px;">
          <ng-template [ngIf]="_iconConfig?.isSvgIcon" [ngIfElse]="defaultIconType">
            <mat-icon [svgIcon]="_iconConfig?.icon"></mat-icon>
          </ng-template>
          <ng-template #defaultIconType>
            <mat-icon>{{ _iconConfig?.icon }}</mat-icon>
          </ng-template>
        </span>
          {{ _process?.title }}
      </ng-container>
    </h5>
    <h5 *ngIf="showProjectName" class="my-0 dvtx-fg-color dvtx-color-fivef-blue-400 d-flex align-items-center">
      <mat-icon *ngIf="_process?.icon && (!_process?.isSvgIcon)" class="mr-1">{{_process?.icon}}
      </mat-icon>
      <mat-icon *ngIf="_process?.icon && (_process?.isSvgIcon)" [svgIcon]="_process?.icon" class="mr-1"></mat-icon>
      &nbsp;{{_process?.title}}
    </h5>
  </div>

  <div *ngIf="callToActionPortal">
    <ng-template [cdkPortalOutlet]="callToActionPortal"></ng-template>
  </div>
</div>

<div class="w-100 d-flex justify-content-between align-items-center">
  <dvtx-organization-card-nav-bar-light [routes]="routes" [activeLink]="activeLink"></dvtx-organization-card-nav-bar-light>

  <!-- Right aligned optional search -->
  <div class="fivef-toolbar fivef-toolbar--end">
    <mat-form-field class="fivef-toolbar--search"
                    *ngIf="enableSearch"
                    (keydown.enter)="$event.preventDefault()">
      <input type="text" [placeholder]="'GENERAL.SEARCH' | translate"
             [(ngModel)]="searchTerm"
             (keyup)="applySearch(searchFieldRef.value)" matInput #searchFieldRef>
      <mat-icon matPrefix>search</mat-icon>

      <button *ngIf="searchFieldRef?.value"
              matSuffix
              mat-icon-button
              [disableRipple]="true"
              aria-label="Clear"
              (click)="applySearch(''); searchFieldRef.value = ''; $event.stopPropagation()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Right aligned action portal e.g. for three dots menu -->
    <div *ngIf="globalActionPortal">
      <ng-template [cdkPortalOutlet]="globalActionPortal"></ng-template>
    </div>
  </div>
</div>
