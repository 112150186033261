import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {DvtxControlValueAccessor} from '../DvtxControlValueAccessor';
import {SimpleEmailAddress} from '../../../../../../+store/contact/legacy/models/contact.interface';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'dvtx-email-address-input',
  templateUrl: './email-address-input.component.html',
  styleUrls: ['./email-address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailAddressInputComponent),
      multi: true,
    }
  ]
})
export class EmailAddressInputComponent extends DvtxControlValueAccessor implements OnInit {
  @Input()
  innerValue: SimpleEmailAddress = new SimpleEmailAddress();

  @Input() requiredField: boolean;
  @Input() showEmailType: boolean = true;
  @Input() label: string;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {
  }

  writeValue(obj: SimpleEmailAddress): void {
    if (obj) {
      this.innerValue = obj;
    }
  }

  notifyChange() {
    this.notifyOnTouch();
    this.notifyOnChange(this.innerValue);
  }
}
