import {Component, OnDestroy} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Invitation, InvitationStatus, InvitationType} from 'app/+store/invitation/invitation.model';
import {LicenceControlService} from 'app/services/licence-control.service';
import {contactListDtoType} from '../../../../models/contact-list-dto.model';
import {first, map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {
  ContactActions,
  InvitationSelectors,
  MembershipActions,
  MembershipSelectors,
  NaturalPersonSelectors,
  OrganizationSelectors
} from 'app/+store';
import {CreateInvitation, Destroy, LoadOfOrganization} from 'app/+store/invitation/invitation.actions';
import {FivefConfirm} from '../../../../lib/fivef-ui/util/fivef-confirm-dialog/fivef-confirm.decorator';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

@Component({
  selector: 'dvtx-license-assignment',
  templateUrl: './license-assignment.component.html',
  styleUrls: ['./license-assignment.component.scss']
})
export class LicenseAssignmentComponent implements OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  filterType$ = new BehaviorSubject<InvitationType>(undefined);
  public usedUserLicences = [];
  public currentUser = {
    hasRequiredLicence: false,
    hasAdministrationRights: false,
    isOrganizationalOwner: false,
    currentLicence: null,
    attributes: {},
    licencedOrganizations: []
  }

  public columnsToDisplay = ['avatar', 'email', 'createdAt', 'status', 'actions'];

  public dataSource: MatTableDataSource<any | undefined>;
  public readonly InvitationStatus = InvitationStatus;
  public readonly InvitationType = InvitationType;
  organizationId;

  contactListDtoType = contactListDtoType;

  loading: boolean = true;

  invitations = [];
  members = [];

  inviteeEmail: string = '';

  constructor(private store: Store<AppState>,
              private licenceControl: LicenceControlService) {
    this.filterType$.next(InvitationType.Member);
    this.currentUser = this.licenceControl._currentUser;

    this.store.select(OrganizationSelectors.getSelected)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((org) => {
        if (org) {
          this.organizationId = org.id;
          this.store.dispatch(new ContactActions.LoadAll(org));
          this.refresh();
        }
      });

    combineLatest(store.select(InvitationSelectors.selectOrgasInvitations), this.filterType$)
      .pipe(
        map(([invitations, filterType]: [Invitation[], InvitationType]) => {
          return invitations.filter((invitation) => invitation.type === filterType && invitation.current_status === InvitationStatus.Pending);
        }),
        takeUntil(this.onDestroy)
      ).subscribe((invitations) => {
      this.invitations = [];
      invitations.map(invitation => {
        this.invitations.push({
          id: invitation.id,
          email: invitation.invitee_email,
          created_at: invitation.created_at,
          status: invitation.current_status
        });
      });
      this.dataSource = new MatTableDataSource(this.members.concat(this.invitations));
    });

    store.select(NaturalPersonSelectors.getNaturalPersonsOfSelectedOrganization)
      .pipe(
        takeUntil(this.onDestroy)
      ).subscribe(persons => {
      this.members = [];
      persons.map(person => {
        this.store.select(MembershipSelectors.getMembershipOfSelectedOrgByNatPersonId(person.id))
          .pipe(first()).subscribe((membershipItem) => {
          const isMemeberOfLicence = this.currentUser.currentLicence && this.currentUser.currentLicence.attributes.licencedMemberships.indexOf(membershipItem.id) > -1;
          const isMe = this.currentUser.attributes && this.currentUser.attributes['uid'] === person.mainEmailAddress.emailAddress;
          this.members.push({
            id: person.id,
            email: person.mainEmailAddress.emailAddress,
            created_at: null,
            status: isMemeberOfLicence || isMe ? null : 'Pending'
          });
        });
      });
      this.dataSource = new MatTableDataSource(this.members.concat(this.invitations));
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.filterType$.complete();
  }

  refresh() {
    if (this.organizationId) {
      this.store.dispatch(new MembershipActions.LoadAll(this.organizationId));
      this.store.dispatch(new LoadOfOrganization(this.organizationId));
    }
  }

  sendMemberInvitation() {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      if (this.inviteeEmail !== '') {
        this.store.dispatch(new CreateInvitation({orgaId: orgaId, inviteeEmail: this.inviteeEmail}));
        this.inviteeEmail = '';
        this.refresh();
      }
    });
  }

  @FivefConfirm({
    message: 'INVITATIONS.DELETE_INVITATION_CONFIRMATION',
    icon: 'warning',
    color: 'warn',
    confirmAction: 'INVITATIONS.DELETE_INVITATION_CONFIRMATION_TITLE'
  })
  public cancelInvitationDialog(member) {
    this.cancelMemberInvitation(member);
  }

  private cancelMemberInvitation(member) {
    this.store.select(OrganizationSelectors.getSelectedId).pipe(
      first()
    ).subscribe((orgaId: string) => {
      this.store.dispatch(new Destroy(member.id, InvitationType.Member, orgaId));
      this.currentUser = this.licenceControl._currentUser;
    });
  }
}
