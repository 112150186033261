import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  Edit,
  EditFail,
  EditSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadDefault,
  LoadDefaultFail,
  LoadDefaultSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  TaskStatusSchemeActionTypes
} from './task-status-scheme.actions';
import {TaskStatusSchemeService} from './task-status-scheme.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TaskStatusScheme} from './task-status-scheme';

@Injectable()
export class TaskStatusSchemeEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusSchemeActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        first(),
        concatMap((res: TaskStatusScheme[]) => {
          return [new LoadAllSuccess(res, res, action.reset)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_SCHEMES_ERROR')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusSchemeActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.schemeId).pipe(
        first(),
        concatMap((res: TaskStatusScheme) => {
          return [new LoadOneSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_SCHEME_ERROR')
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadDefault$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusSchemeActionTypes.LoadDefault),
    switchMap((action: LoadDefault) => {
      return this._svc.getDefault().pipe(
        first(),
        concatMap((res: TaskStatusScheme) => {
          return [new LoadDefaultSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.LOAD_SCHEME_ERROR')
          return of(new LoadDefaultFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusSchemeActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.scheme).pipe(
        first(),
        concatMap((res: TaskStatusScheme) => {
          return [new CreateSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.CREATE_SCHEME_ERROR')
          return of(new CreateFail(err));
        }));
    })
  ));

  edit$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusSchemeActionTypes.Edit),
    switchMap((action: Edit) => {
      return this._svc.update(action.scheme).pipe(
        first(),
        concatMap((res: TaskStatusScheme) => {
          return [new EditSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.UPDATING_SCHEME_ERROR')
          return of(new EditFail(err));
        }));
    })
  ));

  delete$ = createEffect(() => this.actions.pipe(
    ofType(TaskStatusSchemeActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.remove(action.schemeId).pipe(
        first(),
        switchMap((res: TaskStatusScheme) => {
          this._notifyService.success('TASK.DELETED_SCHEME_SUCCESSFULLY');
          return [new DeleteSuccess(res)];
        }),
        catchError(err => {
          this._notifyService.error('TASK.ERRORS.DELETED_SCHEME_FAILURE');
          return of(new DeleteFail(err))
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TaskStatusSchemeService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
