import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Refresh,
  RefreshFail,
  RefreshSuccess,
  Rename,
  RenameFail,
  RenameSuccess,
  SlimFolderActionTypes
} from './slim-folder.actions';
import {SlimFolder} from './slim-folder';
import {SlimFolderService} from './slim-folder.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class SlimFolderEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(action.dmsAccountType).pipe(
        first(),
        concatMap((folders: SlimFolder[]) => {
          return [new LoadAllSuccess(folders)];
        }),
        catchError(err => {
          this._notifyService.error('DMS.LOADING_FOLDERS_FAILED')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadAllOrganization$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.LoadAllOrganization),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(action.dmsAccountType).pipe(
        first(),
        concatMap((folders: SlimFolder[]) => {
          return [new LoadAllSuccess(folders)];
        }),
        catchError(err => {
          this._notifyService.error('DMS.LOADING_FOLDERS_FAILED')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  refresh$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.Refresh),
    concatMap((action: Refresh) => {
      return this._svc.getAll(action.dmsAccountType).pipe(
        first(),
        concatMap((folders: SlimFolder[]) => {
          return [new RefreshSuccess(folders)];
        }),
        catchError(err => {
          this._notifyService.error('DMS.LOADING_FOLDERS_FAILED');
          return of(new RefreshFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id, action.accountType).pipe(
        first(),
        concatMap((folder: SlimFolder) => {
          return [new LoadOneSuccess(folder)];
        }),
        catchError(err => {
          this._notifyService.error('DMS.LOADING_FOLDER_FAILED')
          return of(new LoadOneFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.name, action.parent, action.dmsAccountType).pipe(
        first(),
        concatMap((folder: SlimFolder) => {
          if (folder.parentId) {
            return [new LoadOne(action.parent), new CreateSuccess(folder)];
          }
          return [new CreateSuccess(folder)];
        }),
        catchError(error => {
          if (error && error.error && error.error.errors && error.error.errors.length > 0) {
            this._notifyService.error(error.error.errors[0].title);
          }
          return of(new CreateFail(error));
        }));
    })
  ));

  rename$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.Rename),
    switchMap((action: Rename) => {
      return this._svc.rename(action.id, action.name, action.dmsAccountType).pipe(
        first(),
        concatMap((folder: SlimFolder) => {
          return [new RenameSuccess(folder)];
        }),
        catchError(error => {
          if (error && error.error && error.error.errors && error.error.errors.length > 0) {
            this._notifyService.error(error.error.errors[0].title);
          }
          return of(new RenameFail(error));
        }));
    })
  ));

  destroy$ = createEffect(() => this.actions.pipe(
    ofType(SlimFolderActionTypes.Delete),
    switchMap((action: Delete) => {
      return this._svc.delete(action.id, action.dmsAccountType).pipe(
        first(),
        concatMap((folder: SlimFolder) => {
          return [new DeleteSuccess(folder)];
        }),
        catchError(error => {
          if (error && error.error && error.error.errors && error.error.errors.length > 0) {
            this._notifyService.error(error.error.errors[0].title);
          }
          return of(new DeleteFail(error));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: SlimFolderService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




