<div class="five-f-mat-table">
  <table cellpadding="0" cellspacing="0" border="0">
    <thead>
    <tr>
      <th class="five-f-column-avatar">
      </th>

      <th class="five-f-column-email">
        {{ 'INVITATIONS.INVITEES_EMAIL' | translate }}
      </th>

      <th class="five-f-column-invited_by" *dvtxFeatureToggle="false">
        {{ 'GENERAL.INVITED_BY' | translate }}
      </th>

      <th class="five-f-column-created-at">
        {{ 'INVITATIONS.INVITE_DATE' | translate }}
      </th>

      <th class="five-f-column-status">
        Status
      </th>

      <th class="five-f-column-actions">
        <dvtx-filters-container class="ml-4">
          <div class="fivef-filter-dialog--content">
            <div class="fivef-filter-listing">
              <dvtx-filter-list-selection [options]="filterOptions" [selectedOptions]="[]"
                                          (onChangeSelection)="onFilterSelect($event)">
              </dvtx-filter-list-selection>
            </div>
          </div>
        </dvtx-filters-container>
      </th>
    </tr>
    </thead>
  </table>

  <cdk-virtual-scroll-viewport *ngIf="dataSource" [itemSize]="55" autosize>
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
      <tr *cdkVirtualFor="let member of dataSource.data; index as i; templateCacheSize: 0">
        <td class="five-f-column-avatar">
          <dvtx-avatar [size]="'sm'" [email]="member?.invitee_email"></dvtx-avatar>
        </td>

        <td class="five-f-column-email">
          {{ member.invitee_email }}
        </td>

        <td class="five-f-column-invited_by" *dvtxFeatureToggle="false">
          {{ member?.inviter_first_name }} {{ member?.inviter_last_name }}
        </td>

        <td class="five-f-column-created-at">
          {{ member.created_at | date : 'medium' }}
        </td>

        <td class="five-f-column-status">
          <div>
            <ng-container [ngSwitch]="member.current_status">
              <span *ngSwitchCase="InvitationStatus.Accepted">{{ 'INVITATIONS.ACCEPTED' | translate }}</span>
              <span *ngSwitchCase="InvitationStatus.Declined">{{ 'INVITATIONS.DECLINED' | translate }}</span>
              <span *ngSwitchCase="InvitationStatus.Pending">{{ 'INVITATIONS.PENDING' | translate }}</span>
            </ng-container>
          </div>
        </td>

        <!-- Resend Invitation Column -->
        <td class="five-f-column-invitation-actions">
          <button mat-icon-button

                  *ngIf="member.current_status == InvitationStatus.Pending"
                  (click)="openResendDialog(member)"
                  [matTooltip]="'INVITATIONS.RESEND_INVITATION' | translate">
            <mat-icon>cached</mat-icon>
          </button>

          <button mat-icon-button

                  *ngIf="member.current_status == InvitationStatus.Pending"
                  (click)="openDestroyInvitationDialog(member)"
                  [matTooltip]="'INVITATIONS.DELETE_INVITATION_CONFIRMATION_TITLE' | translate">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
