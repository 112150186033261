import {Injectable} from '@angular/core';
import {catchError, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Confirm,
  ConfirmFail,
  ConfirmSuccess,
  FavoriteActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './favorite.actions';
import {FavoriteService} from './favorite.service';
import {Favorite} from './favorite';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {IProcessQuery} from '../process-event/process-event.interface';
import {ProcessActions} from '../process';

@Injectable()
export class FavoriteEffects {
  confirm$ = createEffect(() => this.actions.pipe(
    ofType(FavoriteActionTypes.Confirm),
    switchMap((action: Confirm) => {
      return this._svc.confirm(action.favoriteId).pipe(
        first(),
        switchMap((fav: Favorite) => {
          return [new ConfirmSuccess(fav)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ConfirmFail(err), new LoadAll())
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(FavoriteActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.remove(action.id).pipe(first()).pipe(
        switchMap((res: Favorite) => {
          // TODO: Add translation
          // this._notifyService.success('CUSTOMIZATION.REMOVE_FAVORITE_SUCCESS')
          return [new RemoveSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          // TODO: Add translation
          // this._notifyService.error('CUSTOMIZATION.REMOVE_FAVORITE_FAILURE')
          return of(new RemoveFail(err), new LoadAll())
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(FavoriteActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(first()).pipe(
        switchMap((res: Favorite[]) => {
          const processedIds = res.map(fav => fav.resourceId);
          const params: IProcessQuery = {dense: true, processIds: processedIds, debug: 'FavoriteActionTypes.LoadAll'};
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err))
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(FavoriteActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(first()).pipe(
        switchMap((res: Favorite) => {
          return [new LoadOneSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err))
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: FavoriteService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




