import {NgModule} from '@angular/core';
import {ProcessListingComponent} from './containers/process-listing/process-listing.component';
import {
  FavoriteToggleComponent
} from './components/favorite-toggle/favorite-toggle.component';
import {MatIconModule} from '@angular/material/icon';
import {SharedModule} from 'app/shared/shared.module';
import {ProcessIconModule} from '../process-icon/process-icon.module';
import {TooltipModule} from '../tooltip/tooltip.module';
import {InViewportModule} from 'ng-in-viewport';
import {ClientCellComponent} from './components/client-cell/client-cell.component';
import {FivefProcessActionsModule} from '../../lib/fivef-ui/process/fivef-process-state-actions/fivef-process-actions.module';
import {RemainingDaysCounterComponent} from './components/remaining-days-counter/remaining-days-counter.component';
import {FivefStatusSelectorComponent} from '../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.component';

@NgModule({
  declarations: [
    ProcessListingComponent,
    FavoriteToggleComponent,
    ClientCellComponent,
    RemainingDaysCounterComponent
  ],
  imports: [
    SharedModule,
    MatIconModule,
    ProcessIconModule,
    TooltipModule,
    InViewportModule,
    FivefProcessActionsModule,
    FivefStatusSelectorComponent
  ],
  exports: [
    ProcessListingComponent
  ]
})
export class ProcessListingModule {
}
