import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {TaskType, TaskTypeTitle} from 'app/+store/task/task.interface';
import {Task} from 'app/+store/task/task';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ContactSelectors, TaskActions, TaskAssigneeActions} from 'app/+store';
import {
  PRIORITIES,
  PRIORITIES_MAP
} from '../../../../../../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.interface';
import {
  IParticipantCandidate,
  transformContactObject
} from '../../../../../../../lib/fivef-ui/input/fivef-item-selector/fivef-item-selector.interface';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import * as api from '../../../../../../../+store/iam/process-policy.interface';
import {TaskAssignee} from '../../../../../../../+store/task-assignee/task-assignee';

@Component({
  selector: 'dvtx-task-settings',
  templateUrl: './task-settings.component.html',
  styleUrls: ['./task-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSettingsComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  public readonly priorities = PRIORITIES;
  public readonly priorityMap = PRIORITIES_MAP;

  taskTypes = TaskType;
  taskTypeTitle = TaskTypeTitle;

  public assigneeCandidates$: Observable<IParticipantCandidate[]>;
  public selectedAssignees$ = new BehaviorSubject<IParticipantCandidate[]>([]);
  public responsible$ = new BehaviorSubject<IParticipantCandidate>(null);

  public _task: Task = new Task(null, null);
  public title: string;

  @Input()
  public policy: api.Iam.IProcessPolicy = api.Iam.DEFAULT_PROCESS_POLICY;

  @Output()
  onChange = new EventEmitter<Task>();


  @Input()
  set task(t: Task) {
    this._task = t;
    this.title = t?.title;
    this.selectedAssignees$.next(t?.assignees?.length ? t.assignees?.map(transformContactObject) : []);
    this.responsible$.next(t?.responsible ? transformContactObject(t.responsible) : null);
  }

  constructor(private store: Store<AppState>,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.assigneeCandidates$ = this.store.select(ContactSelectors.getSelectableContacts)
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.selectedAssignees$.complete();
    this.responsible$.complete();
  }

  public updateTitle(title: string) {
    if (!title || title === this._task?.title) {
      return;
    }
    this._task = Object.assign({}, this._task, {title: title});
    this.cdr.detectChanges();
    this.onChange.emit(this._task);
  }

  public updateDueDate(dueDate) {
    const task = Object.assign({}, this._task, {dates: {dueDate: dueDate}});
    if (this._task.dates.dueDate === task.dates.dueDate) {
      return;
    }
    this._task = task;
    this.cdr.detectChanges();
    this.onChange.emit(this._task);
  }

  public updateResponsible(task: Task, $event: IParticipantCandidate) {
    const _task = Object.assign({}, task, {responsible: {email: $event.id}});
    if (this._task.responsible.email === _task.responsible.email) {
      return;
    }

    this.task = _task;

    if (task?.id) {
      this.store.dispatch(new TaskActions.AddResponsible(task.id, $event.id));
    } else {
      this.onChange.emit(this._task);
    }
  }

  public addAssignee(task: Task, $event: IParticipantCandidate) {
    const alreadyAssigned = task.assignees.find(a => a.email === $event.id);
    if (alreadyAssigned) {
      console.error(`Task assignment: User is already assigned: ${$event.id}. Skipping...`)
      return;
    }

    this.task = Object.assign({}, this._task, {assignees: [...task.assignees, {email: $event.id} as TaskAssignee]});

    if (task?.id) {
      this.store.dispatch(new TaskAssigneeActions.AddAssignment(task.id, $event.id));
    } else {
      this.onChange.emit(this._task);
    }
  }

  public removeAssignee(task: Task, $event: IParticipantCandidate) {
    const assignment = task.assignees.find(a => a.email === $event.id);
    if (assignment) {
      this.task = Object.assign({}, this._task, {assignees: task.assignees.filter(a => a.email !== $event.id)});

      if (task?.id) {
        this.store.dispatch(new TaskAssigneeActions.Delete(task.id, assignment.id));
      } else {
        this.onChange.emit(this._task);
      }
    }
  }

  public updatePriority(priority) {
    const task = Object.assign({}, this._task, {priority: PRIORITIES_MAP[priority.id]});
    if (task.priority === this._task.priority) {
      return;
    }
    this._task = task;
    this.cdr.detectChanges();
    this.onChange.emit(this._task);
  }

  public updateStatus(status) {
    const task = Object.assign({}, this._task, {status: status});
    if (task.status === this._task.status) {
      return;
    }
    this._task = task;
    this.cdr.detectChanges();
    this.onChange.emit(this._task);
  }

  public updateTaskType(taskType) {
    const task = Object.assign({}, this._task, {taskType: taskType});
    if (task.taskType === this._task.taskType) {
      return;
    }
    this._task = task;
    this.cdr.detectChanges();
    this.onChange.emit(this._task);
  }

  public updateDescription(description: string) {
    const task = Object.assign({}, this._task, {description: description});
    if (task.description === this._task.description) {
      return;
    }
    this._task = task;
    this.cdr.detectChanges();
    this.onChange.emit(this._task);
  }
}
