<!-- Requires mt-1 to have floating label not be hidden inside dialogs -->
<div class="mt-1 d-flex justify-content-between align-items-center" *ngIf="showSearch">
  <div class="fivef-toolbar fivef-toolbar--space-between">
    <h5 class="d-flex align-items-center" *ngIf="showSelectionCounter">
      <span class="dvtx-fg-color dvtx-fg-fivef-blue-400 font-weight-bold">{{ selection?.selected?.length }}</span>
      <mat-icon class="five-f-mat-icon-sm dvtx-fg-color dvtx-fg-fivef-blue-300"
                style="padding: 2px">insert_drive_file
      </mat-icon><!-- 2px realignment for the h5 -->
      <span class="ml-1 dvtx-fg-color dvtx-fg-fivef-blue-400">{{ 'GENERAL.SELECTED_2' | translate }}</span>
    </h5>

    <fivef-search (onChange)="applyFilter($event)" [align]="'end'"></fivef-search>
  </div>
</div>

<table mat-table [dataSource]="dataSource" class="dvtx-document-table" multiTemplateDataRows matSort>
  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center">
        <mat-icon style="color: #7b8490;">insert_drive_file</mat-icon>
      </div>
    </td>
  </ng-container>

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [disabled]="disabled"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="toggle($event, row)"
                    [disabled]="disabled"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GENERAL.TITLE' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{ element?.name }}</td>
  </ng-container>

  <!-- Attached to process -->
  <ng-container matColumnDef="attached">
    <th mat-header-cell *matHeaderCellDef>
      <span class="dvtx-inline-valign"><mat-icon>link</mat-icon></span>
      <button mat-icon-button matTooltip="{{ 'DMS.LINKED_TO_PROJECT_ROOM' | translate }}">
        <mat-icon>info</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center" *ngIf="element?.selected">
        <mat-icon style="color: #7b8490;">link</mat-icon>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="publicly-available">
    <th mat-header-cell *matHeaderCellDef>
      <dvtx-table-text-and-info [title]="'EXTERNAL_ACCESS.SHARED'"
                                [tooltip]="'EXTERNAL_ACCESS.PUBLIC_AVAILABLE_INFO'"></dvtx-table-text-and-info>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center" *ngIf="element?.publiclyAvailable">
        <mat-icon style="color: #7b8490;">public</mat-icon>
      </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <!-- the matColumnDef should be the same as the mapped property name for the sorting (matsort) to work -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DMS.FILENAME' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <div [matTooltip]="element?.title">
        {{ element?.title }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="ea-counts">
    <th mat-header-cell *matHeaderCellDef>
      <dvtx-table-text-and-info [title]="'EXTERNAL_ACCESS.COUNT'"
                                [tooltip]="'EXTERNAL_ACCESS.EXTERNAL_LINK_COUNT'"></dvtx-table-text-and-info>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="w-100 d-flex justify-content-center">
        <div>
          <strong>{{ eaCountMap[element.id] | async }}</strong>
        </div>
      </div>
    </td>
  </ng-container>

  <!-- Export available column -->
  <ng-container matColumnDef="export_available">
    <th mat-header-cell *matHeaderCellDef>
      <div class="d-flex align-items-center">
              <span class="dvtx-inline-valign">
        {{ 'DMS.EXPORT_AVAILABLE' | translate }}
      </span>
        <button mat-icon-button
                matTooltip="{{ 'DMS.EXPORT_AVAILABLE_TOOLTIP' | translate }}">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center">
        <div *ngIf="element?.exports?.length && element.exports.length > 0"
             cdk-overlay-origin
             #trigger="cdkOverlayOrigin"
             (mouseenter)="showExportInfoMenu[element.id] = true"
             (mouseleave)="showExportInfoMenu[element.id] = false">
          <button mat-icon-button class="export--count">
            <mat-icon>file_download</mat-icon>
            <span class="count--indicator">{{ element.exports?.length }}</span>
          </button>

          <ng-template cdk-connected-overlay
                       [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
                       [cdkConnectedOverlayOrigin]="trigger"
                       [cdkConnectedOverlayHasBackdrop]="false"
                       [cdkConnectedOverlayOpen]="showExportInfoMenu[element.id]">
            <div style="background-color: white; padding: 10px 15px 8px 15px" class="mat-elevation-z2">
              <ul>
                <li *ngFor="let elem of element.exports" class="p-1">
                  <ng-container *ngIf="getAvatar(elem.performer) as profile">
                    <span>{{ 'DMS.EXPORTED_BY' | translate }}</span>&nbsp;<strong>{{ profile?.firstName ? profile?.firstName + ' ' + profile?.lastName : elem?.performer }}</strong>&nbsp;<span>{{ 'DMS.DATE_AT' | translate }}
                    <strong>{{ elem.createdAt | date:'medium' }}</strong></span>
                  </ng-container>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="dms-ident">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'DATEV.DMS.OBJECT_IDENT' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element?.dmsObjectIdent"
           [matTooltip]="element?.dmsObjectIdent">
        {{ element?.dmsObjectIdent }}
      </div>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="sync-status">
    <th mat-header-cell *matHeaderCellDef> {{ 'DATEV.DMS.SYNC_STATUS' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <div class="d-flex justify-content-center"
           [ngSwitch]="element?.syncStatus">
        <ng-container *ngSwitchCase="DmsSyncStatus.Undefined">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="'DATEV.DMS.SYNC_IN_PROGRESS' | translate">timelapse
          </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="DmsSyncStatus.Open">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="'DATEV.DMS.SYNC_IN_PROGRESS' | translate">timelapse
          </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="DmsSyncStatus.Duplicate">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="('DMS.DUPLICATION_RECOGNITION' | translate) + (element?.syncedAt | date: 'medium')">
            info
          </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="DmsSyncStatus.InProgress">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="'DATEV.DMS.SYNC_IN_PROGRESS' | translate">timelapse
          </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="DmsSyncStatus.Completed">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="('DATEV.DMS.SYNC_COMPLETED' | translate) + ': ' + (element?.syncedAt | date: 'medium')">
            check_circle
          </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="DmsSyncStatus.Error">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="element?.errorCode | translate">error
          </mat-icon>
        </ng-container>
        <ng-container *ngSwitchCase="DmsSyncStatus.Failure">
          <mat-icon class="fivef-color-status-default"
                    [matTooltip]="'DATEV.DMS.SYNC_ERROR' | translate">error
          </mat-icon>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DMS.SIZE' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{ element?.size | fivefFileSize }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'DMS.FILE_TYPE' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{ element?.mimeType ? element?.mimeType : '' }}</td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GENERAL.CREATED_AT' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{ element?.updatedAt | date }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="last-cell">
      <div class="d-flex justify-content-end pr-2">
        <button mat-icon-button
                type="button"
                [disabled]="!element.url"
                (click)="download(element)"
        >
          <mat-icon>file_download</mat-icon>
        </button>

        <fivef-delete-artifact [process]="process"
                               [disabled]="!element.artifactPresent"
                               [hidden]="!deleteEnabled"
                               [artifact]="element"></fivef-delete-artifact>
        <button mat-icon-button type="button"
                (click)="deselectDetails($event, element)">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div class="dvtx-document-details"
           [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
        <ng-template [ngIf]="element === expandedElement">
          <div class="row m-0 pt-1">
            <div class="col-12">
              <button [disabled]="!element.url" mat-button (click)="download(element)">
                <mat-icon matPrefix>file_download</mat-icon>
                {{ 'DMS.DOWNLOAD_ACTION' | translate }}
              </button>

              <fivef-delete-artifact [process]="process"
                                     [buttonStyle]="'button'"
                                     [disabled]="!element.artifactPresent || isClosed"
                                     [hidden]="!deleteEnabled"
                                     [artifact]="element"></fivef-delete-artifact>
            </div>
          </div>
          <div class="w-100 m-0 p-1">
            <fivef-artifact-details [document]="element"></fivef-artifact-details>
          </div>
        </ng-template>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expand-details-action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="last-cell">
      <div class="d-flex justify-content-end pr-2">
        <fivef-artifact-preview-dialog [documentIds]="artifactIds"
                                       [documentId]="element.id"
                                       [lockedProcess]="false"
                                       [processId]="element?.processId"
                                       [disabled]="artifactIds?.length === 0"
                                       [selectedDocumentPreviewTab]="0"
                                       [appearance]="PreviewViewType.Custom">
          <button mat-icon-button>
            <mat-icon>visibility</mat-icon>
          </button>
        </fivef-artifact-preview-dialog>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="example-element-row"
      [class.fivef-artifact-table--expanded]="expandedElement === element">
  </tr>

  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="dvtx-document-detail-row"></tr>
</table>
