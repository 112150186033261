import {startWith, map} from 'rxjs/operators';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {Observable, combineLatest, Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {DvtxControlValueAccessor} from 'app/shared/modules/base-form-elements/components/1_control-value-accessor-components/DvtxControlValueAccessor';
import {ClientSelectors} from 'app/+store/client';
import {Client} from 'app/+store/client/client';
import {ClientContactType} from 'app/+store/client/client.interface';
import {ClientActionType, FivefCreateClientComponent} from '../fivef-create-client/fivef-create-client.component';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {FivefDisableInputHintsWrapperDirective} from '../../input/fivef-disable-input-hints-wrapper.directive';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'fivef-client-select',
  standalone: true,
  imports: [CommonModule, MatInputModule, FivefDisableInputHintsWrapperDirective, TranslateModule, ReactiveFormsModule, MatAutocompleteModule, MatIconModule, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, MatTooltipModule, FivefCreateClientComponent, MatButtonModule, CdkVirtualForOf],
  templateUrl: './fivef-client-select.component.html',
  styleUrls: ['./fivef-client-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FivefClientSelectComponent),
      multi: true,
    }
  ]
})
export class FivefClientSelectComponent extends DvtxControlValueAccessor implements OnInit, OnChanges, OnDestroy {
  @ViewChild('inputElementRef') inputElementRef: ElementRef;
  ClientContactType = ClientContactType;
  ClientActionType = ClientActionType;
  onDestroy = new Subject();

  myControl = new UntypedFormControl();
  filteredOptions: Observable<Client[]>;
  selected: Client;

  @Input() set value(c: Client) {
    this.writeValue(c);
    this.notifyOnChange(c);
  }

  @Input() readOnly = false;

  @Output() onReset = new EventEmitter();

  @Input() placeholder = 'CLIENT.SELECT_CLIENT';

  @Input() action = ClientActionType.CreateButton;

  @Input() showCreateButton = true;
  @Input() required = false;

  @Input() filterByBookman = false;
  @Input() excludedIds = null;

  constructor(
    protected injector: Injector,
    private _store: Store<AppState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.filteredOptions =
      combineLatest(
        this.myControl.valueChanges.pipe(startWith('')),
        this._store.select(ClientSelectors.getAllClients),
      ).pipe(
        map(([value, clients]: [string, Client[]]) => {
          if (this.excludedIds && this.excludedIds.length > 0) {
            return this._filter(value, clients).filter(a => !this.excludedIds.includes(a.id));
          } else {
            return this._filter(value, clients);
          }
        }),
        map(clients => {
          if (this.filterByBookman) {
            return clients.filter(c => c.bookmanClientId && c.bookmanClientName);
          }
          return clients
        })
      );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes) {
  }

  private _filter(value: string, clients: Client[]): Client[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return clients.filter((entry: Client) => {
        return entry.name && entry.name.toLowerCase().indexOf(filterValue) >= 0
          || entry.email && entry.email.toLowerCase().indexOf(filterValue) >= 0
          || entry.firstName && entry.firstName.toLowerCase().indexOf(filterValue) >= 0
          || entry.lastName && entry.lastName.toLowerCase().indexOf(filterValue) >= 0
          || entry.clientId && entry.clientId.toLowerCase().indexOf(filterValue) >= 0;
      });
    } else {
      return [];
    }
  }

  writeValue(obj: any): void {
    this.myControl.setValue(obj);
    this.selected = obj;
  }

  clientSelected($event: MatAutocompleteSelectedEvent) {
    this.notifyOnChange($event.option.value);
    this.selected = $event.option.value;
  }

  reset() {
    this.writeValue(null);
    this.notifyOnChange(null);
    this.onReset.emit();
  }

  displayFn(contact: Client): string {
    let label = contact ? (contact.name ? contact.name : `${contact.firstName} ${contact.lastName}`) : '';
    if (contact && contact.clientId) {
      label = `${label} (${contact.clientId})`;
    }
    return label;
  }

  onBlurEventHandler() {
    this.notifyOnChange(this.myControl.value);
    if (!this.myControl.value) {
      this.selected = null;
    }
  }

  setClient(client: Client) {
    this.writeValue(client);
    this.notifyOnChange(client);
  }

  public focusOnInput() {
    if (!this.inputElementRef) {
      console.error('ClientSelectionCvaComponent.focusOnInput undefined');
    } else {
      this.inputElementRef.nativeElement.focus();
    }
  }
}
