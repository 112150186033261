import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {LicenceControlService} from 'app/services/licence-control.service';

@Directive({
    selector: '[fivefRequiredLicence], [fivefRouteLink]',
    standalone: true
  },
)
export class FivefLicenseControlDirective {
  @Input() fivefRequiredLicence;
  @Input() fivefRouteLink;

  @Output() public fivefAction = new EventEmitter();

  @HostListener('click', ['$event, $event.target'])
  onClick(event, targetElement) {
    if (this.licenceControl.hasRequiredLicence(this.fivefRequiredLicence)) {
      if (this.fivefRouteLink) {
        this.router.navigate(this.fivefRouteLink);
      } else {
        this.fivefAction.emit();
      }
    }
  }

  constructor(private router: Router, private licenceControl: LicenceControlService) {
  }
}
