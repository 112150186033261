<ng-container *ngIf="!hidden">
  <ng-template [ngIf]="buttonStyle === 'icon'" [ngIfElse]="buttonStyleRaised">
    <button mat-icon-button
            (click)="openDeleteDocumentDialog()"
            [disabled]="processLocked || disabled"

            matTooltip="{{ 'DMS.REMOVE_DOCUMENT' | translate }}">
      <mat-icon>delete</mat-icon>
    </button>
  </ng-template>

  <ng-template #buttonStyleRaised>
    <button mat-button
            (click)="openDeleteDocumentDialog()"
            [disabled]="processLocked || disabled"

            matTooltip="{{ 'DMS.REMOVE_DOCUMENT' | translate }}">
      <mat-icon>delete</mat-icon>
      {{ titleBtn | translate }}
    </button>
  </ng-template>
</ng-container>

<ng-template #dialogTpl>
  <fivef-dialog [title]="'DMS.REMOVE_DOCUMENT'"
                [infoColor]="'warn'">
    <ng-template [ngIf]="!removeWithoutDelete" [ngIfElse]="deleteSuccessInfo">
      <p>{{ 'DMS.REMOVE_LINK_TO_PROJECT_ROOM' | translate }}</p>

      <mat-checkbox [(ngModel)]="deleteDocument">
        <strong>{{ 'DMS.DELETE_DOCUMENT_IN_DMS' | translate }}</strong>
      </mat-checkbox>
    </ng-template>

    <ng-template #deleteSuccessInfo>
      <h4>
        <span class="dvtx-inline-valign dvtx-fg-color dvtx-fg-action-green"><mat-icon>check_circle</mat-icon></span> {{ 'DMS.REMOVE_LINK_SUCCESS' | translate }}
      </h4>
      <p>
        <span class="dvtx-inline-valign"><mat-icon>warning</mat-icon></span> {{ 'DMS.DELETE_DOCUMENT_FAILED' | translate }}
      </p>
    </ng-template>

    <div fivef-dialog-footer>
      <ng-template [ngIf]="!removeWithoutDelete" [ngIfElse]="closeToolbar">
        <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
        <button mat-raised-button color="primary"
                [disabled]="submitOnGoing" (click)="submit($event)">
          <ng-template [ngIf]="deleteDocument" [ngIfElse]="deleteLinkButton">
            {{ 'DMS.REMOVE_LINK_AND_DELETE_BUTTON' | translate }}
          </ng-template>

          <ng-template #deleteLinkButton>
            {{ 'DMS.REMOVE_LINK_BUTTON' | translate }}
          </ng-template>
        </button>
      </ng-template>
      <ng-template #closeToolbar>
        <button mat-raised-button color="primary" (click)="closeDialog()">
          {{ 'GENERAL.CLOSE_ACTION' | translate }}
        </button>
      </ng-template>
    </div>
  </fivef-dialog>
</ng-template>
