<form class="dvtx-recipient-autocomplete--form">
  <mat-form-field>
    <input type="text" [placeholder]="placeholder | translate"
           matInput [formControl]="myControl"
           (keyup.enter)="onKeyEnter()"
           [matAutocomplete]="auto" (blur)="onBlurEventHandler()" (focus)="onFocusEventHandler()">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="openConfirmationSelectPerson($event)">
      <cdk-virtual-scroll-viewport *ngIf="(filteredOptions | async)?.length > 0" style="height: 240px;" [itemSize]="30">
        <mat-option style="width: 100%;" *cdkVirtualFor="let option of filteredOptions | async" [value]="option">
          <dvtx-avatar [size]="'xs'" [email]="option" [isVerified]="option?.hasAccount"
                       [showTitle]="true" [showEmail]="showEmail"></dvtx-avatar>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</form>
