import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarComponent} from './components/avatar/avatar.component';
import {AvatarService} from './components/avatar/avatar.service';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SimpleAvatarComponent} from './components/simple-avatar/simple-avatar.component';
import {InViewportModule} from 'ng-in-viewport';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {FivefDialogComponent} from '../../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefSafeHtmlPipe} from '../../../lib/fivef-ui/util/fivef-safe-html-pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    InViewportModule,
    MatMenuModule,
    MatCardModule,
    FivefDialogComponent,
    FivefSafeHtmlPipe
  ],
  declarations: [
    AvatarComponent,
    SimpleAvatarComponent
  ],
  providers: [
    AvatarService
  ],
  exports: [
    AvatarComponent,
    SimpleAvatarComponent
  ]
})
export class UserAccountModule {
}
