import * as model from './tenant.interface';
import {IResource} from '../../../shared/modules/api/models/resource.interface';
import {FivefLoginTheme} from '../../../lib/fivef-ui/theme/fivef-login-theme/fivef-login-theme.interface';

/// <reference path="./tenant.interface.ts" />
export namespace Tenant {
  // tslint:disable-next-line:no-shadowed-variable
  export class Tenant implements model.Tenant.ITenant {
    readonly type = 'tenant_records';

    /**
     * Login theme of the tenant.
     */
    login_theme: string;
    login_theme_config: any;

    /**
     * Available login themes for the tenant.
     */
    available_login_themes: string[];

    /**
     * Color theme of the tenant based on main login theme class.
     */
    color_theme: model.Tenant.FivefAppColorTheme;
    color_theme_config: any;

    constructor(public id,
                public name: string,
                public sender_name: string,
                public domain: string,
                public email_domain: string,
                public favicon_url: string,
                public login_header: string,
                public login_header_en: string,
                public login_footer: string,
                public login_footer_en: string,
                public imprint_url: string,
                public adDepartmentOnboardingActivated: boolean,
                public adDepartmentOffboardingActivated: boolean,
                public adDepartmentOffboardingPerformerId: boolean,
                public autoLicenseUpgrade: boolean,
                public whitelisted_user_domains: string[],
                public createdAt: Date,
                public updatedAt: Date) {
    }

    /**
     * Helper to recognize customer Grant Thornton by domain.
     * Used at default theme selection (see system.service) and for GT specific feature enablement (Purplehub synchronization).
     */
    static isGrantThornton() {
      return !!(window.location.hostname.includes('grantthornton.5fsoftware.org') || window.location.hostname.includes('portal.grantthornton.de') || window.location.hostname.includes('grantthornton.local'));
    }

    /**
     * Helper to recognize customer Curacon by domain.
     * Used at default theme selection (see system.service) and for curacon specific feature enablement.
     */
    static isCuracon() {
      return !!(window.location.hostname.includes('portal.curacon.de') || window.location.hostname.includes('curacon.local') || window.location.hostname.includes('curacon.5fsoftware.org'));
    }

    /**
     * Helper to recognize the paperbird setup as development domain of Andreas Baier.
     */
    static isPaperbirdOrg() {
      return !!(window.location.hostname.includes('paperbird.5fsoftware.org') || window.location.hostname.includes('paperbird.local'));
    }

    /**
     * Helper to recognize the 5F demp domain for development and presentation of new features.
     */
    static isColoredFivef() {
      return !!(window.location.hostname.includes('colored.5fsoftware.com') || window.location.hostname.includes('colored.5fsoftware.local'));
    }
  }

  export class TenantDepartment {
    readonly type = 'tenant_departments';

    constructor(public id,
                public name: string,
                public description: string,
                public createdAt: Date) {
    }
  }

  export class LicenceStats {
    readonly id: 'tenant_user_stats';
    readonly type: 'tenant_user_stats';

    constructor(public all: number,
                public available: number,
                public assigned: number,
                public memberships: any[],
                public uploads: any[]) {}
  }

  export class AdminLogItem {
    readonly type: 'tenant_admin_log_items';

    total: number;
    perPage: number;
    records: number;

    constructor(public id: string,
                public performerEmail: string,
                public performerName: string,
                public category: string,
                public action: string,
                public infoLevel: string,
                public message: string,
                public createdAt: Date) {}
  }

  export class TenantAuthorizedDomain {
    readonly type: 'tenant_authorized_domains';

    constructor(public id: string, public domain: string, public defaultDomain: boolean) {}
  }

  export class OffbordingDryRun {
    readonly type: 'offboarding_dry_run';

    constructor(public id: string, public total: number, public considered: number, public removed, public inactive) {
    }
  }

  export class LoginConfig implements IResource {
    readonly type: 'tenant_login_configs';

    constructor(public id: string,
                public title: string,
                public description: string,
                public bgColor: string,
                public fgColor: string,
                public loginTheme: FivefLoginTheme,
                public backgroundImageUrl: string = null,
                public backgroundImageFilename: string = null,
                public secondaryLogoUrl: string = null,
                public secondaryLogoFilename: string = null,
                public slideshowId: string = null,
                public slideshowTitle: string = null,
                public published: boolean = false,
                public createdAt: Date = null,
                public updatedAt: Date = null) {
    }
  }
}
