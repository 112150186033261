<div class="d-flex">
  <div class="d-flex align-items-start flex-column"
       [ngStyle]="!showEmailType && {'padding-right' : '0px'}">
    <mat-form-field style="min-width: 250px;">
      <mat-label *ngIf="label"> {{ label | translate }}</mat-label>
      <input
        matInput
        [placeholder]="(!label ? ('CONTACTS.EMAIL-TABLE-HEADER' | translate ): '')"
        [(ngModel)]="innerValue.emailAddress"
        (ngModelChange)="notifyChange()"
        [errorStateMatcher]="errorStateMatcher"
        [disabled]="isDisabled"
        [required]="requiredField"
        email
      >
    </mat-form-field>
    <div style="max-width: 300px;">
      <ng-container *ngFor="let err of errorMapper">
        <mat-error *ngIf="dirty && errors && errors[err.error]">
          {{ err.message }}
        </mat-error>
      </ng-container>
    </div>
  </div>

  <dvtx-api-select-cva
    *ngIf="showEmailType"
    class="ml-2"
    [label]="label ? ('ORGANIZATION.CREATION.TYPE' | translate) : ''"
    [elementName]="'ORGANIZATION.CREATION.TYPE' | translate"
    listingPath="name"
    listingType="typed_email-type"
    [(ngModel)]="innerValue.locationOrType"
    (ngModelChange)="notifyChange()"
    [isDisabled]="isDisabled">
  </dvtx-api-select-cva>
</div>
