import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LoadOne, LoadOneSuccess, LoadOneFail, ProcessManagementActionTypes
} from './process-management.actions';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessManagementService} from './process-management.service';
import {Process} from '../process/process';


@Injectable()
export class ProcessManagementEffects {
  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ProcessManagementActionTypes.LoadOne),
    concatMap((action: LoadOne) => {
      return this._svc.getOne(action.processId).pipe(
        first(),
        concatMap((res: Process) => {
          return [new LoadOneSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new LoadOneFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessManagementService,
              private _notifyService: NotificationService) {
  }
}
