import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  CommentActionTypes,
  DeleteArtifactComment,
  DeleteComment,
  DeleteCommentFail,
  DeleteCommentSuccess,
  DeleteTaskComment,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadAllTaskComments,
  LoadArtifactComment,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  LoadProcessStatistics,
  LoadProcessStatisticsFail,
  LoadProcessStatisticsSuccess,
  React,
  ReactFail,
  ReactOnArtifactComment,
  ReactOnTaskComment,
  ReactSuccess,
  Read,
  ReadFail,
  ReadSuccess,
  ReadTaskComment,
  SaveArtifactComment,
  SaveComment,
  SaveCommentFail,
  SaveCommentSuccess,
  SendArtifactComment,
  SendCommentFail,
  SendCommentSuccess,
  SendProcessComment,
  SendTaskComment,
  UpdateTaskComment,
} from './comment.actions';
import {CommentService} from './comment.service';
import {Comment, CommentProcessStatistics} from './comment';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessEventActions} from '../process-event';
import {ProcessArtifactActions} from '../process-artifact';
import {TaskActions} from '../task';
import {CollectorItemActions} from "../collector-item";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";

@Injectable()
export class CommentEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      const call = action.isArtifact ? this.svc.getAllArtifactComments(action.processId) :
        this.svc.getAllProcessComments(action.processId, action.recursive, action.resourceId);
      return call.pipe(
        first(),
        switchMap((comments: Comment[]) => {
          return [new LoadAllSuccess(comments)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.LoadOne),
    concatMap((action: LoadOne) => {
      return this.svc.getOneProcessComment(action.processId, action.commentId).pipe(
        first(),
        switchMap((comment: Comment) => {
          return [new LoadOneSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadArtifactCommentOne$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.LoadArtifactComment),
    concatMap((action: LoadArtifactComment) => {
      return this.svc.getOneArtifactComment(action.artifactId, action.commentId).pipe(
        first(),
        switchMap((comment: Comment) => {
          return [new LoadOneSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadStats$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.LoadProcessStatistics),
    concatMap((action: LoadProcessStatistics) => {
      return this.svc.getCommentProcessStatistics(action.processId, action.recursive).pipe(
        first(),
        switchMap((stats: CommentProcessStatistics) => {
          return [new LoadProcessStatisticsSuccess(stats)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadProcessStatisticsFail(err));
        }));
    })
  ));

  sendProcessComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.SendProcessComment),
    concatMap((action: SendProcessComment) => {
      return this.svc.createProcessComment(action.processId, action.comment).pipe(
        first(),
        switchMap((comment: Comment) => {
          if (comment && comment.backtrackId) {
            this.store.dispatch(new CollectorItemActions.LoadOne(action.processId, comment.backtrackId));
          }
          return [new SendCommentSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new SendCommentFail(err));
        }));
    })
  ));

  sendArtifactComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.SendArtifactComment),
    concatMap((action: SendArtifactComment) => {
      return this.svc.sendArtifactComment(action.artifactId, action.comment).pipe(
        first(),
        switchMap((comment: Comment) => {
          return [
            new SendCommentSuccess(comment),
            new ProcessArtifactActions.LoadOneById(action.artifactId)
          ];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new SendCommentFail(err));
        }));
    })
  ));

  readMessage$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.Read),
    concatMap((action: Read) => {
      return this.svc.markProcessCommentRead(action.comment.processId, action.comment.id).pipe(
        first(),
        concatMap((message: Comment) => {
          return [new ReadSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ReadFail(err));
        }));
    })
  ));

  deleteComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.DeleteComment),
    concatMap((action: DeleteComment) => {
      return this.svc.deleteComment(action.processId, action.commentId).pipe(
        first(),
        concatMap((message: any) => {
          this.notifySvc.success('PROJECT_ROOM.COMMENT_DELETED');
          return [
            new DeleteCommentSuccess(action.commentId),
            new ProcessEventActions.DeleteSuccess(action.commentId)
          ];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new DeleteCommentFail(err));
        }));
    })
  ));

  deleteArtifactComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.DeleteArtifactComment),
    concatMap((action: DeleteArtifactComment) => {
      return this.svc.deleteArtifactComment(action.artifactId, action.commentId).pipe(
        first(),
        concatMap((message: any) => {
          this.notifySvc.success('PROJECT_ROOM.COMMENT_DELETED');
          return [
            new DeleteCommentSuccess(action.commentId),
            new ProcessEventActions.DeleteSuccess(action.commentId)
          ];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new DeleteCommentFail(err));
        }));
    })
  ));

  saveComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.SaveComment),
    concatMap((action: SaveComment) => {
      return this.svc.editComment(action.processId, action.commentId, action.content).pipe(
        first(),
        concatMap((message: any) => {
          this.notifySvc.success('GENERAL.UPDATE_ACTION_DONE');
          return [new LoadOne(action.processId, action.commentId)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new SaveCommentFail(err));
        }));
    })
  ));

  saveArtifactComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.SaveArtifactComment),
    concatMap((action: SaveArtifactComment) => {
      return this.svc.updateArtifactComment(action.artifactId, action.commentId, action.content).pipe(
        first(),
        concatMap((comment: Comment) => {
          this.notifySvc.success('GENERAL.UPDATE_ACTION_DONE');
          return [new SaveCommentSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new SaveCommentFail(err));
        }));
    })
  ));

  react$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.React),
    concatMap((action: React) => {
      return this.svc.doReact(action.processId, action.commentId, action.reactionType).pipe(
        first(),
        concatMap((comment: Comment) => {
          return [new ReactSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new ReactFail(err));
        }));
    })
  ));

  reactOnArtifactComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.ReactOnArtifactComment),
    concatMap((action: ReactOnArtifactComment) => {
      return this.svc.doReactOnArtifactComment(action.artifactId, action.commentId, action.reactionType).pipe(
        first(),
        concatMap((comment: Comment) => {
          return [new ReactSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new ReactFail(err));
        }));
    })
  ));

  loadAllTaskComments$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.LoadAllTaskComments),
    switchMap((action: LoadAllTaskComments) => {
      return this.svc.loadAllTaskComments(action.taskId).pipe(
        first(),
        concatMap((res: Comment[]) => {
          res.forEach(comment => comment.backtrackId = action.taskId);
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          this.notifySvc.error('TASK.ERRORS.LOAD_COMMENTS_ERROR')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  sendTaskComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.SendTaskComment),
    concatMap((action: SendTaskComment) => {
      return this.svc.sendTaskComment(action.taskId, action.comment).pipe(
        first(),
        switchMap((comment: Comment) => {
          comment.backtrackId = action.taskId
          return [new SendCommentSuccess(comment), new TaskActions.LoadOne(action.taskId)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('TASK.ERRORS.ADDING_COMMENT_FAILURE')
          return of(new SendCommentFail(err));
        }));
    })
  ));

  updateTaskComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.UpdateTaskComment),
    concatMap((action: UpdateTaskComment) => {
      return this.svc.updateTaskComment(action.taskId, action.commentId, action.message).pipe(
        first(),
        switchMap((comment: Comment) => {
          comment.backtrackId = action.taskId
          return [new SaveCommentSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveCommentFail(err));
        }));
    })
  ));

  deleteTaskComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.DeleteTaskComment),
    switchMap((action: DeleteTaskComment) => {
      return this.svc.deleteTaskComment(action.taskId, action.commentId).pipe(
        first(),
        switchMap((comment: Comment) => {
          this.notifySvc.success('TASK.DELETED_COMMENT_SUCCESSFULLY')
          return [new DeleteCommentSuccess(comment.id)];
        }),
        catchError(err => {
          this.notifySvc.error('TASK.ERRORS.DELETED_COMMENT_FAILURE')
          return of(new DeleteCommentFail(err))
        }));
    })
  ));

  readTaskComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.ReadTaskComment),
    concatMap((action: ReadTaskComment) => {
      return this.svc.readTaskComment(action.taskId, action.commentId).pipe(
        first(),
        concatMap((comment: Comment) => {
          comment.backtrackId = action.taskId
          return [new ReadSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ReadFail(err));
        }));
    })
  ));

  reactOnTaskComment$ = createEffect(() => this.actions.pipe(
    ofType(CommentActionTypes.ReactOnTaskComment),
    concatMap((action: ReactOnTaskComment) => {
      return this.svc.reactOnTaskComment(action.taskId, action.commentId, action.reactionType).pipe(
        first(),
        concatMap((comment: Comment) => {
          comment.backtrackId = action.taskId
          return [new ReactSuccess(comment)];
        }),
        catchError(err => {
          console.error(err);
          this.notifySvc.error('INBOX.GENERAL_SEND_ERROR');
          return of(new ReactFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private svc: CommentService,
              private store: Store<AppState>,
              private notifySvc: NotificationService) {
  }
}
