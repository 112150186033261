import {Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LicenceActionTypes, LoadAll, LoadAllFail, LoadAllSuccess} from './licence.actions';
import {LicenceService} from 'app/shared/modules/api/services/licence.service';
import {Licence} from 'app/shared/modules/api/models/licence';

@Injectable()
export class LicenceEffects {
  loadMyNP = createEffect(() => this.actions.pipe(
    ofType(LicenceActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll().pipe(
        map((res: Licence[]) => {
            return new LoadAllSuccess(res);
          }
        ),
        catchError(err => of(new LoadAllFail(err))));
    })
  ));

  constructor(private actions: Actions, private _svc: LicenceService) {
  }
}




