import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IWaitingDialogData} from './waiting-dialog.interface';

@Component({
  templateUrl: './waiting-dialog.component.html',
  styleUrls: ['./waiting-dialog.component.scss'],
  selector: 'dvtx-waiting-dialog',
})
export class WaitingDialogComponent {
  @Input()
  public color = 'var(--fivef-color-action)';

  @Input()
  title;

  @Input()
  headline;

  @Input()
  disableClose = false;

  constructor(public dialogRef: MatDialogRef<WaitingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IWaitingDialogData) {
    [
      this.color,
      this.title,
      this.headline
    ] = [
      data.color || this.color,
      data.title,
      data.headline
    ];
  }
}
