import {NgModule} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {AlertBoxComponent} from './components/alert-box/alert-box.component';
import {ActionsPanelComponent} from './components/actions-panel/actions-panel.component';
import {ApiModule} from 'app/shared/modules/api/api.module';
import {BaseFormElementsModule} from 'app/shared/modules/base-form-elements/base-form-elements.module';
import {ContentEditableModule} from './modules/content-editable/content-editable.module';
import {DashboardPanelsModule} from './modules/dashboard-panels/dashboard-panels.module';
import {ListingModule} from 'app/shared/modules/listing/listing.module';
import {FilesUploadModule} from 'app/shared/modules/file-upload/file-upload.module';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {BackLinkComponent} from './components/backlink/backlink.component';
import {NotificationModule} from './modules/notification/notification.module';
import {ComingSoonComponent} from './components/coming-soon/coming-soon.component';
import {LocalizationHelperService} from './services/localization-helper/localization-helper.service';
import {LocaleSwitchDirective} from './directives/locale-switch.directive';
import {FeatureToggleDirective} from './directives/feature-toggle.directive';
import {CustomMatPaginatorIntl} from 'app/shared/services/custom-mat-paginator-intl.service';
import {RoleToggleDirective} from './directives/roleToggle.directive';
import {AuthToggleDirective} from './directives/authToggle.directive';
import {ApiErrorComponent} from './components/api-error/api-error.component';
import {
  CreateContactPersonDialogComponent
} from './components/create-contact-person-dialog/create-contact-person-dialog.component';
import {ApiResourceModule} from 'app/shared/modules/api-resource/api-resource.module';
import {ActioncableComponent} from 'app/shared/containers/actioncable/actioncable.component';
import {PageContainerModule} from './modules/page-container/page-container.module';
import {WizardComponent} from './components/wizard-bar/wizard-bar.component';
import {WizardCopyComponent} from 'app/shared/components//wizard-bar-copy/wizard-bar-copy.component';
import {LegalFormNameByIdPipe} from './pipes/legal-form-name-by-id.pipe';
import {RoutingModule} from './modules/routing/routing.module';
import {FivefLicenseControlDirective} from '../lib/fivef-ui/auth/fivef-license-control.directive';
import {VersionComponent} from './modules/version/version.component';
import {SystemMaintenanceComponent} from 'app/shared/components/system-maintenance/system-maintenance.component';
import {FilterPipe} from './pipes/filter.pipe';
import {AddAccessTokenPipe} from './pipes/add-access-token.pipe';
import {LoadingPanelComponent} from './components/loading-panel/loading-panel.component';
import {TableUtilsModule} from './modules/table-utils/table-utils.module';
import {VarDirective} from './directives/ng-var.directive';
import {FibuProcessIndicatorComponent} from './components/fibu-process-indicator/fibu-process-indicator.component';
import {FivefStepper} from './components/fivef-stepper/fivef-stepper';
import {FroalaViewModule} from 'angular-froala-wysiwyg';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  CreateEmployeeDialogComponent
} from 'app/modules/organization/components/organization-view/create-employee-dialog/create-employee-dialog.component';
import {
  MembershipInvitationComponent
} from 'app/modules/organization/components/organization-view/membership-invitation/membership-invitation.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DatevDuoIndicatorComponent} from './components/datev-duo-indicator/datev-duo-indicator.component';
import {SidebarDetailsModule} from './modules/sidebar-details/sidebar-details.module';
import {PortalModule} from '@angular/cdk/portal';
import {MatBadgeModule} from '@angular/material/badge';
import {RouterModule} from '@angular/router';
import {FivefDialogComponent} from '../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {FivefEnumKeysPipe, FivefEnumToArrayPipe, FivefEnumValuesPipe} from '../lib/fivef-ui/util/fivef-enum.pipe';
import {
  FivefLoadingIndicatorComponent
} from '../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefAvatarGroupComponent} from '../lib/fivef-ui/profile/fivef-avatar-group/fivef-avatar-group.component';
import {FivefInputLabelComponent} from '../lib/fivef-ui/input/fivef-input-label/fivef-input-label.component';
import {FivefFeatureAuthorizationDirective} from '../lib/fivef-ui/auth/fivef-feature-authorization.directive';

const materialMods = [
  MatCardModule,
  MatStepperModule,
  MatTableModule,
  MatPaginatorModule
];

const components = [
  AlertBoxComponent,
  ActionsPanelComponent,
  WizardComponent,
  WizardCopyComponent,
  PageNotFoundComponent,
  SystemMaintenanceComponent,
  BackLinkComponent,
  AddAccessTokenPipe,
  FilterPipe,
  ComingSoonComponent,
  LocaleSwitchDirective,
  FeatureToggleDirective,
  CreateContactPersonDialogComponent,
  CreateEmployeeDialogComponent,
  MembershipInvitationComponent,
  RoleToggleDirective,
  AuthToggleDirective,
  ApiErrorComponent,
  ActioncableComponent,
  LegalFormNameByIdPipe,
  VersionComponent,
  LoadingPanelComponent,
  VarDirective,
  FibuProcessIndicatorComponent,
  FivefStepper,
  DatevDuoIndicatorComponent
];

const sharedModules = [
  FroalaViewModule,
  ApiModule,
  ApiResourceModule,
  BaseFormElementsModule,
  ContentEditableModule,
  NotificationModule,
  ListingModule,
  DashboardPanelsModule,
  FilesUploadModule,
  PageContainerModule,
  SidebarDetailsModule,
  RouterModule,
  MatBadgeModule,
  PortalModule,
  RoutingModule,
  TableUtilsModule,
  FivefDialogComponent,
  FivefLicenseControlDirective,
  FivefEnumToArrayPipe,
  FivefEnumKeysPipe,
  FivefEnumValuesPipe,
  FivefAvatarGroupComponent,
  FivefInputLabelComponent,
  FivefFeatureAuthorizationDirective
];

@NgModule({
  imports: [
    ...materialMods,
    ...sharedModules,
    MatCheckboxModule,
    MatProgressBarModule,
    FivefLoadingIndicatorComponent
  ],
  declarations: components,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    LocalizationHelperService
  ],
  exports: [
    ...materialMods,
    ...sharedModules,
    ...components
  ]
})
export class SharedModule {
}
