import {Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {of} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {Invitation, InvitationResponseList, InvitationStatus, InvitationType} from './invitation.model';
import * as invitationAction from './invitation.actions';
import {
  Accept,
  CreateInvitation,
  Decline,
  Destroy,
  InvitationsActionTypes,
  LoadFail,
  LoadMy,
  LoadOfOrganization
} from './invitation.actions';
import * as licenceAssignmentAction from '../licence-assignment/licence-assignment.actions';
import {LoadAllSuccess} from 'app/+store/message/message.actions';
import {CppApiService} from 'app/services/cpp-api.service';
import {LoadAll} from '../organization/organization.actions';
import * as NotificationActions from '../notification/notification.actions';
import {InvitationService} from './invitation.service';
import {Message} from 'app/+store/message/message';
import {InvitationBuilder} from 'app/+store/invitation/invitation.builder';
import {MembershipInvitationService} from './membership-invitation.service';
import {MembershipActions} from '../membership';
import {NotificationService} from '../../shared/modules/notification/services/notification.service';

@Injectable()
export class InvitationEffects {
  loadMemberInvitations = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.LoadMy),
    switchMap(() => {
      return this.cppApiService.get<InvitationResponseList>('invitation').pipe(
        switchMap((res: InvitationResponseList) => {
          const ret: Invitation[] = [];
          const messages: Message[] = [];
          for (const item of res.data) {
            const invitation = new Invitation(
              item.id,
              item.attributes.invitee_email,
              item.attributes.inviter_name,
              item.attributes.inviter_email,
              item.attributes.inviter_first_name,
              item.attributes.inviter_last_name,
              InvitationStatus[item.attributes.current_state],
              item.attributes.created_at
            );
            ret.push({...invitation});
            const message = InvitationBuilder.invitationToNotification(invitation);
            messages.push(message);
          }
          return [
            new invitationAction.LoadSuccess(ret),
            new LoadAllSuccess(messages)
          ];
        }),
        catchError(err => {
          return of(new LoadFail(err));
        })
      );
    })
  ));

  loadContactPersonInvitations = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.LoadMy),
    switchMap(() => {
      return this.contactPersonService.getAll().pipe(
        switchMap((res: Invitation[]) => {
          const ret: Invitation[] = [];
          const messages: Message[] = [];
          for (const invitation of res) {
            ret.push({...invitation});
            const message = InvitationBuilder.invitationToNotification(invitation);
            messages.push(message);
          }
          return [
            new invitationAction.LoadSuccess(ret),
            new LoadAllSuccess(messages)
          ];
        }),
        catchError(err => {
          return of(new LoadFail(err));
        })
      );
    })
  ));

  loadMemberInvitationsOfOrga = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.LoadOfOrga),
    switchMap((action: LoadOfOrganization) => {
      return this.membershipInvitationService.getAll().pipe(
        map((invitations: Invitation[]) => {
          return new invitationAction.LoadSuccess(invitations);
        }), catchError((err) => {
          return of(new invitationAction.LoadFail(err))
        })
      );
    })
  ));

  loadContactPersonInvitationsOfOrga = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.LoadOfOrga),
    switchMap(() => {
      return this.contactPersonService.getAll(false).pipe(
        map((res: Invitation[]) => {
          return new invitationAction.LoadSuccess(res);
        }),
        catchError(err => {
          return of(new LoadFail(err));
        })
      );
    })
  ));

  createInvitations = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.CreateInvitation),
    switchMap((action: CreateInvitation) => {
      return this.membershipInvitationService.create(action.invitationData.inviteeEmail).pipe(
        switchMap((invitation: Invitation) => {
          const payload = [invitation];
          return [
            new invitationAction.LoadSuccess(payload),
            new licenceAssignmentAction.LoadAll(),
            new MembershipActions.LoadAll(action.invitationData.orgaId),
            new LoadOfOrganization(action.invitationData.orgaId)
          ];
        }),
        catchError((err) => {
          return of(new NotificationActions.ShowHttpError(err))
        })
      )
    })
  ));

  acceptInvitations = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.Accept),
    switchMap((action: Accept) => {
      if (action.invitationType === InvitationType.Member) {
        return this.membershipInvitationService.accept(action.id).pipe(
          switchMap(_ => [
            new invitationAction.AcceptSuccess(action.id),
            new LoadMy(),
            new LoadAll()
          ]),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          }));
      } else {
        return this.contactPersonService.accept(action.id).pipe(
          switchMap(_ => [
            new invitationAction.AcceptSuccess(action.id),
            new LoadMy(),
            new LoadAll()
          ]),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          }));
      }
    })
  ));

  declineInvitations = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.Decline),
    switchMap((action: Decline) => {
      if (action.invitationType === InvitationType.Member) {
        return this.membershipInvitationService.decline(action.id).pipe(
          //  map(id => new invitationAction.DeclineSuccess(action.id)),
          switchMap(_ => [
            new invitationAction.DeclineSuccess(action.id),
            new LoadMy(),
            new LoadAll()
          ]),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          }));
      } else {
        return this.contactPersonService.decline(action.id).pipe(
          //  map(_ => new invitationAction.DeclineSuccess(action.id)),
          switchMap(_ => [
            new invitationAction.DeclineSuccess(action.id),
            new LoadMy(),
            new LoadAll()
          ]),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          }));
      }
    })
  ));

  destroy = createEffect(() => this.actions.pipe(
    ofType(InvitationsActionTypes.Destroy),
    switchMap((action: Destroy) => {
      if (action.invitationType === InvitationType.Member) {
        return this.membershipInvitationService.destroy(action.id).pipe(
          switchMap(_ => {
            this.notifySvc.info('INVITATIONS.INVITATION_DELETED')
            return [
              new invitationAction.DestroySuccess(action.id),
              new licenceAssignmentAction.LoadAll(),
              new MembershipActions.LoadAll(action.organizationId),
              new LoadOfOrganization(action.organizationId)
            ]
          }),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          }));
      } else {
        return this.contactPersonService.Destroy(action.id).pipe(
          map(_ => new invitationAction.DestroySuccess(action.id)),
          catchError((err) => {
            return of(new NotificationActions.ShowHttpError(err))
          }));
      }
    })
  ));

  constructor(private actions: Actions,
              private cppApiService: CppApiService,
              private contactPersonService: InvitationService,
              private membershipInvitationService: MembershipInvitationService,
              private notifySvc: NotificationService) {
  }
}
