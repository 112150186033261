<div class="fivef-address-input-group-cva">
  <div *ngFor="let address of addresses" class="fivef-address-input-group-cva--address">
    <dvtx-address-input-cva
      [enableLocationOrType]="enableLocationOrType"
      [address]="address"
      [isDisabled]="disabled"
      elementName="Adresssuche"
    ></dvtx-address-input-cva>
    <div class="d-flex align-items-end" *ngIf="!disabled">
      <button mat-icon-button [disabled]="disabled" (click)="saveAddress(address)" *ngIf="!address.id">
        <mat-icon>done</mat-icon>
      </button>
      <button mat-icon-button [disabled]="disabled" (click)="deleteAddress(address)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="!disabled && showAddAddress">
    <button mat-icon-button [disabled]="disabled" type="button" (click)="addNewField()"
            class="fivef-address-input-group-cva--add-button"
            [disableRipple]="true">
      <mat-icon>add_circle_outline</mat-icon>
      {{ 'CONTACTS.ADD_ADDRESS' | translate }}
    </button>
  </div>
</div>
