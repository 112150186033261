import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, first, switchMap, takeUntil} from 'rxjs/operators';
import {Net} from 'app/lib/net/uuid';
import {ClientRoute} from '../../components/client-nav-bar/client-nav-bar.component';
import {Client} from 'app/+store/client/client';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ClientActions, ClientSelectors} from 'app/+store/client';
import {ClientActionType} from '../../../../lib/fivef-ui/client/fivef-create-client/fivef-create-client.component';
import {
  BookmanClientActions,
  BookmanClientSelectors,
  BookmanServiceAccountSelectors,
  DmsFolderActions,
  DmsFolderSelectors,
  FeatureSelectors,
  FibuActions,
  FibuSelectors,
  MembershipSelectors,
  OrganizationSelectors
} from 'app/+store';
import {BookmanClient} from 'app/+store/bookman-client/bookman-client';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BookmanServiceAccount} from 'app/+store/bookman-service-account/bookman-service-account';
import {FibuBookmanAccount} from 'app/+store/fibu-bookman-account/fibu-bookman-account';
import {Membership} from 'app/models/membership.model';
import {Organization} from 'app/models/organization.model';
import {Fibu} from 'app/+store/fibu/fibu';
import {DmsFolder} from 'app/+store/dms-folder/dms-folder';
import {DmsAccountType} from 'app/+store/dms-folder/dms-folder.interface';
import {AuditTrailModalComponent} from '../../components/audit-trail-modal/audit-trail-modal.component';
import {IFibuParams} from 'app/+store/fibu/fibu.interface';
import {Feature} from '../../../../+store/feature/feature';
import {BookmanServiceAccountService} from 'app/+store/bookman-service-account/bookman-service-account.service';
import {GetBookmanServiceAccountSuccess} from 'app/+store/bookman-service-account/bookman-service-account.actions';
import {BehaviorSubject, combineLatest as observableCombineLatest, Observable, Subject} from 'rxjs';
import {FibuService} from '../../../../+store/fibu/fibu.service';
import {SlimFolderActions} from 'app/+store/slim-folder';
import { SlimFolderService } from 'app/+store/slim-folder/slim-folder.service';

/**
 * Bookman/Fibu client settings.
 *
 *
 * ATTENTION: TODO: folders are fetched multiple times.
 * Currently the dispatch was moved to the outer container, but this must be rechecked. Expensive call.
 */
@Component({
  selector: 'dvtx-client-bookman',
  templateUrl: './client-bookman.component.html',
  styleUrls: ['./client-bookman.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientBookmanComponent implements OnInit, OnDestroy {
  ClientActionType = ClientActionType;
  onDestroy = new Subject();

  @ViewChild('auditTrailModal') auditTrailModal: AuditTrailModalComponent;

  routes: ClientRoute[];
  activeLink: string;
  @Input() id: string;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  client$: Observable<Client>;
  clientId$ = new BehaviorSubject(null);
  selectedBookmanClient: BookmanClient;

  featureSet$: Observable<Feature>;
  myMembership$: Observable<Membership>;
  selectedOrg$: Observable<Organization>;
  selectedFolder$: Observable<DmsFolder>;
  selectedFolderId$ = new BehaviorSubject<string>(null);
  bookmanClients$: Observable<BookmanClient[]>;
  bookmanAccount$: Observable<FibuBookmanAccount>;
  bookmanServiceAccount$: Observable<BookmanServiceAccount>;
  settingsForm: UntypedFormGroup;
  fibuProcess$: Observable<Fibu>;
  dmsFolderError: any;
  currentSelectedDmsFolder;
  loading = true;
  administrationRights$: Observable<boolean>;
  showAuditTrail$ = new BehaviorSubject<boolean>(false);

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _fb: UntypedFormBuilder,
              private _bookmanSvc: BookmanServiceAccountService,
              private _fibuSettingsSrv: FibuService,
              private _slimFolderSvc: SlimFolderService,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone,
              private _router: Router) {
    // Always initialize the form before the view is initialized by contructor or ngOnInit
    // Never assign the form twice to not loose the reference to the current form at valueChanges
    // Listeners.
    this.settingsForm = this._fb.group({
      bookmanClientId: [null],
      dmsFolder: [{
        id: null,
        type: null,
        path: null,
        bookmanCockpitEnabled: null,
      }],
      bookmanCockpitEnabled: [null],
      fiveFSyncEnabled: [null],
      documentExpiryTime: [60],
    });
  }

  ngOnInit() {
    this.bookmanServiceAccount$ = this._store.select(BookmanServiceAccountSelectors.getAccount);
    this.myMembership$ = this._store.select(MembershipSelectors.getMyMembership);
    this.bookmanClients$ = this._store.select(BookmanClientSelectors.getAll);
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);
    this.administrationRights$ = this._store.select(MembershipSelectors.isAdmin);

    this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(org => !!org), distinctUntilKeyChanged('id'), takeUntil(this.onDestroy))
      .subscribe((_organization) => {
        this._slimFolderSvc.getAll(DmsAccountType.Organization).pipe(first()).subscribe(folders => {
          this._store.dispatch(new SlimFolderActions.LoadAllSuccess(folders));
        })
      });

    this.bookmanClients$
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy))
      .subscribe(bookmanClients => {
        if (bookmanClients && bookmanClients.length && this.selectedBookmanClient) {
          this._updateBookmanClientFormCtrl();
        }
      });

    this.selectedOrg$ = this._store.select(OrganizationSelectors.getSelected)
      .pipe(filter(x => !!x), distinctUntilKeyChanged('id'));

    // The organization is selected, now fetch clients and the bookmanCockpit service account.
    this.selectedOrg$
      .pipe(takeUntil(this.onDestroy))
      .subscribe(org => {
        // Fetch settings only after organization is set, otherwise the X-ORGANIZATION header is missing.
        // Clients do not need to be fetched, they are fetch with organization selection
        this._ngZone.runOutsideAngular(_ => {
          // this._store.dispatch(new BookmanServiceAccountActions.GetBookmanServiceAccount());
          // Load service account directly to get loading state
          this._bookmanSvc.getBookmanServiceAccount().pipe(first()).subscribe(bookmanServiceAccount => {
            const account: BookmanServiceAccount = bookmanServiceAccount;
            this._store.dispatch(new GetBookmanServiceAccountSuccess(account));
            this.loading = false;
            this._cdr.detectChanges();
          }, err => {
            this.loading = false;
            this._cdr.detectChanges();
          });
        });
      });

    // const params = this._route.params.pipe(distinctUntilChanged());
    observableCombineLatest(this.featureSet$, this.selectedOrg$, this.myMembership$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([featureSet, organization, myMembership]) => {
       // const id = params['id'];
      //  this.id = id;
        if (this.id && Net.validUUID(this.id)) {
          // this.routes = [{
          //   title: 'PROJECT_ROOM.PROJECT_ROOMS',
          //   route: `/clients/${id}/workflows`
          // }, {
          //   title: 'Details',
          //   route: `/clients/${id}/details`
          // }];

          if (myMembership && featureSet && featureSet.hasBookman && featureSet.canUpdateBookmanSettings) {
            // this.routes.push({
            //   title: 'FIBU.CLIENT_SETTINGS',
            //   route: `/clients/${id}/fibu-settings`
            // })
            // this.activeLink = this.routes[2].title;
            this.clientId$.next(this.id);
          }
        }
        // Instance members were updated/assigned async (this.*): Detect changes!
        this._cdr.detectChanges();
      });

    // this.clientId$
    //   .pipe(filter(id => !!id), distinctUntilChanged(), takeUntil(this.onDestroy))
    //   .subscribe(id => {
    //     this._ngZone.runOutsideAngular(_ => {
    //       this._store.dispatch(new ClientActions.LoadOne(id));
    //       this._store.dispatch(new FibuActions.LoadByClient(id));
    //       this._store.dispatch(new BookmanClientActions.Refresh());
    //     });
    //   });

    this.client$ = this.clientId$.pipe(switchMap(id => {
      return this._store.select(ClientSelectors.getClientById(id));
    }));

    this.fibuProcess$ = this.clientId$.pipe(switchMap(id => {
      return this._store.select(FibuSelectors.getByClientId(id));
    }));

    this.client$
      .pipe(filter(c => !!c), takeUntil(this.onDestroy))
      .subscribe(client => {
        if (client && client.bookmanClientId && client.bookmanClientName) {
          this.selectedBookmanClient = new BookmanClient(Number(client.bookmanClientId), client.bookmanClientName);
          this._updateBookmanClientFormCtrl();
        }
      });

    this.fibuProcess$
      .pipe(filter(p => !!p), first(), takeUntil(this.onDestroy))
      .subscribe((process: Fibu) => {
        this._store.dispatch(new DmsFolderActions.Refresh(DmsAccountType.Organization));
        this.selectedFolderId$.next(process.dmsFolderId);
        this.currentSelectedDmsFolder = process.dmsFolderId;
        if (process) {
          this.settingsForm.patchValue({
            bookmanCockpitEnabled: process.bookmanCockpitEnabled,
            fiveFSyncEnabled: process.fiveFSyncEnabled,
            documentExpiryTime: process.documentExpiryTime
          });
          this.settingsForm.updateValueAndValidity();
          this._cdr.detectChanges();
        }
      });

    // this.selectedFolder$ = this.selectedFolderId$.pipe(switchMap(id => {
    //   return this._store.select(DmsFolderSelectors.getOne(id));
    // }));

    // this.settingsForm.get('dmsFolder').valueChanges.pipe(
    //   distinctUntilChanged(),
    //   takeUntil(this.onDestroy)
    // ).subscribe(value => {
    //   this.validateDmsFolder(value);
    // });

    // this.selectedFolder$
    //   .pipe(takeUntil(this.onDestroy))
    //   .subscribe(dmsFolder => {
    //     this.settingsForm.patchValue({
    //       dmsFolder: {
    //         id: dmsFolder && dmsFolder.id,
    //         type: dmsFolder && dmsFolder.dmsAccountType,
    //         path: dmsFolder && dmsFolder.path,
    //         bookmanCockpitEnabled: dmsFolder && dmsFolder.bookmanCockpitEnabled,
    //       }
    //     });
    //     this.settingsForm.updateValueAndValidity();
    //     this._cdr.detectChanges();
    //   });

    observableCombineLatest(this.administrationRights$, this.fibuProcess$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([admin, fibuProcess]) => {
        this.showAuditTrail$.next(!!admin && !!fibuProcess);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.showAuditTrail$.complete();
    this.selectedFolderId$.complete();
    this.clientId$.complete();
  }

  selectBookmanClient($event) {
    const value = $event.value;
    this.bookmanClients$.pipe(first()).subscribe(cs => {
      this.selectedBookmanClient = cs.find(c => c.id === value);
      this._cdr.detectChanges();
    });
  }

  refreshBookmanClients() {
    this._store.dispatch(new BookmanClientActions.Refresh());
  }

  submitSettings() {
    const value = this.settingsForm.value;
    if (this.settingsForm.get('bookmanClientId').dirty) {
      this.client$.pipe(filter(c => !!c), first(), takeUntil(this.onDestroy))
        .subscribe(client => {
          if (client) {
            client.bookmanClientId = this.selectedBookmanClient.id;
            client.bookmanClientName = this.selectedBookmanClient.name;
            this.onSave.emit(client);
            this._store.dispatch(new ClientActions.Save(client));
          }
        });
    }

    if (this.selectedBookmanClient && value.dmsFolder) {
      const params: IFibuParams = {
        dmsFolderId: value.dmsFolder && value.dmsFolder.id,
        bookmanCockpitEnabled: value.bookmanCockpitEnabled,
        fiveFSyncEnabled: value.fiveFSyncEnabled,
        documentExpiryTime: value.documentExpiryTime
      }
      this._fibuSettingsSrv.update(this.id, params).pipe(first()).subscribe(client => {
        this._store.dispatch(new SlimFolderActions.Refresh(DmsAccountType.Organization));
        this._store.dispatch(new FibuActions.UpdateSuccess(client));
        this.currentSelectedDmsFolder = value.dmsFolder.id;
        this.settingsForm.markAsPristine();
      })
    }
  }

  private validateDmsFolder(folder) {
    const used = folder.bookmanCockpitEnabled && folder.id !== this.currentSelectedDmsFolder;
    this.settingsForm.get('dmsFolder').setErrors(used ? { used } : null)
  }

  folderSelection(folder) {
    this.selectedFolderId$.next(folder ? folder.id : null);
    this.settingsForm.get('dmsFolder').patchValue(folder);
    this.settingsForm.get('dmsFolder').markAsDirty();
    this.validateDmsFolder(folder);
  }

  openAuditTrail() {
    this.auditTrailModal.openDialog()
  }

  private _updateBookmanClientFormCtrl() {
    this.settingsForm.patchValue({ bookmanClientId: this.selectedBookmanClient.id });
    this.settingsForm.updateValueAndValidity();
    this._cdr.detectChanges();
  }
}
