<fivef-dialog [title]="dialogTitle" [isCallToAction]="true">
  <div *ngIf="form">
    <ng-container *ngIf="alert">
      <dvtx-info-block [text]="alert.text"></dvtx-info-block>
    </ng-container>

    <div [formGroup]="form">
      <fivef-input-label>{{ 'GENERAL.TITLE' | translate }}</fivef-input-label>
      <mat-form-field>
        <input matInput formControlName="display_name" required
               autofocus>
      </mat-form-field>
    </div>

    <div class="row py-1">
      <div *ngIf="uploader.queue.length == 0" class="col-12 mb-2">
        <div class="fivef-upload--dropzone ff-drag-n-drop"
             ng2FileDrop
             [uploader]="uploader"
             [ngClass]="{'file-over': hasBaseDropZoneOver}"
             (fileOver)="fileOverBase($event)">
          <div class="fivef-upload--icon">
            <mat-icon aria-label="upload">file_upload</mat-icon>
          </div>

          <div class="ff-drag-n-drop--button">
            <label class="fivef-upload--file-upload-label btn-outline waves-effect"
                   for="files-uploader">
              <input type="file"
                     class="fivef-upload--button-outside"
                     ng2FileSelect
                     [uploader]="uploader"
                     multiple
                     id="files-uploader"/>{{ 'UPLOAD.DROP_ZONE_BUTTON' | translate }}</label>
            <p>
              {{ 'UPLOAD.SUPPORTED_TYPES' | translate }} jpeg, jpg, gif, png max {{ maxFileSize }}KB
              <!--<br />-->
              <!--<mat-icon [matTooltip]="'Supported file types: ' + supportedFileTypes.join(', ')">info</mat-icon>-->
            </p>
          </div>
        </div>
      </div>
      <ul class="col-12">
        <li class="import-error" *ngFor="let file of getInvalidFiles()">
          <mat-icon aria-label="import error" class="import-error-sign">priority_high</mat-icon>
          <!--<p class="text-mutted">File can not be uploaded:</p>-->
          <p><strong>{{ file.name }}<small>({{ file.reason }})</small></strong></p>
        </li>
      </ul>
    </div>

    <h3 *ngIf="uploader.queue.length > 0">{{ 'GENERAL.SELECTED' | translate }}</h3>
    <div class="row" *ngFor="let fileItem of uploader.queue; let x = index">
      <div class="col-12 uploaded-file-icon">
        <mat-icon style="vertical-align: middle">image</mat-icon>
        <div>{{ fileItem.file.name }}</div>
        &nbsp;&nbsp;&nbsp;
        <button mat-button (click)="uploader.queue[x].remove()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </div>

  <div fivef-dialog-footer>
    <button type="reset" mat-button mat-dialog-close>{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button type="submit" (click)="submit()"
            [disabled]="form.pristine || !uploader.getNotUploadedItems().length || getInvalidFiles().length > 0 || !form.valid"
            mat-raised-button color="primary">{{ submitButtonTitle | translate }}
    </button>
  </div>
</fivef-dialog>
