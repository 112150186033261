<fivef-dialog [title]="'PROJECT_ROOM.ADD_AND_INVITE_CONTACT_BUTTON'" [isCallToAction]="true">
  <div class="fivef-add-and-invite">
    <div class="mb-1">
      <dvtx-info-block [icon]="'info'"
                       [text]="'PROJECT_ROOM.ADD_AND_INVITE_CONTACT_INFO' | translate"></dvtx-info-block>
    </div>

    <form [formGroup]="form" novalidate>
      <div class="fivef-icon-content fivef-icon-content--floating-labels">
        <mat-icon>email</mat-icon>
        <div class="fivef-icon-content--content">
          <mat-form-field>
            <mat-label>{{ 'CONTACTS.EMAIL' | translate }}</mat-label>
            <input matInput formControlName="email" email required/>
          </mat-form-field>
        </div>
      </div>

      <div class="fivef-icon-content fivef-icon-content--floating-labels">
        <mat-icon>person</mat-icon>
        <div class="fivef-icon-content--content">
          <mat-form-field>
            <mat-label>{{ 'CONTACTS.FIRST_NAME' | translate }}</mat-label>
            <input matInput formControlName="first_name" required/>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CONTACTS.LAST_NAME' | translate }}</mat-label>
            <input matInput formControlName="last_name" required/>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div fivef-dialog-footer>
    <button mat-button (click)="closeDialog()">{{ 'GENERAL.CANCEL_ACTION' | translate }}</button>
    <button mat-raised-button color="primary" [disabled]="submitOnGoing || form.invalid" (click)="addParticipant()">
      {{ 'PROJECT_ROOM.ADD_AND_INVITE_SEND_BUTTON' | translate }}
    </button>
  </div>
</fivef-dialog>
