import {IResource} from 'app/shared/modules/api/models/resource.interface';
import {BomNodeDataColumn} from '../process-management/process-management';

export class WorkflowTemplate implements IResource {
  readonly type = 'workflow_template';


  total: number;
  perPage: number;
  records: number;

  constructor(public id: string,
              public templateType: TemplateType,
              public title: string = '',
              public description: string = '',
              public visibility: TemplateVisibility,
              public clients: string[],
              public creator: string,
              public color: string,
              public createdAt: Date,
              public data?: CollectoTemplateDataPayload | ProjectTemplateData,
              public updatedAt?: Date,
              public categoryId?: string,
              public lockedAt?: Date,
              public blockedAt?: Date,
              public statistics?: { usageCount: number },
              public history?: { performerId: string }) {
  }
}

export enum TemplateType {
  Collecto = 'collecto',
  Project = 'project',
}

export enum TemplateStatus {
  Open = 0,
  Blocked = 1,
  Global = 2,
  Personal = 3
}

export enum TemplateVisibility {
  Hidden = 'hidden',
  Global = 'global',
  Personal = 'personal',
}

export enum TemplateLoadingScope {
  Personal = 'personal',
  Organizational = 'admin',
}

/**
 * DTO for Collecto template data.
 *
 * Combines process properties like title, description, the nodes, the upload and
 * comment permission and the column definitions as aggregated object.
 *
 * For API payload use CollectoTemplateDataPayload with underscore payload syntax
 * as API convention.
 */
export interface CollectoTemplateDataDto {
  title: string,
  description: string,
  permitUpload: string,
  permitComments: string,
  columnSetup: BomNodeDataColumn[],
  children: [
    {
      id: string,
      title: string,
      color?: string,
      items: [
        {
          id: string,
          title: string,
          due_date?: string,
          description?: string,
          priority?: string
          multi_choice_enabled,
          multi_choice_options,
          choice_enabled,
          choice_options,
          response_enabled,
          important,
          low_hanging_fruit
        }
      ]
    }
  ]
  /**
   * Note: The project template API uses the children property for processes and
   * categories/items for Collectos.
   */
  categories?: [
    {
      id: string,
      title: string,
      color?: string,
      items: [
        {
          id: string,
          title: string,
          due_date?: string,
          description?: string,
          priority?: string,
          multi_choice_enabled,
          multi_choice_options,
          choice_enabled,
          choice_options,
          response_enabled,
          important,
          low_hanging_fruit
        }
      ]
    }
  ]
}

/**
 * Collecto template POST body payload.
 *
 * Note: Corresponds to CollectoTemplateDataDto in underscore syntax by convention.
 */
export interface CollectoTemplateDataPayload {
  title: string,
  description: string,
  permit_upload: string,
  permit_comments: string,
  column_setup: BomNodeDataColumn[],
  children: [
    {
      id: string,
      title: string,
      color?: string,
      items: [
        {
          id: string,
          title: string,
          due_date?: string,
          description?: string,
          priority?: string
        }
      ]
    }
  ]
}

export interface ProjectTemplateData {
  title: string,
  color: string,
  icon: string,
  isSvgIcon: boolean,
  description: string,
  dmsFolderId: string,
  type: string,
  subStorage?: string,
  children: ProjectTemplateData[]
  permitUpload: string,
  permitComments: string
}

export interface ITemplateParams {
  templateType?: TemplateType;
  title?: string;
  description?: string;
  status?: TemplateStatus;
  visibility?: TemplateVisibility;
  clients?: string[];
  creator?: string;
  color?: string;
  data?: CollectoTemplateDataPayload | ProjectTemplateData;
  categoryId?: string;
  published?: boolean;
}

export interface IProjectTemplateParams {
  title: string;
  description: string;
  dueDate: Date;
  clientId: string;
  color: string;
  dmsFolderId: string;
  dmsAccountType: string;
  children: ProjectTemplateData[];
  permitUpload: string,
  permitComments: string
}
