import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
  FeatureSelectors,
  MembershipSelectors,
  OrganizationSelectors,
  TenantOrganizationActions,
  TenantTenantActions
} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {map, distinctUntilChanged, filter, first, takeUntil} from 'rxjs/operators';
import {Membership} from 'app/models/membership.model';
import {LoadMy} from 'app/+store/membership/membership.actions';
import {ActivatedRoute} from '@angular/router';
import {User} from '../../../../models/user';
import {Feature} from 'app/+store/feature/feature';
import {ProjectRoomAdmin} from 'app/+store/process-role/process-role';
import {ProcessRoleService} from 'app/+store/process-role/process-role.service';
import {AngularTokenService} from 'angular-token';
import {TenantTenantService} from 'app/+store/tenant/tenant/tenant.service';
import {FivefNavLink} from '../../../../lib/fivef-ui/navigation/fivef-nav-link/fivef-nav-link.interface';

@Component({
  selector: 'dvtx-organization-navigator',
  templateUrl: './organization-navigator.component.html',
  styleUrls: ['./organization-navigator.component.scss']
})
export class OrganizationNavigatorComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  public tenantRoute: string[] = null;

  myMembership: Observable<Membership | undefined>;
  orgaId: Observable<string>;
  davSync = false;
  featureSet$: Observable<Feature>;
  projectRoomAdminOwnership$ = new BehaviorSubject<boolean>(false);

  constructor(private _store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private _processRoleSvc: ProcessRoleService,
              private _svc: TenantTenantService,
              private _tokenSvc: AngularTokenService,
              private _cdr: ChangeDetectorRef,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this._store.select(OrganizationSelectors.getSelectedId)
      .pipe(
        filter(id => !!id),
        distinctUntilChanged(),
        takeUntil(this.onDestroy)
      )
      .subscribe(id => {
        this._store.dispatch(new LoadMy(id));
        this._loadProjectRoomAdmins();
      });
    this.myMembership = this._store.select(MembershipSelectors.getMyMembership);

    this.orgaId = this.activatedRoute.params.pipe(map((params) => params.id));

    this._store.select('currentUser').pipe(
      takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        if (user && user.roles) {
          this.davSync = !!user.roles.find(role => role.symbol === 'dav_account');
        }
      });
    this.featureSet$ = this._store.select(FeatureSelectors.getCurrentFeatureSet);

    this._store.select(OrganizationSelectors.getSelectedId)
      .pipe(filter(oid => !!oid), first(), takeUntil(this.onDestroy))
      .subscribe((oid: string) => {
        this._svc.getTenantFor(oid)
          .pipe(first())
          .subscribe(tenant => {
            if (tenant) {
              const tenantId = tenant.id;
              this.tenantRoute = ['tenant', tenantId, 'overview'];
            }
          })
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private _loadProjectRoomAdmins() {
    setTimeout(_ => {
      this._ngZone.runOutsideAngular(_ => {
        this._processRoleSvc.getProjectRoomAdmins()
          .pipe(first())
          .subscribe((admins: ProjectRoomAdmin[]) => {
            const found = !!admins.find(admin => admin.email === this._tokenSvc.currentAuthData.uid);
            this._ngZone.run(_ => {
              this.projectRoomAdminOwnership$.next(found);
              this._cdr.detectChanges();
            });
          });
      });
    });
  }
}
