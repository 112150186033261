import * as model from './tenant.interface';
import * as tenantModel from './../../tenant/tenant/tenant.interface';
import {IResource} from 'app/shared/modules/api/models/resource.interface';

/// <reference path="./tenant.interface.ts" />
export namespace Operator {
  /**
   * 5F Operator tenant interface for the tenant administration.
   *
   * NOTE: The tenant administration available for customers has its own reduced / adapted
   * interface for public usage.
   */
  export class Tenant implements model.Operator.ITenant {
    readonly type = 'tenant_records';

    /**
     * Available login themes for the tenant.
     */
    available_login_themes: string[];

    /**
     * Color theme of the tenant based on main login theme class.
     */
    color_theme: tenantModel.Tenant.FivefAppColorTheme;
    color_theme_config: any;

    /**
     *
     * @param id
     * @param name Tenant name. Also used at browser tab.
     * @param sender_name Clear name used at e-mails.
     * @param domain Main domain of the portal.
     * @param email_domain Domain of e-mails of the tenant.
     * @param favicon_url
     * @param login_header German login message as welcome. Used above sign in.
     * @param login_header_en English login message as welcome. Used above sign in.
     * @param login_footer German login footer e.g. for a disclaimer.
     * @param login_footer_en English login footer e.g. for a disclaimer.
     * @param imprint_url Imprint URL of orgnization, shown at login.
     * @param createdAt
     * @param updatedAt
     */
    constructor(public id,
                public name: string,
                public sender_name: string,
                public domain: string,
                public email_domain: string,
                public favicon_url: string,
                public login_header: string,
                public login_header_en: string,
                public login_footer: string,
                public login_footer_en: string,
                public imprint_url: string,
                public createdAt: Date,
                public updatedAt: Date) {
    }
  }

  /**
   * Single Sign On configuration for tenant entities based on Microsoft Azure.
   */
  export class AzureSSOConfig implements IResource {
    readonly type = 'azure_sso_configs'

    /**
     *
     * @param id
     * @param name Visual identifier name for the operator.
     * @param domain E-Mail domain that requires SSO.
     * @param adTenantId Microsoft tenant ID. Can be retrieved by the Microsoft Azure configuration setup.
     * @param clientId Microsoft client ID of the Azure App.
     * @param clientSecret Microsoft secret of the Azure App.
     * @param fiveFTenantId
     * @param fiveFTenantName
     * @param fiveFTenantDomain
     * @param defaultOrganizationId Default organization at 5F and inside the 5F tenant where users should be onboarded.
     * @param optional Flag if Azure SSO authentication is optional. If set to true, the sign in setting of the user will select the authentication method (e.g. password or SSO).
     * @param adDepartmentOnboardingActivated Microsoft AD department onboarding strategy. If true, then the AD organization setting will be used to automatically onboard a user as employee.
     * @param adDepartmentOnboardingFallbackId Microsoft Fallback AD department where users are onboarded at 5F if no other organization AD setup matches.
     * @param createdAt
     * @param updatedAt
     */
    constructor(public id: string,
                public name: string,
                public domain: string,
                public adTenantId: string,
                public clientId: string,
                public clientSecret: string,
                public fiveFTenantId: string,
                public fiveFTenantName: string,
                public fiveFTenantDomain: string,
                public defaultOrganizationId: string,
                public optional: boolean,
                public adDepartmentOnboardingActivated: boolean,
                public adDepartmentOnboardingFallbackId: string,
                public createdAt: Date = null,
                public updatedAt: Date = null) {
    }
  }

  export class AzureSSODomainMapping implements IResource {
    readonly type = 'azure_sso_domain_mappings'

    constructor(public id: string,
                public sourceDomain: string,
                public targetDomain: string,
                public fiveFTenantId: string,
                public fiveFTenantName: string,
                public fiveFTenantDomain: string,
                public createdAt: Date,
                public updatedAt: Date) {
    }
  }
}
