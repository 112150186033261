import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {AppState} from '../reducers';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {User} from '../models/user';
import * as currentUserActions from '../actions/current-user-actions';
import {GetCurrentUser} from '../actions/current-user-actions';
import * as userActions from '../actions/user-actions';
import {AngularTokenService} from 'angular-token';
import {catchError, debounceTime, first, map, switchMap} from 'rxjs/operators';
import {AutoLogoutService} from '../services/auto-logout.service';

@Injectable()
export class CurrentUserEffects {
  debug = false;

  verifyCurrentUser$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(currentUserActions.GET_CURRENT_USER),
      switchMap((action: GetCurrentUser) => {
        return this._tokenSvc.validateToken().pipe(
          first(),
          map(res => {
            const response = res.data;
            // console.log('verifyCurrentUser$', response);
            const user = User.buildFrom(response);
            const authData = this._tokenSvc.currentAuthData;
            user.client = authData.client;
            user.accessToken = authData.accessToken;
            if (user && user.autoLogout) {
              this._autoLogoutSvc.minutesToLogout = user.autoLogout;
            } else {
              this._autoLogoutSvc.minutesToLogout = this._autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;
            }
            if (this.debug) {
              console.error('GetCurrentUser: Auto-Logout timout to: ', this._autoLogoutSvc.minutesToLogout);
            }

            return new currentUserActions.GetCurrentUserSuccess(user);
          }),
        catchError(error => {
          console.error('ERROR: Unauthenticated', action.payload);
          if (action.payload) {
            return of(new userActions.UserLogoutRequest());
          }
          // this._store.dispatch(new userActions.UserLogoutSuccess());
          // return Observable.of(new currentUserActions.ResetCurrentUser());
          this._autoLogoutSvc.minutesToLogout = this._autoLogoutSvc.DEFAULT_LOGOUT_TIMOUT;
          if (this.debug) {
            console.error('GetCurrentUser: Auto-Logout set to default to: ', this._autoLogoutSvc.minutesToLogout);
          }
          return of(new currentUserActions.ResetCurrentUser());
        })
        );
      })));

  /**
   * Effect listening on all store changes resetting the
   * auto-logout timer.
   */
  extendApplicationTimeout$ = createEffect(() => this._actions$.pipe(
    debounceTime(500),
    switchMap((_action: any) => {
      try {
        this._autoLogoutSvc.reset();
      } catch (error) {
        console.error(error)
      }
      return of(null);
    })
  ), {dispatch: false});

  constructor(private _tokenSvc: AngularTokenService,
              private _actions$: Actions,
              private _store: Store<AppState>,
              private _autoLogoutSvc: AutoLogoutService) {
  }
}
