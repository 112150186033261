<div [formGroup]="userProfileForm" class="fivef-user-profile-form">
  <!--  title and name section-->
  <div class="fivef-user-profile-form--icon-content-layout mb-2">
    <mat-icon class="fivef-user-profile-form--mat-icon">account_box</mat-icon>
    <div class="fivef-user-profile-form--content">
      <div class="row">
        <div class="col-12 col-sm-6">
          <dvtx-text-input-cva
            [label]="'CONTACTS.TITLE'"
            formControlName="honorific">
          </dvtx-text-input-cva>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <dvtx-text-input-cva
            [label]="'CONTACTS.FIRST_NAME'"
            formControlName="firstName"
            [requiredField]="true">
          </dvtx-text-input-cva>
        </div>
        <div class="col-12 col-sm-6">
          <dvtx-text-input-cva
            [label]="'CONTACTS.LAST_NAME'"
            formControlName="lastName"
            [requiredField]="true">
          </dvtx-text-input-cva>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <!--  email section-->
  <div class="fivef-user-profile-form--icon-content-layout mb-2">
    <mat-icon class="fivef-user-profile-form--mat-icon">email</mat-icon>
    <div class="fivef-user-profile-form--content">
      <div class="row">
        <div class="col-12 col-md-9">
          <dvtx-email-address-input
            [label]="'CONTACTS.EMAIL'"
            formControlName="mainEmailAddress"
            [errorMapper]="[{error:'InvalidEmailAddress', message: 'CONTACTS.ENTER_VALID_EMAIL' | translate }]"
          ></dvtx-email-address-input>
        </div>
        <div class="col-12">
          <dvtx-email-address-group-cva
            [emailAddresses]="myContactDetails?.emailAddresses"
            (addEmail)="addFurtherEmail($event)"
            [showAddEmail]="false"
            (removeEmail)="removeFurtherEmail($event)"
            [disabled]="disabled"
          ></dvtx-email-address-group-cva>
        </div>
      </div>
    </div>
  </div>
  <hr>

  <!--  phone number section-->
  <div class="fivef-user-profile-form--icon-content-layout mb-2" style="align-items: flex-start !important;">
    <mat-icon class="fivef-user-profile-form--mat-icon">phone</mat-icon>
    <div class="fivef-user-profile-form--content">
      <div class="row">
        <div class="col-12 col-md-9">
          <dvtx-telephone-input
            [formControlName]="'mainPhoneNumber'" [isType]="true"></dvtx-telephone-input>
        </div>
        <div class="col-12">
          <dvtx-telephone-group-cva
            [telephonenumbers]="myContactDetails?.phoneNumbers"
            (addPhone)="addFurtherPhoneNumber($event)"
            (removePhone)="removeFurtherPhoneNumber($event)"
            [disabled]="disabled">
          </dvtx-telephone-group-cva>
        </div>
      </div>
    </div>
  </div>
  <hr>

  <!--  address section -->
  <div class="fivef-user-profile-form--icon-content-layout mb-2">
    <mat-icon class="fivef-user-profile-form--mat-icon">place</mat-icon>
    <div class="fivef-user-profile-form--content">
      <div class="row">
        <div class="col-12 col-md-9">
          <dvtx-address-input-cva
            [label]="'CONTACTS.ADDRESS'"
            formControlName="mainAddress"
            [isDisabled]="disabled">
          </dvtx-address-input-cva>
        </div>
        <div class="col-12">
          <dvtx-address-input-group-cva
            [enableLocationOrType]="true"
            [addresses]="myContactDetails?.addresses"
            (addAddress)="addFurtherAddress($event)"
            (removeAddress)="removeFurtherAddress($event)"
            [disabled]="disabled">
          </dvtx-address-input-group-cva>
        </div>
      </div>
    </div>
  </div>
</div>
