<div class="fivef-two-factor-auth-dialog">
  <div class="mt-1 mb-2">
    <div class="d-flex mt-1 mb-2 align-items-center col-8">
      <div>
        <mat-icon class="dvtx-fg-color dvtx-fg-fivef-blue-200 mr-2">info</mat-icon>
      </div>
      <p [innerHTML]="'AUTH.DESCRIPTION_TFA' | translate" class="mb-0"></p>
    </div>
  </div>
  <div class="fivef-two-factor-auth-dialog--activate-button">
    <button mat-raised-button color="primary" class="fivef-two-factor-auth-dialog--status-activate"
            *ngIf="twoFactorAuthStatus?.phone && twoFactorAuthStatus?.confirmedAt && twoFactorAuthStatus?.activatedAt && !Activated2FA && Activated2FA != false"
            (click)="enable2faFunction(7);">
      <span>{{ 'AUTH.2FA_IS_ACTIVE' | translate }} </span>
      <p>{{ 'AUTH.DEACTIVATION_2FA' | translate }}</p>
    </button>
    <button mat-raised-button color="primary" *ngIf="Activated2FA === false"
            class="fivef-two-factor-auth-dialog--status-deactivated-successfully"
            (click)="enable2faFunction(1);">
      <span> {{ 'AUTH.ACTIVATION_2FA' | translate }}</span>
    </button>
    <button mat-raised-button color="primary" *ngIf="Activated2FA === true"
            class="fivef-two-factor-auth-dialog--status-acivated-successfully"
            (click)="enable2faFunction(7);">
      <span> {{ 'AUTH.ACTIVATED_2FA_SUCSESSFULY' | translate }} </span>
      <p> {{ 'AUTH.DEACTIVATION_2FA' | translate }}</p>
    </button>
    <button mat-raised-button color="primary" class="fivef-two-factor-auth-dialog--status-deactive"
            *ngIf="!(twoFactorAuthStatus?.activatedAt) && !Activated2FA && Activated2FA != false"
            (click)="enable2faFunction(1);">
      <span> {{ 'AUTH.ACTIVATION_2FA' | translate }}</span>
    </button>
    <p class="p-2" *ngIf="twoFactorAuthStatus?.updatedAt"><i> {{ 'FOOTER.LAST_UPDATED' | translate }}:
      {{ twoFactorAuthStatus?.updatedAt | date : 'short' }}</i></p>

    <div class="fivef-two-factor-auth-dialog--user-status">
      <br>
      <div>
        <div class="fivef-two-factor-auth-dialog--check-status" *ngIf="twoFactorAuthStatus?.activatedAt">
          <mat-icon class="fivef-color-status-success">check_circle</mat-icon>
          <p> {{ 'AUTH.2FA_IS_ACTIVE' | translate }} </p>
        </div>
        <div class="fivef-two-factor-auth-dialog--check-status" *ngIf="!(twoFactorAuthStatus?.activatedAt)">
          <mat-icon class="fivef-color-status-danger">cancel</mat-icon>
          <p> {{ 'AUTH.2FA_IS_DEACTIVE' | translate }} </p>
        </div>
        <div class="fivef-two-factor-auth-dialog--check-status"
             *ngIf="twoFactorAuthStatus?.totpSmsEnabled && twoFactorAuthStatus?.confirmedAt && twoFactorAuthStatus?.activatedAt">
          <mat-icon class="fivef-color-status-success">check_circle</mat-icon>
          <p> {{ 'AUTH.SMS_SERVICE_ACTIVE' | translate }} </p>
        </div>
        <div class="fivef-two-factor-auth-dialog--check-status" *ngIf="!(twoFactorAuthStatus?.activatedAt)">
          <mat-icon class="fivef-color-status-danger">cancel</mat-icon>
          <p> {{ 'AUTH.SMS_SERVICE_DEACTIVE' | translate }} </p>
        </div>
        <div class="fivef-two-factor-auth-dialog--check-status"
             *ngIf="twoFactorAuthStatus?.totpQrEnabled && twoFactorAuthStatus?.totpSmsEnabled && twoFactorAuthStatus?.confirmedAt && twoFactorAuthStatus?.activatedAt">
          <mat-icon class="fivef-color-status-success">check_circle</mat-icon>
          <p> {{ 'AUTH.APP_SERVICE_ACTIVE' | translate }} </p>
        </div>
        <div class="fivef-two-factor-auth-dialog--check-status" *ngIf="!twoFactorAuthStatus?.totpQrEnabled">
          <mat-icon class="fivef-color-status-danger">cancel</mat-icon>
          <p> {{ 'AUTH.APP_SERVICE_DEACTIVE' | translate }} </p>
          <a class="p-1"
             *ngIf="twoFactorAuthStatus?.totpSmsEnabled && !twoFactorAuthStatus?.totpQrEnabled && twoFactorAuthStatus?.activatedAt "
             (click)="enable2faFunction(5)">
            &nbsp; ({{ 'AUTH.ACTIVATE' | translate }})
          </a>
        </div>
      </div>
    </div>
  </div>
  <dvtx-add-authentification [twoFactorAuthEnabled]="twoFactorAuthEnabled | async" [activateStep]="StepActivation"
                             (activated)="submittedActivate($event)"></dvtx-add-authentification>

</div>
