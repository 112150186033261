import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CsvImportComponent} from './components/csv-import/csv-import.component';
import {FivefCsvImportDropzoneComponent} from '../../../lib/fivef-ui/upload/fivef-csv-import-dropzone/fivef-csv-import-dropzone.component';
import {SharedModule} from '../../shared.module';
import {CsvImportDisplayTableComponent} from './components/csv-import-display-table/csv-import-display-table.component';
import {CsvImportService} from './services/csv-import.service';
import {CsvImportErrorBoxComponent} from './components/csv-import-error-box/csv-import-error-box.component';
import {CsvImportDisplayComponent} from './components/csv-import-display/csv-import-display.component';
import {InViewportModule} from 'ng-in-viewport';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {
  FivefDisableInputHintsWrapperDirective
} from '../../../lib/fivef-ui/input/fivef-disable-input-hints-wrapper.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    InViewportModule,
    ScrollingModule,
    FivefDisableInputHintsWrapperDirective,
    FivefCsvImportDropzoneComponent
  ],
  declarations: [
    CsvImportComponent,
    CsvImportDisplayComponent,
    CsvImportDisplayTableComponent,
    CsvImportErrorBoxComponent
  ],
  providers: [
    CsvImportService
  ],
  exports: [
    CsvImportComponent,
    FivefCsvImportDropzoneComponent
  ]
})
export class CsvImportModule {
}
