import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  OperatorUserActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess,
  Save,
  SaveFail,
  SaveSuccess
} from './user.actions';
import * as model from './user';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {OperatorUserService} from './user.service';

@Injectable()
export class OperatorUserEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(OperatorUserActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.params).pipe(
        first(),
        concatMap((user: model.Operator.User) => {
          return [new CreateSuccess(user)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(OperatorUserActionTypes.Save),
    switchMap((action: Save) => {
      return this._svc.update(action.user).pipe(
        first(),
        switchMap((user: model.Operator.User) => {
          return [new SaveSuccess(user)];
        }),
        catchError(err => {
          console.error(err);
          return of(new SaveFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(OperatorUserActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        concatMap((user: model.Operator.User) => {
          return [new LoadOneSuccess(user)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(OperatorUserActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      const reset = action.params && action.params.page && action.params.page === 1;
      return this._svc.getAll(action.params).pipe(
        first(),
        concatMap((res: model.Operator.User[]) => {
          return [new LoadAllSuccess(res, res, reset)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(OperatorUserActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.id).pipe(
        first(),
        concatMap((item: model.Operator.User) => {
          return [new RemoveSuccess(item)];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: OperatorUserService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
