import {
  Component,
  Input} from '@angular/core';
import { SimpleAddress } from 'app/+store/contact/legacy/models/contact.interface';

@Component({
  selector: 'dvtx-address-input-form',
  templateUrl: './address-input-form.component.html',
  styleUrls: ['./address-input-form.component.scss']
})
export class AddressInputFormComponent {
  @Input() isDisabled = false;
  @Input() address: SimpleAddress = new SimpleAddress();
  constructor() {
  }
}
