<div class="fivef-page-sidebar--title">{{ 'TASK.TASKS_BY_PROJECT' | translate }}</div>

<div class="fivef-toolbar fivef-toolbar--start fivef-toolbar--content-spacing fivef-toolbar--content-spacing--end">
  <fivef-search [disabled]="!(nodes$ | async)?.length" (onChange)="applySearch($event)"></fivef-search>
</div>

<fivef-tree [nodes]="nodes$ | async"
            [search]="searchTerm"
            [expandRoot]="false"
            [showNoResult]="true"
            (onSelect)="selectNode($event)">
  <ng-container *fivefTreeNodeDef="let node">
    <fivef-process-tree-node [node]="node"></fivef-process-tree-node>
  </ng-container>
</fivef-tree>
