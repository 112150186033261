import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Process} from 'app/+store/process/process';
import {Router} from '@angular/router';
import {ProcessManagementService} from 'app/+store/process-management/process-management.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {FiveFRoleContext} from 'app/five-f/constants/five-f-role.constants';
import {FiveFMatButtonStyle} from 'app/five-f/constants/five-f-style.constants';
import {ProcessActions} from 'app/+store';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ProcessService} from 'app/+store/process/process.service';
import {first} from 'rxjs/operators';

export function deletionCodeValidator(_code: string): ValidatorFn {
  const code = _code;
  return (control: AbstractControl): { [key: string]: any } | null => {
    const match = code === control.value;
    return match ? null : {'invalidCode': {value: control.value}};
  };
}

@Component({
  selector: 'fivef-process-deletion-dialog',
  templateUrl: './fivef-process-deletion-dialog.component.html',
  styleUrls: ['./fivef-process-deletion-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefProcessDeletionDialogComponent implements OnInit {
  public readonly FiveFMatButtonStyle = FiveFMatButtonStyle;

  @Input()
  public buttonStyle: FiveFMatButtonStyle = FiveFMatButtonStyle.MenuItem

  @Input()
  public userContext: FiveFRoleContext = FiveFRoleContext.Participant;

  @Input()
  public canDeleteProcess = false;

  @Input()
  public process: Process;

  @ViewChild('processDeletionDialogTpl', {static: true})
  private processDeletionDialogTpl: TemplateRef<any>;
  private projectRoomDeletionDialogRef: MatDialogRef<any>;

  public submitOnGoing = false;

  public destroyArtifacts = true;
  public confirmation = null;

  public form: UntypedFormGroup;
  public code: string = null;

  @Output()
  onSuccess = new EventEmitter();

  constructor(private store: Store<AppState>,
              private processSvc: ProcessService,
              private processMgmtSvc: ProcessManagementService,
              private notifySvc: NotificationService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private cdr: ChangeDetectorRef,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openProjectRoomDeletionDialog($event = null) {
    if ($event) {
      $event.stopPropagation();
    }

    if (!this.process) {
      this.confirmation = null;
      this.code = null;
      this.destroyArtifacts = true;
      return;
    }

    this.code = this.process.id.substring(0, 6);
    this.form = this.fb.group({
      code: ['', deletionCodeValidator(this.code)]
    });

    this.confirmation = null;
    this.destroyArtifacts = true;

    // this._resetForm();

    this.projectRoomDeletionDialogRef = this.dialog.open(this.processDeletionDialogTpl);
    this.cdr.detectChanges();
  }

  closeProjectRoomDeletionDialog() {
    if (this.projectRoomDeletionDialogRef) {
      this.projectRoomDeletionDialogRef.close();
    }
  }

  public destroyProcess() {
    if (!this.process) return;

    const organizationId = this.process.organizationId;
    const id = this.process.id;
    const destroyArtifacts = this.destroyArtifacts;
    const confirmation = this.confirmation;

    this.submitOnGoing = true;

    const parentId = this.process.parentId;
    this.cdr.detectChanges();

    const apiCall = this.userContext === FiveFRoleContext.ProjectRoomAdmin
      ? this.processMgmtSvc.destroy(id, confirmation, destroyArtifacts)
      : this.processMgmtSvc.destroyAsUser(id, confirmation, destroyArtifacts);

    apiCall
      .subscribe(process => {
        this.submitOnGoing = false;

        if (this.userContext === FiveFRoleContext.ContainerResponsibility) {
          this.onSuccess.emit(process);

        } else if (this.userContext === FiveFRoleContext.ProjectRoomAdmin) {
          // If current user is inside the Project Room Mgmt then navigate to the all projects page.
          this.router.navigate(['process_management', 'admin', organizationId, 'project_rooms', 'all']);

          // If current user is in normal platform context, check on parent and navigate on access there or
          // navigate to dashboard in all other cases (no parent or no parent access).
        } else if (this.userContext === FiveFRoleContext.Participant && parentId) {
          this.processSvc.getOne(parentId).pipe(first()).subscribe(p => {
            this.store.dispatch(new ProcessActions.RunCommand(parentId, 'index', 'on_click'));

          }, err => {
            this.router.navigate(['dashboard']);
          });

        } else {
          this.router.navigate(['dashboard']);
        }
        this.closeProjectRoomDeletionDialog();
        this.cdr.detectChanges();

      }, err => {
        console.error(err);
        this.submitOnGoing = false;
        this.notifySvc.error('HTTP_ERROR.TRY_AGAIN');
        this.cdr.detectChanges();
        this.closeProjectRoomDeletionDialog();
      });
  }
}
