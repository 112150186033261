import {Action} from '@ngrx/store';
import {DmsFolder} from './dms-folder';
import {DmsAccountType} from './dms-folder.interface';

export enum DmsFolderActionTypes {
  LoadAll = '[DmsFolder] Load All',
  LoadAllSuccess = '[DmsFolder] Load All Success',
  LoadAllFail = '[DmsFolder] Load All Fail',
  LoadAllOrganization = '[DmsFolder] Load All',
  LoadOne = '[DmsFolder] Load One',
  LoadOneSuccess = '[DmsFolder] Load One Success',
  LoadOneFail = '[DmsFolder] Load One Fail',
  Create = '[DmsFolder] Create',
  CreateSuccess = '[DmsFolder] Create Success',
  CreateFail = '[DmsFolder] Create Fail',
  Delete = '[DmsFolder] Delete',
  DeleteSuccess = '[DmsFolder] Delete Success',
  DeleteFail = '[DmsFolder] Delete Fail',
  Refresh = '[DmsFolder] Refresh',
  RefreshSuccess = '[DmsFolder] Refresh Success',
  RefreshFail = '[DmsFolder] Refresh Fail',
  Rename = '[DmsFolder] Rename',
  RenameSuccess = '[DmsFolder] Rename Success',
  RenameFail = '[DmsFolder] Rename Fail',
  LockFolder = '[DmsFolder] LockFolder',
  LockFolderSuccess = '[DmsFolder] LockFolder Success',
  LockFolderFail = '[DmsFolder] LockFolder Fail',
}

export class LoadAll implements Action {
  readonly type = DmsFolderActionTypes.LoadAll;

  constructor(public dmsAccountType: DmsAccountType = DmsAccountType.Private) {
  }
}

export class LoadAllOrganization implements Action {
  readonly type = DmsFolderActionTypes.LoadAllOrganization;

  constructor(public dmsAccountType: DmsAccountType = DmsAccountType.Organization) {
  }
}

export class LoadAllSuccess implements Action {
  readonly type = DmsFolderActionTypes.LoadAllSuccess;

  constructor(public payload: DmsFolder[]) {
  }
}

export class LoadAllFail implements Action {
  readonly type = DmsFolderActionTypes.LoadAllFail;

  constructor(public payload: any) {
  }
}

export class Refresh implements Action {
  readonly type = DmsFolderActionTypes.Refresh;

  constructor(public dmsAccountType: DmsAccountType = DmsAccountType.Private) {
  }
}

export class RefreshSuccess implements Action {
  readonly type = DmsFolderActionTypes.RefreshSuccess;

  constructor(public payload: DmsFolder[]) {
  }
}

export class RefreshFail implements Action {
  readonly type = DmsFolderActionTypes.RefreshFail;

  constructor(public payload: any) {
  }
}

export class LoadOne implements Action {
  readonly type = DmsFolderActionTypes.LoadOne;

  constructor(public id: string, public accountType: DmsAccountType) {
  }
}

export class LoadOneSuccess implements Action {
  readonly type = DmsFolderActionTypes.LoadOneSuccess;

  constructor(public folder: DmsFolder) {
  }
}

export class LoadOneFail implements Action {
  readonly type = DmsFolderActionTypes.LoadOneFail;

  constructor(public payload: any) {
  }
}

export class Create implements Action {
  readonly type = DmsFolderActionTypes.Create;

  constructor(public name: string, public parent: string, public dmsAccountType: DmsAccountType) {
  }
}

export class CreateSuccess implements Action {
  readonly type = DmsFolderActionTypes.CreateSuccess;

  constructor(public folder: DmsFolder) {
  }
}

export class CreateFail implements Action {
  readonly type = DmsFolderActionTypes.CreateFail;

  constructor(public payload: any) {
  }
}

export class Rename implements Action {
  readonly type = DmsFolderActionTypes.Rename;

  constructor(public id: string, public name: string, public dmsAccountType: DmsAccountType) {
  }
}

export class RenameSuccess implements Action {
  readonly type = DmsFolderActionTypes.RenameSuccess;

  constructor(public folder: DmsFolder) {
  }
}

export class RenameFail implements Action {
  readonly type = DmsFolderActionTypes.RenameFail;

  constructor(public payload: any) {
  }
}

export class Delete implements Action {
  readonly type = DmsFolderActionTypes.Delete;

  constructor(public id: string, public dmsAccountType: DmsAccountType) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = DmsFolderActionTypes.DeleteSuccess;

  constructor(public folder: DmsFolder) {
  }
}

export class DeleteFail implements Action {
  readonly type = DmsFolderActionTypes.DeleteFail;

  constructor(public payload: any) {
  }
}

export class LockFolder implements Action {
  readonly type = DmsFolderActionTypes.LockFolder;

  constructor(public id: string, public lockUntil: string, public dmsAccountType: DmsAccountType = DmsAccountType.Organization) {
  }
}

export class LockFolderSuccess implements Action {
  readonly type = DmsFolderActionTypes.LockFolderSuccess;

  constructor(public folder: DmsFolder) {
  }
}

export class LockFolderFail implements Action {
  readonly type = DmsFolderActionTypes.LockFolderFail;

  constructor(public payload: any) {
  }
}

export type DmsFolderActions =
  | LoadAll
  | LoadAllSuccess
  | LoadAllFail
  | LoadAllOrganization
  | LoadOne
  | LoadOneSuccess
  | LoadOneFail
  | Create
  | CreateSuccess
  | CreateFail
  | Delete
  | DeleteSuccess
  | DeleteFail
  | Refresh
  | RefreshSuccess
  | RefreshFail
  | Rename
  | RenameSuccess
  | RenameFail
  | LockFolder
  | LockFolderSuccess
  | LockFolderFail;
