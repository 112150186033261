<ng-template #callToActionRef>
  <button color="primary" dvtxContact mat-raised-button type="button">
    <mat-icon aria-label="back" matPrefix>person_add</mat-icon>
    {{ 'ADDRESSBOOK.CREATE_CONTACT'| translate }}
  </button>
</ng-template>

<ng-template #globalActionRef>
  <div class="d-flex align-items-center">
    <ng-container [formGroup]="filterForm">
      <!--<dvtx-tooltip *ngIf="(filters?.options?.length + filters?.clients?.length) > 0" [tooltipText]="'FILTER.RESET_FILTER' | translate" [position]="'bottom left'">
        <button mat-icon-button  (click)="onClearAllFilters()" class="mat-icon-button-with-text">
          <mat-icon svgIcon="filter_disable"></mat-icon>
        </button>
      </dvtx-tooltip>-->
      <dvtx-filters-container [selectedCount]="filters?.options?.length + filters?.clients?.length"
                              class="ml-2">
        <div class="fivef-toolbar fivef-toolbar--space-between fivef-toolbar--content-spacing">
          <dvtx-api-autocompleted-input-select
            [placeholder]="'GENERAL.FILTER_SEARCH' | translate"
            [prefixIcon]="'search'"
            [form]="searchForm"
            [controlName]="'searchTerm'">
          </dvtx-api-autocompleted-input-select>
          <button *ngIf="(filters?.options?.length + filters?.clients?.length) > 0"
                  [matTooltip]="'FILTER.RESET_FILTER' | translate"
                  mat-icon-button (click)="onClearAllFilters()">
            <mat-icon svgIcon="filter_disable"></mat-icon>
          </button>
        </div>

        <div class="fivef-filter-dialog--content">
          <div class="fivef-filter-listing">
            <dvtx-filter-list-selection-clients
              [title]="'CLIENT.CLIENT' | translate"
              [options]="clients$ | async"
              [selectedOptions]="filters.clients"
              [search]="searchForm.get('searchTerm').value"
              [isAddressBook]="true"
              (onChangeSelection)="onFilterByClients($event)">
            </dvtx-filter-list-selection-clients>
          </div>

          <div class="fivef-filter-listing">
            <dvtx-filter-list-selection
              [title]="''"
              [options]="filterOptions"
              [selectedOptions]="filters.options"
              [search]="searchForm.get('searchTerm').value"
              (onChangeSelection)="onFilterByOptions($event)">
            </dvtx-filter-list-selection>
          </div>
        </div>
      </dvtx-filters-container>
    </ng-container>

    <button mat-icon-button
            *ngIf="isAdmin"
            [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="importContacts()">
        <mat-icon>unarchive</mat-icon>
        {{ 'ADDRESSBOOK.IMPORT_CONTACTS'| translate }}
      </button>

      <button mat-menu-item (click)="exportAddressBook()">
        <mat-icon svgIcon="excel_download"></mat-icon>
        {{ 'CONTACTS.ADDRESS_BOOK_EXPORT_ACTION'| translate }}
      </button>
    </mat-menu>
  </div>
</ng-template>

<dvtx-settings-header #settingsHeaderRef
                      [routes]="routes"
                      [activeLink]="activeLink"
                      [toOverview]="true"
                      [callToActionPortal]="callToActionPortal"
                      [globalActionPortal]="globalActionPortal"
                      (onSearch)="search($event)"
                      [enableSearch]="true"
                      [title]="'ORGANIZATION.ADDRESS_BOOK'"></dvtx-settings-header>

<dvtx-address-book class="five-f-settings-container"
                   [searchTerm]="searchTerm"
                   [contactType]="contactType"
                   [filterByClients]="filterByClients"
                   [clearAllFilters]="clearAllFilters"
                   [enableToolbar]="false"></dvtx-address-book>
