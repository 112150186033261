import {Action} from '@ngrx/store';
import {NaturalPerson} from 'app/models/natural-person.model';
import {SimpleAddress, SimpleEmailAddress, SimplePhoneNumber} from 'app/+store/contact/legacy/models/contact.interface';

export enum NaturalPersonActionTypes {
  LoadMy = '[Natural Person] Load my',
  LoadMySuccess = '[Natural Person] Load my Success',
  LoadMyFail = '[Natural Person] Load my Fail',
  LoadSuccess = '[Natural Person] Load Success',
  UpdateMy = '[Natural Person] Update My',
  UpdateMySuccess = '[Natural Person] Update My Success',
  AddFurtherAddress = '[Natural Person] Add Further Address',
  AddFurtherAddressSuccess = '[Natural Person] Add Further Address Success',
  RemoveFurtherAddress = '[Natural Person] Remove Further Address',
  RemoveFurtherAddressSuccess = '[Natural Person] Remove Further Address Success',
  AddFurtherPhoneNumber = '[Natural Person] Add Further Phone Number',
  AddFurtherPhoneNumberSuccess = '[Natural Person] Add Further Phone Number Success',
  RemoveFurtherPhoneNumber = '[Natural Person] Remove Further Phone Number',
  RemoveFurtherPhoneNumberSuccess = '[Natural Person] Remove Further Phone Number Success',
  AddFurtherEMail = '[Natural Person] Add Further Email',
  AddFurtherEMailSuccess = '[Natural Person] Add Further Email Success',
  RemoveFurtherEMail = '[Natural Person] Remove Further Email',
  RemoveFurtherEMailSuccess = '[Natural Person] Remove Further Email Success',
}

export class LoadMy implements Action {
  readonly type = NaturalPersonActionTypes.LoadMy
}

export class LoadMySuccess implements Action {
  readonly type = NaturalPersonActionTypes.LoadMySuccess;

  constructor(public payload: NaturalPerson) {
  }
}

export class LoadMyFail implements Action {
  readonly type = NaturalPersonActionTypes.LoadMyFail;

  constructor(public payload: any) {
  }
}

export class LoadSuccess implements Action {
  readonly type = NaturalPersonActionTypes.LoadSuccess;

  constructor(public payload: NaturalPerson[]) {
  }
}

export class UpdateMy implements Action {
  readonly type = NaturalPersonActionTypes.UpdateMy;

  constructor(public payload: NaturalPerson) {
  }
}

export class UpdateMySuccess implements Action {
  readonly type = NaturalPersonActionTypes.UpdateMySuccess;

  constructor(public payload: NaturalPerson) {
  }
}

export class AddFurtherAddress implements Action {
  readonly type = NaturalPersonActionTypes.AddFurtherAddress

  constructor(public payload: {
    personId: string,
    address: SimpleAddress
  }) {
  }
}

export class AddFurtherAddressSuccess implements Action {
  readonly type = NaturalPersonActionTypes.AddFurtherAddressSuccess

  constructor(public payload: {
    personId: string,
    address: SimpleAddress
  }) {
  }
}

export class RemoveFurtherAddress implements Action {
  readonly type = NaturalPersonActionTypes.RemoveFurtherAddress

  constructor(public payload: {
    personId: string,
    addressId: string
  }) {
  }
}

export class RemoveFurtherAddressSuccess implements Action {
  readonly type = NaturalPersonActionTypes.RemoveFurtherAddressSuccess

  constructor(public personId: string, public addressId: string) {
  }
}

export class AddFurtherEmail implements Action {
  readonly type = NaturalPersonActionTypes.AddFurtherEMail

  constructor(public payload: {
    personId: string,
    emailAddress: SimpleEmailAddress
  }) {
  }
}

export class AddFurtherEmailSuccess implements Action {
  readonly type = NaturalPersonActionTypes.AddFurtherEMailSuccess

  constructor(public payload: {
    personId: string,
    emailAddress: SimpleEmailAddress
  }) {
  }
}

export class RemoveFurtherEmail implements Action {
  readonly type = NaturalPersonActionTypes.RemoveFurtherEMail

  constructor(public payload: {
    personId: string,
    emailAddressId: string
  }) {
  }
}

export class RemoveFurtherEmailSuccess implements Action {
  readonly type = NaturalPersonActionTypes.RemoveFurtherEMailSuccess

  constructor(public personId: string, public emailAddressId: string) {
  }
}

export class AddFurtherPhoneNumber implements Action {
  readonly type = NaturalPersonActionTypes.AddFurtherPhoneNumber

  constructor(public payload: {
    personId: string,
    phoneNumber: SimplePhoneNumber
  }) {
  }
}

export class AddFurtherPhoneNumberSuccess implements Action {
  readonly type = NaturalPersonActionTypes.AddFurtherPhoneNumberSuccess

  constructor(public payload: {
    personId: string,
    phoneNumber: SimplePhoneNumber
  }) {
  }
}

export class RemoveFurtherPhoneNumber implements Action {
  readonly type = NaturalPersonActionTypes.RemoveFurtherPhoneNumber

  constructor(public payload: {
    personId: string,
    phoneNumberId: string
  }) {
  }
}

export class RemoveFurtherPhoneNumberSuccess implements Action {
  readonly type = NaturalPersonActionTypes.RemoveFurtherPhoneNumberSuccess

  constructor(public personId: string, public phoneNumberId: string) {
  }
}

export type NaturalPersonActions =
  | LoadMy
  | LoadMySuccess
  | LoadMyFail
  | LoadSuccess
  | UpdateMy
  | UpdateMySuccess
  | AddFurtherAddress
  | AddFurtherAddressSuccess
  | RemoveFurtherAddress
  | RemoveFurtherAddressSuccess
  | AddFurtherEmail
  | AddFurtherEmailSuccess
  | RemoveFurtherEmail
  | RemoveFurtherEmailSuccess
  | AddFurtherPhoneNumber
  | AddFurtherPhoneNumberSuccess
  | RemoveFurtherPhoneNumber
  | RemoveFurtherPhoneNumberSuccess
