import {IQuickCollector} from './quickcollector.interface';
import {Process} from '../process/process';
import {CollectorCategory} from '../collector-category/collector-category';
import {Client} from 'app/+store/client/client';
import {ProcessProfile} from '../process/process.interface';

export class QuickCollector implements IQuickCollector {
  readonly type = 'workflow_engine_quickcollectors';
  color: string;
  client: Client;
  bookmanCockpitEnabled = false;
  processType = 'quickcollector';
  profile: ProcessProfile = ProcessProfile.StandardProfile;
  year: number;
  month: number;

  constructor(public id: string,
              public parentId: string,
              public title: string,
              public description: string,
              public state: string,
              public creatorName: string,
              public creatorEmail: string,
              public ownerName: string,
              public ownerEmail: string,
              public recipients: string[],
              public workers: string[],
              public dmsFolderId: string,
              public createdAt: Date,
              public updatedAt: Date,
              public categories: CollectorCategory[] = [],
              public canCreateTask = true,
              public startsAt = null,
              public endsAt = null,
              public dueDate: Date = null,
              public startedAt = null,
              public identifier = null) {
  }

  clone(categories = []) {
    return QuickCollector.clone(this, categories);
  }

  static clone(collector, categories) {
    return new QuickCollector(
      collector.id,
      collector.parentId,
      collector.title,
      collector.description,
      collector.state,
      collector.ownerName,
      collector.ownerEmail,
      collector.creatorName,
      collector.creatorEmail,
      collector.recipients,
      collector.workers,
      collector.dmsFolderId,
      collector.createdAt,
      collector.updatedAt,
      collector.identifier,
      categories
    );
  }

  static asProcess(collector): Process {
    const p = new Process(
      collector.id,
      collector.title,
      null,
      collector.description,
      collector.dueDate,
      collector.createdAt,
      'workflow_engine_quickcollectors',
      collector.updatedAt
    );

    p.color = collector.color;
    if (collector.client) {
      p.client = collector.client;
    }
    return p;
  }
}


export class QuickCollectorItemOrder {
  readonly type = '';

  constructor(public nodes: any[]) {
  }
}
