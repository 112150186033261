import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {ExportCommentsComponent} from './components/export-comments/export-comments.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from 'app/shared/shared.module';
import {AlertsModule} from 'app/modules/alerts/alerts.module';
import {NoElementFoundModule} from '../no-element-found/no-element-found.module';
import {InstantMessagingModule} from 'app/modules/inbox/modules/instant-messaging/instant-messaging.module';

@NgModule({
  declarations: [
    ExportCommentsComponent
  ],
  exports: [
    ExportCommentsComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    AlertsModule,
    InstantMessagingModule,
    NoElementFoundModule
  ]
})
export class ExportCommentsModule {
}
