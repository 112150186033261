import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AvatarService} from 'app/shared/modules/user-account/components/avatar/avatar.service';
import {Comment} from 'app/+store/comment/comment';
import {CommonModule} from '@angular/common';
import {UserAccountModule} from '../../../../shared/modules/user-account/user-account.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FroalaViewModule} from 'angular-froala-wysiwyg';
import {TranslateModule} from '@ngx-translate/core';
import {FivefMarkReadDirective} from '../../input/fivef-mark-read.directive';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {FivefConfirm} from '../../util/fivef-confirm-dialog/fivef-confirm.decorator';
import {FivefMessageEditorComponent} from '../../input/fivef-message-editor/fivef-message-editor.component';
import {FivefReactionsComponent} from '../fivef-reactions/fivef-reactions.component';
import {FivefSeenbyComponent} from '../fivef-seenby/fivef-seenby.component';
import {AngularTokenService} from 'angular-token';
import {CommentReactionType, CommentResourceType} from '../../../../+store/comment/comment.interface';
import {MatRippleModule} from '@angular/material/core';

/**
 * Show comment.
 * - Provide options
 * - to do or remove a reaction.
 * - to reply to a comment.
 * - to provide a menu for edit or delete.
 * - to trigger read.
 * - to show read status.
 */
@Component({
  selector: 'fivef-comment',
  standalone: true,
  host: {'class': 'fivef-comment'},
  templateUrl: './fivef-comment.component.html',
  styleUrls: ['./fivef-comment.component.scss'],
  imports: [
    CommonModule,
    UserAccountModule,
    MatTooltipModule,
    FroalaViewModule,
    TranslateModule,
    FivefMarkReadDirective,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    FivefMessageEditorComponent,
    FivefReactionsComponent,
    FivefSeenbyComponent,
    MatRippleModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefCommentComponent implements OnInit {
  public editMode = false;

  private uid: string; // Email aka uid of current user.

  @Input()
  backtrackId: string;

  /**
   * Resource ID, currently only used for artifact comments.
   * Resource ID in this context is the artifact ID.
   */
  @Input()
  resourceId: string;

  @Input()
  resourceType: CommentResourceType = null;

  @Input()
  enableReply = true;

  @Input()
  canCreateComment = false;

  @Output()
  onMarkedRead = new EventEmitter();

  @Output()
  onSave = new EventEmitter<string>();

  @Output()
  onReply = new EventEmitter<string>();

  @Output()
  onDelete = new EventEmitter<string>();

  @Output()
  onReaction = new EventEmitter<CommentReactionType>();

  public showReplyEditor = false;

  public reply = '';

  public isAuthor = false;

  public _comment: Comment;
  @Input()
  private set comment(c: Comment) {
    this._comment = c;
    if (c) {
      this.isAuthor = this.uid === c.performer;
    }
  }

  private get comment(): Comment {
    return this._comment;
  }

  constructor(public avatarService: AvatarService,
              private tokenSvc: AngularTokenService,
              private cdr: ChangeDetectorRef) {
    this.uid = this.tokenSvc.currentUserData.uid;
  }

  ngOnInit(): void {
  }

  public cancelReply() {
    this.showReplyEditor = false;
    this.cdr.detectChanges();
  }

  public react($event: CommentReactionType) {
    this.onReaction.emit($event);
  }

  public enableEditMode() {
    this.editMode = true;
    this.showReplyEditor = false;
    this.cdr.detectChanges();
  }

  public enableReplyMode() {
    this.editMode = false;
    this.showReplyEditor = true;
    this.cdr.detectChanges();
  }

  public sendReply(message: string) {
    this.onReply.emit(message);
    this.showReplyEditor = false;
    this.cdr.detectChanges();
  }

  public saveComment(message: string) {
    this.onSave.emit(message);
    this.editMode = false;
    this.cdr.detectChanges();
  }

  public cancelEdit() {
    this.editMode = false;
    this.cdr.detectChanges();
  }

  @FivefConfirm({
    message: 'GENERAL.DELETE_COMMENT_QUESTION', // title: 'GENERAL.DELETE_COMMENT'
    icon: 'chat_bubble_outline',
    color: 'warn',
    confirmAction: 'GENERAL.DELETE_ACTION'
  })
  public deleteComment() {
    this.onDelete.emit(this._comment?.id);
    this.cdr.detectChanges();
  }
}
