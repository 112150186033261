<div class="dvtx-organization-master-data--settings" *ngIf="(orga)">
  <h3>{{ 'ORGANIZATION.MASTER_DATA_TITLE' | translate}}</h3>

  <div class="fivef-icon-input">
    <mat-icon>domain</mat-icon>

    <mat-form-field>
      <input matInput
             [(ngModel)]="orga.name"
             [placeholder]="'CONTACTS.ORG_NAME' | translate"
             [disabled]="readonly || !editmode"/>
    </mat-form-field>

    <dvtx-api-select-cva [elementName]="'CONTACTS.LEGAL_FORM' | translate"
                         listingType="legal-form"
                         listingPath="name"
                         [isDisabled]="readonly || !editmode"
                         [(ngModel)]="orga.legalFormId">
    </dvtx-api-select-cva>
  </div>

  <div class="fivef-icon-input">
    <mat-icon>email</mat-icon>

    <dvtx-email-address-input [(ngModel)]="orga.email"
                              [isDisabled]="readonly || !editmode">
    </dvtx-email-address-input>
  </div>

  <div class="fivef-icon-input">
    <mat-icon>phone</mat-icon>

    <dvtx-telephone-input [(ngModel)]="orga.telephone"
                          [isDisabled]="readonly || !editmode"
                          [isType]="true">
    </dvtx-telephone-input>
  </div>

  <div class="fivef-icon-input">
    <mat-icon>place</mat-icon>

    <dvtx-address-input-form [address]="orga.address" [isDisabled]="readonly || !editmode"></dvtx-address-input-form>
  </div>

  <hr>

  <h3>{{ 'CLIENT.DATEV_CONSULTANT_TITLE' | translate }}</h3>

  <div class="fivef-icon-input"
         [formGroup]="organizationProxyForm">
    <mat-icon svgIcon="datev"></mat-icon>

    <dvtx-text-input-cva [elementName]="'CLIENT.DATEV_CONSULTANT_ID' | translate" formControlName="consultantId"
                         [isDisabled]="readonly || !editmode"></dvtx-text-input-cva>
  </div>
</div>
