import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateSuccess,
  CreateFail,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Remove,
  RemoveSuccess,
  RemoveFail,
  ArtifactKeywordActionTypes
} from './artifact-keyword.actions';
import {ArtifactKeyword} from './artifact-keyword';
import {ArtifactKeywordService} from './artifact-keyword.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';

@Injectable()
export class ArtifactKeywordEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(ArtifactKeywordActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.organizationId, action.keyword).pipe(
        first(),
        concatMap((keyword: ArtifactKeyword) => {
          return [new CreateSuccess(keyword)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new CreateFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ArtifactKeywordActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.organizationId, action.id).pipe(
        first(),
        concatMap((keyword: ArtifactKeyword) => {
          return [new LoadOneSuccess(keyword)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new LoadOneFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(ArtifactKeywordActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.delete(action.organizationId, action.id).pipe(
        first(),
        concatMap((keyword: ArtifactKeyword) => {
          return [new RemoveSuccess(keyword)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new RemoveFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ArtifactKeywordActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.organizationId).pipe(
        first(),
        concatMap((res: ArtifactKeyword[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          this._notifyService.error('HTTP_ERROR.TRY_AGAIN')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ArtifactKeywordService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
