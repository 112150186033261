<ng-container *ngIf="isClient">
  <div class="row mb-2">
    <ng-container>
      <div class="col-12 d-flex flex-row align-items-center">
        <div style="padding-right: 15px;">
          <mat-icon>add_circle_outline</mat-icon>
        </div>
        <dvtx-recipient-autocomplete
          style="width: 100%"
          elementName="E-Mail"
          [showEmail]="true"
          [placeholder]="'CONTACTS.ADDRESS_BOOK_SEARCH'"
          (onSelect)="createContactClient($event)"
          [excludedIds]="excludedIds"
          [clientContact]="true"
          [loadNew]="false"
          [valueIsEmail]="true">
        </dvtx-recipient-autocomplete>
      </div>
    </ng-container>
  </div>
  <div class="dvtx-participant-role-dialog">
    <ul>
      <li>
        <div class="row m-0">
          <div class="col-6">
            {{ 'WORKFLOW_ENGINE.MEMBER' | translate }}
          </div>
          <div class="col-5 text-center">
            <div class="d-flex">
              <div>
                {{ 'ROLE.ROLE' | translate }}
              </div>
            </div>
          </div>
          <div class="col-1 text-center">
            <div class="d-flex justify-content-center">
              <div class="align-self-center">
                {{ 'GENERAL.REMOVE_ACTION' | translate }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul style="min-height: 300px; max-height: 400px; overflow-y: auto">
      <li class="m-0 p-1" *ngFor="let participant of selectedClient?.contacts; let i = index;">
        <div class="row m-0">
          <div class="col-6 dvtx-contact-chip-cell text-truncate">
            <dvtx-avatar [size]="'sm'" [email]="allContactsMap[participant?.contactId]?.email" [showEmail]="showEmail"
                         [showTitle]="true"></dvtx-avatar>
          </div>
          <div class="col-5 text-center align-self-center">
            <ng-container>
              <dvtx-title-editable
                [classNames]="'m-0'"
                [renameDoc]="true"
                (onChange)="updateContactClient(participant, $event)"
                [fontSize]="'null'"
                [editIcon]="true"
                [removeIcon]="true"
                [placeholder]="'CONTACTS.ADD_ROLE' | translate"
                [value]="participant?.role">
              </dvtx-title-editable>
            </ng-container>
          </div>
          <div class="col-1 text-center align-self-center">
            <button mat-icon-button (click)="$event.preventDefault(); removeContactClient(participant)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </li>

    </ul>
  </div>
</ng-container>
<ng-container *ngIf="isContact">
  <div class="row mb-2">
    <ng-container>
      <div class="col-12 d-flex flex-row align-items-center">
        <div style="padding-right: 15px;">
          <mat-icon>add_circle_outline</mat-icon>
        </div>
        <fivef-client-select [readOnly]="false"
                             [(ngModel)]="clientSelection"
                             (ngModelChange)="createClientContact($event)"
                             [ngModelOptions]="{standalone: true}"
                             [placeholder]="'PROJECT.CREATE_FORM.CLIENT_FIELD_DESCRIPTION'"
                             [showCreateButton]="false"
                             [excludedIds]="excludedIds"
                             style="width: 80%;"
                             #clientSelectionCvaComponent></fivef-client-select>
      </div>
    </ng-container>
  </div>
  <div class="dvtx-participant-role-dialog">
    <ul>
      <li>
        <div class="row m-0">
          <div class="col-6">
            {{ 'CLIENT.CLIENT' | translate }}
          </div>
          <div class="col-5 text-center">
            <div class="d-flex ">
              <div>
                {{ 'ROLE.ROLE' | translate }}
              </div>
            </div>
          </div>
          <div class="col-1 text-center">
            <div class="d-flex justify-content-center">
              <div class="align-self-center">
                {{ 'GENERAL.REMOVE_ACTION' | translate }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul style="min-height: 300px; max-height: 400px; overflow-y: auto">
      <li class="m-0 p-1" *ngFor="let data of selectedContact?.clients; let i = index;">
        <div class="row m-0">
          <div [matTooltip]="allClientsMap[data?.clientId]?.name" style="position: relative;"
               class="col-6 dvtx-contact-chip-cell text-truncate">
            <mat-icon svgIcon="clients"></mat-icon> &nbsp;
            <strong style="position: absolute;">
              {{ (allClientsMap[data?.clientId]?.name | slice:0:30) + (allClientsMap[data?.clientId]?.name?.length > 30 ? '...' : '') }}
            </strong>
          </div>
          <div class="col-5 text-center align-self-center">
            <ng-container>
              <dvtx-title-editable
                [classNames]="'m-0'"
                [renameDoc]="true"
                [placeholder]="'CONTACTS.ADD_ROLE' | translate"
                (onChange)="updateContactClient(data, $event)"
                [fontSize]="'null'"
                [editIcon]="true"
                [removeIcon]="true"
                [value]="data?.role">
              </dvtx-title-editable>
            </ng-container>
          </div>
          <div class="col-1 text-center align-self-center">
            <button mat-icon-button
                    (click)="$event.preventDefault(); removeContactClient(data)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
