import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  TenantMemberActionTypes,
  Remove,
  RemoveFail,
  RemoveSuccess
} from './member.actions';
import * as model from './member';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TenantMemberService} from './member.service';

@Injectable()
export class TenantMemberEffects {
  create$ = createEffect(() => this.actions.pipe(
    ofType(TenantMemberActionTypes.Create),
    switchMap((action: Create) => {
      return this._svc.create(action.organizationId, action.params).pipe(
        first(),
        concatMap((member: model.Tenant.Member[]) => {
          return [new LoadAllSuccess(member)];
        }),
        catchError(err => {
          console.error(err);
          return of(new CreateFail(err));
        }));
    })
  ));

  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(TenantMemberActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.userId).pipe(
        first(),
        concatMap((res: model.Tenant.Member[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  remove$ = createEffect(() => this.actions.pipe(
    ofType(TenantMemberActionTypes.Remove),
    switchMap((action: Remove) => {
      return this._svc.destroy(action.organization, action.membership).pipe(
        first(),
        concatMap((item: model.Tenant.Member[]) => {
          return [new RemoveSuccess(item[0])];
        }),
        catchError(err => {
          console.error(err);
          return of(new RemoveFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: TenantMemberService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
