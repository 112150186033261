<div *ngIf="!user">
  <p>{{ 'REGISTRATION.VERIFICATION_LINK_CHECK' | translate }}</p>
  <mat-spinner [diameter]="40" [strokeWidth]="4" aria-label="loading..."></mat-spinner>
</div>

<!--  Use autocomplete="new-password" to prevent chrome pre-fill-->
<ng-container *ngIf="form">

  <!--E-mail section (readonly) -->
  <mat-label class="fivef-session--label">{{ 'Email' | translate }}</mat-label>
  <mat-form-field [appearance]="'outline'"
                  class="fivef-session-registration-form--email"
                  fivefDisableInputHints>
    <input matInput type="text"
           autocomplete="new-password" value="{{user?.email}}" disabled>
  </mat-form-field>

  <form [formGroup]="form" (ngSubmit)="submit()" novalidate>

    <div class="fivef-session-registration-form--name-wrapper">
      <!-- First name section -->
      <div class="fivef-session-registration-form--firstname">
        <mat-label class="fivef-session--label">{{ 'CONTACTS.FIRST_NAME' | translate }}</mat-label>
        <mat-form-field [appearance]="'outline'" fivefDisableInputHints>
          <input matInput type="text"
                 autocomplete="new-password"
                 (focus)="hidePassword()" [formControlName]="'first_name'">
        </mat-form-field>
      </div>

      <!-- Last name section -->
      <div class="fivef-session-registration-form--lastname">
        <mat-label class="fivef-session--label">{{ 'CONTACTS.LAST_NAME' | translate }}</mat-label>
        <mat-form-field [appearance]="'outline'" fivefDisableInputHints>
          <input matInput
                 type="text"
                 [formControlName]="'last_name'" autocomplete="new-password"
                 (focus)="hidePassword()">
        </mat-form-field>
      </div>
    </div>

    <!-- Password field -->
    <div class="fivef-session-registration-form--password-outer-wrapper">
      <mat-label class="fivef-session--label">{{ 'AUTH.PASSWORD' | translate }}</mat-label>
      <div class="fivef-session-registration-form--password-wrapper">
        <mat-form-field [appearance]="'outline'" fivefDisableInputHints>
          <input matInput [type]="showPassword" [formControlName]="'password'"
                 #password
                 (keyup)="verifyPassword(password.value)"
                 autocomplete="new-password">
          <button mat-icon-button matSuffix
                  (click)="togglePasswordVisibility()"
                  class="fivef-session-login--password-icon">
            <mat-icon *ngIf="showPassword === FivefInputFieldType.Password">visibility</mat-icon>
            <mat-icon *ngIf="showPassword === FivefInputFieldType.Text">visibility_off</mat-icon>
          </button>
        </mat-form-field>

        <mat-icon matSuffix
                  [matTooltip]="'PASSWORD.PASSWORD_POLICY' | translate"
                  class="fivef-session-registration-form--password-policy-info">info
        </mat-icon>
      </div>
    </div>

    <!-- Password validation -->
    <div class="fivef-session-registration-form--password-error-validation">
          <span *ngIf="verifyPasswordResponse && verifyPasswordResponse.level"
                [class]="'fivef-session-registration-form--password-error-validation--' + verifyPasswordResponse.level">
              {{ verifyPasswordResponse.message | translate }}&nbsp;
            <span
              *ngIf="passwordform.errors?.StrongPassword">{{ "AUTH.MIN" | translate }} {{ PASSWORD_MIN_LENGTH }} {{ "AUTH.CHARACTERS" | translate }}</span>
      <span
        *ngIf="passwordform.errors?.WeakPassword">{{ "AUTH.MIN" | translate }} {{ PASSWORD_MIN_LENGTH }} {{ "AUTH.CHARACTERS" | translate }}</span>
      <span
        *ngIf="passwordform.errors?.minlength">{{ "AUTH.MIN" | translate }} {{ PASSWORD_MIN_LENGTH }} {{ "AUTH.CHARACTERS" | translate }}</span>
          </span>

    </div>

    <!-- Confirmation password field -->
    <div>
      <mat-label class="fivef-session--label">{{ 'PASSWORD.CONFIRM_PASSWORD' | translate }}</mat-label>
      <mat-form-field [appearance]="'outline'" fivefDisableInputHints>
        <input matInput class="fivef-session-registration--password"
               [type]="showPassword"
               [formControlName]="'passwordConfirm'"
               autocomplete="new-password" (focusout)="hidePassword()" (focus)="hidePassword()">
        <button mat-icon-button matSuffix
                (click)="togglePasswordVisibility()"
                class="fivef-session-login--password-icon">
          <mat-icon *ngIf="showPassword === FivefInputFieldType.Password">visibility</mat-icon>
          <mat-icon *ngIf="showPassword === FivefInputFieldType.Text">visibility_off</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="fivef-session-registration-form--wrong-password-error">
      <span *ngIf="(passwordConfirmform.errors?.MatchPassword) && passwordConfirmform.dirty">
        {{ 'PASSWORD.PASSWORDS_DONT_MATCH' | translate }}
      </span>
    </div>

    <div class="d-flex mt-2 align-items-center" style="margin-left: -10px">
      <mat-checkbox (change)="readTerms($event)"
                    class="terms-checkbox fivef-session-registration--terms-checkbox"
                    color="primary"
                    formControlName="terms"
                    required></mat-checkbox>
      <div class="ml-2"
           [innerHTML]="'REGISTRATION.TERMS_ACCEPT' | translate">
      </div>
    </div>

    <!--      submit button-->
    <div class="mx-0 mt-2">
      <div class="d-flex justify-content-end">
        <button mat-raised-button
                color="primary" class="fivef-session-registration--button"
                [disabled]="form.invalid || passwordform.errors?.minlength || passwordform.errors?.WeakPassword || passwordform.errors?.MatchPassword"
                type="submit">
          {{ 'GENERAL.NEXT_ACTION' | translate }}
        </button>
      </div>
    </div>
  </form>
</ng-container>
