import {LOCALE_ID, NgModule} from '@angular/core';
import {userProfileRoutes} from './user-profile.routes';
import {SharedModule} from '../../shared/shared.module';
import {ResetPasswordService} from './services/reset-password.service';
import {UserProfileComponent} from './user-profile.component';
import {NavigatorComponent} from './components/navigator/navigator.component';
import {ProfileContainerComponent} from './containers/profile-container/profile-container.component';
import {DashboardModule} from '../two-factor-auth/dashboard.module';
import {ResetPasswordDialogComponent} from './components/reset-password-dialog/reset-password-dialog.component';
import {HttpClientModule} from '@angular/common/http';
import {FoxdoxModule} from '../foxdox/foxdox.module';
import {NotificationSettingsModule} from 'app/modules/inbox/modules/notification-settings/notification-settings.module';
import {CustomizationModule} from '../customization/customization.module';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {TrialPopupService} from 'app/modules/user-profile/services/trial-popup.service';
import {UserProfileFormComponent} from './components/user-profile-form/user-profile-form.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AlertsModule} from 'app/modules/alerts/alerts.module';
import {AutoLogoutTimerComponent} from './components/auto-logout-timer/auto-logout-timer.component';
import {DocumentSignatureUserAccountModule} from '../document-signature/modules/document-signature-user-account/document-signature-user-account.module';
import {DocumentSignatureDefaultSignatureSettingsComponent} from './containers/document-signature-default-signature-settings/document-signature-default-signature-settings.component';
import {OrganizationCardModule} from '../../five-f/organization-card/organization-card.module';
import {DocumentSignatureAccountSettingsComponent} from './containers/document-signature-account-settings/document-signature-account-settings.component';
import {DocumentSignatureFpSignAccountComponent} from './containers/document-signature-fp-sign-account/document-signature-fp-sign-account.component';
import {DocumentSignatureProfilePreviewModule} from './modules/document-signature-profile-preview/document-signature-profile-preview.module';
import {MobileLoginContainerComponent} from './containers/mobile-login-container/mobile-login-container.component';
import {DataPrivacyContainerComponent} from './containers/data-privacy-container/data-privacy-container.component';
import {UsageTermsContainerComponent} from './containers/usage-terms-container/usage-terms-container.component';
import {ProfileSettingsNavigationContainerComponent} from './containers/profile-settings-navigation-container/profile-settings-navigation-container.component';
import {TwoFactorAuthContainerComponent} from './containers/two-factor-auth-container/two-factor-auth-container.component';
import {PasswordResetContainerComponent} from './containers/password-reset-container/password-reset-container.component';
import {AutoLogoutContainerComponent} from './containers/auto-logout-container/auto-logout-container.component';
import {TwoFactorAuthDialogComponent} from './components/two-factor-auth-dialog/two-factor-auth-dialog.component';
import {QRCodeModule} from 'angularx-qrcode';
import {FivefDialogComponent} from '../../lib/fivef-ui/common/fivef-dialog/fivef-dialog.component';
import {AddressBookTableModule} from '../address-book/modules/address-book-table/address-book-table.module';

@NgModule({
  imports: [
    userProfileRoutes,
    SharedModule,
    DocumentSignatureUserAccountModule,
    DashboardModule,
    HttpClientModule,
    FoxdoxModule,
    NotificationSettingsModule,
    CustomizationModule,
    ImageCropperModule,
    AlertsModule,
    OrganizationCardModule,
    DocumentSignatureProfilePreviewModule,
    QRCodeModule,
    FivefDialogComponent,
    AddressBookTableModule
  ],
  declarations: [
    UserProfileComponent,
    NavigatorComponent,
    ProfileContainerComponent,
    TwoFactorAuthContainerComponent,
    PasswordResetContainerComponent,
    AutoLogoutContainerComponent,
    ResetPasswordDialogComponent,
    ResetPasswordComponent,
    UserProfileFormComponent,
    AutoLogoutTimerComponent,
    DocumentSignatureAccountSettingsComponent,
    DocumentSignatureDefaultSignatureSettingsComponent,
    DocumentSignatureFpSignAccountComponent,
    MobileLoginContainerComponent,
    DataPrivacyContainerComponent,
    UsageTermsContainerComponent,
    ProfileSettingsNavigationContainerComponent,
    TwoFactorAuthDialogComponent
  ],
  exports: [
    ResetPasswordComponent,
    UserProfileComponent
  ],
  providers: [
    ResetPasswordService,
    TrialPopupService,
    {provide: LOCALE_ID, useValue: 'de-DE'}
  ]
})
export class UserProfileModule {
}
