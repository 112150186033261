<!--dropdown viewmode-->
<li *ngIf="viewMode == 'dropdown'" class="nav-item nav-item-locale-switcher dropdown"
    [class.nav-item]="showUnderlineOnHover">
  <a class="nav-link dropdown-toggle account-drop-down"
     [class.dvtx-show-bottom-line-on-hover]="showUnderlineOnHover"
     style="padding-left: 6px"
     type="button"
     id="navbarDropdownMenuLink"
     data-toggle="dropdown"
     aria-haspopup="true"
     aria-expanded="true">
    <ng-template [ngIf]="showFlag" [ngIfElse]="showText">
      <span class="flag-icon flag-icon-{{selectedLang === 'de' ? 'de' : 'gb'}}"></span>
    </ng-template>
    <ng-template #showText>
      {{ selectedLang  | uppercase }}
    </ng-template>
  </a>

  <ul class="dropdown-menu dropdown-menu-right text-capitalize" aria-labelledby="navbarDropdownMenuLink"
      data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
    <li *ngFor="let locale of locales$ | async" class="dropdown-item-wrapper">
      <a (click)="selectLanguage(locale.code)" class="dropdown-item">
        <span class="flag-icon flag-icon-{{locale.code === 'de' ? 'de' : 'gb'}}"></span>
        &nbsp;<span style="text-transform: uppercase">{{ locale.code }}</span>
      </a>
    </li>
  </ul>
</li>

<!--inline viewmode-->
<div *ngIf="viewMode == 'inline'" class="d-flex">
  <div *ngFor="let locale of locales$ | async" class="pr-4">
    <a (click)="selectLanguage(locale.code)" [class.active]="selectedLang == locale.code">
      <span class="flag-icon flag-icon-{{locale.code === 'de' ? 'de' : 'gb'}}"></span>
      &nbsp;<span style="text-transform: uppercase">{{ locale.code }}</span>
    </a>
  </div>
</div>
