import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  ProcessEventActionTypes,
  React,
  ReactFail,
  ReactSuccess,
  Read,
  ReadFail,
  ReadSuccess,
  SendInstantMessage,
  SendInstantMessageFail,
  SendInstantMessageSuccess,
  RefreshUploads
} from './process-event.actions';
import {ProcessEventService} from './process-event.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessEvent} from './process-event';
import {ProcessActions} from '../process';
import {ProcessService} from '../process/process.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import {ProcessEventSelectors} from './index';

@Injectable()
export class ProcessEventEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(ProcessEventActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this._svc.getAll(action.id, action.page).pipe(
        first(),
        concatMap((events: ProcessEvent[]) => {
          const followUpIds = events.map(event => event.processId).filter(id => id !== action.id);
          const woDuplicates = new Set(followUpIds);
          const ids = Array.from(woDuplicates);
          if (ids && ids.length > 0) {
            this._processSvc.getAll(ids, {debug: 'ProcessEventActionTypes.LoadAll'})
              .pipe(first()).subscribe(res => {
              this._store.dispatch(new ProcessActions.LoadAllSuccess(res))
            }, (error) => console.error(error));
          }
          // const refresh = action.page && action.page === 1;
          return [new LoadAllSuccess(events, [], false)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(ProcessEventActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this._svc.getOne(action.processId, action.eventId, action.parentId).pipe(
        first(),
        concatMap((ev: ProcessEvent) => {
          return [new LoadOneSuccess(ev)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadOneFail(err));
        }));
    })
  ));

  /**
   * Refreshes all document events inside the store.
   * Currently two applications:
   * - Renaming by current user.
   * - Renamed by other user and connected timeline websocket service.
   *   See ProcessEventService/subscribeWsToProcess rename event.
   */
  refreshUploads$ = createEffect(() => this.actions.pipe(
    ofType(ProcessEventActionTypes.RefreshUploads),
    withLatestFrom(this._store.select(ProcessEventSelectors.getUploadProcessEvents)),
    switchMap(([action, uploadEvents]: [RefreshUploads, ProcessEvent[]]) => {
      const candidates = uploadEvents.filter(ev => {
        return ev.backtrackId === action.documentId
      });

      const calls = candidates.map(c => {
        return this._svc.getOne(action.processId, c.id)
          .pipe(
            first(),
            switchMap(( ev: ProcessEvent) => of(new LoadOneSuccess(ev))),
            catchError(err => {
              console.error(err);
              return of(new LoadOneFail(err));
            }));
      });
      return forkJoin(calls).pipe(
        mergeMap(response => {
          const res = [];
          for (let i = 0; i < candidates.length; ++i) {
            res.push(response[i]);
          }
          return res;
        })
      );
    })
  ));

  sendInstantMessage$ = createEffect(() => this.actions.pipe(
    ofType(ProcessEventActionTypes.SendInstantMessage),
    switchMap((action: SendInstantMessage) => {
      return this._svc.sendInstantMessage(action.id, action.message, action.replyTo).pipe(
        first(),
        concatMap((message: ProcessEvent) => {
          return [new SendInstantMessageSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
            this._notifyService.error('INBOX.GENERAL_SEND_ERROR')
          return of(new SendInstantMessageFail(err));
        }));
    })
  ));

  readMessage$ = createEffect(() => this.actions.pipe(
    ofType(ProcessEventActionTypes.Read),
    concatMap((action: Read) => {
      return this._svc.read(action.processId, action.eventId).pipe(
        first(),
        concatMap((message: ProcessEvent) => {
          return [new ReadSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ReadFail(err));
        }));
    })
  ));

  thumbUpMessage$ = createEffect(() => this.actions.pipe(
    ofType(ProcessEventActionTypes.React),
    switchMap((action: React) => {
      return this._svc.doReaction(action.processId, action.eventId, action.reactionType).pipe(
        first(),
        concatMap((message: ProcessEvent) => {
          return [new ReactSuccess(message)];
        }),
        catchError(err => {
          console.error(err);
          return of(new ReactFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessEventService,
              private _processSvc: ProcessService,
              private _store: Store<AppState>,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}
