import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FivefLoadingIndicatorComponent} from '../../util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefMenuItemComponent} from '../../navigation/fivef-menu-item/fivef-menu-item.component';
import {FivefProcessTrashDialogComponent} from './fivef-process-trash-dialog/fivef-process-trash-dialog.component';
import {FivefProcessClosingDialogComponent} from './fivef-process-closing-dialog/fivef-process-closing-dialog.component';
import {FivefProcessDeletionDialogComponent} from './fivef-process-deletion-dialog/fivef-process-deletion-dialog.component';
import {FivefProcessReopenDialogComponent} from './fivef-process-reopen-dialog/fivef-process-reopen-dialog.component';
import {FivefProcessStateActionsComponent} from './fivef-process-state-actions.component';
import {TranslateModule} from '@ngx-translate/core';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    FivefLoadingIndicatorComponent,
    FivefMenuItemComponent,
    TranslateModule,
    FivefDialogComponent,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatMenuModule
  ],
  declarations: [
    FivefProcessStateActionsComponent,
    FivefProcessReopenDialogComponent,
    FivefProcessDeletionDialogComponent,
    FivefProcessTrashDialogComponent,
    FivefProcessClosingDialogComponent
  ],
  exports: [
    FivefProcessStateActionsComponent,
    FivefProcessReopenDialogComponent,
    FivefProcessDeletionDialogComponent,
    FivefProcessTrashDialogComponent,
    FivefProcessClosingDialogComponent
  ]
})
export class FivefProcessActionsModule {
}
