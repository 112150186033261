import {Injectable} from '@angular/core';
import {catchError, concatMap, first, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LoadMany,
  LoadManyFail,
  LoadManySuccess,
  LoadOne,
  LoadOneByHttp,
  LoadOneFail,
  LoadOneSuccess,
  Updated,
  UpdatedFail,
  UpdatedSuccess,
  UserAvatarActionTypes
} from './user-avatar.actions';
import {UserAvatarService} from './user-avatar.service';
import {UserAvatar} from './user-avatar';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {AppState} from 'app/reducers';
import {Store} from '@ngrx/store';
import * as userAvatarSelectors from './user-avatar.selectors';
import { EmailToNameAction } from '../email-to-name';
import {StringUtils} from 'app/lib/string_utils';

@Injectable()
export class UserAvatarEffects {
  /**
   * The fetching effect is split up into a initializing part with a default item and
   * the real loading, to avoid massive parallel Calls to the API.
   * The latest store is used to provide caching.
   */
  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(UserAvatarActionTypes.LoadOne),
    withLatestFrom(this._store.select(userAvatarSelectors.getUserAvatarEntities),
      (action: LoadOne, store) => {
        return {
          action: action,
          store: store
        }
      }
    ),
    concatMap((params): any[] => {
      if (params.store[params.action.id]) {
        return [];
      } else {
        const tmpAvatar = new UserAvatar(params.action.id, null, null, null, null, null);
        tmpAvatar.loading = true;
        return [new LoadOneSuccess(tmpAvatar), new LoadOneByHttp(params.action.id)];
      }
    })
  ));

  loadOneByHttp$ = createEffect(() => this.actions.pipe(
    ofType(UserAvatarActionTypes.LoadOneByHttp),
    concatMap((action: LoadOneByHttp) => {
      return this._svc.getOne(action.id).pipe(
        first(),
        map((res: UserAvatar[]) => {
            this.loadtoEmailToNameStore(res);
            return new LoadManySuccess(res);
          }
        ),
        catchError(err => of(new LoadOneFail(err))));
    })
  ));

  loadMany$ = createEffect(() => this.actions.pipe(
    ofType(UserAvatarActionTypes.LoadMany),
    concatMap((action: LoadMany) => {
      return this._svc.getMany(action.ids).pipe(
        first(),
        map((res: UserAvatar[]) => {
            this.loadtoEmailToNameStore(res);
            return new LoadManySuccess(res);
          }
        ),
        catchError(err => of(new LoadManyFail(err))));
    })
  ));

  update$ = createEffect(() => this.actions.pipe(
    ofType(UserAvatarActionTypes.Updated),
    switchMap((action: Updated) => {
      return this._svc.update(action.avatar).pipe(
        first(),
        switchMap((res: UserAvatar) => {
          this.loadtoEmailToNameStore([res]);
          return [new UpdatedSuccess(res)];
        }),
        catchError(err => {
          return of(new UpdatedFail(err))
        }));
    })
  ));

  loadtoEmailToNameStore(res: UserAvatar[]) {
    if (res && res.length > 0) {
      const participantNameArray = [];
      res.forEach((participant) => {
        if (participant.id && participant.firstName && participant.lastName) {
       const particpantName = {id: StringUtils.normalizeString(participant.id), firstName : participant.firstName , lastName: participant.lastName};
       participantNameArray.push(particpantName);
        }
      });
      if (participantNameArray && participantNameArray.length > 0) {
       this._store.dispatch(new EmailToNameAction.LoadAllSuccess(participantNameArray));
      }
    }
   }
  constructor(private actions: Actions,
    private _svc: UserAvatarService,
    private _store: Store<AppState>,
    private _translateSvc: TranslateService,
    private _notifyService: NotificationService) {
  }
}
