<button mat-icon-button
        *ngIf="!isClosed && (isAuthor || enableReplyButton)"
        [matMenuTriggerFor]="menu"

        matTooltip="{{ 'PROJECT_ROOM.ACTIONS' | translate }}">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <ul class="m-0">
    <li class="d-flex align-items-center p-2 fivef-message-panel-action-dropdown--list-menu"
        *ngIf="isAuthor"
        (click)="onEditMode.emit($event)">
      <mat-icon class="mr-2">edit</mat-icon>
      {{ 'PROJECT_ROOM.EDIT_COMMENT' | translate }}
    </li>
    <li class="d-flex align-items-center p-2 fivef-message-panel-action-dropdown--list-menu"
        *ngIf="isAuthor"
        (click)="deleteComment($event)">
      <mat-icon class="mr-2">delete</mat-icon>
      {{ 'PROJECT_ROOM.DELETE_COMMENT' | translate }}
    </li>
    <li class="d-flex align-items-center p-2 fivef-message-panel-action-dropdown--list-menu"
        *ngIf="enableReplyButton"
        (click)="onReply.emit($event)">
      <mat-icon class="mr-2">reply</mat-icon>
      {{ 'PROJECT_ROOM.REPLY_ACTION' | translate }}
    </li>
  </ul>
</mat-menu>
