import {IApiResourceBuilder} from 'app/shared/modules/api-resource/models/api.interface';
import {ParticipatingProcess, ProcessParticipant, ProcessParticipantRole, ProcessParticipation} from './process-participant';
import {ProcessParticipantType} from './process-participant.interface';

export class ProcessParticipantBuilder implements IApiResourceBuilder<ProcessParticipant> {
  constructor(private _processId: string = null) {}

  fromResponse(data): ProcessParticipant {
    const attrs = data.attributes;
    const roles = attrs.roles ? attrs.roles.map((role => new ProcessParticipantRole(role))) : [];
    const participant = new ProcessParticipant(
      data.id,
      attrs.process_id,
      ((attrs.first_name || attrs.lastName) ? (attrs.first_name + ' ' + attrs.last_name) : (null)),
      attrs.email,
      attrs.gender,
      attrs.title,
      attrs.first_name,
      attrs.last_name,
      attrs.recursive,
      attrs.updated_at
    );

    participant.createdAt = attrs.created_at;
    participant.twoFactorEnabled = attrs.two_factor_enabled;
    participant.processOrganizationMember = attrs.process_organization_member;
    if (!roles || roles && roles.length === 0) {
      participant.isAdmin = false;
      participant.isOwner = false;
      participant.isCreator = false;
    } else {
      participant.isAdmin = !!roles.find((role: ProcessParticipantRole) => role.symbol === 'admin');
      participant.isOwner = !!roles.find((role: ProcessParticipantRole) => role.symbol === 'owner');
      participant.isCreator = !!roles.find((role: ProcessParticipantRole) => role.symbol === 'creator');
    }
    participant.canDelegate = attrs.can_delegate;
    participant.expiresAt = attrs.expires_at;

    if ('internal' in attrs) {
      participant.type = attrs.internal ? ProcessParticipantType.Member : ProcessParticipantType.External;
      if (attrs.internal) {
        participant.contactId = attrs.contact_id;
        participant.roles = roles;
      }
    } else {
      // Old API v1 serialization
      // console.error('ProcessParticipantBuilder: Property internal missing.')
      switch (data.type) {
        case 'workflow_engine_process_participants':
          participant.type = ProcessParticipantType.Member;
          participant.contactId = attrs.contact_id;
          participant.roles = roles;
          return participant;
        case 'workflow_engine_process_external_participants':
          participant.type = ProcessParticipantType.External;
          return participant;
        default:
          return null;
      }
    }
    return participant;
  }

  toRequest(participant: ProcessParticipant) {
    const requestFormat = {
      data: {
        type: participant.type,
        attributes: {
          process_id: participant.processId,
          gender: participant.gender,
          title: participant.title,
          email: participant.email,
          first_name: participant.firstName,
          last_name: participant.lastName,
          roles: participant.roles.map((role: ProcessParticipantRole) => role.symbol)
        }
      }
    };
    return requestFormat;
  }
}

/**
 * Returns all process participations of the current organization.
 * {
 *   "id": "auditor@dvtx.de",
 *   "type": "process_participations",
 *   "attributes": {
 *     "first_name": "Max",
 *     "last_name": "Richter",
 *     "process_count": 57
 *   }
 * }
 */
export class ProcessParticipationBuilder implements IApiResourceBuilder<ProcessParticipation> {
  fromResponse(data): ProcessParticipation {
    const attrs = data.attributes;
    return new ProcessParticipation(
      data.id,
      attrs.first_name,
      attrs.last_name,
      attrs.process_count
    );
  }

  toRequest(_: ProcessParticipation) {
    return null;
  }
}

export class ParticipatingProcessBuilder implements IApiResourceBuilder<ParticipatingProcess> {
  fromResponse(data): ParticipatingProcess {
    const attrs = data.attributes;
    return new ParticipatingProcess(
      data.id,
      attrs.title,
      attrs.color,
      attrs.status,
      attrs.type,
      attrs.client_name,
      attrs.can_add_participants,
      attrs.path
    );
  }

  toRequest(_: ParticipatingProcess) {
    return null;
  }
}
