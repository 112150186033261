import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {FiltersContainerComponent} from './container/filters-container.component';
import {DropdownContentModule} from 'app/five-f/dropdown-content/dropdown-content.module';
import {FilterListSelectionComponent} from './components/filter-list-selection/filter-list-selection.component';
import {FilterListSelectionColoredComponent} from './components/filter-list-selection-colored/filter-list-selection-colored.component';
import {FilterListSelectionClientComponent} from './components/filter-list-selection-clients/filter-list-selection-clients.component';
import {FilterListSelectionProfilesComponent} from './components/filter-list-selection-profiles/filter-list-selection-profiles.component';
import {UserAccountModule} from 'app/shared/modules/user-account/user-account.module';
import {TooltipModule} from '../tooltip/tooltip.module';
import {FilterListSelectionOrganizationsComponent} from './components/filter-list-selection-organizations/filter-list-selection-organizations.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    DropdownContentModule,
    MatBadgeModule,
    UserAccountModule,
    TooltipModule,
    ScrollingModule,
    MatTooltipModule
  ],
  declarations: [
    FiltersContainerComponent,
    FilterListSelectionComponent,
    FilterListSelectionColoredComponent,
    FilterListSelectionClientComponent,
    FilterListSelectionProfilesComponent,
    FilterListSelectionOrganizationsComponent
  ],
  exports: [
    FiltersContainerComponent,
    FilterListSelectionComponent,
    FilterListSelectionColoredComponent,
    FilterListSelectionClientComponent,
    FilterListSelectionProfilesComponent,
    FilterListSelectionOrganizationsComponent
  ]
})
export class FiltersContainerModule {
}
