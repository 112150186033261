import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {AppState} from 'app/reducers/index';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {AngularTokenService} from 'angular-token';
import {UploadUtils} from 'app/lib/upload_utils';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'dvtx-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnDestroy {
  dialogTitle: string;
  submitButtonTitle: string;
  supportedFileTypes: string[];
  maxFileSize: number;
  uploadUrl: string;
  onSuccess: () => void;
  onItemSuccess: (_) => void;

  form: UntypedFormGroup;
  uploader: FileUploader;
  uploadedFiles: FileItem[] = [];
  uploadSuccess = false;
  isUploaded = false;
  organizationUUID: string;
  hasBaseDropZoneOver = false;

  alert;

  onDestroy: Subject<void> = new Subject<void>();

  constructor(private _dialogRef: MatDialogRef<UploadDialogComponent>,
              private _store: Store<AppState>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _fb: UntypedFormBuilder,
              private _tokenSvc: AngularTokenService,
              public _notifyService: NotificationService,
              private _translateSvc: TranslateService) {
    this.dialogTitle = data.options.dialogTitle;
    this.submitButtonTitle = data.options.submitButtonTitle;
    this.supportedFileTypes = data.options.supportedFileTypes;
    this.maxFileSize = data.options.maxFileSize;
    this.uploadUrl = data.options.uploadUrl;
    this.organizationUUID = data.options.organizationUUID;
    this.onSuccess = data.options.onSuccess;
    this.onItemSuccess = data.options.onItemSuccess;
    if (data.options.alert) {
      this.alert = data.options.alert;
    }
    this._initForm();
    this.form.markAsDirty();
    this._initUploadWorker();
  }

  private _initForm() {
    this.form = this._fb.group({
      display_name: [null, Validators.required],
      files: this._fb.array([], Validators.required)
    });
  }

  addFile(fileContext) {
    if (this._validateFile(fileContext)) {
      return;
    }

    const control = <UntypedFormArray>this.form.controls['files'];
    control.push(this._fb.group({
      title: [fileContext.file.name]
    }));
    this.form.markAsDirty();
    this.isUploaded = true;
    return fileContext;
  }

  removeFile(i: number) {
    const control = <UntypedFormArray>this.form.controls['files'];
    control.removeAt(i);
  }

  getFileValue(i: number) {
    const control = <UntypedFormArray>this.form.controls['files'];
    return control.at(i).value;
  }

  fileOverBase($event: any): void {
    this.hasBaseDropZoneOver = $event;
  }

  submit() {
    this.uploader.uploadAll();
  }

  getInvalidFiles() {
    const files = this.uploader.queue;
    const invalidFiles = [];
    files.forEach(fileItem => {
      const invalidFile = this._validateFile(fileItem);
      if (invalidFile) {
        invalidFiles.push(invalidFile);
      }
    });
    return invalidFiles;
  }

  private _validateFile(fileItem) {
    return UploadUtils.validate(fileItem, this.supportedFileTypes, this.maxFileSize);
  }

  private _initUploadWorker() {
    const requestAuthHeaders = UploadUtils.authHeaders(this._tokenSvc.currentAuthData, this.organizationUUID);
    this.uploader = new FileUploader({
      url: this.uploadUrl,
      headers: requestAuthHeaders,
      autoUpload: false,
      itemAlias: 'attachment'
    });
    this.form.controls['display_name'].updateValueAndValidity();

    this.uploader.onBeforeUploadItem = (item => item.url = this.uploadUrl);
    this.uploader.onAfterAddingFile = (fileContext) => {
      fileContext.withCredentials = false;
      this.form.controls['display_name'].updateValueAndValidity();
      this.addFile(fileContext);
    };
    this.uploader.onBuildItemForm = (item, form) => {
      item.url = this.uploadUrl;

      const index = this.uploader.queue.indexOf(item);
      const formObject = this.getFileValue(index);
      for (const key in formObject) {
        if (formObject.hasOwnProperty(key)) {
          form.append(key, formObject[key]);
        }
      }
      form.append('display_name', this.form.get('display_name').value);
    };
    this.uploader.onCancelItem = (item: FileItem, response: string, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.removeFile(index);
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: any) => {
      const index = this.uploader.queue.indexOf(item);
      this.uploader.removeFromQueue(item);
      this.removeFile(index);
      this.onItemSuccess(item);
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: any) => {
      if (status === 422 || status === 400) {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (status === 200) {
        this.uploadedFiles.push(item);
      } else if (status === 422 || status === 400) {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_SERVER_ISSUE')
      } else {
        this._notifyService.error('UPLOAD.UPLOAD_FAILURE_FILE_ISSUE')
      }
    };
    this.uploader.onCompleteAll = () => {
      if (this.uploader.queue.length === 0) {
        this.uploadSuccess = true;
        this._dialogRef.close();
        this.onSuccess();
      }
    };

    // Hack for dialog closing on backdrop click
    document.getElementsByClassName('cdk-overlay-backdrop')[0].addEventListener('click', () => {
      this._dialogRef.close(this.uploadedFiles);
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
