<div class="dvtx-api-select">
  <mat-form-field>
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedValue"
                [placeholder]="elementName"
                [disabled]="isDisabled"
                [required]="requiredField">
      <mat-option *ngFor="let item of listing$ | async" [value]="item.value">
        {{ getVisual(item) | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
