import {Injectable} from '@angular/core';
import {catchError, first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  ProcessParticipantAclActionTypes,
} from './process-participant-acl.actions';
import {ProcessParticipantAclService} from './process-participant-acl.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from 'app/shared/modules/notification/services/notification.service';
import {ProcessParticipantAcl} from './process-participant-acl';

/**
 * Actions for the process permission view/table of the project room.
 * @deprecated This view will be replaced, so this service and API is deprecated.
 */
@Injectable()
export class ProcessParticipantAclEffects {
  load$ = createEffect(() => this.actions.pipe(
    ofType(ProcessParticipantAclActionTypes.LoadAll),
    switchMap((action: LoadAll) => {
      return this._svc.getAll(action.id).pipe(
        first(),
        switchMap((acls: ProcessParticipantAcl[]) => {
          return [new LoadAllSuccess(acls)];
        }),
        catchError(err => {
          console.error(err);
          return of(new LoadAllFail(err));
        }));
    })
  ));

  constructor(private actions: Actions,
              private _svc: ProcessParticipantAclService,
              private _translateSvc: TranslateService,
              private _notifyService: NotificationService) {
  }
}




