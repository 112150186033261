import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {ProcessParticipantService} from 'app/+store/process-participant/process-participant.service';
import {ProcessParticipantActions} from 'app/+store/process-participant';
import {ProcessParticipant} from '../../../../+store/process-participant/process-participant';
import {ContactSelectors} from 'app/+store/contact';
import {PersonContactListDto} from 'app/models/contact-list-dto.model';
import {Subject} from 'rxjs/internal/Subject';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {first, takeUntil} from 'rxjs/operators';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {InfoBlockModule} from '../../../../five-f/info-block/info-block.module';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';

export enum ParticipationType {
  Participant = 'Participant',
  ExternalParticipant = 'ExternalParticipant'
}

export interface IFivefAddAndInviteComponentData {
  processId: string;
  participationType?: ParticipationType
  participant?: ProcessParticipant;
}

/**
 * Invitation dialog with first name, last name and email for new contacts not
 * in address book.
 */
@Component({
  selector: 'fivef-add-and-invite',
  standalone: true,
  imports: [
    CommonModule, MatButtonModule, MatTooltipModule, TranslateModule, MatInputModule,
    MatIconModule, FivefDialogComponent, InfoBlockModule, ReactiveFormsModule
  ],
  templateUrl: './fivef-add-and-invite.component.html',
  styleUrls: ['./fivef-add-and-invite.component.scss']
})
export class FivefAddAndInviteComponent implements OnDestroy, OnInit {
  private onDestroy = new Subject<void>();

  private processId: string;

  private participant: ProcessParticipant;
  private participantType = ParticipationType.Participant;
  private participant$ = new BehaviorSubject<ProcessParticipant>(null);

  form: UntypedFormGroup;

  dialogTitle = 'PROJECT_ROOM.ADD_AND_INVITE_TITLE';
  submitOnGoing = false;

  constructor(private dialogRef: MatDialogRef<FivefAddAndInviteComponent>,
              private store: Store<AppState>,
              private participantSvc: ProcessParticipantService,
              @Inject(MAT_DIALOG_DATA) private data: IFivefAddAndInviteComponentData,
              private _cdr: ChangeDetectorRef,
              fb: UntypedFormBuilder) {
    this.participantType = data.participationType;
    this.participant = data.participant;
    this.form = fb.group({
      email: [data.participant?.email, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required]
    });
  }

  ngOnInit() {
    const contacts$ = this.store.select(ContactSelectors.getMembersAndContactPersonsOfSelectedOrg())

    combineLatest(this.participant$, contacts$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([participant, contacts]) => {
        if (participant && contacts && contacts.length) {
          const found: PersonContactListDto = <PersonContactListDto>contacts.find(c => c.email === participant.email);
          if (found) {
            this.form.patchValue({
              first_name: found.firstName,
              last_name: found.lastName
            });
            this.form.updateValueAndValidity();
            this.form.markAsPristine();
            try {
              this._cdr.detectChanges();
            } catch (err) {
              console.error(err);
            }
          }
        }
      });

    if (this.participant) {
      this.participant$.next(this.participant);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.participant$.complete();
  }

  public addParticipant() {
    if (this.form.invalid) {
      console.error('Form is invalid. Exiting...');
      return;
    }

    this.submitOnGoing = true;
    const values = this.form.value;
    if (this.participant && this.participantType === ParticipationType.ExternalParticipant) {
      if (!this.processId) {
        console.error('Process ID is missing. Exiting...');
        return;
      }
      this.participantSvc.inviteExternalToProjectRoom(this.processId, this.participant.id, values.email, values.first_name, values.last_name)
        .pipe(first())
        .subscribe(participant => {
          this.store.dispatch(new ProcessParticipantActions.CreateSuccess(participant));
          this.submitOnGoing = false;
          this.closeDialog();

        }, err => {
          console.error(err);
          this.submitOnGoing = false;
          this.closeDialog();
        });
    } else if (this.participantType === ParticipationType.Participant) {
      this.submitOnGoing = false;
      this.closeDialog(values);
    }
  }

  public closeDialog(values = null) {
    this.dialogRef.close(values);
  }
}
