import {Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {DvtxControlValueAccessor} from '../../DvtxControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'dvtx-text-input-cva',
  templateUrl: './text-input-cva.component.html',
  styleUrls: ['./text-input-cva.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputCvaComponent),
      multi: true,
    }
  ]
})
export class TextInputCvaComponent extends DvtxControlValueAccessor implements OnInit {
  @Input()
  innerValue: string;

  @Input()
  requiredField: boolean;

  @ViewChild('textinput', {static: true})
  textinput: ElementRef;
  @Input() label: string;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {
  }

  writeValue(newVal: string): void {
    this.innerValue = newVal;
  }

  focus() {
    setTimeout(() => {
      this.textinput.nativeElement.focus();
    });
  }
}
