import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {UserAccountModule} from '../../../../shared/modules/user-account/user-account.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {FivefDatepickerComponent} from '../../input/fivef-datepicker/fivef-datepicker.component';
import {FormsModule} from '@angular/forms';

export interface ConfirmationDialogParticipantData {
  remove: boolean;
  color: 'primary' | 'warn';
  email: string;
  title: string;
  message: string;
  onSubmitAction: (recursive?: boolean, admin?: boolean, canDelegate?: boolean, expiresAt?: boolean) => void;
  onCancelAction?: () => void;
  submitButtonTitle?: string;
  cancelButtonTitle?: string;
  adminOption?: boolean;
  canDelegateOption?: boolean;
  expiresAtOption?: boolean;
  expiresAt?: Date;
  recursiveOption?: boolean;
}

@Component({
  selector: 'fivef-confirm-participant-dialog',
  host: {class: 'fivef-icon'},
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    FivefDialogComponent,
    UserAccountModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
    FivefDatepickerComponent,
    FormsModule
  ],
  templateUrl: './fivef-confirm-participant-dialog.component.html',
  styleUrls: ['./fivef-confirm-participant-dialog.component.scss']
})
export class FivefConfirmParticipantDialogComponent {
  submitButtonTitle = 'GENERAL.CONFIRM_ACTION';

  cancelButtonTitle = 'GENERAL.CANCEL_ACTION';

  email: string;

  remove: boolean = false;

  @ViewChild('recursiveCheckbox', {static: true}) recursiveCheckbox;
  recursive = true;

  @ViewChild('adminCheckbox', {static: true}) adminCheckbox;
  admin = false;

  public canDelegate = false;

  public expiresAt = null;

  public tomorrow = new Date();

  public color: 'primary' | 'warn' = 'primary';

  constructor(public dialogRef: MatDialogRef<FivefConfirmParticipantDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogParticipantData) {
    if (this.data.submitButtonTitle) {
      this.submitButtonTitle = this.data.submitButtonTitle;
    }

    if (this.data.cancelButtonTitle) {
      this.cancelButtonTitle = this.data.cancelButtonTitle;
    }

    if (this.data.email) {
      this.email = this.data.email;
    }

    if (this.data.color) {
      this.color = this.data.color;
    }

    if (this.data.remove) {
      this.remove = true;
    }

    if (this.data.expiresAt) {
      this.expiresAt = this.data.expiresAt;
    }
  }

  onSubmitClick(): void {
    this.data.onSubmitAction(this.recursive, this.admin, this.canDelegate, this.expiresAt);
    this.dialogRef.close();
  }

  onNoClick(): void {
    if (this.data.onCancelAction) {
      this.data.onCancelAction();
    }
    this.dialogRef.close();
  }

  recursiveChanged(event) {
    this.recursive = event.checked;
  }

  adminChanged(event) {
    this.admin = event.checked;
  }

  manageParticipationChange(event) {
    this.canDelegate = event.checked;
  }

  // choose date by buttons
  addOneWeek() {
    this.expiresAt = new Date();
    this.expiresAt.setDate(this.expiresAt.getDate() + 7);
  }

  addOneYear() {
    this.expiresAt = new Date();
    this.expiresAt.setFullYear(this.expiresAt.getFullYear() + 1);
  }

  addThreeMonth() {
    this.expiresAt = new Date();
    this.expiresAt.setMonth(this.expiresAt.getMonth() + 3);
  }

  resetDate() {
    this.expiresAt = null;
  }
}
