import {Injectable, OnDestroy} from '@angular/core';
import {IFivefFlatNode, IFivefTreeNode} from './fivef-tree.interface';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';
import {map, takeUntil} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {FivefTreeTreeControl} from './fivef-tree.tree-control';

/**
 * Internal tree service encapsulating the tree control and the tree model.
 */
@Injectable()
export class FivefTreeService implements OnDestroy {
  private onDestroy = new Subject<void>();

  /**
   * Internal data nodes as immutables.
   *
   * @private
   */
  private dataNodes$ = new BehaviorSubject<IFivefTreeNode[]>([]);

  /**
   * Node being intended to be selected.
   *
   * @private
   */
  private selectedNodeId$ = new BehaviorSubject<string>(null);

  /**
   * Search model.
   *
   * @private
   */
  private searchTerm$ = new BehaviorSubject<string>(null);

  /**
   * Internal expansion state for quick access in O(1).
   * @private
   */
  private expanded = {};

  /**
   * All transformed nodes as lookup list.
   *
   * @private
   */
  private flattenedNodes: IFivefFlatNode[] = [];

  /**
   * Parent lookup map for fast tree navigation, e.g. for expansion.
   *
   * @private
   */
  private parentMap: { [id: string]: IFivefFlatNode } = {};

  /**
   * CDK tree setup.
   */
  private treeControl: FivefTreeTreeControl<IFivefFlatNode, string> =
    new FivefTreeTreeControl<IFivefFlatNode, string>(getNodeLevel, isNodeExpandable, {trackBy: trackExpansion});
  public dataSource: MatTreeFlatDataSource<IFivefTreeNode, IFivefFlatNode, string>;

  /**
   *
   * @private
   */
  private initialized = false;

  /**
   * Expand the root tree on initialization.
   */
  public expandRoot = true;

  constructor() {
    this.init();
  }

  /**
   * Initializes the tree data layer and creates the tree control.
   */
  public init() {
    this.initialized = false;

    const treeFlattener =
      new MatTreeFlattener<IFivefTreeNode, IFivefFlatNode, string>(
        this.nodeTransformer,
        getNodeLevel,
        isNodeExpandable,
        getNodeChildren,
      );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, treeFlattener);

    combineLatest(this.dataNodes$, this.selectedNodeId$, this.searchTerm$)
      .pipe(takeUntil(this.onDestroy), map(([nodes, selectedNodeId, term]) => searchTree(nodes, selectedNodeId, term)))
      .subscribe(([nodes, selectedNodeId]) => {
        this.dataSource.data = nodes;

        // Expand the root node if not initialized, yet.
        if (!this.initialized && this.expandRoot) {
          const root = this.treeControl.dataNodes[0];
          if (root) {
            this.treeControl.expand(root);
            this.selectNode(root, true);
            this.initialized = true;
          }
        }

        // Selects a certain node.
        if (selectedNodeId) {
          const node = this.treeControl.dataNodes.find(n => n.id === selectedNodeId);
          if (node) {
            this.selectNode(node, true);
          }
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.dataNodes$.complete();
    this.searchTerm$.complete();
  }

  /**
   * Search node by text search inside the tree.
   * The tree is reduced to it subtree.
   *
   * @param term
   */
  public search(term: string) {
    this.searchTerm$.next(term);
  }

  /**
   * Return true if node is currently expanded, false otherwise.
   *
   * @param node
   */
  public isExpanded(node: IFivefFlatNode): boolean {
    return this.treeControl.isExpanded(node);
  }

  /**
   * Returns true if node is selected.
   * @param node
   */
  public isSelected(node: IFivefFlatNode): boolean {
    return this.treeControl.isSelected(node);
  }

  /**
   * Sets and prepares all data nodes.
   *
   * @param nodes
   */
  public set nodes(nodes: IFivefTreeNode[]) {
    this.dataNodes$.next(nodes);
  }

  /**
   * Selects a node by id.
   *
   * @param id
   */
  public selectNodeById(id: string): number {
    this.selectedNodeId$.next(id);

    const nodes = this.flattenedNodes;
    if (nodes && nodes.length && id) {
      let node = null;
      const nodeIndex = nodes.findIndex(n => {
        if (n.id === id) {
          node = n;
          return true;
        }
        return false;
      });

      this.selectNode(node, true);

      return nodeIndex
    }
  }

  /**
   * Toggles the expansion state of a node.
   *
   * @param node
   */
  public toggle(node: IFivefFlatNode) {
    this.treeControl.toggle(node);
    node.expanded = this.treeControl.isExpanded(node);
    this.expanded[node.id] = node.expanded;
  }

  public toggleSelect(node: IFivefFlatNode): boolean {
    const selected = this.treeControl.isSelected(node);
    if (selected) {
      this.treeControl.deselect(node);
    } else {
      this.treeControl.select(node);
    }
    return !selected;
  }

  /**
   * Selects the given node.
   *
   * @param node
   * @param expand
   */
  public selectNode(node: IFivefFlatNode, expand: boolean = true): void {
    if (!node) return;

    this.treeControl.select(node);

    if (expand) {
      let currentNode = node;
      this.treeControl.expand(currentNode);
      while (this.parentMap[currentNode.id]) {
        currentNode = this.parentMap[currentNode.id];
        this.treeControl.expand(currentNode);
      }
    }
  }

  /**
   * Transforms the input data into a CDK flat node.
   *
   * @param node
   * @param level
   */
  private nodeTransformer = (node: IFivefTreeNode, level: number): IFivefFlatNode => {
    const expanded = !!this.expanded[node.id];
    const flattenedNode: IFivefFlatNode = {
      id: node.id,
      title: node.title,
      icon: node.icon,
      level,
      hasChildren: node.children.length > 0,
      active: node.active,
      expanded: expanded,
      trackBy: `${node.id}|${node.active}|${expanded}`,
      data: node
    };
    return flattenedNode;
  }
}

/**
 * Returns the node's depth inside the tree (level).
 *
 * @param level
 */
function getNodeLevel({level}: IFivefFlatNode) {
  return level;
}

/**
 * Returns children of node.
 */
function getNodeChildren({children}: IFivefTreeNode) {
  return children;
}

/**
 * Returns true if inner node, false otherwise.
 *
 *
 * @param hasChildren
 */
function isNodeExpandable({hasChildren}: IFivefFlatNode) {
  return hasChildren;
}

/**
 * Helper to track expansion state even if node reference changes..
 * @param node
 */
function trackExpansion(node: IFivefFlatNode) {
  return node.id;
}

/**
 * Searches the tree by searchTerm.
 * Returns a copy if searchTerm exists with children elements in tree structure.
 * Note: The returned values are just the base nodes. Subtrees are returned inside
 * the children property.
 *
 * @param nodes
 * @param searchTerm
 * @param selectedNodeId
 */
function searchTree(nodes: IFivefTreeNode[], selectedNodeId: string, searchTerm: string): [IFivefTreeNode[], string] {
  if (searchTerm && typeof searchTerm === 'string' && searchTerm.length) {
    const term = searchTerm.toLowerCase();
    const result = [];
    nodes.forEach(node => {
      const match = searchTreeRecursive(node, term);
      if (!!match) {
        result.push(match);
      }
    });
    return [result, selectedNodeId];
  } else {
    return [nodes, selectedNodeId];
  }
}

/**
 * Recursive sub call of tree.
 *
 * @param node
 * @param term
 */
function searchTreeRecursive(node: IFivefTreeNode, term: string): IFivefTreeNode {
  const dup = Object.assign({}, node) as IFivefTreeNode;
  dup.children = [];

  if (node.children && node.children.length) {
    node.children.forEach(n => {
      const match = searchTreeRecursive(n, term);
      if (!!match) {
        dup.children.push(match);
      }
    });

    if (dup.children.length) {
      return dup;
    }
  }
  return matches(node, term) ? dup : null;
}

/**
 * String match of the node's title and term.
 * @param node
 * @param term
 */
function matches(node: IFivefTreeNode, term): boolean {
  if (!node || !node.title) {
    return false;
  }
  return node.title.trim().toLowerCase().indexOf(term) >= 0
}
