import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {OrganizationActions, OrganizationSelectors} from '../../../../../+store/organization';
import {Observable} from 'rxjs';
import {Organization} from '../../../../../models/organization.model';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../reducers';
import {MembershipSelectors} from '../../../../../+store/membership';
import {Membership} from '../../../../../models/membership.model';
import {ITabNavRoute} from 'app/five-f/organization-card/models/tab-nav-route.interface';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {OrganizationMasterDataComponent} from '../../organization-view/organization-master-data/organization-master-data.component';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import { NotificationService } from 'app/shared/modules/notification/services/notification.service';

@Component({
  selector: 'dvtx-organization-profile-frame',
  templateUrl: './profile-frame.component.html',
  styleUrls: ['./profile-frame.component.scss']
})
export class ProfileFrameComponent implements AfterViewInit, OnInit, OnDestroy {
  private onDestroy = new Subject<void>();
  public organization$: Observable<Organization>;
  private organization: Organization;
  public myMembership: Observable<Membership>;
  routes: ITabNavRoute[] = [];
  activeLink: string;
  readonly = true;
  editmode = false;

  @ViewChild('callToActionRef', { static: true }) callToActionRef: TemplateRef<any>;
  callToActionPortal: Portal<any>;

  @ViewChild('masterDataComponent', { static: true }) masterDataComponent: OrganizationMasterDataComponent;

  constructor(private _store: Store<AppState>,
              private _route: ActivatedRoute,
              private _notifyService: NotificationService,
              private _viewContainerRef: ViewContainerRef,
              private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.organization$ = this._store.select(OrganizationSelectors.getSelected);
    this.organization$.pipe(takeUntil(this.onDestroy)).subscribe(org => this.organization = org);
    this.myMembership = this._store.select(MembershipSelectors.getMyMembership);
    const orgId = this._route.snapshot.params.id;
    this.routes = [{
      title: 'ORGANIZATION.MASTER_DATA',
      route: `/organization/${orgId}/orgSettings/profile`
    }];

    this.myMembership.pipe(takeUntil(this.onDestroy)).subscribe(membership => {
      if (membership && membership.hasAdministrationRights) {
        this.readonly = false;

        this.routes = [{
          title: 'ORGANIZATION.MASTER_DATA',
          route: `/organization/${orgId}/orgSettings/profile`
        }, {
          title: 'ORGANIZATION.GLOBAL_SETTINGS',
          route: `/organization/${orgId}/orgSettings/settings`
        }];
        this._cdr.detectChanges();
      }
    });
    this.activeLink = this.routes[0].title;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(_ => {
      this.callToActionPortal = this._createPortal(this.callToActionPortal, this.callToActionRef);
    });
  }

  private _createPortal(ref: Portal<any>, context: TemplateRef<any>): Portal<any> {
    if (ref) return ref;
    return new TemplatePortal(context, this._viewContainerRef);
  }

  save() {
    if (!this.masterDataComponent
      || !this.masterDataComponent.orga
      || !this.masterDataComponent.orga.name
      || this.masterDataComponent.orga.name.length < 2) {
      this._notifyService.error('CONTACTS.ENTER_ORG_NAME')
      return;
    }

    if (!this.organization || !this.organization.id) {
      this.editmode = false;
      return;
    }

    this.masterDataComponent.save();
    this.editmode = false;
    this._cdr.detectChanges();
  }

  cancel() {
    this.masterDataComponent.refresh();
    this.editmode = false;
  }
}
