<div id="fivef-carousel--wrapper"
     class="carousel slide fivef-carousel--wrapper"
     data-ride="carousel">
  <div *ngIf="_slides?.length"
       class="carousel-inner fivef-carousel--inner">
    <div *ngFor="let slide of _slides; index as i"
         class="carousel-item fivef-carousel--item" [class.active]="i == 0">
      <div [style.background-image]="'url(' + slide.backgroundImageUrl + ')'"
           class="fivef-carousel--container">

        <!-- Content area -->
        <div class="fivef-carousel--content-area"
             [class.fivef-carousel--highlight-content-area]="highlighContentArea && slide.contentImageWidth && slide.contentImageHeight"
             [style.width]="(2 + +slide.contentImageWidth) + 'px'"
             [style.height]="(2 + +slide.contentImageHeight) + 'px'">
          <div class="fivef-carousel--content-area--hint">{{'CMS.SLIDESHOW.CONTENT_AREA' | translate }}</div>
        <!-- If a foreground/content image is set -->
          <ng-container *ngIf="slide.contentImageUrl">
            <img *ngIf="slide.contentImageUrl" src="{{slide.contentImageUrl}}"
                 [attr.alt]="slide.alt"
                 [attr.usemap]="'#fivef-carousel--image-map-'+ i"
                 width="{{slide.contentImageWidth}}" height="{{slide.contentImageHeight}}">

            <map *ngIf="slide.contentImageMapAreas?.length"
                 name="fivef-carousel--image-map-{{i}}">
              <ng-container *ngFor="let area of slide.contentImageMapAreas">
                <area shape="{{area.shape}}" coords="{{area.coords}}" [attr.alt]="area.alt" href="{{area.href}}" target="_blank">
              </ng-container>
            </map>
          </ng-container>

          <!-- If content text is set -->
          <ng-container *ngIf="slide.content"
                        class="fivef-carousel--slide-content">
            <div [froalaView]="slide.content"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <a class="carousel-control-prev fivef-carousel--carousel-control fivef-carousel--carousel-control--previous"
     href="#fivef-carousel--wrapper"
     role="button"
     data-slide="prev">
    <mat-icon>navigate_before</mat-icon>
  </a>

  <a class="carousel-control-next fivef-carousel--carousel-control fivef-carousel--carousel-control--next"
     href="#fivef-carousel--wrapper"
     role="button"
     data-slide="next">
    <mat-icon>navigate_next</mat-icon>
  </a>

  <ul *ngIf="_slides?.length"
      class="carousel-indicators fivef-carousel--carousel-indicators">
    <li *ngFor="let slide of _slides; index as i"
        data-target="#fivef-carousel--wrapper"
        [attr.data-slide-to]="i"></li>
  </ul>
</div>
