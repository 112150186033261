import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from 'app/reducers';
import {AngularTokenService} from 'angular-token';
import {FeatureSelectors} from 'app/+store/feature';
import {filter, first, map} from 'rxjs/operators';

@Injectable()
export class DatevEnabledGuard  {
  constructor(private _store: Store<AppState>,
              private _authTokenService: AngularTokenService,
              private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._store.select(FeatureSelectors.getCurrentFeatureSet).pipe(filter(f => !!f), first(), map(fs => !!(fs && fs['hasDatevSettings'])))
  }
}

