import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {TaskBuilder} from '../../../../+store/task/task.builder';
import {CollectorAttachmentActions, ProcessArtifactActions, QuickshareAttachmentActions, TaskActions} from '../../../../+store';
import {QuickshareAttachmentBuilder} from '../../../../+store/quickshare-attachment/quickshare-attachment.builder';
import {ProcessArtifactBuilder} from '../../../../+store/process-artifact/process-artifact.builder';
import {ProcessArtifactUpdateMode} from './fivef-upload.component';
import {UploadType} from '../../../../+store/process/process';
import {NotificationService} from '../../../../shared/modules/notification/services/notification.service';
import {ProcessArtifact} from '../../../../+store/process-artifact/process-artifact';
import {CollectorAttachmentBuilder} from '../../../../+store/collector-attachment/collector-attachment.builder';

/**
 * Upload helper service.
 * Attention shared between instances. All methods must be purely functional.
 */
@Injectable()
export class FivefUploadService {

  constructor(private store: Store<AppState>,
              private notifyService: NotificationService,
              private ngZone: NgZone) { }

  public handleComplete(processId: string, parentId: string, isDraft: boolean, processArtifactUpdateMode: ProcessArtifactUpdateMode, uploadType: UploadType, response): ProcessArtifact | null {
    let artifact = null;

    if (uploadType === 'task') {
      const data = JSON.parse(response);

      const builder = new TaskBuilder();
      builder.addIncludedSection(data.included);
      const taskObj = builder.fromResponse(data.data);
      this.ngZone.runOutsideAngular(() => this.store.dispatch(new TaskActions.EditSuccess(taskObj)));
      this.notifyService.success('UPLOAD.SUCCESS_DEFAULT');

    } else if (uploadType === 'quickshare') {
      const builder = new QuickshareAttachmentBuilder();
      const attachment = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new QuickshareAttachmentActions.LoadOneSuccess(attachment));

    } else if (uploadType === 'collector' &&  isDraft) {
      const builder = new CollectorAttachmentBuilder();
      const attachment = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new CollectorAttachmentActions.LoadOneSuccess(attachment));

    } else if (parentId && processArtifactUpdateMode !== ProcessArtifactUpdateMode.None) {
      const recursive = processArtifactUpdateMode === ProcessArtifactUpdateMode.Recursive;
      this.store.dispatch(new ProcessArtifactActions.LoadAll(processId, recursive, true, false));

    } else {
      // Regular case: Add uploaded artifact to store.
      const builder = new ProcessArtifactBuilder(processId);
      artifact = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new ProcessArtifactActions.LoadOneSuccess(artifact));
      this.notifyService.success('UPLOAD.SUCCESS_DEFAULT');
    }

    return artifact;
  }
}
