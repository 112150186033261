import {Component} from '@angular/core';
import {FilterListSelectionComponent} from '../filter-list-selection/filter-list-selection.component';
import {Colors} from 'app/lib/color/hsl';

@Component({
  selector: 'dvtx-filter-list-selection-colored',
  templateUrl: './filter-list-selection-colored.component.html',
  styleUrls: ['./filter-list-selection-colored.component.scss']
})
export class FilterListSelectionColoredComponent extends FilterListSelectionComponent {
  public colors = Colors;

  // some colors like priority are retrieved from global var values
  getNormalStyle(option) {
    const color = option.color.indexOf('#') > -1 || option.color.indexOf('var') > -1 ? option.color : '#' + option.color;
    return {
      'border-color': color,
      'color': color
    };
  }

  getActiveStyle(option) {
    const color = option.color.indexOf('#') > -1 || option.color.indexOf('var') > -1 ? option.color : '#' + option.color;
    return {
      'background-color': color,
      'border-color': color,
      'color': this.colors.IsDarkColor(color) ? 'white' : 'black'
    };
  }
}
